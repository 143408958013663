<div class="overflow-md-auto w-100 pb-5 pb-lg-0 cass">
  <table class="w-100 row-table ">
    <thead *ngIf="!disableHead">
      <tr>
        <th *ngFor="let td of columns" [ngClass]="{
            'canSort': td.canSort,
            'up': td.order == 'DESC',
            'down': td.order == 'ASC',
            'max-content': td.type == 'Menu'
          }" [width]="td.width || ''" [class]="td.class || ''">
          <span (click)="td.canSort ? sortBy(td.id, td) : false">{{ td.label }}</span>
        </th>
      </tr>
    </thead>

    <tbody *ngIf="!loading">
      <tr *ngIf="title" class="title">
        <td colspan="7">
          <h5 class="mb-0 aura-h2">{{ title }}</h5>
        </td>
      </tr>

      <tr *ngFor="let row of data; let rowIndex = index">
        <ng-container *ngFor="let td of row">
          <td [class]="data.class" *ngIf="{
            type: columns | _tableType:td.id,
            class: columns | _tableClass:td.id
          } as data" [ngClass]="{
              'w-40': data.type === 'Menu'
            }" (click)="data.type !== 'Menu' && onClickRowEventHandler(td)"
            [ngClass]="{'max-content': data.type == 'Menu'}">
            <ng-container [ngSwitch]="data.type">
              <ng-template *ngSwitchCase="'TextWithIcon'" [ngTemplateOutlet]="CellTextWithIcon"></ng-template>

              <ng-template *ngSwitchCase="'Text'" [ngTemplateOutlet]="CellText"></ng-template>

              <ng-template *ngSwitchCase="'Date'" [ngTemplateOutlet]="CellDate"></ng-template>

              <ng-template *ngSwitchCase="'Menu'" [ngTemplateOutlet]="CellMenu"></ng-template>

              <ng-template *ngSwitchCase="'HTML'" [ngTemplateOutlet]="CellHTML"></ng-template>
            </ng-container>

            <ng-template #CellTextWithIcon>
              <div class="bloc ps_d-flex align-items-center" [ngClass]="{
                  'cursor-pointer': td.link
                }" [routerLink]="td.link || null" (click)="td.link ? null : td.onClick?.(row)">
                <img *ngIf="td.iconImg" class="icon" [src]="td.iconImg" />

                <i *ngIf="td.iconClass" [class]="td.iconClass"></i>

                <div class="bloc-info">
                  <h6 class="mb-0 aura-h3" style="font-size: 14px;">{{ td.value }}</h6>

                  <p *ngIf="td.subtitle" auraTextTruncate>{{ td.subtitle }}</p>
                </div>
              </div>
            </ng-template>

            <ng-template #CellText>
              <div class="bloc ps_d-flex align-items-center" (click)="td.onClick ? td.onClick(row) : ''">
                <span>{{ td.value }}</span>
              </div>
            </ng-template>

            <ng-template #CellHTML>
              <div class="bloc ps_d-flex align-items-center html" (click)="td.onClick ? td.onClick(row) : ''">
                <ng-container *ngTemplateOutlet="td.html || null; context: td.context || null"></ng-container>
              </div>
            </ng-template>


            <ng-template #CellDate>
              <div class="bloc ps_d-flex align-items-center" (click)="td.onClick ? td.onClick(row) : ''">
                <span>{{ td.value | date }}</span>
              </div>
            </ng-template>

            <ng-template #CellMenu>
              <aura-dropdown position="bottom-right" [items]="getMenuItems(td.menuItems, row)">
                <div aria-expanded="false" id="dropdownMenuButton" ngbDropdownToggle type="button">
                  <i class="bi bi-three-dots-vertical" style="font-size: 1.4rem;"></i>
                </div>
              </aura-dropdown>
            </ng-template>
          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>

  <div *ngIf="(!data || data.length === 0) && !loading" class="empty_list">
    <p>{{ emptyTableMssage }}</p>
  </div>
  <div class="loader-wrap">
    <aura-loader [show]="loading || false"></aura-loader>
  </div>
</div>