import { Injectable } from '@angular/core';
import { search_config } from '../../../environments/config/search_config';
import * as engines from "src/modules/engines/models/index";
import * as engineServices from "src/modules/engines/services/index";

@Injectable({
  providedIn: 'root'
})
export class SearchEngineInitializerService 
{
  constructor() 
  {
  }

  currentUser;
  searchEngineObjects = []
  clientContexts = [];

  async getEngines(
    categoryLabel = '',
    withContext = false,
    searchExtension : boolean = false
  ) 
  {
    let engineList;

    if (searchExtension) 
    {
      engineList = search_config.engineList.filter(e => e.active == true && e.searchExtensionActive !== false);
    }
    else
    {
      engineList = search_config.engineList.filter(e => e.active == true);
    }

    // init search engines
    if (withContext == false) 
    {
      // this.searchEngineObjects = engineList.map(engine=>engine.e)
      //need to specify each engine
      this.searchEngineObjects = engineList
        .map(e => 
        {
          const handlerName = e?.handlerName;
          const serviceName = e?.serviceName;
          const instance = engineServices[serviceName].instance;

          return new engines[handlerName](instance);
        });
    }

    if (withContext == true) 
    {
      // if(withContext) await this.setContexts();
      this.searchEngineObjects = engineList
        .map(e => 
        {
          const handlerName = e?.handlerName;
          const serviceName = e?.serviceName;
          const context = this.clientContexts.find(item => item.engine == e.e).context;
          const instance = engineServices[serviceName].instance;

          return new engines[handlerName](instance, context);
        });
    }

    // group them by category
    if (categoryLabel) 
    {
      const category = search_config.categories.find(cat => cat.id == categoryLabel);

      // list of active search engines
      const engineNames = category.engines;

      // filters requested engine among supported handlers
      this.searchEngineObjects = this.searchEngineObjects
        .filter(e => engineNames.includes(e.getEngine()));

      // console.log("engines", this.searchEngineObjects)
    }

    return this.searchEngineObjects;
  }
}
