import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-right-side',
  templateUrl: './search-right-side.component.html',
  styleUrls: ['./search-right-side.component.scss']
})
export class SearchRightSideComponent implements OnInit 
{

  constructor() 
  { }

  ngOnInit(): void 
  {
  }

}
