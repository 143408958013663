import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchGcsService 
{

  constructor() 
  {
    SearchGcsService.instance = this;
  }
  static instance: SearchGcsService
}
