import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import { SearchEngine } from 'src/modules/engines/models/search-engine';
import { SearchFacet } from "src/modules/engines/models/search-facet";

// import { GoogleLoginService } from 'src/modules/user/services';
import { SearchEngineInitializerService } from './search-engine-initializer.service';
import _ from 'underscore';
import { APISce } from 'src/modules/app-common/services';
import { search_config } from '../../../environments/config/search_config';
import { AuthService } from 'src/modules/user/services';
import { MockDataService } from './mock-data-service.service';

// const BACKEND_URL = environment.BACKEND_URL;
const SEARCH_API = environment.SEARCH_API;

// btoa
function encode64(str) 
{
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) 
  {
    return String.fromCharCode(parseInt(p1, 16))
  }))
}

function decode64(str) 
{
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) 
  {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

/*
const encoder = new TextEncoder();
function encode64(utf8String)
{
  const data = encoder.encode(utf8String);
  let res = btoa(String.fromCharCode(...data));

  let test = decode64(res);
  if(test == utf8String)
    console.log("encode ok");
  else
    console.log("encode KO",res,test);

  return res;
}

function decode64(base64String)
{
  const binaryData = atob(base64String);
  let utf8String = "";
  for (let i = 0; i < binaryData.length; i++) {
    utf8String += String.fromCharCode(binaryData.charCodeAt(i));
  }

  return utf8String;
}
*/

export class SearchResponse 
{
  url: string;
  resp: any;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class SearchGenericService 
{
  searchReqs = new Set();
  searchResponses: Set<SearchResponse> = new Set();
  keyWord: string;
  categorieLabel: string;

  constructor(
    private searchEngines: SearchEngineInitializerService,
    // private googleLoginService: GoogleLoginService,
    private auth: AuthService,
    private api: APISce,
    private mockDataService: MockDataService
  ) 
  {
    SearchGenericService.instance = this;

    this.initEngines();
  }

  async initEngines() 
  {
    const engines = await this.searchEngines.getEngines();
  }

  static instance: SearchGenericService
  engines: SearchEngine[] = []
  results = []
  queryB64: string; // query string as base64

  async execSearch(
    query: string,
    engines?: SearchEngine[],
    categoryLabel?: string,
    queryParams?: {},
    groupName?: string,
    isExtension?: boolean ) 
  {
    // load search engine list (from configuration)
    if (this.engines?.length != 0)
    {
      engines = this.engines;
    }
    else
    {
      engines = await this.searchEngines.getEngines(categoryLabel, false, isExtension);
    }

    let results = null;
    let facets = [];
    let engineOptions = [];

    this.categorieLabel = categoryLabel;

    // build full query with facets string
    const facetQueryParam = queryParams['facets'];
    const fullQuery = this.buildQueryWithFacets(query, facetQueryParam);

    // for router navigation to document, keep query as base64 string, to be added to document URL
    this.queryB64 = encode64(JSON.stringify(queryParams));
    this.keyWord = query;

    // get engines list with their query options
    engineOptions = engines.map(e => e.getEngineOptions())

    // add pagination
    let start = (!!queryParams && queryParams?.["start"]) ? Number(queryParams["start"]) : 0;

    // manage user page refresh : querying previous pages before querying current one...
    // if already displaying up to 4 pages, request them as one page
    const pageSize = (start == 0) ? 25 : 25 + start

    start = 0

    if (pageSize <= 100) 
    {
      // request first 100 items
      const searchResponse = await this.searchRequest(
        fullQuery,
        engineOptions,
        engines,
        start, 
        pageSize,
        categoryLabel,
        isExtension);

      results = searchResponse.results;
      facets = searchResponse.facets;
    }
    else 
    {
      // request first 100 items
      const times = (pageSize - 100) / 25

      results = await this.searchRequest(
        fullQuery,
        engineOptions,
        engines,
        0,
        100,
        categoryLabel,
        isExtension);

      // request first next pages
      for (let i = 0; i <= times - 1; i++) 
      {
        const newStart = 100;

        const pageResponse = await this.searchRequest(
          fullQuery,
          engineOptions,
          engines,
          newStart + i * 25,
          25,
          categoryLabel,
          isExtension);

        // process results : add new results and complete existing facets
        //this.processResultPage(results,facets,pageResponse);
        for (const i in results) 
        {
          const newResponse = pageResponse.results
            .find(groupRes =>
              groupRes.getGroupName() == results[i]?.getGroupName()
            );

          results[i].getResultsArray().push(...newResponse.getResultsArray());

          results[i].mergeFacets(newResponse.getFacetsArray());
          facets.push(pageResponse.facets);
        }
      }
    }

    this.results = results;

    // display only a group
    if (groupName)
    {
      return this.results
        .find(item => item.getGroupName() == groupName) || [];
    }

    // or all groups
    return { results: results, facets: facets };
  }

  processResultPage(results, facets, pageResponse) 
  {
    for (const i in results) 
    {
      const newResponse = pageResponse.results.find(groupRes => groupRes.getGroupName() == results[i]?.getGroupName());

      results[i].getResultsArray().push(...newResponse.getResultsArray());

      results[i].mergeFacets(newResponse.getFacetsArray());
      facets.push(pageResponse.facets);
    }
  }

  async searchRequest(
    query: string,
    engineOptions,
    engines: SearchEngine[],
    start,
    pageSize,
    category?,
    isExtension?: boolean) 
  {
    // let groupNames = environment.search_config.groups;

    const body = {
      "query": query,
      "pageSize": pageSize,
      "start": start,
      "engineOptions": engineOptions,
    }

    // START: cache : not used
    let queryResponseGroups = null;

    // any results ?
    const existingResponse = Array.from(this.searchResponses)
      .find((item: { data: {} }) =>
        _.isEqual(item?.data, JSON.stringify(body)
        )
      );


    // if(this.searchReqs.has(JSON.stringify(body)) && existingResponse!=undefined){
    //   queryResponseGroups = existingResponse?.resp?.content?.groups;
    // }
    // else{


    // call search API
    const url = '/search/query'
    let queryResponse

    queryResponse = await this.api.post(url, body, null, this.httpConfig())

    this.searchReqs.add(JSON.stringify(body));

    // cache results
    this.searchResponses.add({ url: url, data: JSON.stringify(body), resp: queryResponse });

    queryResponseGroups = queryResponse["content"]?.groups;

    // }

    // get total nb of results
    let finalResults = [];

    finalResults = await this.setTotalEngineResults(engines, queryResponseGroups, isExtension);


    //
    finalResults = this.setCategories(finalResults, this.queryB64);
    const facets = await this.getFacets(engines, queryResponseGroups);

    // let mergedResults = [].concat.apply([], finalResults);;
    // let finalMergedGroups = (await Promise.all(groupNames)).map(groupName=> new SearchGroup(groupName,mergedResults.filter(res=>res.getGroupName()==groupName)));

    // this.spellResults = engines.find(e=>e.getSpellResults(queryResponseGroups)!=null)?.getSpellResults(queryResponseGroups) || null;
    // this.hasMoreResults = engines.find(e=>e.getMoreResults(queryResponseGroups)!=null && e.getMoreResults(queryResponseGroups)!=false)?.getMoreResults(queryResponseGroups) || false;

    // return finalMergedGroups;
    return { results: finalResults, facets: facets };
  }


  // TBD : should we create all facets, even if we dont select a group ??
  async getFacets(
    engines: SearchEngine[],
    queryResponseGroups,
    categoryId = '') 
  {
    const cleanFacets: SearchFacet[] = [];

    let facetsArray = (await Promise
      .all
      (
        engines
          .map(e => 
          {
            return (
              // set facets for the engine based on engine results
              e.setFacets(
                queryResponseGroups
                  .find(resultsArray =>
                    resultsArray.engine == e.getEngine()
                  )
              ));
          })
      )
      || []);

    // filter by search group
    if (categoryId != '') 
    {
      facetsArray = facetsArray.filter((f: any) => f.getCategoryId() == categoryId);
    }

    // add group facets to the whole set of facets
    facetsArray.forEach((facetGroup: any) =>
      cleanFacets.push(...facetGroup)
    );

    return cleanFacets;
  }

  async setTotalEngineResults(engines, queryResponseGroups, isExtension: boolean = false) 
  {


    const prefixUrl = '/search/' + this.queryB64;

    return await Promise.all(

      engines.map(e => 
      {
        const engResults = queryResponseGroups
          .find(resultsArray => resultsArray.engine == e.getEngine())

        const resultsArray =
          e.setResults(engResults, prefixUrl, this.keyWord, isExtension);

        return {
          engine: e.getEngine(),
          id: e.getEngine(),
          resultsArray,
          data: e?.displayProperties || null,
          columns: e?.columns || []
        }
      })
    );
  }

  // manage results per category groups and retreive their display proprties
  setCategories(finalResults, urlPrefixParamID: string = '') 
  {
    const categories = search_config.categories.sort((a, b) => (a.order - b.order));
    const groupedResults = categories.map(cat => (
      {
        id: cat.id,
        label: cat.label,
        engines: cat.engines.map(e => finalResults.find(item => item.engine == e)).filter(item => item != undefined),
        results: [],
        data: [],
        columns: []
      }
    )).filter(item => item.engines.length != 0)

    for (const i in groupedResults) 
    {
      const item = groupedResults[i];
      const formattedarray = []
      const arrayedarray = (finalResults
        .filter(engineResults =>
          (item.engines.indexOf(engineResults)) > -1)
        .map(obj => obj.resultsArray));

      for (const arrarr of arrayedarray) 
      {
        formattedarray.push(...arrarr)
      }

      item.results = formattedarray

      item.data = item.results.map(element => 
      {

        const data = element.getDisplayProperties(urlPrefixParamID)

        data[0].file = {
          id: element?.item?.id,
          mimeType: element?.item?.mimeType
        }

        return data
      }) || []

      const it = item.engines.find(e => e?.columns != undefined);

      item.columns = it.columns;
      groupedResults[i] = item;
    }

    return groupedResults;
  }

  httpConfig() 
  {
    const token = this.getToken()
    const options = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + token)
    };

    return options;
  }

  getToken() 
  {
    return this.auth.token

    // return this.googleLoginService.getToken() // ag
    return '';

  }

  // create full query string including facet options
  buildQueryWithFacets(query: string, facetQueryParam: string) 
  {
    if (facetQueryParam) 
    {
      // build query : text + /facets

      const facetsQuery = facetQueryParam
        .split(';')                 // split by facet
        .filter(facet => facet != '') // facet not empty
        .map(facet =>               // map string "label:value" to "/label=value"
        {
          const [label, value] = facet.split(':');

          return "/" + label + "=" + value;
        })
        .join(' ');  // join as string

      // text + facets query
      const newQuery = query + ' ' + facetsQuery;

      return newQuery;
    }

    // no facets => simple text query
    return query;
  }
}
