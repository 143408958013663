import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TasksListComponent } from './containers/tasks-list/tasks-list.component';
import { DocumentPageComponent } from '../documents/containers/document-page/document-page.component';
import { TaskTableComponent } from './containers/tasks-list/task-table/task-table.component';
import { TaskGridComponent } from './containers/tasks-list/task-grid/task-grid.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { SidePanelComponent } from '../documents/containers/document-page/side-panel/side-panel.component';


const routes: Routes =
  [
    {
      path: 'all',
      component: TasksListComponent,
      data: {
        title: environment.stringsFile.taskPage,
      }
    },
    // {
    //   path: "all/view/table",
    //   component: TaskTableComponent,
    //   data:{ title: environment.stringsFile.tasksTitle}
    // },
    {
      path: "",
      component: TasksComponent,
      data:{ title: environment.stringsFile.tasksTitle}
    },
    /*{
      path: "all/view/grid",
      component: TaskGridComponent
    },*/

    {
      path: "view/:type/file/:docId",
      component: SidePanelComponent
    },
    {
      path: "view/:type/:flagged/file/:docId",
      component: SidePanelComponent
    },
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule 
{ }
