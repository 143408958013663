import { NgModule } from '@angular/core';

import { TableRowExtension } from './table-row';
import { TableSolidExtension } from './table-solid';


@NgModule({
  exports: [
    TableRowExtension,
    TableSolidExtension
  ],
  imports: [
    TableRowExtension,
    TableSolidExtension
  ]
})
export class TableExtensionModule 
{ }
