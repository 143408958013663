import { stringFile } from "src/assets/strings/strings";
import { GFile } from "./gdrive-item";
import { Document } from "./gdrive-back.model";
import { environment } from "src/environments/environment";

type Tag = "flag" | "Published" | "published" | "verify";


/**
 * Represents a GDrive document, extending GFile.
 */
export class GdriveDocument extends GFile 
{
  /**
     * Indicates whether to include the rating in the display properties.
     */
  withRating: boolean;

  /**
     * Constructs a new instance of the GdriveDocument class.
     * @param item - The item data.
     * @param withRating - Indicates whether to include the rating. Default is false.
     */
  constructor(item: Document, withRating: boolean = false) 
  {
    super(item);
    this.withRating = withRating;
  }

  /**
     * Gets the display properties of the document for rendering.
     * @param urlPrefix - The URL prefix for constructing links. Default is an empty string.
     * @returns An array of display properties.
     */
  getDisplayProperties(urlPrefix: string = '', isExtension: boolean = false): any[] 
  {
    if (!this.displayProperties) 
    {
      const self: GdriveDocument = this;

      urlPrefix = urlPrefix || '/s/space/' + this.getParents();

      const tags: string = this.transform(
        (this.getTags() || '').split(',').join(', '),
        30
      );
      const name:  string = this.transform(
        (this.getName() || '').split(',').join(', '),
        25
      );

      const flag: Tag = this.getFlag();
      const state: Tag = this.getState();
      const flags: Tag[] = [flag, state];

      const flagContext: any = {
        classFlag: this.getClassFlaged(flags),
        tooltipFlag: this.getTooltipText(flags),
        countFlags: this.countFlags(flags),
        labelFlag: this.getLabelFlag(flags),
      };

      const ratingContext: any = {
        valeur: this.prop('rating_int'),
        nbVotes: this.prop('nb_rates'),
      };

      this.displayProperties = [
        {
          id: 'Flags',
          context: { flag: flagContext },
        },
        {
          id: "Tags",
          value: tags,
          longValue: this.getTags()
        },
        {
          id: 'owner',
          value: this.getCreatorName(),
        },
        {
          id: 'type',
          value: this.getTypeDocument(),
        },
        {
          id: 'lastModified',
          value: this.getModifiedTime(),
        }
      ];

      const link = environment.URL_FRONT+'workspace/main/file/' + this.getUid();

      if (!isExtension) 
      {
        this.displayProperties.splice(0, 0,
          {id: 'name',
            value: this.getName()
              .replace(/_/g, " ")
              .replace(/(\w+)[/](\w+)/, "$1/ $2"),
            iconImg: this.getIconLink()}
        );
      }
      else 
      {
        this.displayProperties.splice(0, 0,{
          id: 'name',
          value: name,
          longValue: this.getName()
            .replace(/_/g, " ")
            .replace(/(\w+)[/](\w+)/, "$1/ $2"),
          iconImg: this.getIconLink(),
          onClick: function () 
          {
            window.open( link, '_blank');
          }
        });
      }

      if (this.withRating)
      {
        if(!isExtension)
        {
          this.displayProperties.push({
            id: 'rating',
            value: (
              this.getRating() !== null && this.getRating() !== undefined
                ? this.getRating()
                : ''
            ) + (
              this.prop('nb_rates') !== null
                ? ' (' + this.prop('nb_rates') + ')'
                : ''
            )
          });
        }
        else
        {
          this.displayProperties.push({
            id: 'rating',
            context: { rating: ratingContext }
          });
        }
      }


      this.displayProperties.push({
        id: 'Menu',
        value: '',
        menuItems: [
          /*{
                        label: stringFile.stringsJson['fichierstr'],
                        callback: () => {
                            ToolbarComponent.instance.navigateTo([urlPrefix + '/file/' + self.getUid()])
                        },
                    },
                    {
                        label: stringFile.stringsJson['openspace'],
                        callback: () => {
                            ToolbarComponent.instance?.backToSpace(self.getUid())
                        },
                    },*/
          {
            label: stringFile.stringsJson['fichier'],
            callback: () => 
            {
              window.open(self.getWebViewLink(), "_blank")
            }
          }
        ]
      });
    }

    return this.displayProperties;
  }

  /**
     * Gets the session identifier (sid) of the document.
     * @returns The session identifier.
     */
  getSid(): string 
  {
    return this.prop('sid');
  }

  /**
     * Gets the type of the document.
     * @returns The type of the document.
     */
  getTypeDocument(): string 
  {
    return this.prop('doctype');
  }

  /**
     * Gets the document type (docType).
     * @returns The document type.
     */
  getDocType(): string 
  {
    return this.prop('docType');
  }

  /**
     * Gets the file identifier (file_oid or oid).
     * @returns The file identifier.
     */
  getOid(): string 
  {
    return this.prop('file_oid') || this.prop('oid');
  }

  /**
     * Gets the date of the document.
     * @returns The date of the document.
     */
  getDate(): string 
  {
    return this.prop('date');
  }

  /**
     * Gets the creation date of the document.
     * @returns The creation date of the document.
     */
  getDateCreation(): string 
  {
    return this.prop('date_creation');
  }

  /**
     * Gets the description of the document.
     * @returns The description of the document.
     */
  getDescription(): string 
  {
    return this.prop('description');
  }

  /**
     * Gets the metadata model of the document.
     * @returns The metadata model of the document.
     */
  getMetadataModel(): string 
  {
    return this.prop('metadata_model');
  }

  /**
     * Gets the chronological number of the document.
     * @returns The chronological number of the document.
     */
  getNumChrono(): string 
  {
    return this.prop('num_chrono');
  }

  /**
     * Gets the revision of the document.
     * @returns The revision of the document.
     */
  getRevision(): string 
  {
    return this.prop('revision');
  }

  /**
     * Gets the version of the document.
     * @returns The version of the document.
     */
  getVersion(): string 
  {
    return this.prop('version');
  }
}
