import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkspaceRoutingModule } from './workspace-routing.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { MaterialModule } from '../material/material.module';

import * as wsContainers from "./containers"
import * as wsComponents from "./components";
import { AuthModule } from '../auth/auth.module';

@NgModule({
  declarations: [...wsContainers.containers, ...wsComponents.components],
  entryComponents: [...wsComponents.entryComponents],
  imports: [
    AppCommonModule,
    CommonModule,
    WorkspaceRoutingModule,
    MaterialModule,
    AuthModule
  ],
  exports: [...wsContainers.containers, ...wsComponents.components]
})
export class WorkspaceModule 
{ }
