import { Injectable } from '@angular/core';
import { APISce } from 'src/modules/app-common/services';

import { Workflow, WorkflowAction, WorkflowData,WorkflowActionData } from '../models/workflow.model'
import { environment } from 'src/environments/environment';

const GPEOPLE_API = '/gpeople';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService 
{

  constructor(
    private api: APISce
  ) 
  {
  }

  /**
   *
   * @param uid document uid
   * @returns {Promise<Workflow>}
   */
  async getWorkflow(uid:string) : Promise<Workflow>
  {
    // let url = `/ged/documents/uid/${uid}/workflow`;
    const url = '/workflow/documents/uid/'+uid; // workflow V2

    try
    {
      const result : { content : WorkflowData } = await this.api.get(url);

      if(!result)
      {
        return null;
      }

      const workflow = new Workflow(result.content);

      return workflow;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  /* ####################################### ACTIONS ################################### */
  formFields = [];
  dialog;
  metaFields;
  async getActionDialog(doc, actionName: string) 
  {
    //const action = doc.action(actionId);
    this.formFields = []

    const params = {
      oid: doc.content.data.oid,
      role: doc.content.role,
      state: doc.content.state,
      action: actionName
    };

    const url = `/ged/documents/${params.oid}/role/${params.role}/state/${params.state}/actions/${params.action}/dialog`;

    try 
    {
      const data = await this.api.get(url);

      this.dialog = data["data"];
      this.metaFields = this.dialog.metadata.fields

      let i = 0;

      for (const key of Object.keys(this.metaFields)) 
      {
        if (this.metaFields[key]["edit"] == false || this.metaFields[key]["show"] == false) 
        {
          //console.log('hide', key)
        }
        else 
        {
          const f = this.metaFields[key];
          const st = JSON.stringify(f);

          const leng = st.length;
          const t = st.slice(0, leng - 1);

          let temp = t + ',"value":""}'

          temp = JSON.parse(temp);


          this.formFields.push(temp);
          i++;
        }
      }

      return this.formFields;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  async getActions(path:string,uid:string,component?)
  {
    const url = `/ged/documents/uid/${uid}?$withMeta=true&path=${path}`;

    try
    {
      const document : { content: {
          workflow : any
        }
      } = await this.api.get(url);



      const data = document?.content?.workflow;
      const actions : WorkflowAction[] = [];

      const description = document?.content?.workflow?.state_infos?.description;

      const titre = document?.content?.workflow?.state_infos?.title;
      const icon = document?.content?.workflow?.state_infos?.icon;

      if(data && data.actions)
      {
        for (const a in data.actions)
        {
          actions.push(new WorkflowAction(data.actions[a]));
        }
      }

      return {'actions':actions, 'description':description, 'title':titre, 'icon':icon}
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  async getSteps(uid:string): Promise<Workflow>
  {
    const url = '/workflow/documents/uid/'+uid;

    try
    {
      const result  = await this.api.get(url);

      return new Workflow(result?.content);
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }

  }

  //not yet

  async execAction(action : WorkflowAction,doc)
  {
    //: execute action on the fileGed
    // --> changed status of the file
    // workflow step completed
    // different actions displayed to users

    const state = doc.state?.value || doc.state;

    const params =
     {
       oid:doc.oid,
       role:action.role,
       state,
       action:action.name
     };

    // let url =`/ged/documents/${params.oid}/role/${params.role}/state/${params.state}/actions/${params.action}?$withMeta=true`;
    const url =`/workflow/documents/${params.oid}/role/${params.role}/state/${params.state}/actions/${params.action}?$withMeta=true`; // V2

    try
    {
      const response = await this.api.post(url,null,{uid:doc.uid, actionId:action.action},null,true,true);

      return response;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  async getRoles(fileGed) 
  {
    //: getRoles of users on a specific file in the GED
    const params =
    {
      oid: fileGed.data.oid,
    };

    const url = `/ged/documents/${params.oid}/workflow`;

    try 
    {
      const roles = await this.api.get(url);

      return roles;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  /* ####################################### Get User picture ################################### */

  /** get user picture from it ged oid.
   * NB. the oid is an internal id, that is different from google id
   */
  async getUserPictureUrl(userOid: string): Promise<string> 
  {
    const url = `/ged/users/${userOid}`;

    try 
    {
      const user: { content: { photos: { url }[] } } = await this.api.get(url);

      let pic;

      if (user.content) 
      {
        pic = user.content.photos[0].url || null;
      }

      return pic || null;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  async getImageByMail(mail: string, size = '600') 
  {
    if (mail) 
    {
      try 
      {
        const url = `${GPEOPLE_API}/${mail}/photo?width=${size}`;
        const headers = await this.api.httpConfig(true, false)   //this.googleLoginService.httpConfig(); // ag

        console.log(url)
        const options = { headers, responseType: 'text' }


        // let image = await this.http.get(url, options).toPromise()
        //   return image;
        // })
        const image = await this.api.get(url) || null;

        console.log(image)

        return image;
      }
      catch (exception) 
      {

        return null;
      }
    }
    else 
    {
      return null 
    }


  }


}
