<mat-toolbar class="dialog-header">
  <span mat-dialog-title>{{'profilePage' | translate}}</span>
  <span class="app-spacer"></span>
  <button mat-icon-button style="vertical-align:middle;" (click)="onCancel()">
    <mat-icon style="color: white">close</mat-icon>
</button>
</mat-toolbar>
<div mat-dialog-content class="col-12">
  <img src="{{currentPhotoUrl}}">
  <p>{{'usernameLbl' | translate}}: {{name}}</p>
  <p>{{'emailLbl' | translate}}: {{email}}</p>
</div>

<div mat-dialog-actions class="col-12 actions"  align="end">
  <button auraButton label required -button type="light" [hasShadow]="true" label="{{'closeBtn' | translate | uppercase}}" (click)="onCancel()" ></button>

 </div>
