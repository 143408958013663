<aura-modal [isOpen]="isOpen"
  [title]="title"
  (close)="closeModal()">
  <div *ngIf="currentStep == 1"
    class="steps step-1 mb-3"
    [formGroup]="Step_1">
    <aura-select inline
      label="What types of document"
      placeholder="Select document type"
      size="large"
      wrapClass="mb-5"
      [formControl]="$any(Step_1.get('doctype'))"
      [items]="doctypes"></aura-select>

    <div class="row justify-content-between gx-2">
      <div class="col-md-4 col-6">
        <button auraButton label="Use Template"

          type="light"
          [full]="true"
          [hasShadow]="true"></button>
      </div>

      <div class="col-md-4 col-6">
        <button auraButton label="Next"

          type="primary"
          [full]="true"
          (click)="nextStep()"></button>
      </div>
    </div>
  </div>

  <!-- ----------------------------------------- -->

  <div class="steps step-2 mb-3" *ngIf="currentStep == 2" [formGroup]="Step_2">
    <aura-input-text label="Name your Document"
      placeholder="Document name"
      size="large"
      wrapClass="mb-5"
      [formControl]="$any(Step_2.get('doctype'))"></aura-input-text>

    <div class="row justify-content-between gx-2">
      <div class="col-md-4 col-6">
        <button auraButton label="Back"

          type="light"
          [full]="true"
          [hasShadow]="true"
          (click)="prevStep()"></button>
      </div>

      <div class="col-md-4 col-6">
        <button auraButton label="Next"

          type="primary"
          [disabled]="Step_2.invalid"
          [full]="true"
          (click)="nextStep()"></button>
      </div>
    </div>
  </div>

    <!-- ----------------------------------------- -->

    <div class="steps step-2 mb-3" *ngIf="currentStep == 3">
      <aura-select id="department"
        inline
        label="Where do want create your Document"
        placeholder="departments"
        size="large"
        [items]="departs"></aura-select>

      <a class="link"
        href="">Add a new Deparment ?</a>

      <div class="row justify-content-between gx-2 ">
        <div class="col-md-4 col-6">
          <button auraButton [hasShadow]="true"
            [full]="true"

            label="Back"
            (click)="prevStep()"
            type="light"></button>
        </div>

        <div class="col-md-4 col-6">
          <button auraButton label="Next"

            type="primary"
            [full]="true"
            (click)="nextStep()"></button>
        </div>
      </div>
    </div>

    <!-- ----------------------------------------- -->

    <div *ngIf="currentStep == 4"
      class="steps step-2 mb-3">
      <aura-select id="department"
        inline
        label="Choose a space to create your document in"
        placeholder="Space"
        size="large"
        [items]="spaces"></aura-select>

      <a href="" class="link">Add a new Space ?</a>

      <div class="row justify-content-between gx-2 ">
        <div class="col-md-4 col-6">
          <button auraButton label="Back"

            type="light"
            [full]="true"
            [hasShadow]="true"
            (click)="prevStep()"></button>
        </div>

        <div class="col-md-4 col-6">
          <button auraButton label="Next"

            type="primary"
            [full]="true"
            (click)="nextStep()"></button>
        </div>
      </div>
    </div>

    <!-- ----------------------------------------- -->

    <div class="steps step-5 mb-3" *ngIf="currentStep == 5">
      <div class="templates row mb-5">
        <div class="col-4">
          <div class="shadow rounded">
            <div class="check-box">
              <div class="p-2 position-absolute">
                <aura-input-checkbox id
                  label
                  value></aura-input-checkbox>
              </div>

              <img class="w-100"
                src="/assets/images/document-1.png" />
            </div>

            <div class="card border-0 p-3 border-top">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="card-title mb-0 h3 primary-font">template 01</h5>
                </div>

                <div class="col-2 icon-list d-flex justify-content-end align-items-center">
                  <div class="dropdown my-auto"
                    ngbDropdown
                    placement="bottom-right">
                    <div aria-expanded="false"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                      type="button">
                      <i class="bi bi-three-dots-vertical"
                        style="font-size: 1.4rem;"></i>
                    </div>

                    <ul aria-labelledby="dropdownMenuButton"
                      class="dropdown-menu dropdown-menu-end"
                      ngbDropdownMenu>
                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Voir</a>
                      </li>

                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="shadow rounded">
            <div class="check-box">
              <div class="p-2 position-absolute">
                <aura-input-checkbox id
                  label
                  value></aura-input-checkbox>
              </div>

              <img class="w-100"
                src="/assets/images/document-2.png" />
            </div>

            <div class="card border-0 p-3 border-top">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="card-title mb-0 h3 primary-font">template 02</h5>
                </div>

                <div class="col-2 icon-list d-flex justify-content-end align-items-center">
                  <div class="dropdown my-auto"
                    ngbDropdown
                    placement="bottom-right">
                    <div aria-expanded="false"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                      type="button">
                      <i class="bi bi-three-dots-vertical"
                        style="font-size: 1.4rem;"></i>
                    </div>

                    <ul aria-labelledby="dropdownMenuButton"
                      class="dropdown-menu dropdown-menu-end"
                      ngbDropdownMenu>
                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Voir</a>
                      </li>

                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="shadow rounded">
            <div class="check-box">
              <div class="p-2 position-absolute">
                <aura-input-checkbox id
                  label
                  value></aura-input-checkbox>
              </div>

              <img class="w-100"
                src="/assets/images/document-2.png" />
            </div>

            <div class="card border-0 p-3 border-top">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="card-title mb-0 h3 primary-font">template 03</h5>
                </div>

                <div class="col-2 icon-list d-flex justify-content-end align-items-center">
                  <div class="dropdown my-auto"
                    ngbDropdown
                    placement="bottom-right">
                    <div aria-expanded="false"
                      id="dropdownMenuButton"
                      ngbDropdownToggle
                      type="button">
                      <i class="bi bi-three-dots-vertical"
                        style="font-size: 1.4rem;"></i>
                    </div>

                    <ul aria-labelledby="dropdownMenuButton"
                      class="dropdown-menu dropdown-menu-end"
                      ngbDropdownMenu>
                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Voir</a>
                      </li>

                      <li>
                        <a class="dropdown-item"
                          ngbDropdownItem>Delete</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-between gx-2 ">
        <div class="col-md-4 col-6">
          <button auraButton label="Use Template"

            type="primary"
            [full]="true"></button>
        </div>

        <div class="col-md-4 col-6">
          <button auraButton label="Next"

            type="primary"
            [full]="true"
            (click)="nextStep()"></button>
        </div>
      </div>
    </div>

    <div class="steps step-5 mb-3" *ngIf="currentStep == 6">
      <div class="form">
        <aura-select id="department"
          inline
          label="What types of document"
          placeholder="Doc type"
          size="large"
          wrapClass="mb-3"
          [items]="spaces"></aura-select>

        <aura-input-text label="Client"
          placeholder="What’s the Clients name"
          size="large"
          wrapClass="mb-3"></aura-input-text>

        <aura-input-text label="Date"
          placeholder="JJ/MM/AAAA"
          size="large"
          wrapClass="mb-3"></aura-input-text>

        <aura-input-text label="Product"
          placeholder="The product name"
          size="large"
          wrapClass="mb-3"></aura-input-text>

        <aura-input-text label="Tags"
          placeholder="Add your tags"
          size="large"
          wrapClass="mb-3"></aura-input-text>
      </div>

      <div class="row justify-content-between gx-2 ">
        <div class="col-md-4 col-6">
          <button auraButton label="Use Template"

            type="light"
            [full]="true"
            [hasShadow]="true"></button>
        </div>

        <div class="col-md-4 col-6">
          <button auraButton label="Create"

            type="primary"
            [full]="true"
            [hasShadow]="true"></button>
        </div>
      </div>
    </div>
</aura-modal>
