import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ThemeService } from '@services/theme.service';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
  selector: 'app-header',
  templateUrl: $theme + '/header.component.html',
  styleUrls: [$theme + '/header.component.scss'],
})
export class HeaderComponent implements OnInit 
{
  title: string = '';
  Mode: string = 'light';
  activeAccountMenu: boolean = false;
  activeSearch: boolean = false;
  activeNotifsMenu: boolean = false;

  constructor(
      private theme: ThemeService,
      private titleService: Title,
      private router: Router
  ) 
  {
    this.Mode = this.theme.current;

    this.router.events.subscribe(() => 
    {
      this.title = this.titleService.getTitle();
    });
  }

  toggleAccountMenu() 
  {
    this.activeAccountMenu = !this.activeAccountMenu;
    this.activeNotifsMenu = false;
    this.activeSearch = false;
  }
  toggleSearch() 
  {
    this.activeSearch = !this.activeSearch;
    this.activeAccountMenu = false;
    this.activeNotifsMenu = false;
  }
  toggleNotifsMenu() 
  {
    this.activeNotifsMenu = !this.activeNotifsMenu;
    this.activeAccountMenu = false;
    this.activeSearch = false;
  }
  ngOnInit(): void 
  {
    this.title = this.titleService.getTitle();
  }
}
