import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { GedService } from 'src/modules/app-common/services';

@Component({
  selector: 'app-folder-delete',
  templateUrl: './folder-delete.component.html',
  styleUrls: ['./folder-delete.component.scss']
})
export class FolderDeleteComponent implements OnInit 
{
 
  constructor(private gedService : GedService,private router:Router , private route: ActivatedRoute,
              public dialogRef: MatDialogRef<FolderDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data )
  { }

  ngOnInit(): void 
  {

  }

  deleteFolder()
  {
   

    this.gedService.deleteFolder(this.data.folder,this.data.folder.title)
      .then(resp=>
      {
        
        const currentUrl = this.router.url;

        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => 
        {
          this.router.navigate(['/s/space/root'])
        })
      })
    
  } 
}
