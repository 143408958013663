import {
  ChangeDetectionStrategy,
  Component,
  NgZone,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { ContactService } from "src/modules/contact/services/contact.service";
import { GcalEvent } from "../../models/event/gcal-event.model";
import { RecentDocument } from "../../models/recent-document.model";
import { UserService } from "../../services/user/user.service";
import { WorkplaceService } from "../../services/workplace.service";
import { RecentFile } from "../../models/recent-file.model";
import { Workspace } from "../../models/workspace";

const themePath = environment.themePath;

// console logs (activated by ENV variables)
import { DebugLog } from "src/modules/app-common/services/log/log.service";
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { Router } from '@angular/router';
const trace = DebugLog.build("Home");

@Component({
  selector: "app-workspace-main",
  templateUrl: `./${themePath}/workspace-main.component.html`,
  styleUrls: [`./${themePath}//workspace-main.component.scss`],
})
export class WorkspaceMainComponent implements OnInit 
{
  Listevents = [];
  workSpace: Workspace;

  eventRecents: GcalEvent[] = [];
  filesWithActions: RecentFile[] = [];
  recentDocs: RecentDocument[] = [];
  recentFiles: RecentFile[] = [];
  recentContacts = [];
  withRecentContacts: boolean = false;
  withEvents: boolean  ;
  bc = []
  constructor(
    private workspaceService: WorkplaceService,
    private router: Router,
  ) 
  {
    WorkspaceMainComponent.instant = this;
  }
 

  public static instant: WorkspaceMainComponent;

  ngOnInit(): void 
  {
    
    const _home = environment.stringsFile.homeSideNav;
    const homeCrumb = new Crumb(_home,
      (event, self) => 
      {
        this.router
          .navigateByUrl("workspace/main");
      });



    this.bc = [homeCrumb];

    this.loadWorkSpaceData();

  }

  async loadWorkSpaceData() 
  {

    this.eventRecents = [];
    this.recentContacts = [];
    this.recentFiles = [];
    this.filesWithActions = [];

    const workspace = await this.workspaceService.getWorkspaceData();

    this.withEvents = workspace.eventsEnabled();
    this.workSpace = workspace;

    if (this.withEvents) 
    {
      this.eventRecents = this.workSpace.getEvents();
    }

    this.withRecentContacts = workspace.recentContactsEnabled();
    if (this.withRecentContacts)
    {
      this.recentContacts = this.workSpace.getRecentContacts();
    }

    //let Users = this.userservice.getUser(this.recentContacts);
    this.recentFiles = this.workSpace.getRecentFiles();
    this.recentDocs = this.workSpace.getRecentDocs();
    this.filesWithActions = this.workSpace.getFilesWithActions();
   
    trace.log("withEvents", this.withEvents);
  }

  getEventsOutput(event) 
  {
    if (event?.relaunchEventsReq == true) 
    {
      this.loadWorkSpaceData();
    }
  }
}
