<h2>
    No Tasks in Queue
</h2>

<p>
    You're all caught up! <br>
Currently, there are no documents awaiting your actions. Feel free to check back later.
</p>

<svg width="360" height="349" viewBox="0 0 360 349" fill="none" version="1.1" id="svg18"
    sodipodi:docname="empty-tasks.svg" inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <path
        d="m 309.76803,174.5 c 0,74.4584 -60.2208,134.8 -134.484,134.8 -74.26328,0 -134.484001,-60.3416 -134.484001,-134.8 0,-74.45824 60.220721,-134.8 134.484001,-134.8 74.2632,0 134.484,60.34176 134.484,134.8 z"
        stroke="#ffffff" stroke-width="12" id="path1" />
    <mask id="mask0_1783_48943" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10" width="328"
        height="329">
        <ellipse cx="174.105" cy="174.501" rx="163.886" ry="164.258" fill="white" id="ellipse1" />
    </mask>
    <g mask="url(#mask0_1783_48943)" id="g8" transform="matrix(0.8,0,0,0.8,36.000029,34.9)">
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="m 154.544,113.245 v -8.596 c 0,-10.3698 8.406,-18.776 18.776,-18.776 10.369,0 18.775,8.4062 18.775,18.776 v 8.596 h 9.175 c 10.393,0 18.818,8.425 18.818,18.818 0,10.393 -8.425,18.818 -18.818,18.818 h -55.901 c -10.393,0 -18.818,-8.425 -18.818,-18.818 0,-10.393 8.425,-18.818 18.818,-18.818 z"
            fill="url(#paint0_linear_1783_48943)" id="path2" style="fill:url(#paint0_linear_1783_48943)" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M 120.086,131.564 H 71.879 c -11.0457,0 -20,8.955 -20,20 V 354.21 c 0,11.046 8.9543,20 20,20 h 205.24 c 11.046,0 20,-8.954 20,-20 V 151.564 c 0,-11.045 -8.954,-20 -20,-20 h -50.565 c 0.398,1.642 0.609,3.357 0.609,5.121 0,11.965 -9.7,21.665 -21.665,21.665 h -64.356 c -11.965,0 -21.665,-9.7 -21.665,-21.665 0,-1.764 0.211,-3.479 0.609,-5.121 z"
            fill="url(#paint1_linear_1783_48943)" id="path3" style="fill:url(#paint1_linear_1783_48943)" />
        <rect x="96.682098" y="181.98399" width="154.061" height="33.875801" rx="10" fill="#ffffff" id="rect3" />
        <rect x="110.831" y="245.797" width="116.332" height="9.4537201" rx="4.72686" fill="#ffffff" id="rect4" />
        <ellipse cx="101.399" cy="250.524" rx="4.7161598" ry="4.72686" fill="#ffffff" id="ellipse4" />
        <rect x="110.831" y="285.18799" width="80.174698" height="9.4537201" rx="4.72686" fill="#ffffff" id="rect5" />
        <ellipse cx="101.399" cy="289.914" rx="4.7161598" ry="4.72686" fill="#ffffff" id="ellipse5" />
        <ellipse cx="232.66499" cy="61.449402" rx="13.3624" ry="13.3928" fill="url(#paint2_linear_1783_48943)" id="ellipse6"
            style="fill:url(#paint2_linear_1783_48943)" />
        <ellipse cx="91.573196" cy="90.204597" rx="8.2720098" ry="8.2532701" transform="rotate(-90,91.5732,90.2046)"
            fill="url(#paint3_linear_1783_48943)" id="ellipse7" style="fill:url(#paint3_linear_1783_48943)" />
        <ellipse cx="281.005" cy="113.052" rx="8.2720098" ry="8.2532701" transform="rotate(-90,281.005,113.052)"
            fill="url(#paint4_linear_1783_48943)" id="ellipse8" style="fill:url(#paint4_linear_1783_48943)" />
    </g>
    <ellipse cx="293.50241" cy="96.34832" rx="30.497839" ry="30.567041" fill="#7cdfc4" id="ellipse9"
        style="stroke-width:0.8" />
    <path fill-rule="evenodd" clip-rule="evenodd"
        d="m 298.42403,85.67032 c -2.2296,-0.99568 -4.7208,-1.24232 -7.1016,-0.7032 -2.3808,0.53912 -4.524,1.83512 -6.1096,3.6948 -1.5856,1.85968 -2.5296,4.18328 -2.6904,6.62432 -0.16,2.44104 0.4704,4.86872 1.7992,6.92096 1.328,2.05224 3.2832,3.61912 5.5728,4.46688 2.2896,0.84784 4.7912,0.93112 7.132,0.23752 2.34,-0.69368 4.3944,-2.12704 5.856,-4.0864 1.4616,-1.95936 2.252,-4.33968 2.2536,-6.78608 v -1.15888 c 0,-0.69616 0.5624,-1.26056 1.2576,-1.26056 0.6944,0 1.2576,0.5644 1.2576,1.26056 v 1.1596 c -0.002,2.99 -0.968,5.90008 -2.7544,8.2948 -1.7856,2.3948 -4.2968,4.14672 -7.1576,4.99448 -2.8608,0.84776 -5.9184,0.74592 -8.7168,-0.29024 -2.7984,-1.03616 -5.1872,-2.95128 -6.8112,-5.45952 -1.6232,-2.50832 -2.3944,-5.47552 -2.1984,-8.45904 0.196,-2.98344 1.3496,-5.82344 3.288,-8.09632 1.9384,-2.27296 4.5576,-3.85696 7.4672,-4.51592 2.9096,-0.65888 5.9544,-0.35744 8.6792,0.85944 0.6344,0.28336 0.92,1.02856 0.6368,1.6644 -0.2824,0.63592 -1.0256,0.92168 -1.66,0.6384 z m 8.8584,-0.61336 c 0.4912,0.492 0.4912,1.29008 8e-4,1.78256 l -12.5768,12.6176 c -0.236,0.23656 -0.5552,0.36952 -0.8896,0.3696 -0.3336,8e-5 -0.6536,-0.13272 -0.8896,-0.3692 l -3.7728,-3.78144 c -0.4912,-0.49224 -0.4912,-1.2904 0,-1.78264 0.4912,-0.49224 1.288,-0.49224 1.7792,0 l 2.8832,2.88976 11.6864,-11.72536 c 0.4912,-0.49256 1.2872,-0.49296 1.7792,-8.8e-4 z"
        fill="#ffffff" id="path9" style="stroke-width:0.8" />
    <defs id="defs18">
        <linearGradient id="paint0_linear_1783_48943" x1="173.319" y1="85.873" x2="173.319" y2="150.881"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#0083F6" id="stop9" />
            <stop offset="1" stop-color="#0083F6" stop-opacity="0.4" id="stop10" />
        </linearGradient>
    <linearGradient id="paint1_linear_1783_48943" x1="70.7436" y1="149.684" x2="223.354" y2="410.148"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#0083F6" stop-opacity="0.4" id="stop11" />
        <stop offset="1" stop-color="#0083F6" id="stop12" />
    </linearGradient>
    <linearGradient id="paint2_linear_1783_48943" x1="232.665" y1="48.0566" x2="232.665" y2="74.8422"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#0083F6" id="stop13" />
        <stop offset="1" stop-color="#0083F6" stop-opacity="0" id="stop14" />
    </linearGradient>
    <linearGradient id="paint3_linear_1783_48943" x1="91.5732" y1="81.9513" x2="91.5732" y2="98.4578"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#0083F6" id="stop15" />
        <stop offset="1" stop-color="#0083F6" stop-opacity="0" id="stop16" />
    </linearGradient>
    <linearGradient id="paint4_linear_1783_48943" x1="281.005" y1="104.799" x2="281.005" y2="121.305"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#0083F6" id="stop17" />
        <stop offset="1" stop-color="#0083F6" stop-opacity="0" id="stop18" />
    </linearGradient>
</defs>
</svg>
