import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { GedService } from 'src/modules/app-common/services';
import { WorkflowService } from 'src/modules/workflow/services';
import { ActionDialog } from '../../models/action-dialog/action-dialog.model';

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})
export class ActionDialogComponent implements OnInit
{
  dialogFields=[];
  dialog;
  basicfile;

  constructor(public dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ActionDialog, private workflowService: WorkflowService)
  {
    this.workflowService.getActionDialog(this.dialogData.doc,this.dialogData.action.name)
      .then(dialog=>
      {
        this.dialog=dialog;
      });
  }

  ngOnInit(): void 
  {}

  onActionButton(formValues)
  {
    this.workflowService.execAction(this.dialogData.action,this.dialogData.doc)
      .then(data=>
      {
      })
  }
}
