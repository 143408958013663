<mat-toolbar class="infos-toolbar">

  <div class="col-8">
    <img [src]="DocInfos?.icon_link" style="width: 35px ;display: inline-block ; margin-right: 22px ; margin-left: 15px">
    <p  style="max-width: 15px ;display: inline-block ;font-weight:400 ;font-size : 18px"> <span *ngIf="DocInfos?.client?.html">{{ DocInfos?.client?.html}} </span>  <span *ngIf="DocInfos?.client__html">{{ DocInfos?.client__html}} </span><span span *ngIf="DocInfos?.name || DocInfos?.reference ||  DocInfos?.description">-</span> <span *ngIf="DocInfos?.description">{{DocInfos?.description}}</span>  <span *ngIf="DocInfos?.name">{{DocInfos?.name}}</span> <span *ngIf="!DocInfos?.name">{{DocInfos?.reference}}</span>   </p>

  </div>
  <div class="col-2 div-logo" style="text-align: right;">
    <img src="assets/logos/presencedocs.png" class="logo">
  </div>


  <div>
    <button mat-icon-button (click)="openInDrive()" matTooltip="{{'openDocDriveTT' | translate}}" matTooltipPosition="below">
      <mat-icon>open_in_new</mat-icon>
    </button>

   <!--  <button mat-icon-button (click)="toggleBar()" matTooltip="{{'fullscreenTT' | translate}}" matTooltipPosition="below" >
      <mat-icon>fullscreen</mat-icon>
    </button> -->

    <button mat-icon-button [matMenuTriggerFor]="doc_options" matTooltip="{{'deleteDocTT' | translate}}" matTooltipPosition="below">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #doc_options="matMenu" xPosition="after">

      <button mat-menu-item class="newElementBtn" *ngIf="false && inGed" (click)="detachFromGed()">
        <i class="material-icons-round optionIcon">clear</i>
        {{'detachDoc' | translate}}
      </button>
      <button mat-menu-item class="newElementBtn" (click)="deleteDoc()">
        <i class="material-icons-round optionIcon">delete</i>
        {{'deleteDocTT' | translate}}
      </button>
    </mat-menu>
  </div>


</mat-toolbar>
