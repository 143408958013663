import { EmptyComponent } from './components/empty/empty.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TasksRoutingModule } from './tasks-routing.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { MaterialModule } from '../material/material.module';
import * as taskContainers from './containers';
import { TaskTableComponent } from './containers/tasks-list/task-table/task-table.component';
import { TaskGridComponent } from './containers/tasks-list/task-grid/task-grid.component';
import { FlaggedContentComponent } from './containers/tasks-list/flagged-content/flagged-content.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { ModalApproveAllDocumentsComponent } from './pages/tasks/modal-approve-all-documents/modal-approve-all-documents.component';
import { ChipComponent } from './pages/tasks/chip/chip.component';

@NgModule({
  declarations: [...taskContainers.containers, TaskTableComponent, TaskGridComponent, FlaggedContentComponent, TasksComponent, ModalApproveAllDocumentsComponent, ChipComponent],
  imports: [
    AppCommonModule,
    CommonModule,
    TasksRoutingModule,
    MaterialModule,
    EmptyComponent
  ],
  exports: [...taskContainers.containers, TasksRoutingModule]

})
export class TasksModule 
{ }
