export class PersonGcontact
{
  jsonPerson

  iconLink: string = null;
  name: string;
  nameEN: string;
  mail: string;
  manager;
  id;
  initialLetter;
  resourceName: string;
  workPhone: any = null;
  mobilePhone: any  = null;
  googleVoicePhone: any = null;
  job: string;
  company;
  country;
  location;
  department: string;
  gcontactId;

  constructor(personGcontact)
  {
    this.jsonPerson = personGcontact;

    this.setIconLink(personGcontact);
    this.setMail(personGcontact);
    this.setName(personGcontact);
    this.setId(null);
    this.setGcontactPhones(personGcontact)
    this.setGcontactId(personGcontact);
    this.setInitial();
    this.setResourceName(personGcontact);
    this.setManager(personGcontact);
    this.setJob(personGcontact)
    this.setDept(personGcontact);
    this.setCountry(personGcontact);
    this.setCompany(personGcontact);
    this.setLocation(personGcontact);
  }

  setGcontactPhones(personGcontact?) : void 
  {
    this.setMobilePhone(personGcontact)
    this.setGoogleVoicePhone(personGcontact)
    this.setWorkPhone(personGcontact)
  }

  setInitial()
  {
    if(this.getName()!=null && this.getName()?.length!=0)
    {
      this.initialLetter = this.getName()[0];
    }
    else if(this.getMail()!=null && this.getMail()?.length!=0)
    {
      this.initialLetter = this.getMail()[0]
    }
  }

  getPhones()
  {
    const phoneList= []

    const temp =  [this.getMobilePhone(),this.getWorkPhone(),this.getGoogleVoicePhone()]

    if(temp)
    {
      for(const phone of temp)
      {
        if(phone!=undefined && phone!=null) phoneList.push(phone)
      }
    }

    return phoneList;
  }

  getMobilePhone() 
  {
    return this.mobilePhone;
  }

  getWorkPhone()
  {
    return this.workPhone;
  }

  getGoogleVoicePhone()
  {
    return this.googleVoicePhone;
  }

  setMobilePhone(personGcontact) 
  {
    const phone = (personGcontact?.phoneNumbers || []).find((phone)=>phone.type=="mobile");

    this.mobilePhone = phone? {label:phone.formattedType, value: phone.value.replace(/\s/g, '') } : null;
  }

  setGoogleVoicePhone(personGcontact) : void
  {
    const phone = (personGcontact?.phoneNumbers || []).find((phone)=>phone.type=="Google Voice");

    this.googleVoicePhone = phone?
      {label:phone.formattedType, value: phone.value.replace(/\s/g, '') }
      : null;
  }

  setWorkPhone(personGcontact): void 
  {
    const phone = (personGcontact?.phoneNumbers || []).find((phone)=>phone.type=="work");

    this.workPhone = phone?
      {label:phone.formattedType, value: phone.value.replace(/\s/g, '') }
      : null;
  }

  setIconLink(personGcontact)
  {
    const photos = personGcontact?.photos || []

    this.iconLink = (photos?.length!=0) ? personGcontact?.photos[0].url : null;
  }

  getIcon()
  {
    return this.iconLink;
  }

  setMail(personGcontact)
  {
    const emailAddresses = personGcontact?.emailAddresses || []

    this.mail = (emailAddresses?.length!=0)? emailAddresses[0].value : null;
  }

  getMail()
  {
    return this.mail;
  }

  setName(personGcontact)
  {
    const names = personGcontact?.names || [];

    this.name = (names?.length!=0) ? names[0].displayName : null;

  }

  getName()
  {
    return this.name;
  }

  setManager(personGcontact)
  {

  }

  getManager()
  {
    return this.manager || null;
  }

  setId(idValue)
  {
    this.id = idValue;
  }

  getId()
  {
    return this.id;
  }

  getWorkchat()
  {
    return null;
  }

  getWorkplaceId()
  {
    return null;

  }

  getWorkplace()
  {
    return null;
  }

  getDept()
  {
    return this.department;
  }
  getCompany()
  {
    return this.company;
  }

  getCountry()
  {
    return this.country;
  }

  getFilAriane()
  {
    let arr = [
      {
        fieldLabel: 'company',
        fieldValue:this.getCompany()
      },
      {
        fieldLabel: 'country',
        fieldValue:this.getCountry()
      },
      {
        fieldLabel: 'businesscategory',
        fieldValue:this.getDisplayLocation()
      },
      {
        fieldLabel: 'department',
        fieldValue:this.getDept()
      }
    ]

    arr = arr.filter(element=>element.fieldValue!=null)

    return arr;
  }

  setResourceName(personGcontact)
  {
    this.resourceName = personGcontact?.resourceName || null;
  }
  getResourceName()
  {
    return this.resourceName;
  }

  setJob(personGcontact)
  {
    const orga = personGcontact?.organizations || []

    this.job =(orga?.length!=0)? orga[0]?.title : null;

  }

  setDept(personGcontact)
  {
    const orga = personGcontact?.organizations || []

    this.department =(orga?.length!=0)? orga[0]?.department : null;

  }
  setCompany(personGcontact)
  {
    const orga = personGcontact?.organizations || []

    this.company =(orga?.length!=0)? orga[0]?.company : null;

  }

  setCountry(personGcontact)
  {
    const orga = personGcontact?.organizations || []

    this.country =(orga?.length!=0)? orga[0]?.country : null;
  }


  getJob()
  {
    return this.job;
  }

  getDisplayJob()
  {
    return this.getJob()
  }

  getDisplayLocation()
  {
    return this.getLocation()
  }

  getLocation()
  {
    return this.location;
  }

  setLocation(personGcontact)
  {
    const orga = personGcontact?.organizations || []

    this.location =(orga?.length!=0)? orga[0]?.location : null;
  }


  getInfoArray()
  {
    const infoArray =
    [
      {
        label: 'jobTitleLabel',
        data: this.getDisplayJob() || null,
        facetName: 'job'
      },
      {
        label: 'companyLabel',
        data: this.getCompany() || null,
        facetName: 'company'
      },
      {
        label: 'countryLabel',
        data: this.getCountry() || null,
        facetName: 'country'
      },
      {
        label: 'locationLabel',
        data: this.getDisplayLocation() || null,
        facetName: 'businesscategory'
      },
      {
        label: 'departmentLabel',
        data: this.getDept() || null,
        facetName: 'department'
      }
    ];

    return infoArray;
  }




  setGcontactId(personGcontact)
  {
    this.gcontactId = null;

    const resourceName = personGcontact?.resourceName;

    this.gcontactId = resourceName?.replace('people/', '') || null;

  }

  getGcontactId()
  {
    return this.gcontactId;
  }

  getPhoto()
  {
    return this.iconLink;
  }

  getPhone()
  {
    return null;
  }

  getDepartmentId()
  {
    return null;
  }


  getDisplayName()
  {
    return this.name;
  }

  getJobmultilingue()
  {
    return null;
  }
}


