 
 
  <div ngbDropdown class="d-inline-block">
 <aura-dropdown position="bottom-right" [items]="items" size="lg">
  <img src="/assets/images/icon-bell.svg" class="notif-icon"  id="dropdownBasic1" />
</aura-dropdown>  
   <!--  <img src="/assets/images/icon-bell.svg" class="notif-icon"  id="dropdownBasic1" ngbDropdownToggle>
 
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menu-content">
    <ng-container *ngFor="let task of displayTasks; let i=index;">
        <button ngbDropdownItem  class="btn-menu"    (click)="openDocument(task)" >
            <span> <i class="gg-play-list-check"></i> </span>
            <span class="menu-items">{{task?.getName()}}</span>
          </button>
      
    </ng-container>
    
   
        <button ngbDropdownItem  class="btn-menu"   routerLink="/t/all" *ngIf="myTasks?.length!=0">
                <span class="menu-items">{{'notif_read_more' | translate}}</span>
        </button>
  
      <button ngbDropdownItem  class="btn-menu"  *ngIf="myTasks?.length==0">
              <span class="menu-items">{{'no_tasks_left' | translate}}</span>
      </button>
  
  </div> -->
</div>

<!-- <aura-dropdown position="bottom-right" [items]="displayTasks" size="lg">
  <img src="/assets/images/icon-bell.svg" class="notif-icon"  id="dropdownBasic1" />
</aura-dropdown> -->