

  <aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="docInfos?.title" [fullscreen]="false" width="80%" height="80%">
  <!--   <div class="px-5 py-4 mb-5">
    <h1>Add doc</h1>
    </div> -->
    <div>

      <mat-progress-bar mode="indeterminate" *ngIf="done"></mat-progress-bar>
      <div class="row">
        <div class="col-md-8">
          <form #f="ngForm">
            <div>
               <mat-form-field  appearance="fill" style="display: block; text-justify: center;" >
                 <mat-label>{{docTypeLabel}}</mat-label>
                 <input matInput  name="docType" value="{{docTypeValue?.html}}" readonly="readonly">

               </mat-form-field>
             </div>

               <div>
                 <mat-form-field  appearance="fill" style="display: block; text-justify: center;"
                 *ngFor="let field of formFields; let j=index">
                   <mat-label>{{field.label}}</mat-label>
                   <div *ngIf="field.auto==null && field.key!='planned_date_publication'">
                     <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
                     required="{{field.required}}">
                   </div>

                    <div *ngIf="field.key=='planned_date_publication'">
                     <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="date"
                     required="{{field.required}}">
                   </div>
                   <!-- <div div*ngIf="field.auto!=null && field.key!='space' && field.key!='template'">
                     <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
                     required="{{field.required}}"
                     [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">

                     <mat-autocomplete #autofield="matAutocomplete" (optionSelected)="onSelectedField($event,field)" class="autoOption">
                       <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                         {{option.html}}
                       </mat-option>
                     </mat-autocomplete>

                   </div> -->

                   <div *ngIf="field.key=='space' || field.key=='template' ||field.control=='select'">
                      <mat-select [(ngModel)]="field.value.html" [(value)]="field.value.html"
                     (selectionChange)="onSelectedValue(field, field.value)" name="{{field.key}}">
                       <mat-option *ngFor="let option of field.fieldAutoList" [value]="option.html">
                         {{option.html}}
                       </mat-option>
                     </mat-select>
                   </div>

                 </mat-form-field>
               </div>

         </form>
        </div>
        <div class="col-md-4">
          <div class="rightdrawer" mode="side" position="end"
          style="width: 40% !important; box-shadow:none !important; visibility:visible !important;">
           <img *ngIf="previewLink!=null" [src]="previewLink" class="previewImg">

          </div>

        </div>
      </div>




    </div>
    <div  class="col-12 actions" align="end" >
      <button auraButton  type="light"  (click)="onCancel()"  label="{{'cancelBtn' | translate}}" class="cancelBtn"> </button>
      <button auraButton type="primary" [hasShadow]="true" label="{{'addBtn' | translate}}" (click)="onCreateDoc(f.value)" [disabled]="!f.valid || done"></button>

      <!-- <button mat-button class="submitBtn" type="submit"  (click)="onCreateDoc(f.value)"  [disabled]="!f.valid || done"
       >{{'addBtn' | translate | uppercase}}</button>      -->
    </div>

  </aura-modal>
