import { DatePipe } from "@angular/common";
import { EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatStepper } from "@angular/material/stepper";
import { environment } from "src/environments/environment";
import { WorkflowService } from "src/modules/workflow/services";
import { OneData } from "../../models/one-data/one-data.model";
import { Workflow, WorkflowRole } from "src/modules/workflow/models";

const accent = "#85BFF2";
const primary = "#3f51b5";
const themePath = environment.themePath;

@Component({
  selector: "app-doc-workflow-processus",
  templateUrl: `./${themePath}/doc-workflow-processus.component.html`,
  styleUrls: [`./${themePath}/doc-workflow-processus.component.scss`],
})
export class DocWorkflowProcessusComponent implements OnInit 
{
  @Input("path") path;
  @ViewChild("stepper") public stepper: MatStepper;
  @Input("isInfo") isInfo;

  //workflow variables
  step: number = 0;
  oneData: OneData;
  allData = [];
  meta: any;
  isLinear = true;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;

  workflow : Workflow;
  currentTask: any;
  workflowTasks: any;
  workflowState: any;

  doc;
  currentDocId = localStorage.docId;
  isRoleUser = true;

  pendingRole: WorkflowRole;

  workflowRoles : WorkflowRole[] = null;

  showWorkflow = true;
  showSteps = true;

  showState = true;
  showContent = true;

  isLoading = true;
  isLoadingState = true;
  showActions = true;

  gedDoc;
  @Input() set _gedDoc(value) 
  {
    this.gedDoc = value;
    this.getDoc();
  }

  buttonRight = document.getElementById("slideRight");
  buttonLeft = document.getElementById("slideLeft");

  //getting chip meta data

  constructor(
    // private gedService: GedService,
    private _formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    private workflowService: WorkflowService
  )
  {
    this.currentDocId = localStorage.docId;

    if (this.stepper) 
    {
      this.stepper.selectedIndex = 1;
    }

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ["", Validators.required],
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ["", Validators.required],
    });

    //this.getDoc();
  }

  ngOnInit(): void
  {
    this.getWorkflow(this.currentDocId)
      .then(
        (workflow) => 
        {
          if (this.isInfo)
          {
            this.isInfo = true;
          }
          else
          {
            this.isInfo = false;
          }
        }
      )
  }

  /** get document metadata */
  getDoc()
  {
    if (this.gedDoc != undefined)
    {
      this.doc = this.gedDoc.content.data;
      this.getBaseMeta(this.path, this.currentDocId);
    }
  }

 
  @Output() wfState: EventEmitter<any> = new EventEmitter();

  async getWorkflow(currentDocId)
  {
    const workflow : Workflow = await this.workflowService.getWorkflow(currentDocId);

    //on data retrieval, remove the progression spinner
    this.workflowRoles = workflow?.getRoles();

    this.workflowTasks = workflow?.getTasks(false);

    this.workflowState = workflow?.getCurrentState();
    // console.log("showWorkflow", this.workflow)

    const workflowOptions = workflow?.getOptions();

    this.showSteps = workflowOptions.steps == undefined ? true : workflowOptions.steps;

    // console.log("showWorkflow", this.workflow)

    // this.wfState.emit(this.workflowRoles);
    this.wfState.emit(workflow);

    //if workflow is empty don't show card
    this.showWorkflow = workflow.hasWorkflow();

    if(this.showWorkflow)
    {
      if (this.workflowTasks?.length > 0)
      {
        this.currentTask = workflow.getCurrentTask();
        this.pendingRole = workflow.getPendingRole();
      }
    }

    this.isLoading = false;

    return this.workflow = workflow;
  }

  editWorkflow(values) 
  {
    this.showContent = !this.showContent;
  }

  getBaseMeta(path, currentDocId)
  {
    this.isLoadingState = false;
    const document = this.gedDoc;

    //GET METADATA JSON
    this.meta = document?.["content"].metadata.fields;

    //GET METADATA JSON
    for (const key of Object.keys(this.meta)) 
    {
      //SELECT METADATA WITH TAG = META
      if (this.meta[key]["x-tags"]) 
      {
        if (
          this.meta[key]["x-tags"] == "base_meta" ||
          this.meta[key]["x-tags"].includes("base_meta")
        ) 
        {
          const label = this.meta[key].label;

          this.oneData = new OneData(key, label);

          if (this.doc[key] instanceof Object == true) 
          {
            this.oneData.val = this.doc[key].html;
          }
          else 
          {
            this.oneData.val = this.doc[key];
          }
          if (this.meta[key].type == "string") 
          {
            this.oneData.type = "text";
          }
          if (this.meta[key].type == "integer") 
          {
            this.oneData.type = "number";
          }

          if (this.meta[key].type == "date") 
          {
            this.oneData.type = "date";

            this.oneData.val = this.datepipe.transform(
              this.doc[key],
              "dd/MM/yyyy"
            );
          }
          if (this.meta[key]["x-ui"]) 
          {
            this.oneData.bgc = this.meta[key]["x-ui"]["color"];
            if (this.meta[key]["x-ui"]["class"] == "md-accent") 
            {
              this.oneData.bgc = accent;
            }
            if (this.meta[key]["x-ui"]["class"] == "md-primary") 
            {
              this.oneData.bgc = primary;
            }
            this.oneData.color = "white";
          }
          this.allData.push(this.oneData);
        }
      }
    }

    if (this.allData.length == 0) 
    {
      this.showState = false;
    }
    else 
    {
      this.showState = true;
    }
  }

  doSomething({showActions, isLoading=false}) 
  {
    this.showActions = showActions;
    if(isLoading)
    {
      this.isLoading = true;
    }
  }
}
