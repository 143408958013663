
<ng-container *ngIf="!isLoading && showWorkflow && showSteps">

  <!-- steps (roles) line -->

<app-wokrflow [workflowRoles]="workflowRoles" [isInfo]="isInfo">

</app-wokrflow>

<!--   <section id="containerP" class="steps-line  justify-content-between gx-0 "
    [ngClass]="{'containerP': workflowRoles?.length >3 && !isInfo}"
    >
      <ng-container  *ngFor="let role of workflowRoles; let i=index ;let j=last" id="content"
        [ngClass]="{'content':  workflowRoles?.length >3 && !isInfo}">

        <ng-container  class="contentT">
            <div class="col position-relative number-step item-content"
              *ngIf="role.isPending && !role.isCurrent"
              [title]="role.data.description"
              [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm">{{i+1}}</span>
            </div>

            <div class="col position-relative number-step item-content"
              *ngIf="role.isDone"
                [title]="role.data.description"
                [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm"
                [ngClass]="{'active': true}">{{i+1}}</span>
            </div>

            <div class="col position-relative number-step item-content"
              *ngIf="role.isCurrent"
                [title]="role.data.description"
                [attr.data-label]="role.name">
              <span class="d-flex justify-content-center align-items-center shadow-sm"
                [ngClass]="{'active': true}">{{i+1}}</span>
            </div>

          </ng-container>

        <ng-container *ngIf="!j" class="lineProgress">
            <span class="lined"
            [ngClass]="{
              'active':  role.isDone,
              'linedplus': workflowRoles?.length >3 ,
              'linednormal': workflowRoles?.length < 4}"></span>
        </ng-container>

      </ng-container>
  </section>
 -->
  <!-- workflow sliding buttons-->
<!--   <button id="slideRight" *ngIf="workflowRoles?.length >3 && !isInfo"
      type="button" (click)="rightButton()"
      ><i class="gg-chevron-right"></i></button>

  <button id="slideLeft" *ngIf="workflowRoles?.length >3 && !isInfo"
      type="button" (click)="LeftButton()"><i class="gg-chevron-left"></i></button> -->

</ng-container>

<div class="row justify-content-center spinnerLoading" *ngIf="isLoading">
  <mat-spinner ></mat-spinner>
</div>

<section *ngIf="showActions" class="showActions" [ngClass]="{'showSteps': showSteps}">
  <app-doc-actions
    [path]="path"
    (emitState)="doSomething($event)"
    [_gedDoc]="gedDoc"></app-doc-actions>
</section>
