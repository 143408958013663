import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { SearchGcontributorService } from "../services/search-gcontributor.service";
import { GcontributorPerson } from "./gcontributor-person";
import { environment } from "src/environments/environment";

const DOTS_ENABLED = environment.DOTS_ENABLED

/**
 * Class representing a search engine for GContributors.
 */
export class SearchGcontributorEngine extends SearchEngine 
{

  // Properties for search engine
  searchGcontributorService: SearchGcontributorService;
  facets = [];

  /**
     * Constructor for SearchGcontributorEngine class.
     * @param searchGcontributorService - The service for searching GContributors.
     * @param clientContexts - The client contexts.
     */
  constructor(searchGcontributorService?, clientContexts?) 
  {
    super();
    this.searchGcontributorService = searchGcontributorService;
  }

  /**
     * Sets the engine type to "gcontributor".
     */
  setEngine() 
  {
    this.engine = "gcontrib";
  }

  /**
     * Sets the results based on the engine response and a keyword.
     * @param engineResponse - The response from the search engine.
     * @param prefixUrl - The prefix URL.
     * @param keyWord - The keyword for filtering results.
     * @returns An array of GcontributorPerson objects representing the search results.
     */
  setResults(engineResponse, prefixUrl, keyWord: string = "") 
  {
    console.log(engineResponse)
    const jsonResults = engineResponse?.items || {};

    for (const key in jsonResults) 
    {
      if (jsonResults.hasOwnProperty(key)) 
      {
        const item = jsonResults[key];
        const mappedObject = new GcontributorPerson(item, keyWord);

        this.results.push(mappedObject);
      }
        
              
    }
          
        
    //this.results = jsonResults.map(item => new GcontributorPerson(item, keyWord));
    this.setDisplayProperties(this.results);
    this.getColumns();

    return this.results;
  }

  // Property to store display properties
  displayProperties;

  /**
     * Sets the display properties based on the search results.
     * @param results - An array of GcontributorPerson objects representing the search results.
     */
  setDisplayProperties(results) 
  {
    this.displayProperties = results.map(item => item.getDisplayProperties());
  }

  // Property to store columns for the table
  columns: TableColumn[];

  /**
     * Gets the columns for the table.
     * @returns An array of TableColumn objects.
     */
  getColumns() 
  {
    this.columns = [
      {
        id: 'name',
        type: 'TextWithIcon'
      },
      {
        id: 'details',
        type: 'Text',
        label: 'details'
      },
      {
        id: 'mail',
        type: 'Text',
        label: 'Mail'
      },

    ];

    if (DOTS_ENABLED) {
      this.columns.push(
      {
        id: 'Menu',
        type: 'Menu',
        label: 'Actions',
      })
    }
    return this.columns;
  }

  /**
     * Gets the display properties.
     * @returns Display properties.
     */
  getDisplayProperties() 
  {
    return this.displayProperties;
  }

  /**
     * Gets the search results.
     * @returns An array of GcontributorPerson objects representing the search results.
     */
  getResults() 
  {
    return this.results;
  }

  /**
     * Sets the engine options.
     */
  setEngineOptions() 
  {
    this.engineOptions = { engine: this.getEngine() };
  }

  /**
     * Sets the facets based on the engine response.
     * @param engineResponse - The response from the search engine.
     * @returns An array of facets.
     */
  setFacets(engineResponse) 
  {
    const jsonFacets = engineResponse;
    // Process jsonFacets and set this.facets accordingly

    return this.facets;
  }

  /**
     * Gets the facets.
     * @returns An array of facets.
     */
  getFacets() 
  {
    return this.facets;
  }
}
