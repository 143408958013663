<app-sidenav title="{{'tasksString' | translate}}">
    <div class="" >
        <div class="row pb-3">
            <div class="col icons d-flex justify-content-end gap-2 view-icons">
                <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                    (click)="toggleView('table')">
                <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                    (click)="toggleView('grid')">
            </div>
        </div>

        <ng-container *ngIf="!isLoading">
            <div *ngIf="activeTab === 'grid'" class="row grid">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="box">
                      <!-- <aura-title>{{ 'My tasks' | translate }}</aura-title>

                        <aura-list [globalMenuItems]="globalMenu"
                          verticalAlign="center"
                          [hasMenu]="true"
                          [data]="TasksListe"></aura-list> -->

                        <aura-list [hasGlobalMenu]="true" [globalMenuItems]="globalMenu"
                            verticalAlign="center"
                            [hasMenu]="true"
                          [data]="TasksListe"></aura-list>

                        <p  *ngIf="TasksListe.length == 0">{{ 'no_tasks_left' | translate }}</p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6">
                    <div class="box">
                      <!-- <aura-title>{{ 'Team tasks' | translate }}</aura-title>

                        <aura-list [globalMenuItems]="globalMenu"
                          verticalAlign="center"
                          [hasMenu]="true"
                          [data]="TasksTeam"></aura-list> -->

                        <aura-list
                            [hasGlobalMenu]="true" [globalMenuItems]="globalMenu"
                            verticalAlign="center"
                            [hasMenu]="true"
                          [data]="TasksTeam"></aura-list>

                        <p  *ngIf="TasksTeam.length == 0">{{ 'no_tasks_left' | translate }}</p>
                    </div>
                </div>
            </div>

            <div *ngIf="activeTab === 'table'" class="row list hide">
                <div class="col">
                    <aura-table-row
                        title="{{'My tasks' | translate}}"
                        [disableHead]="taskTableData.length ==0"
                        [columns]="columns"
                        [data]="taskTableData" ></aura-table-row>
                    <p  *ngIf="taskTableData.length ==0">{{'no_tasks_left' | translate}}</p>

                <aura-table-row
                    [disableHead]="true && teamTaskTableData.length ==0"
                    title="{{'Team tasks' | translate}}"
                    [columns]="columns"
                    [data]="teamTaskTableData"></aura-table-row>

                    <p  *ngIf="teamTaskTableData.length ==0">{{'no_tasks_left' | translate}}</p>

                <!--   <app-rowtable [disableHead]="true" title="To Edit" [columns]="columns" [data]="data"></app-rowtable>
                    <app-rowtable [disableHead]="true" title="Done" [columns]="columns" [data]="data"></app-rowtable>
                    <button class="btn gray-500 w-100 border bg-transparent border-2 border-dashed">+ Add New row</button> -->
                </div>
            </div>
        </ng-container>
    </div>
</app-sidenav>
