import { environment } from "src/environments/environment";
import { DocumentService } from "src/modules/documents/services";
import { GdriveItem } from "src/modules/gdrive/models/gdrive-item";
const defaultPictureDoc = environment.defaultPictureDesign;

//============= TASK DATA RECEIVED FROM SERVER ===========

export type TTaskId     = string;
export type TResourceId =  string;
export type TWorkflowTaskState = "pending" | "done" | "cancelled";
export type TWorkflowTaskApproval = "" | "accepted" | "denied" | "cancelled" | "cancelled_accepted" | "cancelled_denied";
export type TWorkflowTaskAcceptStatus = "ok" | "denied" | "pending" | "error" | "already_done";
export interface ITaskUser
{
    html: string;
    value?: number|string;
    email?: string;
}

export interface ITaskDate
{
    value:string;
    time: number;
}

export interface IUserTaskData_old
    {
        /** user task oid */
        id?: TTaskId;

        /** Action id that created the task */
        action: string;

        /** date creation of task */
        date_creation: ITaskDate;

        /** date execution of task
        * @type {ITaskDate}
        */
        date_closing;

        /** user task state */
        task_state: TWorkflowTaskState;

        // ========= WORKFLOW =========

        /** workflow id */
        workflow: string;

        /** Action state when task created */
        state: string;

        /** resource oid */
        resource_id: TResourceId;

        /** resource uid */
        resource_uid: string;

        /** resource name */
        resource_name: string;

        /** User role */
        role: string;

        /** user sending the task */
        from_user: ITaskUser;

        /** destination role */
        to_role: string;

        /** destination user */
        to_user: ITaskUser| null;

        /** destination state */
        to_state: string;


        /** user comment when creating this task */
        comment?: string;
    }

export type TTaskNotif = {
      sendNotif:boolean,
      subject: string,
      body:string
  }

export type TFileProperties = {
    id?: string;
    properties: {
        tags?: string;
        doctype?: string;
        responsible?: string;
        owner?: string;
        iconLink?: string;
    }
  mimeType?: string;
    modifiedTime?: string;
    name?: string;
    appProperties:{
      rating?:string;
    }
    iconLink?: string;
}

export type TTaskResource = {
      resource_id?: TResourceId;
      resource_uid?: string;
      resource_name?: string;
      version?,
      revision?,
      file?: TFileProperties;
      iconLink?: string;
  }

export type TWorkflowTaskRoleType = "user" | "group" | "space member";
  type IRoleDescType =
          'all'               |

          'user'        |
          'manager'           |

          'function'          |
          'group_email'       |
          'group'       |

          'document creator'  |
          'resource_field'    |

          'space admin'       |
          'space editor'      |
          'space member'      |
          'space reader'      |
          'space commentator' |
          'custom';

export type TTaskRole = {
    id:string,
    actor:string,
    is_a: TWorkflowTaskRoleType,
    email?:string,
    type:IRoleDescType
    space?: {uid?:string, oid?:number,name:string}
  }

export type TTaskRoleFrom = {
      id:string,
      actor:string,
      is_a: TWorkflowTaskRoleType,
      email?:string,
      type:IRoleDescType
  }

export interface IUserTaskData
  {
      /** user task oid */
      id?: TTaskId;

      /** date creation of task */
      date_creation: ITaskDate;

      /** date execution of task */
      date_closing: ITaskDate;

      /** user closing the task, either by executing it or by cancelling it
       * (can be different than target user) */
      closed_by: ITaskUser;

      /** user task state */
      task_state: TWorkflowTaskState;

      // ========= WORKFLOW =========

      /** Action id that created the task */
      action: string;

      /** workflow id */
      workflow: string;

      /** resource oid */
      resource : TTaskResource

      /** state of resource after task is created  */
      state: string;

      /** Targeted user role */
      role: TTaskRole;

      /** user creating the task */
      from_user: ITaskUser;

      /** destination role */
      from_role: TTaskRoleFrom;

      /** state of resource before task is created  */
      from_state: string;

      /** notification */
      notif: TTaskNotif;

      /** user form data (ex.comment) when creating this task */
      data?: any;
  }
export class GedFile 
{
  data : TFileProperties;
  index : number;

  constructor(data : TFileProperties,index=0)
  {
    this.data = data;
    this.index = index;
  }

  getName() : string
  {
    return this.data?.name;
  }

  getIcone(): string 
  {
    const iconLink = DocumentService.instance.getIconbyMimeType(this.getIconLink());

    if (iconLink) 
    {
      return iconLink;
    }
    else 
    {
      return this.getIconLink();
    }
  }

  getIconLink(): string
  {
    return this.data.iconLink;

  }

  getTags() 
  {
    return this.data.properties?.tags;
  }

  getDocType() 
  {
    return this.data.properties?.doctype;
  }

  getModifiedTime() 
  {
    return this.data.modifiedTime;
  }

  getRating() 
  {
    return this.data.appProperties?.rating;
  }

  getUid()
  {
    return this.data?.id;
  }

    
}

export class GedTask
{
  data : IUserTaskData;
  index : number;
  revisionDesc;
  revId;

  displayProperties;
  source?:string;
  className?:string;

  constructor(data : IUserTaskData,index=0, source?: string, className?:string )
  {
    this.data = data;
    this.index = index;
    this.source = source;
    this.className = className;

    this.revId = this.getRevisionId();
  }

  iconUrl() 
  {
    return defaultPictureDoc;
  }


  getIcone(): string 
  {
    const iconLink = DocumentService.instance.getIconbyMimeType(this.getIconLink());

    if (iconLink) 
    {
      return iconLink;
    }
    else 
    {
      return this.getIconLink();
    }
  }

  getIconLink(): string
  {
    return this.data.resource.file?.mimeType ?? this.data.resource.file?.properties?.iconLink ?? this.data.resource.file?.iconLink;

  }

  getFileName(path: string)
  {
    let pathSplit = [path];

    pathSplit = path?.split('/');
    let i = 0;

    while (i < pathSplit?.length)
    {
      i++;
    }

    let name = null;

    if (pathSplit != undefined)
    {
      name = pathSplit[i - 1];
    }

    return name;
  }

  getName() : string
  {
    return this.data.resource.resource_name;
  }

  getSource() : string
  {
    return this.data.resource.resource_name;
  }
  // tbd : remove [] ?
  getOwner() : any
  {
    return this.data.resource.file?.properties?.owner;
  }

  getType()
  {
    return "approval"; // this.data.type;
  }

  getState()
  {
    return this.data.state;
  }

  getUid()
  {
    return this.data.resource.resource_uid;
  }

  getPath()
  {
    return this.data.resource.resource_uid;
  }

  // date type?
  getDate() : Date
  {
    return new Date(this.data.date_creation.value);
  }

  /** return version.revision id (ex. 2.1) */
  getRevisionId() 
  {
    const {version,revision} = this.data.resource;

    return ""+version+"."+revision;
  }

  setRevisionDesc(revisionDesc) 
  {
    this.revisionDesc = revisionDesc;
  }

  getTags() 
  {
    return this.data.resource.file?.properties?.tags;
  }

  getDocType() 
  {
    return this.data.resource.file?.properties?.doctype;
  }

  getModifiedTime() 
  {
    return this.data.resource.file?.modifiedTime;
  }

  getRating() 
  {
    return this.data.resource.file?.appProperties?.rating;
  }
}
