<div class="left-section">
  <div *ngFor="let task of tasks">
    <div class="user-profile">
      <div class="profile-image">
        <app-doc-avator-creator [name]="task.userName"></app-doc-avator-creator>

      <!-- <img [src]="'h'" alt="User Image">-->
      </div>
      <div class="user-details">
        <div class="user-name">{{ task.userName }}</div>
        <div class="user-role">
          {{ task?.actionDesc }}
          <span class="no-wrap">{{ task.dateTask }}</span>
        </div>
      </div>
    </div>
    <hr class="divider">
  </div>
</div>