import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class OnlyLoggedGuard implements CanActivate 
{
  constructor(private auth: AuthService,
    private router: Router
  ) 
  { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    return this.isLogged(route.url);
  }

  isLogged(url): boolean 
  {
    // we can implement here other logic related to non authenticated users

    const state = this.auth.isConnected()

    if (!state)
    {
      this.router.navigate(['in', 'login'])
    }

    return state
  }

}
