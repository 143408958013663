import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { WorkflowService } from "src/modules/workflow/services";

import {
  WorkflowAction,
  InfosStepTask
} from "src/modules/workflow/models";

const primary = "#245E99";
const white = "#FFFFFF";
const black = "#212121";
const themePath = environment.themePath;

@Component({
  selector: "app-doc-actions",
  templateUrl: `./${themePath}/doc-actions.component.html`,
  styleUrls: [`./${themePath}/doc-actions.component.scss`],
})
export class DocActionsComponent implements OnInit, OnChanges
{
  @Input("path") path;
  @Input() set _gedDoc(value) 
  {
    this.gedDoc = value;
    this.getDoc();
  }
  
  @Output() emitState: EventEmitter<any> = new EventEmitter();
  @Output() infoActions: EventEmitter<InfosStepTask> = new EventEmitter();

  currentDocId: string ;
  pending: any;
  showContent = true;
  actions: WorkflowAction[] = [];
  action: any;
  isLoading = true;
  isPopup = false;
  gedDoc;
  doc;
  showActions = true;

  constructor(
    private workflowService: WorkflowService,
    private router: Router,
    private route: ActivatedRoute
  ) 
  {
  }

  /**
   * Initialise le composant
   */
  ngOnInit(): void 
  {
    this.route.params.subscribe(params => 
    {
      const id = params["docId"];

      this.currentDocId = id;
    })
  }

  /**
   * Fonction déclenchée lors de changements dans les propriétés d'entrée
   */
  ngOnChanges(changes: SimpleChanges): void 
  {
    if (changes.gedDoc) 
    {
      this.gedDoc = changes.gedDoc.currentValue;
      this.getDoc();
    }
  }

  /**
   * Récupère les détails du document
   */
  getDoc() 
  {
    if (this.gedDoc != undefined) 
    {
      this.doc = this.gedDoc.content.data;
      this.getActions(this.path, this.gedDoc.content.data.uid);
    }
  }

  /**
   * Récupère les actions en fonction du chemin et de l'ID du document
   * @param path - Chemin d'accès
   * @param currentDocId - ID actuel du document
   */
  getActions(path: string, currentDocId: string)
  {
    this.actions = [];
    this.workflowService
      .getActions(path, currentDocId, this)
      .then((data) => 
      {
        this.isLoading = false;
        if (data.actions)
        {
          data.actions.forEach(action => 
          {
            action.setColors({ primary, white, black });
            this.actions.push(action);
            this.actions.reverse();
          });
        }
        data.titleOfActions = data.title; 

        this.infoActions.emit(data);
      
        //if actions is empty don't show card
        if (this.actions.length == 0)
        {
          this.showActions = false;
        }

        this.emitState.emit({showActions:this.showActions,isLoading:false});
      });
  }

  /** 
   * Exécute une action de flux de travail 
   * @param action - Action de flux de travail à exécuter
   */
  execAction(action: WorkflowAction): void
  {
    // Affiche le spinner de chargement
    this.isLoading = true;
    this.showActions = false;
    this.emitState.emit({showActions:this.showActions,isLoading:true});

    // Ensuite, exécute l'action
    this.workflowService
      .execAction(action, this.doc)
      .then(() => 
      {
        const url = this.router.url;

        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => 
          {
            this.router.navigate([url]);
          });
      });
  }
}
