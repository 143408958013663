
<aura-modal
    [isOpen]="isOpen"
    (close)="closeModal()"
    [title]="'New space' | translate"
    [fullscreen]="false">
    <ng-container>
        <div class="row justify-content-center spinnerLoading" *ngIf="isLoading">
            <mat-spinner ></mat-spinner>
            <div class="in-loader">
                
            </div>
          </div>
    </ng-container>
   <ng-container>

    

    
    <form #form="ngForm">
      <app-form-manager
            [inputData]="inputData"
            [displayButtons]="true"
            [type]="'form'"
            (onFormCancelEvent)="closeModal()"
            (onFormSubmitEvent)="createSpace($event)"></app-form-manager>
       <!-- <div  class="form-create" *ngIf="!isLoading">
          <div>
            <mat-form-field  appearance="fill" style="display: block; text-justify: center;"
            *ngFor="let field of formFields; let j=index">
              <mat-label>{{field.label}}</mat-label>

              <div *ngIf="field.auto==null && field.key!=='icon' && field.key!=='color'">
                <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}"
                required="{{field.required  }}">
              </div>

              <div *ngIf="field.auto!=null">
                <input matInput [(ngModel)]="field.value" name="{{field.key}}" type="{{field.type}}" required="{{field.required}}"

                [matAutocomplete]="autofield" (ngModelChange)="doFilterField(field)" #optionfield="ngModel">

                <mat-autocomplete #autofield="matAutocomplete">
                  <mat-option *ngFor="let option of fieldAutoList[field.key]" [value]="option.html">
                    {{option.html}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <mat-error *ngIf="form.submitted && form.controls[field.key]?.invalid">This field is required.</mat-error>

            </mat-form-field>
          </div>
        </div>

        <div class="col-md-12 actions">
          <button auraButton   (click)="closeModal()"  label="{{'cancelBtn' | translate}}"  class="cancelBtn"> </button>

          <button auraButton
            type="light"
            [hasShadow]="true"
            label="{{ 'addBtn' | translate }}"
            (click)="createSpace(form.value)"
            [disabled]="!form.valid || isLoading" ></button>
        </div>-->

      </form>
    <!---->
   </ng-container>
</aura-modal>
