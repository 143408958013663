import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GedTask } from 'src/modules/tasks/model/ged-task.model';
import { TaskService } from 'src/modules/tasks/services/task.service';
import { TableElement } from '../../models/table-element/table-element.model';

const themePath = environment.themePath;

@Component({
  selector: 'app-notif-bar',
  templateUrl:  `./${themePath}/notif-bar.component.html`,
  styleUrls: [ `./${themePath}/notif-bar.component.scss`]
})
export class NotifBarComponent implements OnInit
{
  items: { label: any; icon: any; callback: any; }[];

  constructor(
    private taskService: TaskService ,
    private router: Router ) 
  { }

  hidden = false;
  isLoading=true;

  tasksList;
  myTasks=[];
  tempMytasks;
  displayTasks=[];
  mtask=[]

  ngOnInit(): void 
  {
    this.getTasks()
  }

  toggleBadgeVisibility() 
  {
    this.hidden = !this.hidden;
  }

  async getTasks()
  {
    const tasksResponse = await this.taskService.getTasks();

    this.isLoading=false;
    this.tasksList=tasksResponse;
    this.myTasks=this.tasksList?.myTasks || [];
    const teamTasks=this.tasksList?.groupTasks || [];

    const allTasks = [
      ...this.myTasks,
      ...teamTasks
    ]

    if (allTasks.length > 0)
    {
      this.displayTasks = allTasks.slice(0, 5);

      this.items = this.displayTasks.map(task => ({
        label: task.getName(),
        icon: 'gg-play-list-check',
        callback: this.openDocument.bind(this, task)
      }));
    }
    else
    {
      this.items = [{ label: environment.stringsFile.no_tasks_left , icon: '', callback: () => 
      {} }];
    }
  }

  prepareTable(taskList: GedTask[]): TableElement[]
  {
    let element:TableElement;
    const resultList=[]

    for(const t of taskList)
    {
      element ={
        Name: t.getName(),
        Owner: t.getOwner(),
        Type: t.getType(),
        State: t.getState(),
        uid: t.getUid(),
        path: t.getPath(),
        date: t.getDate()
      }

      resultList.push(element)
    }

    return resultList
  }


  openDocument(element)
  {
    this.taskService.openDocument(element , "workspace/main/")
  }

}
