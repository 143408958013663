<div class="fullscreen" id="doc-side-panel">
  <div class="">
    <div class="row">
      <div *ngIf="!sidePanelOnly" id="app-doc-display-wrapper" class="col position-relative"
        [ngClass]="{'pe-5': isSideBarOpened}">
        <app-doc-display [path]="path" [document]="docData" [docId]="docId"></app-doc-display>
      </div>
      <div [ngClass]="{
          'isSideBarOpened': isSideBarOpened,
          'full-width-content': isExtention,
          'sidePanelOnly' : sidePanelOnly,
          'sidebar ml-0 col-lg-3 position-relative': !isExtention
          }">

        <!--  <ng-template #Alert>
           <div class="p-3">  *

             <aura-alert type="warning" message="No comments to display"></aura-alert>
           </div>
         </ng-template> -->

        <div *ngIf="!sidePanelOnly" class="toggle-button" (click)="isSideBarOpened = !isSideBarOpened">
          <img src="/assets/images/icon-collapse-sidebar.svg" alt="">
        </div>

        <div class=" px-0 right-side d-flex flex-column" id="collapseWidthExample">
          <section class="title bg-white px-3 py-2">
            <div class="row justify-content-between align-items-center mb-3">

              <div class="col-2 " *ngIf="!sidePanelOnly">
                <img style="cursor: pointer;" (click)="goback()" src="/assets/images/icon-arrow-back.svg" alt="">
              </div>

              <div class="col doc-title-single">
                <h2 class="mb-0 mt-3 text-center title-doc" [ngClass]="{'title-doc-extention': sidePanelOnly}">
                  {{formateLongTitle(docData?.name)}}</h2>
              </div>
              <!--<div class="breadcrumb_wrapper row justify-content-between align-items-center">

              <aura-breadcrumb *ngIf="bc !== null" [steps]="bc"></aura-breadcrumb>

              <!--  <app-bread-crumb *ngIf="breadcrumb"
              [parents]="breadcrumb"
              [filter]="filterBreadCrumbParent"
              separator=" > "
              (click)="onBreadCrumbClick($event)"></app-bread-crumb>
          </div>-->

              <div class="col-2 col-section">
                <div ngbDropdown class="d-inline-block" *ngIf="!sidePanelOnly">
                  <img class="dropdown-toggle ms-auto me-0 d-block" src="/assets/images/icon-three-dots-circle.svg"
                    id="dropdownBasic1" ngbDropdownToggle alt="">

                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menu-content">
                    <button ngbDropdownItem (click)="openPageHome()" class="btn-menu">
                      <span class=""><i class="gg-home-alt"></i></span>
                      <span class="menu-items">{{'homepageTT' | translate}}</span>
                    </button>

                    <button ngbDropdownItem (click)="backToSpace()" *ngIf="docSpace && !spaceDesactivated"
                      class="btn-menu">
                      <span><i class="gg-folder"></i></span>
                      <span class="menu-items">{{'returSpace' | translate}}</span>
                    </button>

                    <button ngbDropdownItem (click)="backToFolder()" class="btn-menu">
                      <span><i class="gg-folder colorfolder"></i></span>
                      <span class="menu-items">{{'parentFolderTT' | translate}}</span>
                    </button>

                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#exModal" class="btn-menu"
                      *ngIf="inGed" (click)="moreDetailsModal = true">
                      <span class=""><i class="gg-info"></i></span>
                      <span class="menu-items">{{'openMainInfo' | translate}}</span>
                    </button>

                    <button ngbDropdownItem (click)="openInDrive()" class="btn-menu">
                      <span class="icons-detache"><i class="gg-arrow-top-right"></i></span>
                      <span class="menu-items">{{'openDocDriveTT' | translate}}</span>
                    </button>

                    <hr *ngIf="actionsMenuItems?.length">

                    <button ngbDropdownItem (click)="execAction(action)" class="btn-menu"
                      *ngFor="let action of actionsMenuItems">
                      <span class="icons-detache"><i class="gg-chevron-right-o"></i></span>
                      <span class="menu-items"> {{ action.name }}</span>
                    </button>

                    <hr *ngIf=" canEdit && canDelete">

                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#exModal" class="btn-menu"
                      (click)="detacheDocModal = true" *ngIf="false && inGed && canEdit">
                      <span class=""><i class="gg-close"></i></span>
                      <span class="menu-items">{{'detachDoc' | translate}}</span>
                    </button>

                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#exModal" class="btn-menu"
                      *ngIf="canDelete" (click)="deleteDocModal = true">
                      <span class=" "><i class="gg-trash"></i></span>
                      <span class="menu-items">{{'deleteDocTT' | translate}}</span>
                    </button>
                    <hr *ngIf="dataAdd?.Add[0]&& canEdit">

                    <button ngbDropdownItem data-bs-toggle="modal" data-bs-target="#exModal" class="btn-menu"
                      *ngIf="dataAdd?.Add[0] && canEdit" (click)="docDocumentModal = true">
                      <span class=""> <i class="gg-add"></i> </span>
                      <span class="menu-items">Ajouter {{dataAdd?.Add[0].title}}</span>
                    </button>
                  </div>
                </div>

                <div ngbDropdown class="d-inline-block" *ngIf="sidePanelOnly">
                  <img class="dropdown-toggle ms-auto me-0 d-block" src="/assets/images/icon-three-dots-circle.svg"
                    id="dropdownBasic1" ngbDropdownToggle alt="">

                  <div ngbDropdownMenu aria-labelledby="dropdownBasicExtention" class="menu-content">
                    <button ngbDropdownItem (click)="goToGedApp()" class="btn-menu">
                      <span class=""><i class="gg-home-alt"></i></span>
                      <span class="menu-items">{{'openInGedApp' | translate}}</span>
                    </button>
                  </div>
                </div>

                <ng-container>
                  <app-doc-type-add *ngIf="dataAdd?.Add[0]" [isOpen]="docDocumentModal"
                    (close)="docDocumentModal = false" [dataLink]="dataAdd?.Add"
                    [parentId]="dataAdd?.parentUid"></app-doc-type-add>
                  <app-doc-dialogs [isOpen]="deleteDocModal" (close)="deleteDocModal = false"
                    [data]="dataDelete"></app-doc-dialogs>
                  <app-doc-dialogs [isOpen]="detacheDocModal" (close)="detacheDocModal = false"
                    [data]="dataDetach"></app-doc-dialogs>
                </ng-container>

              </div>
            </div>
            <span class="etiquette" *ngIf="docIsPublished" matTooltip="{{'fondational-title' | translate}}"
              [matTooltipPosition]="'left'" [matTooltipClass]="'custom-tooltip'"> {{'fondational' | translate}}</span>
            <app-flagging-status></app-flagging-status>
            <!-- <app-aurax-button label="New boutton" icon="gg-flag-alt" disabled></app-aurax-button> -->
            <!--  <div class="path">
                             <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
                             <ng-template #iconTemplate>
                                 >
                             </ng-template>
                         </div> -->
          </section>
          <div class="spinner-div">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
          </div>

          <div class="controls py-2  d-flex justify-content-evenly">

            <button (click)="currentTab = 0" [ngClass]="{'active': currentTab == 0}"
              class="btn infos px-0 mx-2 active">{{'infos' | translate | titlecase}}</button>

            <button (click)="currentTab = 1" [ngClass]="{'active': currentTab == 1}" *ngIf="isDocLinks"
              class="btn linked px-0 mx-2">{{'links' | translate | titlecase}}
            </button>

            <button (click)="detectChanges()" [ngClass]="{'active': currentTab == 2}"
              class="btn comments px-0 mx-2">{{'Approbations' | translate }}</button>
          </div>

          <!--<section *ngIf="!inGed && !isLoading">
            <div class=" px-0 right-side d-flex flex-column showAddDoc">
              <button auraButton type="primary" label=" {{'addtoGedTitle' | translate}}" *ngIf="!showAddDoc && !spaceDesactivated"
                (click)="showAddDoc = !showAddDoc"></button>
                <div class="flagging-btns">

                  <app-aurax-button label="verify" icon="gg-check-o" (click)="verify()" tooltipClass="aura-tooltip"
                    matTooltip="Stamp the document to highlight its quality & freshness"
                    [active]="lastFlaggingEvent?.type=='verify' && lastFlaggingEvent?.user ?.email===currentUser?.email"></app-aurax-button>
                  <app-aurax-button label="flag" icon="gg-flag-alt" (click)="flag()" tooltipClass="aura-tooltip"
                    matTooltip="Notify the doc’s owner/contributor of any errors or freshness issues"
                    [active]="lastFlaggingEvent?.type=='flag' && lastFlaggingEvent?.user ?.email===currentUser?.email"></app-aurax-button>

                </div>
              <app-doc-add *ngIf="(showAddDoc || spaceDesactivated) && !inGed" (emitState)="updateDoc($event)" [dontShowDocType]="spaceDesactivated"></app-doc-add>
              <section class="px-3 d-flex justify-content-center mt-3 mb-3">
                <app-doc-rating [gedDoc]="documentCurent"></app-doc-rating>
              </section>
            </div>

          </section>-->



          <section class="main flex-fill d-flex flex-column" *ngIf="!isLoading">

            <!-- TAB HEADERS -->

            <!-- MAIN TAB CONTENT -->
            <div *ngIf="currentTab == 0" class="tabs  mt-0 bg-white rounded flex-fill">


              <!-- DOCUMENT PROPERTIES -->
              <section *ngIf="currentDocument !== 'K'" class="lists">
                <div class="py-2 section section-information">
                  <div class="flagging-btns">
                    <!-- <button auraButton label="verify" icon="gg-check-o" (click)="verify()" tooltipClass="aura-tooltip"
                                        matTooltip="Stamp the document to highlight its quality & freshness"></button>
                                      <button auraButton label="flag" icon="gg-flag-alt" (click)="flag()" tooltipClass="aura-tooltip"
                                        matTooltip="Notify the doc’s owner/contributor of any errors or freshness issues"></button> -->
                    <app-aurax-button label="verify" icon="gg-check-o" (click)="verify()" tooltipClass="aura-tooltip"
                      matTooltip="Stamp the document to highlight its quality & freshness"
                      [active]="lastFlaggingEvent?.type=='verify' && lastFlaggingEvent?.user ?.email===currentUser?.email"></app-aurax-button>
                    <app-aurax-button label="flag" icon="gg-flag-alt" (click)="flag()" tooltipClass="aura-tooltip"
                      matTooltip="Notify the doc’s owner/contributor of any errors or freshness issues"
                      [active]="lastFlaggingEvent?.type=='flag' && lastFlaggingEvent?.user ?.email===currentUser?.email"></app-aurax-button>

                  </div>
                  <!-- <aura-modal [isOpen]="true" [title]="'title'">
                                                              <div>
                                                                test
                                                              </div>
                                                            </aura-modal> -->
                  <aura-accordion>
                    <span auraAccordianTitle class="accordian-header">
                      <div class="py-3 accor-header">
                        <div class="info-icon-container">
                          <img class="align-text-bottom me-3" src="/assets/images/icon-info-gris.svg" />
                          <span>{{'DocumentProperties' | translate}}</span>
                        </div>

                      </div>
                      <span (click)="toggleEdit($event)" class="edit-icon" *ngIf="canEdit"><svg
                          xmlns="http://www.w3.org/2000/svg" class="icon-crayon" viewBox="0 0 24 24">
                          <g>
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M6.414 16L16.556 5.858l-1.414-1.414L5 14.586V16h1.414zm.829 2H3v-4.243L14.435 2.322a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414L7.243 18zM3 20h18v2H3v-2z" />
                          </g>
                        </svg> </span>

                    </span>
                    <span auraAccordianContent>
                      <app-doc-add *ngIf="!inGed" (emitState)="updateDoc($event)" [dontShowDocType]="spaceDesactivated"
                        [canEdit]="canEdit" [iseditMeta]="iseditMeta"></app-doc-add>
                      <app-doc-desc *ngIf="inGed" [path]="path" [canEdit]="canEdit" [iseditMeta]="iseditMeta"
                        [gedDoc]="currentDocument" (emitState)="updateDoc($event)"
                        [spaceName]="spaceParent?.name"></app-doc-desc>

                    </span>

                  </aura-accordion>
                </div>
                <hr>


                <!-- RATING -->
                <section class="px-3 d-flex justify-content-center mt-3 mb-3">
                  <app-doc-rating [gedDoc]="currentDocument"></app-doc-rating>
                </section>

                <div *ngIf="currentDocument !== 'K'" class="px-3 py-2 section section-information">
                  <aura-accordion closed>
                    <span auraAccordianTitle>
                      <div class="py-3 accor-header">
                        <img class="align-text-bottom me-3" src="/assets/images/icon-file-gris.svg" /> {{"etatDoc" |
                        translate}}
                      </div>
                    </span>

                    <span auraAccordianContent class="section-infos">
                      <app-doc-workflow-metadata [_gedDoc]="currentDocument"
                        (emitState)="updateDoc($event)"></app-doc-workflow-metadata>
                    </span>
                  </aura-accordion>
                </div>
              </section>


              <!--  <section class="feedback my-5 d-flex justify-content-center">
                  <div class="like me-5">
                    <img src="/assets/images/icon-thumbs-up.png" alt="" class="align-text-bottom me-2">
                    <span>599 Like</span>
                  </div>
                  <div class="dislike">
                    <img (click)="doUnlike = true" src="/assets/images/icon-thumbs-down.png" alt=""
                      class="align-middle me-2">
                    <span>599 Dislike</span>
                  </div>
                  <app-tell-us-why [isOpen]="doUnlike" (close)="doUnlike = false"></app-tell-us-why>
                </section> -->

              <!--  <section class="px-3 d-flex justify-content-center mb-4 mt-5">
                  <button auraButton class="w-50" [full]="true" label="Submit" type="primary" size="large">
                  </button>
                </section>

              <section class="url pb-3 text-center" *ngIf="documentCurent !== 'K'">
                <a data-bs-toggle="modal" data-bs-target="#exModal"
                  class="a text-decoration-underline btn-moreDetailsModal" (click)="moreDetailsModal = true">
                  {{'openMainInfo' | translate}}
                </a>
              </section> -->
              <app-doc-infos [isOpen]="moreDetailsModal" (close)="moreDetailsModal = false" [canEdit]="canEdit"
                [documentCurent]="currentDocument" [path]="path" *ngIf="currentDocument !== 'K'"
                [spaceName]="spaceParent?.name" [window]="selctedWindow">
              </app-doc-infos>
            </div>

            <div *ngIf="currentTab == 2" class="tabs  mt-0 bg-white rounded flex-fill">

              <div [ngClass]="{
                'px-3 py-2 mb-2': workflow
              }">
                <!-- PROCESSUS -->
                <div class="containerWorkflow" *ngIf="waitWorkflow">
                  <div class="description">
                    {{'workfolowNotActifdescription' | translate}}
                  </div>

                  <div class="actions-workflow ">
                    <button auraButton label="{{'goToOverview' | translate}}" type="primary" size="large"
                      (click)="goToOverview()"></button>
                  </div>
                </div>
                <app-doc-list-approval-activity *ngIf="workflowActive" [_gedDoc]="currentDocument">
                </app-doc-list-approval-activity>
              </div>
              <footer>
                <!--<section class="url pb-3 text-center" *ngIf="documentCurent !== 'K'">
                <a data-bs-toggle="modal" data-bs-target="#exModal" class="a text-decoration-underline btn-moreDetailsModal"
                  (click)="moreDetailsModal = true">
                  {{'openMainInfo' | translate}}
                </a>
              </section>-->
                <app-doc-infos [isOpen]="moreDetailsModal" (close)="moreDetailsModal = false" [canEdit]="canEdit"
                  [documentCurent]="currentDocument" [path]="path" [window]="selctedWindow"
                  *ngIf="currentDocument !== 'K'" [spaceName]="spaceParent?.name">
                </app-doc-infos>
              </footer>
            </div>
            <div style="display: none;">
              <app-doc-related [_gedDoc]="currentDocument" (emitState)="updateDoc($event)" (emitAdd)="getData($event)"
                (documentRelated)="getDocLinks($event)"></app-doc-related>
            </div>

            <div *ngIf="currentTab == 1" class="tabs m-3 mt-0 bg-white rounded flex-fill pb-3 pt-4">
              <app-doc-related [_gedDoc]="currentDocument" *ngIf="currentDocument !== 'K'" (emitState)="updateDoc($event)"
                (emitAdd)="getData($event)" [canEdit]="canEdit"></app-doc-related>


            </div>

            <!-- <div *ngIf="currentTab == 2" class="tabs m-3 mt-0 bg-white rounded flex-fill">
                             <section class="chat h-100 d-flex flex-column">
                                 <app-comments [comments]="comments"></app-comments>
                                 <div class="mt-3 border-top mb-0 mt-auto">
                                     <app-comment-input (send)="onSend($event)"></app-comment-input>
                                 </div>
                             </section>
                         </div> -->
          </section>

        </div>

      </div>
    </div>
  </div>
</div>
<aura-modal [isOpen]="flagModal" width="50%" (close)="flagModal = false"
  title="Reason(s) why you are flagging this content">

  <form (ngSubmit)="onSubmitFlag()" #form="ngForm">
    <div *ngFor="let option of flagOptions">
      <aura-input-checkbox [label]="option.option" [checked]="option.selected" [(ngModel)]="option.selected"
        [ngModelOptions]="{standalone: true}"></aura-input-checkbox>

    </div>
    <div class="actions">

      <button auraButton label="Cancel" (click)="flagModal = false"></button>
      <button auraButton type="primary" [disabled]="!isFlagSubmitButtonEnabled()">Flag content</button>
    </div>
  </form>
</aura-modal>
<aura-modal [isOpen]="verifyModal" width="50%" (close)="verifyModal = false" title="“Mark as verified” confirmation">
  <p>
    To designate the content as 'Verified,' ensure that all three checkboxes are selected.
  </p>
  <form (ngSubmit)="onSubmitVerify()" #formVerify="ngForm">
    <div *ngFor="let option of verifyOptions">
      <aura-input-checkbox [label]="option.option" [checked]="option.selected" [(ngModel)]="option.selected"
        [ngModelOptions]="{standalone: true}"></aura-input-checkbox>

    </div>
    <div class="actions">

      <button auraButton label="Cancel" (click)="verifyModal = false"></button>
      <button auraButton type="primary" [disabled]="!isVerifySubmitButtonEnabled()">Mark as verified</button>
    </div>
  </form>
</aura-modal>