
 
 <mat-toolbar class="dialog-header">
    <span mat-dialog-title>{{data.title}}</span>
    <span class="app-spacer"></span>
    <button mat-icon-button style="vertical-align:middle;"  mat-button mat-dialog-close cdkFocusInitial>
      <mat-icon style="color: white">close</mat-icon>
  </button>
  </mat-toolbar>
 
 <div mat-dialog-content>
      
</div>
<div mat-dialog-actions class="col-12 actions"  align="end">
    <div style="padding-right: 31px;">
        <button class="cancelBtn" mat-button   mat-dialog-close cdkFocusInitial>{{'cancelBtn' | translate}}</button>
        <button class="submitBtn" mat-button mat-dialog-close    (click)="deleteFolder()">{{'deleteConfirmBtn' | translate}}</button>
    </div>
</div>
