<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0083F6" class="custom-svg" viewBox="0 0 16 16">
  <path
    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
</svg>

<aura-input-text [clearButton]="true" (onClear)="clearButton()" className="ps-5 fixed-input" placeholder="{{ 'searchBar' | translate }}"
  [custom-style]="{'border': 'none' }" (onInput)="searchInPresenceDoc($event)">
</aura-input-text>

<ng-container class="test">

  <div *ngIf="isLoading" class="spinner-div">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!isLoading && !inputCleared">
    <div *ngFor="let group of searchResponseObservable | async">
      <div class="row list hide">
        <div class="col" *ngIf="group.data.length!=0">
          <aura-table-solid-extension [data]="group.data.slice(0, 5)" [columns]="group.columns" [title]="group.label"
            [disableHead]="true">
          </aura-table-solid-extension>
        </div>
      </div>
    </div>
    <aura-table-solid-extension class="show-details" *ngIf="multipleResults" [data]="dataShowResults" [columns]="columnsShowResults" [title]="'showResults'"
            [disableHead]="true">
          </aura-table-solid-extension>
  </div>
</ng-container>

<ng-template #myTemRef let-flag="flag">
  <span class="tag {{ flag.classFlag }}" [matTooltip]="flag.tooltipFlag">{{
    flag.labelFlag
    }}</span><span *ngIf="flag.countFlags" [matTooltip]="flag.tooltipFlag" class="tag tag-plus">{{ flag.countFlags
    }}</span>
</ng-template>

<ng-template id="rating" #temRating let-rating="rating">
  <div *ngIf="rating.nbVotes">
    <span class="stars">&#9733;</span>
    <span class="average-rating"><strong>{{ rating.valeur }}/5</strong></span>
    <span class="total-votes">({{ rating.nbVotes }})</span>
  </div>
</ng-template>