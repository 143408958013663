<mat-card class="meta-card mat-elevation-z0">
    <mat-card-header>

      <mat-card-title>
        <mat-toolbar class="card-title">
            <span>{{element?.Name}}</span>
            <span class="app-spacer"></span>
            <button mat-icon-button style="vertical-align:middle;" (click)="closeDrawer()">
              <mat-icon style="color: white">close</mat-icon>
          </button>
          </mat-toolbar>

      </mat-card-title>
    </mat-card-header>

    <mat-card-content>
            <mat-list>
                <mat-list-item>
                    <span class="label">Type</span>
                    <span>{{element?.Type}}</span>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="label">State</span>
                    <span>{{element?.State}}</span>
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item>
                    <span class="label">Date</span>
                    <span>{{gedElement?.date|  date: 'dd/MM/yyyy'}}</span>
                </mat-list-item>
            </mat-list>  
    </mat-card-content>
  
    <mat-card-actions>
        <div  class="card-action right-align">
            <button mat-raised-button class="openBtn float-right" (click)="openDocument(gedElement)">{{'openBtn' | translate | uppercase}}</button>
        </div>

    </mat-card-actions>
</mat-card>
