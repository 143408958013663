<div id="root" theme-mode="dark">
    <app-header></app-header>
    <div class="togglemeu px-2" [ngClass]="{'on': isOn}" (click)="togglesidebar()">
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="layout">
        <app-left-aside [isOn]="isOn" (closeSideMenu)="togglesidebar()"></app-left-aside>
        <section class="section lytcontent" id="lytcontent">
            <h1 class="d-md-none h1 text-center py-3 bg-color">{{title}}</h1>
            <div class="wrap px-3 py-30 flex-fill">
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </section>
        <app-right-aside></app-right-aside>
    </div><!-- End Sections -->


</div>