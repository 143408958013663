

<div class="dropzone" appDragNDrop (fileDropped)="onFileDropped($event)">
  <div class="dropzone-content">
    <button
    auraButton
    label="Select File"
    [full]="true"
    tag="button"
    type="primary"
    class="file-upload-button"
    (click)="triggerFileInput()"
  ></button>
    
    <input
      type="file"
      accept="*"
      (change)="uploadfromPc($event)"
      class="file-upload"
    />
    <p class="message mt-3">{{ "or drag and drop files here" | translate }}</p>
    <p class="uploaded-file-name mt-3">{{ uploadedFileName }}</p>
  </div>
</div>
<div>
  <!-- <h2 class="aura-h2 text-center mt-3">
    {{ "Select document from local disk" | translate }}
  </h2> -->
</div>