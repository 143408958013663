import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-approve-all-documents',
  templateUrl: './modal-approve-all-documents.component.html',
  styleUrls: ['./modal-approve-all-documents.component.scss']
})
export class ModalApproveAllDocumentsComponent 
{
  public approveAllDocuments: any;
  constructor(public dialogReference: MatDialog) 
  { }

  closeModal(): void 
  {
    this.dialogReference.closeAll();
  }

  approveAllDocumentsFunction(): boolean 
  {
    this.approveAllDocuments.bool = true;
    this.closeModal();

    return this.approveAllDocuments.bool;
  }
}
