import { environment } from "src/environments/environment";
import { DocumentService } from "src/modules/documents/services";
import { SpaceService } from "src/modules/spaces/services";
import { RecentDocsComponent } from "../components";

const lastDay = environment.stringsFile.lastDay;
const confirm = environment.stringsFile.confirmed;
const at = environment.stringsFile.at;
const on = environment.stringsFile.on;
const dateLangue =  environment.stringsFile.dateLangue;
const openDestination =  environment.stringsFile.openDestination;
const openInDrive =  environment.stringsFile.open_in_drive;
const open =  environment.stringsFile.open;


export class RecentFile 
{
  item: any;
  id: string;
  iconLink: string;
  name: string;
  owner: string;
  webViewLink: string;
  typeDoc: string;
  type_document
  parents;
  modifiedAt;
  modifiedBy;
  modifiedIcon;
  mimeType: string;

  displayProperties;

  constructor(item )
  {
    this.item  = item;

    this.setId(item);
    this.setIconLink(item);
    this.setName(item);
    this.setOwner(item);
    this.setwebViewLink(item);
    this.setDocType(item);
    this.setParents(item);
    this.setModifiedAt(item);
    this.setModifiedBy(item);
    this.setTypeDocument(item);
    this.setModifiedIcon(item);
    this.setMimeType(item)

    this.getDisplayProperties();
  }

  getDisplayProperties()
  {
    const self = this;
    const space = this.getSpace();
    const typeDoc = this.getTypeDocument();
    let subtitle='';

    if(space && typeDoc)
    {
      subtitle = space && space+" / " || '';
      subtitle += typeDoc;
    }

    this.displayProperties =
    [
      {
        id: this.getId(),
        title: this.getName().replace(/[-_]/g, ' '),
        text: subtitle,
        image: this.getIconLink(),
        redirectTo: '/workspace/main/file/'+self.getId(),

        menuItems :
        [
          {
            label: open,
            callback: function ()
            {
              SpaceService.instance.openDocInList(self.getId(),'/workspace/main');
            }
          }
          ,

          {
            label: openDestination,
            callback: function ()
            {
              RecentDocsComponent.instance.rowParentList(self.getParents())

              // SpaceService.instance.openDocInList(self.getId());
              //console.log(self.getName());
            }
          },

          {
            label: openInDrive,
            callback: function ()
            {
              window.open(self.getwebViewLink(), '_blank');
            },
          }

        ]
      }
    ];

    return this.displayProperties;
  }



  getId() 
  {
    return this.id;
  }
  getIconLink(): string 
  {
    return this.iconLink;
  }
  getName(): string 
  {
    return this.name;
  }
  getOwner(): string 
  {
    return this.owner;
  }
  getTypeDoc(): string 
  {
    return this.typeDoc;
  }
  getParents(): string 
  {
    return this.parents;
  }
  getwebViewLink() 
  {
    return this.webViewLink;
  }
  getModifiedAt() 
  {
    return this.modifiedAt;
  }
  getModifiedBy() 
  {
    return this.modifiedBy;
  }

  getTypeDocument() 
  {
    return this.type_document;
  }

  getSpace() 
  {
    return this.item.properties?.space;
  }

  getModifiedIcon() 
  {
    return this.modifiedIcon;
  }

  getMimeType(): string 
  {
    return this.mimeType;
  }

  /**setter */
  setId(item) 
  {
    this.id = item.id;
  }
  setIconLink(item) 
  {

    const icon = DocumentService.instance.getIconbyMimeType(item.iconLink);

    if(icon)
    {
      this.iconLink = icon;
    }
    else
    // console.log("iconLinkiconLink",icon)
    {
      this.iconLink = item.iconLink;
    }
  }
  setName(item) 
  {
    this.name = item.name;
  }

  setDocType(item) 
  {
    this.typeDoc = item.mimeType;
  }

  setOwner(item) 
  {
    // console.log("ownersDoc", item);
    if (item.owners) 
    {
      if (item.ownedByMe.length != 0) 
      {
        if (item.ownedByMe == true) 
        {
          this.owner = "me";
        }
        else 
        {
          if (item.owners[0].displayName.length != 0) 
          {
            if (!!item.owners && item.owners.length != 0)
            {
              this.owner = item?.owners[0]?.displayName;
            }
          }
          else 
          {
            this.owner = null;
          }
        }
      }
      else 
      {
        this.owner = null;
      }
    }
    else 
    {
      if (item.properties) 
      {
        this.owner = item?.properties?.owner;
      }
      else 
      {
        this.owner = null;
      }
    }
  }

  setwebViewLink(item) 
  {
    this.webViewLink = item.webViewLink;
  }
  setParents(item) 
  {
    this.parents = item?.parents || null;
  }
  setTypeDocument(item) 
  {
    this.type_document = item?.properties?.type_document || null;
  }

  setModifiedAt(item) 
  {

    const start = item?.modifiedTime ;
    const d = new Date(start);
    let options;
    let options2
    const rel = this.relative(d);
    let s ;

    options = { hour12: false  , hour: '2-digit', minute: '2-digit'  };
    options2 = {   month: "long", day: "numeric"};
    if(rel == 'today')
    {

      s = at +d.toLocaleTimeString(dateLangue, options) ;
    }



    else if(rel == 'lastDay')
    {
      s =  lastDay +' ' + d.toLocaleTimeString(dateLangue, options) ;
    }
    else

    {
      s = on + d.toLocaleDateString(dateLangue, options2)
    }


    this.modifiedAt = s

  }
  setModifiedBy(item) 
  {
    if(item?.lastModifyingUser)
    {
      this.modifiedBy = item?.lastModifyingUser?.displayName
    }
    else
    {
      this.modifiedBy = null;
    }
  }
  setModifiedIcon(item) 
  {
    if(item?.lastModifyingUser)
    {
      this.modifiedIcon = item?.lastModifyingUser.photoLink ;
    }
    else
    {
      this.modifiedIcon =null
    }
  }

  setMimeType(item)
  {
    this.mimeType = item.mimeType;
  }









  relative(d: Date)
  {
    const now = new Date();

    if(d.toDateString() === now.toDateString())
    {
      return 'today';
    }

    const lastDay = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);

    if (lastDay.getFullYear() == d.getFullYear() && lastDay.getMonth() == d.getMonth() && lastDay.getDate() == d.getDate()) 
    {
      return "lastDay"; // date2 is one day after date1.
    }

    return 'other';
  }


}


