
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchGenericService } from '../../services/search-generic.service';
import { SearchTopComponent } from '../../components/search-top/search-top.component';

const $theme = environment.themeUI;

// atob for utf8 string

function decode64(str) 
{
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) 
  {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}


@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit 
{

  @ViewChild(SearchTopComponent) appSearchTop: SearchTopComponent;

  keyword = '';

  resultGroup = ''
  searchResponse = [];

  isToggled: boolean = false;

  loading: boolean = false;

  allEmptyData: boolean = false;

  currentSearchId: number = 0;

  constructor(
    private searchGenericService: SearchGenericService,
    private route: ActivatedRoute,
  ) 
  { }

  ngOnInit(): void 
  {
    this.route.params.forEach(routeParam => 
    {
      let queryParams1 = null;

      if (routeParam['query64']) 
      {
        try 
        {
          // decode query parameters
          queryParams1 = JSON.parse(decode64(routeParam['query64']));

        }
        catch (error) 
        {
          console.error("error in search url param ");
        }
      }

      if (queryParams1) 
      {
        this.keyword = queryParams1["q"];

        // exec search query
        this.execSearch(queryParams1);
      }
      else 
      {
        // decode query parameters and execute search query
        this.route.queryParams.forEach(queryParams => 
        {
          this.keyword = queryParams["q"];
          // exec search query
          this.execSearch(queryParams);
        });
      }
    });
  }


  async execSearch(queryParams = {}) 
  {
    // Incrémente le compteur à chaque nouvelle recherche
    const searchId = ++this.currentSearchId;
    
    const query = queryParams['q'];
    const group = queryParams['group'] || '';

    this.setSelectedGroup(group);
  
    this.loading = true;
    this.allEmptyData = false;
  
    // Effectuez la recherche
    const searchResponse = await this.searchGenericService.execSearch(query, [], group, queryParams);
  
    // Vérifiez si cette réponse correspond à la dernière recherche avant de mettre à jour l'UI
    if (searchId === this.currentSearchId) 
    {
      this.searchResponse = searchResponse;
      this.allEmptyData = searchResponse['results'].find(item => item.results.length != 0) === undefined;
  
      this.loading = false;
    }
    else 
    {
      // Cette réponse est pour une recherche précédente, donc on ne fait rien.
      console.log("Ignored outdated search response");
    }
  }

  setSelectedGroup(group: string): void 
  {
    this.resultGroup = group || '';

    /*
    this.route.queryParams.forEach(queryParams=>{
      this.resultGroup = (queryParams["group"])? queryParams["group"] : '' ;
    })
    */

  }

  toggleRightSidebar(): void 
  {
    this.isToggled = !this.isToggled;
  }


}
