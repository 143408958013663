import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SearchContainerComponent } from './containers';
import { SidePanelComponent } from '../documents/containers/document-page/side-panel/side-panel.component';

const routes: Routes = [
  {
    path: '', 
    component: SearchContainerComponent, 
    data: 
    {
      title: environment.stringsFile.searchPage
    }
  },

  {
    path: ':query64', 
    component: SearchContainerComponent, 
    data: 
    {
      title: environment.stringsFile.searchPage
    }
  },

  {
    path: ":query64/file/:docId",
    component: SidePanelComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SearchGenericRoutingModule 
{ }
