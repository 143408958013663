
// =============== WORKFLOW DATA RECEIVED FROM SERVER =========

import { environment } from "src/environments/environment";
import { TTaskRoleFrom } from "src/modules/tasks/model/ged-task.model";

export type TaskUser = {
  html : string,
  value
}

interface WorkflowDate {
  value: string;
  time: number;
}

export type InfosStepTask = {
  description: string;
  titleOfActions: string;
  icon: string;
}

interface TStateInfos
{
  background_color: string,
  color: string;
  icon_cls: string;
  status: string;
  title: string;
}

/** workflow task data received from server */
interface WorkflowTaskData
{
  /** action executed */
  action: string;

  date_creation: WorkflowDate;
  time: number;
  value: string;
  email: string;

  date_closing: WorkflowDate;

  from_user: TaskUser;

  closed_by: TaskUser;

  item_data: string;

  /** resource id */
  item_oid: string;

  message: string;

  /** task id */
  oid: string;

  /** permission for the action, usually empty */
  permission: string;

  /** role id */
  role: string;

  /** task state */
  state: string;

  /** is it a task for approval */
  type: "submit" | "approval";

  /** user having executed the action */
  user_oid: TaskUser;

  /** workflow id@package */
  workflow: string;

  state_infos : TStateInfos;  

  actionLabel: string;

  task_state? : string;

  from_role : {
    actor: string
  };

  actionDesc : {
    desc : string,
    desc_done : string 
  }

}

/** workflow role data received from server */
export interface WorkflowDataRole
{
  date_action: WorkflowDate;
  file_oid: string;
  function: { html : string, value : string};
  index: string;
  oid: string;
  project_role: { html : string, value : string};
  role: { html : string, value : string};
  role_user: { html : string, value : string};
  signature: string;
  signed_by: { html : string, value : string};
}

/** workflow role information with id, title, list of states */
export interface IWorkflowRoleInfo
{
    id :string;
    title: string;
    states: string[];
    description?:string
}

/** workflow action data received from server */
export interface WorkflowActionData
{
  action?: string;
  id?: string;
  name: string;

  /** display options */
  options?: {
    in_menu?:boolean,
    in_buttons?:boolean,
    display_steps?:boolean,
    color?:string,
    icon_cls?:string
  };

  /** action description */
  description?: string;

  dialog?:any;
}

export class WorkflowAction implements WorkflowActionData
{
  action: string;
  iconCls: string;
  inButtons: boolean;
  inMenu: boolean;
  name: string;

  options: {
    in_menu?:boolean,
    in_buttons?:boolean,
    color?:string,
    icon_cls?:string,
  };


  /** action description */
  description?: string;

  dialog?:any;

  button_class?:string;
  text_class?:string;
  role: string;

  constructor(
    data : WorkflowActionData,
    roleName : string = "default")
  {
    this.action = data.action || data.id;

    // display options
    this.options = data.options || {}
    this.iconCls = this.options.icon_cls;
    this.inButtons = this.options.in_buttons == undefined ? true : this.options.in_buttons;
    this.inMenu = !!this.options.in_menu;

    this.name = data.name;
    this.description = data.description;
    this.dialog = data.dialog;

    this.role = roleName;
  }

  setColors(colors : {primary,white,black} )
  {
    const {primary,white,black} = colors;

    if (this.button_class)
    {
      const color = this.button_class

      if(color=="primary")
      {
        this.button_class = primary;
        this.text_class = white;
      }
      else
      {
        if(color=="alert")
        {
          this.button_class = white;
          this.text_class = black;
        }
        else
        {
          this.button_class = white;
          this.text_class = primary;
        }
      }
    }
  }

  setRole(role:string) 
  {
    this.role = role;
  }

  hasDialog() : boolean
  {
    return !!this.dialog;
  }
}

/** workflow action data received from server */
export interface WorkflowData
{
  state;

  /** display options */
  options?: {
    can_edit?:boolean,
    steps?:boolean,
    color?:string,
    icon_cls?:string
  };

  state_infos :{
    background_color: string,
    color: string;
    icon_cls: string;
    status: string;
    title: string;
    actions_title?: string;
    actions_help?: string;
  };  

  roles : { items };
  tasks : WorkflowTaskData[] ;
  actions : Record<string,WorkflowActionData>;

  currentRole : {actor : string};
}

// =============== MODELS =================

/** Workflow user task model */
export class WorkflowUserTask
{
  item : WorkflowTaskData;
  state : string;
  iconLink : string;
  version : string;
  picture : string;
  action  : string;
  userName : string;
  actionLabel: string;
  dateCompletion;
  task_state? : string;
  dateTask?: string;
  closedDate?: string;
  actionDesc : string;

  /** true if task is still pending */
  pending : boolean;

  approvalStatus:
  {
    labelStateColor: string,
    labelBackgroundColor:string,
    labelIcon: string,
    status: string,
    descriptionOfCurrentState: string
  }

  constructor(
    item: WorkflowTaskData,
    picture = null)
  {
    this.item = item;
    this.setState(item);
    this.setIconLink(item);

    this.setPicture(picture);
    this.setAction(item);
    this.setUserName(item);
    this.setDateCompletion(item);
    this.setStateOfTask(item);
    this.setActionLabel(item);
    this.setDateTask(item);
    this.setClosedDate(item);
    this.setActionDesc(item);

    this.pending = this.isPending();

  }

  getClosedUser(): TaskUser
  {
    return this.item.closed_by
  }

  getFromUser() : TaskUser
  {
    return this.item.from_user;
  }

  isPending() : boolean
  {
    // NB. bug in data retreived : the state is always "pending",
    // so check if a user has validated the task instead..
    return this.item?.task_state  == "pending" ;
  }

  getState() 
  {
    return this.state;
  }

  getRoleName() 
  {
    return this.item.role;
  }

  getIconLink() 
  {
    return this.state;
  }

  getPicture() 
  {
    return this.picture;
  }
  getAction() 
  {
    return this.action;
  }
  getUserName() 
  {
    return this.userName;
  }
  getUserOid() 
  {
    return this.item.user_oid.value;
  }

  getDateCompletion() 
  {
    return this.dateCompletion;
  }
  getVersion() 
  {
    return this.version;
  }

  setState(item) 
  {
    this.state = item?.workflow_state;
  }
  setIconLink(item) 
  {
    this.state = item.workflow_state;
  }

  setPicture(picture) 
  {
    this.picture = picture;
  }
  setAction(item) 
  {
    this.action = item.action;
  }
  setUserName(item) 
  {
    this.userName = item.from_user?.html ?? item.closed_by?.html;
  }
  setDateCompletion(item) 
  {
    this.dateCompletion = item.date_completion?.value;
  }

  setVersion(item) 
  {
    this.version = item.version;
  }

  setStateOfTask(item)
  {
    const statusItem = item.state_infos;

    this.approvalStatus = 
    {
      labelStateColor: statusItem?.color,
      labelBackgroundColor: statusItem?.background_color,
      labelIcon: statusItem?.icon_cls,
      status: statusItem?.status,
      descriptionOfCurrentState: statusItem?.title,
    };
  }

  setActionLabel(item)
  {
    this.actionLabel = item.actionLabel;
  }

  setDateTask(item)
  {
    this.dateTask = ' • ' + this.transformTimestamp(item.date_creation.time);

  }

  setClosedDate(item)
  {
    this.closedDate =  ' • ' + this.transformTimestamp(item.date_closing?.time ?? item.date_creation.time)
  }

  setActionDesc(item)
  {
    this.actionDesc =  item.actionDesc.desc_done;
  }

  transformTimestamp(timestamp: number): string 
  {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    const ordinal = this.getOrdinal(day);
    const monthTranslated = environment.stringsFile[month];
    
    const lang = navigator.language;

    if (lang.split("-")[0] == "fr")
    {
      return `${day} ${monthTranslated} ${year}`;
    } 
    else 
    {
      return `${monthTranslated} ${day}${ordinal} ${year}`;
    }
  }
  
  getOrdinal(n: number): string 
  {
    if (n >= 11 && n <= 13) 
    {
      return 'th';
    }
    const remainder = n % 10;

    if (remainder === 1) 
    {
      return 'st';
    }
    else if (remainder === 2) 
    {
      return 'nd';
    }
    else if (remainder === 3) 
    {
      return 'rd';
    }
    else 
    {
      return 'th';
    }
  }
}

/** Workflow role model */
export class WorkflowRole
{
  role_user : {
    html: string,
    value
  }

  data;

  task : WorkflowUserTask;

  /** true if role is the current one to execute in the workflow */
  isPending: boolean = false;

  /** true if role has been executed */
  isDone: boolean = false;

  /** true if role is current */
  isCurrent: boolean = false;

  /** role description */
  description: string;

  name : string;

  constructor(data : IWorkflowRoleInfo)
  {
    this.data = data;
    this.name = this.getName();
    this.description = this.getDescription();
  }

  setIsDone(done=true) 
  {
    this.isDone = done;
    this.isPending = !done;
    this.isCurrent = false;
  }

  setIsCurrent() 
  {
    this.isDone = false;
    this.isPending = true;
    this.isCurrent = true;
  }

  /** checks if the role has this state in the workflow */
  hasState(stateName : string)
  {
    return this.data.states?.includes(stateName);
  }

  getId() 
  {
    return this.data.id;
  }

  getName() 
  {
    return this.data.title;
  }

  getRoleName() 
  {
    return this.data.name;
  }

  getUser() : TaskUser 
  {
    return this.role_user;
  }

  getUserName() : string 
  {
    return this.role_user?.html;
  }

  getUserId() 
  {
    return this.role_user?.value;
  }

  getActionDate() 
  {
    return this.data.task?.getDateCompletion();
  }

  getDescription() 
  {
    return this.data.description
  }

  attachTask(task : WorkflowUserTask) 
  {
    this.task = task;
  }
}

/** Workflow model */
export class Workflow
{  
  /** state of workflow*/
  state: string;

  /** state of workflow*/
  actionLabel: string;

  /** object received from server */
  data : WorkflowData;

  /** has the workflow any role, if not, no workflow */
  withWorkflow: boolean;

  tasks: WorkflowUserTask[];
  currentTask: WorkflowUserTask;

  /** user for current task to be executed */
  currentUser : TaskUser;

  assignedUser: string;

  /** role for current task's user to be executed */
  currentRole : TTaskRoleFrom;

  /** workflow steps/roles */
  roles : WorkflowRole[];

  /** current role name for the workflow */
  currentRoleName: string;

  /** if the workflow in on state for approval */
  isForApproval: boolean;

  approvalStatus:
  {
    labelStateColor: string,
    labelBackgroundColor:string,
    labelIcon: string,
    status: string,
    descriptionOfCurrentState: string,
    helpText?: string,
    titleOfActions?: string,
  }

  /** current role to be executed in the workflow */
  pendingRole : WorkflowRole;

  /** list of actions */
  actions : WorkflowAction[];

  date_creation;

  state_infos;  

  options;


  constructor(data : WorkflowData)
  {
    this._init(data);
  }

  getCurrentState() : string 
  {
    return this.data?.state;
  }

  getOptions() : any 
  {
    return this.data.options || {steps: true};
  }

  /** has the workflow any role? */
  hasWorkflow() : boolean
  {
    return this.withWorkflow;
  }

  /** has the workflow any role? */
  hasRoles() : boolean
  {
    return this.withWorkflow;
  }

  /** has the workflow any role? */
  hasActions() : boolean
  {
    return this.actions?.length>0;
  }

  /** get all tasks
   * @param [reverseOrder=true] reverse chronological order ?
   */
  getTasks(reverseOrder=true) : WorkflowUserTask[]
  {
    if(this.tasks)
    {
      if(reverseOrder && this.tasks.reverse)
      {
        return this.tasks.reverse();
      }
      else
      {
        return this.tasks;
      }
    }
  }

  /** current task to be executed */
  getCurrentTask() : WorkflowUserTask
  {
    return this.currentTask || null;
  }

  /** get all roles */
  getRoles() : WorkflowRole[] 
  {
    return this.roles;
  }

  /** get current role */
  getPendingRole() : WorkflowRole 
  {
    return this.pendingRole || null;
  }

  /** set current state */
  setState(item : WorkflowData) 
  {
    this.state = item?.state;
  }

  /** init model from data received from server */
  _init(data : WorkflowData) : void
  {
    this.setState(data);

    this.data = data;
    this.isForApproval = (data?.tasks[0]?.state === "for approval" && data?.tasks[0]?.task_state === "pending");
    this.assignedUser = data?.tasks[0]?.from_user?.html;

    if(!this.isForApproval && data?.tasks[0]?.task_state === "pending")
    {
      this.assignedUser = data?.tasks[0]?.from_user?.html;
    }
    else
    {
      this.assignedUser = data?.tasks[0]?.closed_by?.html  ?? data?.currentRole?.actor;
    }

    this.withWorkflow = data?.roles?.items?.length>0;
    const stateInfo = data?.state_infos

    this.approvalStatus = {
      labelStateColor: stateInfo?.color,
      labelBackgroundColor: stateInfo?.background_color,
      labelIcon: stateInfo?.icon_cls,
      status: stateInfo?.status,
      descriptionOfCurrentState: stateInfo?.title,
      helpText: stateInfo?.actions_help,
      titleOfActions: stateInfo?.actions_title,
    };

    const currentState = this.getCurrentState();

    // ========  ACTIONS =======
    // get actions
    this.actions = [];
    for (const a in data.actions)
    {
      this.actions.push(new WorkflowAction(data.actions[a],this.currentRoleName));
    }

    if(!this.withWorkflow)
    {
      return;
    }

    // =========  TASKS ========
    // set tasks and get pending task

    if(data?.tasks.length>0)
    {
      this.tasks = (data?.tasks || [])
        .map((tdata : WorkflowTaskData) =>
        {
          const task = new WorkflowUserTask(tdata,null);

          if(task.isPending())
          {
            this.currentTask = task;
          }

          return task;
        });
        
      // user for current task
      if(this.currentTask)
      {
        this.currentUser = this.currentTask.getFromUser() ;

        this.currentRoleName = this.currentTask.getRoleName();
      
      }
      else if(this.tasks.length>0)
      {
        // no pending task
        this.currentUser = this.tasks[0].getClosedUser() ?? this.tasks[0].getFromUser();
        this.currentRoleName = this.tasks[0].getRoleName();
      }

      if(!this.tasks[0].pending)
      {
        const lastTask= new  WorkflowUserTask(data.tasks[0], null) ;

        lastTask.userName = this.currentUser.html;
        lastTask.actionDesc = this.approvalStatus.descriptionOfCurrentState;
        lastTask.dateTask = this.tasks[0].closedDate;

        this.tasks.unshift(lastTask);
      }
    }

    // =========  ROLES ========

    // set roles and get role for pending task
    let isDone = true; // this.currentTask ? true : false; // if no task, no role has been done..

    this.roles = (data?.roles?.items || [])
      .map((rdata : IWorkflowRoleInfo) =>
      {
        const role = new WorkflowRole(rdata);

        // if role is attached to current state, this role is then pending.
        // before, other roles are "done", and other ones are also pending.
        if(role.hasState(currentState))
        // if(this.currentRoleName && role.getRoleName() == this.currentRoleName)
        {
          this.pendingRole = role;
          this.pendingRole.isPending = true;

          role.setIsCurrent();

          // only first roles before pending one is "active" (in blue in the stepper)
          // ==> following roles are still pending...
          isDone = false;
        }
        else
        {
          role.setIsDone(isDone);
        }

        return role;
      });

  }

  getActions() 
  {
    return this.actions;
  }

  
}
