<div *ngIf="showMeta">

  <!--<div *ngIf="showContent || !canEdit">
    <div class="spinner-div">
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>


    <table class="w-100 tbl">
      <tbody>
        <ng-container *ngFor="let chipData of chipContent; let i = index">
          <tr class="listitem doc-desc-list" role="listitem" (click)="showContent=!showContent"
            *ngIf="!!chipData.value && chipData.type!='date' && chipData.value && chipData.value.html  ">
            <td class="table-label" 
              width="100"> {{chipData.label}} :</td>
            <th>
              <span class="formValues"
                *ngIf="(chipData.value != '-' && chipData.value != 0 && chipData.value != '' &&  chipData.value != null  && chipData.value.value==undefined && chipData.value.html==undefined) || (chipData.value.value != '-' &&chipData.value.value != 0 && chipData.value.value != null && chipData.value.html != '-' && chipData.value.html != null)
              ">{{chipData.value.html || chipData.value}}</span>

              <span class="formValues"
                *ngIf="chipData.value == '' || chipData.value == '-' || chipData.value == null || chipData.value.value == '-' || chipData.value.value == null || chipData.value.html == '-' || chipData.value.html == null "></span>
            </th>
          <tr>

          <tr class="listitem doc-desc-list" role="listitem" (click)="showContent=!showContent"
            *ngIf="!!chipData.value && chipData.type=='date'  && chipData.value && chipData.value.html">

            <td  class="table-label"
              width="100">{{chipData.label}} :</td>
            <th>
              <span *ngIf="chipData.value != '-'">{{chipData.value.html || chipData.value | date: 'dd/MM/yyyy'}}</span>
              <span *ngIf="chipData.value == '-'">{{chipData.value.html || chipData.value }}</span>
            </th>

          </tr>
        </ng-container>  
      </tbody>
    </table>

  </div>
  <div *ngIf="!showContent && canEdit">-->

  <app-form-manager [inputData]="gedDoc?.content" [displayGroupe]="'meta'" [type]="'form'"
    (onFormCancelEvent)="onCancel()" (onFormSubmitEvent)="onUpdateMeta($event)" [showRecap]="true"
    [editForm]="iseditMeta" [canEdit]="canEdit" (callBack)="detectFormEdit($event)"
    [isExtension]="true"></app-form-manager>
  <!--<form #f="ngForm">
      <div *ngFor="let chipData of chipContent; let i=index">
        <ng-container appearance="fill" style="display: block; text-justify: center;">
          <mat-form-field class="form-dev"
            *ngIf="!chipData.showAuto && chipData.type!=='date' && !(chipData.isEnum) && !(chipData.isAuto) && chipData.control !=='checkbox'">
            <mat-label>{{chipData.label}} </mat-label>

            <input matInput type="{{chipData.type}}" [(ngModel)]="formValues[i]" name="{{chipData.meta}}">
          </mat-form-field>

          <mat-form-field class="form-dev" *ngIf="(chipData.isEnum) && chipData.control !=='checkbox'  ">
            <mat-label>{{chipData.label}}</mat-label>

            <mat-select name="enum"
                (selectionChange)="onSelectChange($event,i,chipData.enumValues)"
                [(value)]="formValues[i].value">
              <mat-option *ngFor="let enumItem of chipData.enumValues" [value]="enumItem.value">
                {{enumItem.html}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="form-dev" *ngIf="chipData.showAuto &&  chipData.control !=='checkbox' && chipData.control!=='select'">
            <mat-label>{{chipData.label}}</mat-label>

            <input matInput type="{{chipData.type}}" [(ngModel)]="formValues[i].html" name="{{chipData.meta}}"
              [matAutocomplete]="auto" (ngModelChange)="doFilter(i)" #optionfield="ngModel">

            <mat-autocomplete #auto="matAutocomplete">
              <mat-option *ngFor="let option of chipData.autoComplete" [value]="option.html">
                {{option.html}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="form-dev box"
            *ngIf="!chipData.showAuto && chipData.type==='date' && chipData.control !=='checkbox'">
             <mat-label>{{chipData.label}}</mat-label>

             <input
              matInput id="dateInput"
              [(ngModel)]="formValues[i]"
              name="{{chipData.meta}}"
              (dateChange)="OnDateChange(formValues[i],i)"
              [matDatepicker]="picker">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>

          </mat-form-field>

           <mat-form-field class="form-dev" *ngIf="chipData.control=='select' ">
             <mat-label>{{chipData.label}}</mat-label>
              <mat-select
                [(value)]="formValues[i].value"
                [(ngModel)]="formValues[i].value"
                [ngModelOptions]="{standalone: true}"
                (selectionChange)="onSelectChange($event,i,chipData.fieldAutoList)"
                name="{{chipData.key}}">
               <mat-option *ngFor="let option of chipData.fieldAutoList" [value]="option.value">
                 {{option.html}}
               </mat-option>
             </mat-select>
          </mat-form-field>

          <div *ngIf="chipData.control ==='checkbox' && chipData.isEnum  " class="checkbox-dev">
            <mat-label>{{chipData.label}}</mat-label>
           <!--  <section *ngFor="let item of chipData.enumValues  |keyvalue  " class="checkboxSection">
              <input type="checkbox" class="checkboxItem"
                (change)="onChange(item, $event.target.checked , chipData.label , i)"
                [checked]="isChecked(item.value , chipData.label)"> {{item.value}}<br>
            </section> -->
  <!-- <select multiple class="checkboxItem" >
              <option *ngFor="let item of chipData.enumValues" [value]="item.value" [selected]="isChecked(item.value, chipData.label)" (onSelectionChange)="onChange(item ,$event.target.selectedOptions, chipData.label, i)">
                {{item.html}}
              </option>
            </select> 
            <hr class="linecheckbox">
          </div>

          <div *ngIf="chipData.control ==='checkbox' && chipData.isAuto  " class="checkbox-dev">
            <mat-label>{{chipData.label}}</mat-label>
             <mat-select  
             [ngModelOptions]="{standalone: true}"
             [multiple]="true" 
             [(ngModel)]="chipContent[i].selectedValues"  
             >
               <mat-option *ngFor="let item of chipData.enumValues" [value]="item.html"   (onSelectionChange)="onChange(item ,$event, chipData.label, i)">
               
                {{item.html}}
              </mat-option>
            </mat-select>
           <!--  <section *ngFor="let item of chipData.enumValues  [selected]="isChecked(item.value, chipData.label)" " class="checkboxSection">
              <input type="checkbox" class="checkboxItem"
                (change)="onChange(item, $event.target.checked, chipData.label , i)"
                [checked]="isChecked(item.html , chipData.label)"> {{item.html}}<br>
            </section>
            <hr class="linecheckbox">

          </div>
        </ng-container>
      </div>

      <div class="col-md-12 actions" style="margin-top: 15px ; text-align: center;">
        <button auraButton (click)="onCancel()"  label="{{'cancelBtn' | translate | uppercase}}" class="btn-cancel">
        </button>
        <button
          auraButton
          type="light"
          [hasShadow]="true"
          label="{{'saveBtn' | translate | uppercase}}"
          (click)="onUpdateMeta(f.value)"
          [disabled]="!ischeckEdit && !f.dirty"></button>
      </div>

    </form>-->

</div>