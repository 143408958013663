<a [href]="link">
    <div class="badge-container" *ngIf="badge">
        <span class="badge">
            <span class="badge-content">
                {{badge}}
            </span>
        </span>
    </div>
    <div class="container">
        <img [src]="icon" id="logo" [alt]="label +' icon'">
    </div>
    <span>{{label}}</span>
</a>