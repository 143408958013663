import {Component, Input, OnInit} from '@angular/core';
import {GedService} from '../../../app-common/services';



@Component({
  selector: 'app-doc-revision',
  templateUrl: './doc-revision.component.html',
  styleUrls: ['./doc-revision.component.scss']
})
export class DocRevisionComponent implements OnInit 
{
  @Input('path') path;
  doc;
  revisions: any =[];

  currentDocId = localStorage.docId;

  constructor(private gedService: GedService) 
  {
    this.currentDocId = localStorage.docId;
    this.getDoc();

  }

  ngOnInit(): void 
  {
  }

  gedDoc
  @Input() set _gedDoc(value) 
  {
    this.gedDoc= value;
    this.getDoc()
  }
  getDoc()
  {
    if(this.gedDoc!=undefined)
    {
      this.doc = this.gedDoc.content.data;
      this.getRevision(this.doc.oid);

    }

  }

  //revesion doc

  getRevision(currentDocId)
  {
    this.gedService.getDocRevisions(currentDocId)
      .then((data)=>
      {

        this.revisions= data['content'];

      });

  }
  revisionDrive(link)
  {
    window.open(link, '_blank');
  }

}
