import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Card } from 'src/design-system/core/interfaces/card';
import { environment } from 'src/environments/environment';

const $theme = environment.themeUI;

@Component({
  selector: 'app-support',
  templateUrl: $theme + '/support.component.html',
  styleUrls: [$theme + '/support.component.scss'],
})
export class SupportComponent implements OnInit 
{
  constructor(private titleService: Title) 
  {
    this.titleService.setTitle('Support');
  }

  cards: Card[] = [
    {
      title: 'Tutorial',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo',
      src: '/assets/images/icon-youtube.svg',
      size: 55,
      link: '/support/tutorials',
    },
    {
      title: 'Costumer service',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo',
      src: '/assets/images/icon-send.svg',
      size: 55,
      link: '/support/tickets',
    },
    {
      title: 'General',
      text: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo',
      src: '/assets/images/icon-info.svg',
      size: 55,
    },
  ];

  ngOnInit(): void 
  {}
}
