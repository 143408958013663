import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ListItem } from '@interfaces/list-item';
import { TranslateService } from '@ngx-translate/core';
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { BreadcrumbService } from 'xng-breadcrumb';
import { Spacetype } from '../../models/type-space/spacetype.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-space-type',
  templateUrl: './space-type.component.html',
  styleUrls: ['./space-type.component.scss']
})

export class SpaceTypeComponent implements OnInit, OnChanges 
{
  listeSpaces: Spacetype[] = [];
  displaySpaces: ListItem[] = [];
  typeSpace: string;
  isOpen: boolean = false;
  activeTab: string = 'grid';
  static instance: SpaceTypeComponent
  bc: Crumb[] = [];

  canAddSpace = true;
  spaceNewModal: boolean = false;

  constructor(
    private titleService: Title,
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
  ) 
  {
    this.typeSpace = this.route.params["_value"]["type"];
    this.titleService.setTitle(this.typeSpace);
    SpaceTypeComponent.instance = this;
  }
  ngOnChanges(changes: SimpleChanges): void 
  {
    throw new Error('Method not implemented.');
  }

  async ngOnInit(): Promise<void> 
  {

    await this.translate.get('Name').toPromise();

    this.route.params.forEach((params) => 
    {
      const type = params['type'] || '';

      this.setBC(type);
    });
  }

  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  setBC(type = null) 
  {
    this.bc = [];

    if (type) 
    {
      const _type = this._(type);
      const _Groups = this._('Spaces');
      const _home =  environment.stringsFile.homeSideNav;

      this.bc.push(new Crumb(_home, (event, self) => 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["workspace/main"]));
      }));

      this.bc.push(new Crumb(_Groups, (event, self) => 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/s/groups/all"]));
      }
      ));

      this.bc.push(new Crumb(type, (event, self) => 
      { }));

    }
  }

  getData(data) 
  {
    let groups = [];

    if (data) 
    {
      groups = [...data['groups']]

      const group = groups.find((val) => val["title"] === this.typeSpace) || [];

      if (group && group['spaces']) 
      {
        group['spaces'].forEach(space => 
        {
          this.listeSpaces.push(new Spacetype(space));
        });
      }

      this.setDisplayProperties(this.listeSpaces);
    }
  }

  setDisplayProperties(results) 
  {
    Object.values(results).forEach(item => 
    {
      this.displaySpaces.push(item['displayProperties'][0])
    })
  }

  toggleView(view: string) 
  {
    this.activeTab = view;
  }

  open() 
  {
    this.isOpen = true;
  }

  close() 
  {
    this.isOpen = false;
  }

  rowClick(id) 
  {
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() =>
      this.router.navigate(["/s/space/" + id],
        {
          queryParams: { d: localStorage.host_domain },
        })
    );
  }
  openInDrive(id) 
  {
    window.open("https://drive.google.com/drive/folders/" + id, "_blank");
  }
}
