<div class="">
    <div class="row mb-3">
        <div class="col">
            <img src="/assets/images/icon-left-arrow-circle.svg" width="33" (click)="goback()" />
            <span class="align-middle d-inline-block ms-2 primary-color fw-bold">Retour</span>
        </div>
    </div>
    <div class="row gx-3">
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <button auraButton size="large" label="New Document" [full]="true" imageIcon="/assets/images/icon-plus.svg"
                type="success"  (click)="open()">
            </button>
            <app-new-document [isOpen]="isOpen" (close)="close()"></app-new-document>


        </div>
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <aura-input-text placeholder="Search by name" size="large" className="shadow-sm border-0"></aura-input-text>
        </div>
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <aura-input-text placeholder="Search by client" size="large" className="shadow-sm border-0"></aura-input-text>
        </div>
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <aura-input-text placeholder="Type of document" size="large" className="shadow-sm border-0"></aura-input-text>
        </div>
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <aura-input-text placeholder="State" size="large" className="shadow-sm border-0"></aura-input-text>
        </div>
        <div class="col col-sm-6 col-md-4 col-lg-2">
            <button auraButton size="large" label="Search" type="primary" [full]="true">
            </button>
        </div>
    </div>
    <div class="row py-3">
        <div class="col icons d-flex justify-content-end gap-2 view-icons">
            <button auraButton size="large" type="light" icon="gg-user-add" [full]="true" label="Share"></button>
            &nbsp;&nbsp;
            <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                (click)="toggleView('table')">
            &nbsp;&nbsp;
            <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                (click)="toggleView('grid')">
        </div>
    </div>
    <div *ngIf="activeTab === 'grid'" class="row grid">
        <aura-list [hasMenu]="true" [columns]="activeTab == 'grid' ? 4 : 1" verticalAlign="center" [data]="contracts">
        </aura-list>
    </div>
    <div *ngIf="activeTab === 'table'" class="row list hide">
        <div class="col">
            <aura-table-row [data]="data" [columns]="columns"></aura-table-row>

        </div>
    </div>
</div>
