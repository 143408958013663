export class OneData 
{
  meta;
  label;
  val;
  bgc;
  color;
  type;
  constructor(key, meta) 
  {
    this.setMeta(key);
    this.setLabel(meta);
    this.setBgc();
    this.setColor();
    this.setType();
  }

  /*Getter*/
  getMeta() 
  {
    return this.meta;
  }
  getLabel() 
  {
    return this.label;
  }
  getVal() 
  {
    return this.meta;
  }
  getBgc() 
  {
    return this.meta;
  }
  getColor() 
  {
    return this.meta;
  }
  getType() 
  {
    return this.meta;
  }
  /*setter*/

  setMeta(meta) 
  {
    this.meta = meta;
  }
  setLabel(label) 
  {
    this.label = label;
  }
  setVal() 
  {
    this.val = "";
  }
  setBgc() 
  {
    this.bgc = "white";
  }
  setColor() 
  {
    this.color = "black";
  }
  setType() 
  {
    this.type = "";
  }
}
