import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchResultItem } from "src/modules/engines/models/search-engine-result";
import { SearchEngine } from "src/modules/engines/models/search-engine";
// import { search_config } from "src/environments/config/search_config";
import { SearchGdriveService } from "../services/search-gdrive.service";
import { GdriveDocument } from "./gdrive-document";
import { GdriveFolder } from "./gdrive-folder";
import { SearchGdriveFacet } from "./search-gdrive-facet";

import { stringFile } from "src/assets/strings/strings";
import { environment } from 'src/environments/environment';
const DOTS_ENABLED = environment.DOTS_ENABLED

export class SearchGedEngine extends SearchEngine 
{
  searchGdriveService: SearchGdriveService;
  facets = [];

  strings = stringFile.stringsJson;

  constructor(
    searchGdriveService?,
    clientContexts?
  )
  {
    super();
    this.searchGdriveService = searchGdriveService;
  }

  setEngine()
  {
    this.engine = "ged";
  }

  setResults(
    engineResponse, // data returned by this engine
    prefixUrl = '',
    keyWord = '',
    isExtension
  ): SearchResultItem[] 
  {
    const items = engineResponse?.items;

    if (!items)
    {
      return [];
    }

    // folders
    const folders = (items?.folders || [])
      .map(item =>
        new GdriveFolder(item));

    // documents
    let files = (items?.files || [])
      .map(item =>
        new GdriveDocument(item, true));

    files = this.sortResults(files);

    this.results = [...files, ...folders];

    this.setDisplayProperties(this.results, prefixUrl, isExtension);

    this.getColumns(isExtension);

    return this.results;
  }

  _(s: string): string 
  {
    return this.strings[s] || s;
  }

  displayProperties;

  setDisplayProperties(
    results: SearchResultItem[],
    prefixUrl = '',
    isExtension) 
  {
    this.displayProperties = results
      .map((item: SearchResultItem) => 
      {
        return item.getDisplayProperties(prefixUrl, isExtension);
      });
  }

  sortResults(files) 
  {
    const sortFname = "rating_int";
    const direction = -1;

    const files2 = files.sort((a, b) => 
    {
      return (a.prop(sortFname) - b.prop(sortFname)) * direction;
    });

    return files2;
  }

  columns: TableColumn[];

  getColumns(isExtension: boolean = false) 
  {
    this.columns =
            [
              {
                id: 'name',
                type: 'TextWithIcon'
              },
              {
                id: 'Flags',
                type: 'HTML'
              },
              {
                id: "Tags",
                label: "Tags",
                width: 110,
              },
              {
                id: "owner",
                label: "Creator",
                type: "Text",
                width: 110,
                canSort: true,
              },

              {
                id: 'type',
                type: 'Text',
                label: 'Type',
              },
              {
                id: 'lastModified',
                type: 'Date',
                label: 'Last Modified',
              },
              {
                id: 'rating', 
                type: isExtension ? 'HTML' : 'Text',
                label: 'Rating',
        }
      ];

    if (DOTS_ENABLED) {
      this.columns.push(

        {
          id: 'Menu',
          type: 'Menu',
          label: 'Actions',
        }
      )
    }
    return this.columns;
  }

  getDisplayProperties() 
  {
    return this.displayProperties;
  }
  getResults() 
  {
    return this.results;
  }

  setEngineOptions() 
  {
    this.engineOptions = { engine: this.getEngine() }
  }

  setFacets(engineResponse) 
  {
    if (!engineResponse)
    {
      return [];
    }

    // get list of facets declared by space (in backend)
    const spacesInfos = engineResponse?.infos?.spaces;

    if (!spacesInfos) 
    {
      if (engineResponse.code == 403)
      {
        this.authError(
          {
            code: engineResponse.code,
            message: engineResponse.message || ''
          });
      }
    }

    // extract facet values from results
    // NB. future version should not depend on results for populating facets?
    const facetsByType = {}

    for (const sid in spacesInfos) 
    {
      const spaceInfos = spacesInfos[sid];

      // get facets from document type
      const facetIdList = spaceInfos.facets?.fields;
      const filterLabels =
                [
                  "type_document",
                  "space"
                ];

      const facetIds: Array<string> = (facetIdList || '')?.split(',');

      filterLabels.forEach(item => 
      {

        facetIds.unshift(item)
      })


      const facetFields = spaceInfos.facetInfos?.fields || {};

      facetIds.forEach(fname => 
      {
        if (!facetFields[fname]) 
        {
          facetFields[fname] =
                    {
                      // FY : todo , get I8n labels instead...
                      label: this.labelFromId(fname),
                      type: 'string'
                    }
        }
      });

      facetsByType[sid] = [];

      facetIds.forEach(fname => 
      {
        const label = facetFields[fname]?.label;

        const facet = new SearchGdriveFacet(
          {
            type: sid, // type => list of facets
            id: fname,
            label,
            values: new Set()
          });

        facetsByType[sid].push(facet);
        this.facets.push(facet);

        return facet;
      });
    }

    // add standard facets
    const defaultFacets = [];

    facetsByType[''] = [];
    ['type'].forEach(label => 
    {
      const facet = new SearchGdriveFacet({
        type: '',
        label,
        values: new Set()
      });

      facetsByType[''].push(facet);
      defaultFacets.push(facet);
      this.facets.push(facet);
    });

    // extract values for each facet from results
    const results = this.getResults();

    this.processResults(results, facetsByType, defaultFacets)

    /*
        this.facets.forEach(f=>
            f.setValuesFromResults(f?.type,
                results));
        */

    // remove facets with no value in results
    this.facets = this.facets.filter((f: SearchGdriveFacet) => f.hasValues());

    return this.facets;
  }

  labelFromId(fname) 
  {
    return fname
      .replace('_', ' ')
      .split(' ')
      .map(n =>
        n.charAt(0).toUpperCase() + n.slice(1)
      )
      .join(' ');
  }

  processResults(results, facetsByType, defaultFacets) 
  {
    results.forEach(item => 
    {
      defaultFacets.forEach(facet => 
      {
        facet.addValue(item);
      });

      let sid = item.properties?.sid;

      if (sid) 
      {
        sid = sid.replace('main.', '');

        if (sid && facetsByType[sid]) 
        {
          facetsByType[sid].forEach(facet => 
          {
            facet.addValue(item);
          })
        }
      }
    });
  }

  authError(code) 
  {

  }

  getFacets() 
  {
    return this.facets;
  }
}
