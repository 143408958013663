import { availableLocales } from "../i18n";
import englishJson from "src/assets/i18n/en.json";

export const stringFile={
  stringsJson: returnNavObjectFile(),
  defaultStringFile: englishJson
}

export function returnNavObjectFile()
{
  const locale = availableLocales.find(item =>
    navigator.language.startsWith(item.localeName)
  );

  const navLanguage = (locale && locale.languageJson) || englishJson;

  return navLanguage;
}
