<app-sidenav title="{{'tasksString' | translate}}" [breadcrumb]="bc">
    <div class="" >
        <div class="row pb-3">
            <div class="col icons d-flex justify-content-end gap-2 view-icons">
                <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                    (click)="toggleView('table')">
                <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                    (click)="toggleView('grid')">
            </div>
        </div>

        <ng-container *ngIf="!isLoading">
            <div *ngIf="activeTab === 'grid'" class="row grid">
                <div class="col-12 col-md-6 col-lg-6">
                    <div class="box">
                      <!-- <aura-title>{{ 'My tasks' | translate }}</aura-title>

                      <aura-list [globalMenuItems]="globalMenu"
                        verticalAlign="center"
                        [hasMenu]="true"
                        [data]="TasksListe"></aura-list> -->

                        <aura-list [data-title]="'My tasks' | translate"
                            [hasGlobalMenu]="true" [globalMenuItems]="globalMenu"
                            verticalAlign="center"
                            [hasMenu]="true"
                        [data]="TasksListe"></aura-list>

                      <p *ngIf="TasksListe.length == 0">{{'no_tasks_left' | translate}}</p>
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6">
                    <div class="box">
                      <!-- <aura-title>{{ 'Team tasks' | translate }}</aura-title>

                      <aura-list [globalMenuItems]="globalMenu"
                        verticalAlign="center"
                        [hasMenu]="true"
                        [data]="TasksTeam"></aura-list> -->

                        <aura-list [data-title]="'Team tasks' | translate"
                            [hasGlobalMenu]="true" [globalMenuItems]="globalMenu"
                            verticalAlign="center"
                            [hasMenu]="true"
                        [data]="TasksTeam"
                        title="{{'Team tasks' | translate}}"></aura-list>

                      <p  *ngIf="TasksTeam.length == 0">{{'no_tasks_left' | translate}}</p>
                    </div>
                </div>
            </div>


        </ng-container>
    </div>
</app-sidenav>
