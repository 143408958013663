import { TasksModule } from 'src/modules/tasks/tasks.module';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MaterialModule } from "../modules/material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { UserModule } from "../modules/user/user.module";
import { SpacesModule } from "../modules/spaces/spaces.module";
import { AppCommonModule } from "../modules/app-common/app-common.module";
import { DocumentsModule } from "../modules/documents/documents.module";
import { ErrorsModule } from "../modules/errors/errors.module";
import { SearchModule } from "../modules/search/search.module";
import { SearchGenericModule } from "../modules/search-generic/search-generic.module";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOptionModule,
  NativeDateModule,
} from "@angular/material/core";
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

import { PeopleModule } from "src/modules/people/people.module";
import { WorkspaceModule } from "src/modules/workspace/workspace.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ContactModule } from "src/modules/contact/contact.module";

// Angular Material
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient } from "@angular/common/http";

// import ng-bootstrap
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';


import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DesignSystemModule } from "src/design-system/design-system.module";
import { MetatableModule } from "src/modules/metatable/metatable.module";
import { CrudManagerModule } from "src/modules/crud/crud-manager.module";

import { TokensInterceptor } from './tokens.interceptor';
import { environment } from 'src/environments/environment';

const oauthConfig = {
  google: {
    api: environment.BACKEND_OAUTH_URL,
    successLogoutRedirectTo: '/in/login',
    successLoginRedirectTo: '/workspace/main',
    buttons: {
      redirect: {
        text: environment.LOGIN_TEXT

      }
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    MaterialModule,
    ScrollingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    UserModule,
    SpacesModule,
    AppCommonModule,
    DocumentsModule,
    MatChipsModule,
    MatIconModule,
    MatBadgeModule,
    ErrorsModule,
    SearchModule,
    SearchGenericModule,
    TasksModule,
    NgxDocViewerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NativeDateModule,
    MatMomentDateModule,
    PeopleModule,
    MatOptionModule,
    WorkspaceModule,
    ContactModule,
    NgbModule,
    DesignSystemModule,
    CrudManagerModule,
    MetatableModule,
    NgbDropdownModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    //this one renders the app after refreshing page (on pm2/node server)
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: "fr-FR" },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    //{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    CookieService,

    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokensInterceptor, multi: true
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule 
{ }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader 
{
  return new TranslateHttpLoader(http);
}
