<mat-toolbar class="toolbar-tools">
  <div class=" ">
    <img src="assets/logos/presencedocs.png" class="logo" >
  </div>

  <div class="buttonTool">
    <button mat-icon-button (click)="openInDrive()" matTooltip="{{'openDocDriveTT' | translate}}" matTooltipPosition="below">
      <mat-icon>open_in_new</mat-icon>
    </button>

    <button mat-icon-button (click)="toggleBar()" matTooltip="{{'fullscreenTT' | translate}}" matTooltipPosition="below" >
      <mat-icon>fullscreen</mat-icon>
    </button>
  <!--NEW BUTTON-->
  <mat-list-item class="newBtnItem " *ngIf="dataAdd && dataAdd?.Add?.length!=0">
    <button mat-raised-button [matMenuTriggerFor]="new" class="newDocBtn" >
      <mat-icon class="material-icons-round" >add_circle  </mat-icon>
    </button>
     <mat-menu #new="matMenu" xPosition="after"  >
      <div  *ngIf=" dataAdd?.Add?.length !==0">
       <button mat-menu-item  (click)="openDocumenttypeDialog()" >
        <mat-icon class="material-icons-round newElementIcon" >    add_circle </mat-icon>Add     {{dataAdd?.Add[0]['title']}}
      </button> 
      </div>


    </mat-menu>
   

  </mat-list-item>

    <button mat-icon-button [matMenuTriggerFor]="doc_options" matTooltip="{{'deleteDocTT' | translate}}" matTooltipPosition="below">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #doc_options="matMenu" xPosition="after">

      <button mat-menu-item class="newElementBtn" *ngIf="inGed" (click)="detachFromGed()">
          <i class="material-icons-round optionIcon">clear</i>
          {{'detachDoc' | translate}}
      </button>
      <button mat-menu-item class="newElementBtn" (click)="deleteDoc()">
        <i class="material-icons-round optionIcon">delete</i>
        {{'deleteDocTT' | translate}}
    </button>


  </mat-menu>
  </div>


</mat-toolbar>

<div *ngIf="!inGed" style="height: 100%;">
  <app-doc-add (emitState)="updateDoc($event)"></app-doc-add>
</div>

<div *ngIf="inGed">
  <mat-tab-group  [ngStyle]="{'backgroundColor' : white}" [color]="'accent'" >
      <mat-tab label="{{'infos' | translate | uppercase}}" >
        <app-doc-desc [path]="path" [gedDoc]="doc" (emitState)="updateMetas($event)"></app-doc-desc>
        <app-doc-workflow [path]="path" [gedDoc]="doc" ></app-doc-workflow>
      </mat-tab>

      <mat-tab label="{{'links' | translate | uppercase}}" *ngIf="showLinks">
         <app-doc-links [path]="path" [gedDoc]="doc"  (emitState)="doSomething($event)" (emitAdd)="getData($event)"></app-doc-links>
      </mat-tab>

  </mat-tab-group>

</div>
