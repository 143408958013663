
<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="titleDocument" [fullscreen]="false">
  <div class="row justify-content-center spinnerLoading">

    <mat-spinner *ngIf="isLoading"></mat-spinner>

  </div>

  <div >

    <ng-container>
      <form #f="ngForm">
        <div class="form-folder" >
          <aura-input-text ngDefaultControl
          placeholder="{{'NomStrg'|translate}}" label="{{'NomStrg'|translate}}"
          [formControl]="folderName"
          (keydown.enter)="onCreateFolder(folderName)"
          >
        </aura-input-text>

       </div>
      </form>

    </ng-container>


      <div  class="col-12 actions  mb-2" align="end">
        <div  class="action-btn">
          <button auraButton   (click)="closeModal()"  label="{{'cancelBtn' | translate}}"  class="cancelBtn"> </button>
          <button auraButton type="light" [hasShadow]="true" label="{{'addBtn' | translate}}" (click)="onCreateFolder(folderName)" [disabled]="!f.valid" ></button>  
          
        </div>
      </div>
    </div>



</aura-modal>
