
<!-- <aura-title icon="/assets/images/icon-envolope.svg">{{ 'peoples' | translate }}</aura-title>

<aura-list titleSize="h4"
  [data]="displayProperties"
  [hasRightText]="true"
  [isImageCirle]="true"
  [maxHeight]="315"></aura-list> -->

<aura-list
  icon="/assets/images/icon-envolope.svg"
  [data-title]="peoples"
  [data]="displayProperties"
  [hasRightText]="true"
  [isImageCirle]="true"
  [maxHeight]="315"></aura-list>
