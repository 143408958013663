<div class="h-100 d-flex flex-column">
    <div class="">
        <form class="row">
            <div class="col pe-0 me-0">
                <aura-input-text id="search" className="shadow-sm border-0" size="large"></aura-input-text>
            </div>
            <div class="col-4 col-md-2 me-0">
                <button auraButton size="large" type="primary" [full]="true" label="Search"></button>
            </div>
        </form>

        <div class="row">
            <div class="col mt-4">
                <aura-table-solid [data]="data" [columns]="columns"></aura-table-solid>
                <app-list-of-accesses [isOpen]="assignAccess" (close)="assignAccess = false"></app-list-of-accesses>
            </div>
        </div>
    </div>

</div>
