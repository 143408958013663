import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TaskService } from '../../services/task.service';
import { switchMap, tap } from 'rxjs/operators';
import { GedTask } from '../../model/ged-task.model';
import { ListItem } from '@interfaces/list-item';
import { MatDialog } from "@angular/material/dialog";
import { TableRow } from '@pdoc/aura';
import { environment } from 'src/environments/environment';
import { TableColumn } from '@interfaces/table';
import { TranslateService } from '@ngx-translate/core';
import { GedFile } from 'src/modules/documents/models';
import { EmailToNamePipe } from 'src/modules/explorer/pipes/email-to-name.pipe';
import { Router } from '@angular/router';
import { ModalApproveAllDocumentsComponent } from './modal-approve-all-documents/modal-approve-all-documents.component';
import { WorkflowService } from 'src/modules/workflow/services';

const CURRENT_URL = "/t/all/view/table";
const defaultPictureDoc = environment.defaultPictureDesign;
const DOTS_ENABLED = environment.DOTS_ENABLED

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit 
{
  myTasks
  groupTasks
  approbations
  columns
  taskTableData
  approbationsList
  flaggedTasks
  flaggedTasksTableData
  loading=true
  approveAllDocuments: any = {bool: false};
  chipFilter = 'All';
  timeoutTruthTable: boolean = true;
  displayWarningMessage: boolean = false;
  chipsData = [
    {label:  environment.stringsFile.All, icon: 'check', activeClass: 'chip-active', filter: 'All'},
    {label:  environment.stringsFile.approvals, icon: '', activeClass: '', filter: 'Approbations'},
    {label:  environment.stringsFile.flagged, icon: '', activeClass: '', filter: 'Flagged'}
  ];

  @ViewChild('myTemRef') myTemRef!: TemplateRef<any>;

  constructor(
    private taskService: TaskService,
    private workflowService: WorkflowService,
    private translate: TranslateService,
    private emailToNamePipe: EmailToNamePipe,
    private router: Router,
    public dialog: MatDialog
  ) 
  { }

  ngOnInit(): void 
  {
    setTimeout(() => {this.timeoutTruthTable = false}, 4000);
    this.getApprobations()
      .pipe(
        switchMap(
          () =>  this.taskService.getFlaggedTasksObs()
        ),
        tap(
          flaggedTasks => 
          { 
            // this.flaggedTasks = this.prepareTable(flaggedTasks ); 
            this.flaggedTasksTableData = this.prepareTableFlaggedTable( flaggedTasks as any);
            this.loading= false
          }
        )
      )
      .subscribe()
  }

  updateClickedChipAndFilter(chipIndex: number) 
  {
    this.chipsData.forEach((chip, index) => 
    {
      chip.activeClass = index === chipIndex ? 'chip-active' : '';
      chip.icon = index === chipIndex ? 'check' : '';
      if (index === chipIndex) this.chipFilter = chip.filter;
    });
  }

  prepareTableFlaggedTable(files: GedFile[]): TableRow[][]
  {
    const fileTable: TableRow[][] = []

    for (const f of files)
    {

      fileTable.push( this.getDisplayDataTableFlagged(f, CURRENT_URL));

    }

    return fileTable;
  }
  getApprobationsForAllDocuments() 
  {
    /* Open the modal to ask for confirmation */
    const dialogRef = this.dialog.open(ModalApproveAllDocumentsComponent, {
      height: '224px',
      width: '600px',
    });

    dialogRef.componentInstance.approveAllDocuments = this.approveAllDocuments;
    /* Post function to run when the modal is getting closed */
    dialogRef.afterClosed().subscribe(() => 
    {
      if (this.approveAllDocuments?.bool) 
      {
        this.taskService.approveAllDocuments(this.approbations, this.workflowService);
        this.taskTableData = [];
        this.displayWarningMessage = true;
      }
      this.approveAllDocuments.bool = false;
    });
  }
  getApprobations() 
  {


    return this.taskService.getTasksApprobations().pipe(

      tap(response => 
      {
        this.myTasks = response.myTasks
        this.groupTasks = response.groupTasks
        this.approbations = response.myTasks.concat(response.groupTasks)

        this.approbationsList = this.prepareTaskList(this.approbations);

        this.columns = this.getTableColumns();

        this.taskTableData = this.prepareTaskTable(this.approbations);
      })
    )
  }


 
  prepareTaskList(tasks: GedTask[]): ListItem[] 
  {
    const taskList: ListItem[] = []
    let index = 0;

    for (const t of tasks) 
    {
      taskList.push(this.getDisplayListProperties(t, index++, CURRENT_URL));
    }

    return taskList;
  }


  prepareTable(files: GedTask[]): TableRow[][]
  {
    const fileTable: TableRow[][] = []
  
    for (const f of files)
    {
  
      fileTable.push( this.getDisplayDataTable(f  , CURRENT_URL));

    }
  
    return fileTable;
  }
  

  getTableColumns(): TableColumn[] 
  {
    const cols: TableColumn[] =
      [
        {
          id: 'title',
          type: 'TextWithIcon',
          width: 320,
        },
        {
          id: "flag",
          label: "",
          type: "HTML",
          width: 100,
        },
        {
          id: "tags",
          //label: "Tags",
          width: 110,
        },
        {
          id: 'owner',
          type: 'Text',
          width: 110,
          //label: this._('Owner'),
          //label: "",
          //canSort :true
        },
        {
          id: "doctype",
          //label: "Doc type",
          type: "Text",
          width: 110,
          //canSort: true,
        },
        {
          id: "lastEdit",
          //label: "Last edit",
          type: "Date",
          width: 110,
          //canSort: true,
        },
        {
          id: "rating",
          //label: "Rating",
          type: "Text",
          width: 110,
          class: "yellow-text",
          //canSort: true,
        }
      ];


    if (DOTS_ENABLED) {
      cols.push({
        id: 'menu',
        type: 'Menu',
      },)
    }

    return cols;
  }


  prepareTaskTable(tasks: GedTask[]): TableRow[][] 
  {
    const taskTable: TableRow[][] = []

    for (const t of tasks) 
    {

      taskTable.push(this.getDisplayDataTable(t, CURRENT_URL));
    }

    return taskTable;
  }

  // prepare infos for table view
  getDisplayDataTable(t: GedTask, prefixURL: string): TableRow[] 
  {
    const dataTable =
      [
        {
          id: 'title',
          value: t.getName(),
          iconImg: t.getIcone(),
          file: t.data.resource.file,
        },
        {
          id: "flag",
          html: this.myTemRef,
          file: t.data.resource.file,
          context: { title: t.source, class: t.className }
        },
        {
          id: 'tags',
          file: t.data.resource.file,
          value: this.transform(
            (t.getTags() || '').split(',').join(', ')
            , 30)
        },
        {
          id: 'owner',
          file: t.data.resource.file,
          value: t.getOwner() || "",
        },
        {
          id: 'doctype',
          file: t.data.resource.file,
          value: t.getDocType() || ""
        },
        {
          id: 'lastEdit',
          file: t.data.resource.file,
          value: t.getModifiedTime(),
        },
        {
          id: 'rating',
          file: t.data.resource.file,
          value: t.getRating(),
        }
      ] as any[];

    if (DOTS_ENABLED) {
      dataTable.push({
        id: 'menu',
        value: '',
        menuItems:
          [
            {
              label: this._('Ouvrir'),
              callback: (row: TableRow) => {
                this.openDocument(t);
              },
            }
          ]
      })
    }

    return dataTable;
  }

  transform(tags: string, at: number) 
  {
    if (tags && tags.length && tags.length > at) 
    {
      return tags.substring(0, at - 3).concat('...');
    }
    else 
    {
      return tags
    }
  }

  // prepare infos for list view
  getDisplayListProperties(t: GedTask, index: number, prefixURL: string): ListItem 
  {
    console.log('t : ', t)
    const displayProperties =
    {
      id: index,
      //id: t.getUid(),
      subtitle: t.getType(),
      title: t.getName(),
      text: this._('state') + " : " + t.getState(),
      image: t.getIcone(),
      redirectTo: prefixURL + '/file/' + t.getUid(),

      menuItems:
        [
          {
            label: this._('Open'),
            callback: () => 
            {
              this.openDocument(t);
            },
          }
        ]
    }

    return displayProperties;
  }
  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  getDisplayDataTableFlagged(f: any, prefixURL?: string) : TableRow[]
  {

    const owner= this.transform(
      (f as any)?.data?.properties?.owner, 30)

    const dataTable   =
        [
          {
            id: 'title',
            value: f.getName(),
            iconImg: f.getIcone(),
            file: f?.data
          },
          {
            id: "flag",
            file: f?.data,
            html: this.myTemRef
          },
          {
            file: f?.data,
            id: 'tags',
            value: this.transform(
              (f.getTags() || '').split(',').join(', ')
              , 30)
          },
          {
            file: f?.data,

            id: 'owner',
            value: owner
          },
          {
            id: 'doctype',
            file: f?.data,
            value: f.getDocType() || ""
          },
          {
            id: 'lastEdit',
            file: f?.data,
            value: f.getModifiedTime(),
          },
          {
            id: 'rating',
            file: f?.data,
            value: f.getRating(),
        }

      ] as any[];

    if (DOTS_ENABLED) {
      dataTable.push(
        {
            id: 'menu',
            value: '',
            menuItems:
              [
                {
                  label: this._('Ouvrir'),
                  callback: (row) => this.openDocument(f),
                }
              ]
          }
      )
    }
    return dataTable ;
  }
  
  openDocument(file : GedTask) 
  {
    const url = CURRENT_URL + '/flagged/file/' + file.getUid();

    this.router.navigate([url], { queryParams: { d: localStorage.host_domain } });
  }
}
