import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SearchGenericService } from 'src/modules/search-generic/services/search-generic.service';

@Component({
  selector: 'app-search-extension',
  templateUrl: './search-extension.component.html',
  styleUrls: ['./search-extension.component.scss']
})
export class SearchExtensionComponent implements OnInit 
{

  @ViewChild("myTemRef") myTemRef!: TemplateRef<any>;
  @ViewChild("temRating") temRating!: TemplateRef<any>;

  isLoading: boolean;
  searchResponseObservable: Observable<{results:any, facets:any}>; // Assurez-vous de déclarer le type correct ici
  searchResponse: any;
  @Input() inputCleared: boolean = false;
  multipleResults: boolean = false;
  input : string ="";
  dataShowResults= [
    [
      {
        id: 'title',
        value: environment.stringsFile.ShowAllResults,
        onClick: () => 
        {
          window.location.href = '/#/search?q=' + this.input;
        },
        
      },
     
    ]]

  columnsShowResults= [
    {
      id: 'title',
      type: 'TextWithIcon',
      width: 260,
    }
  ]

  constructor(private searchGenericService: SearchGenericService) 
  { }

  ngOnInit(): void 
  {
  }

  async searchInPresenceDoc(event)
  {
    
    // Attendre 30 secondes (30000 millisecondes)
    await new Promise(resolve => setTimeout(resolve, 500));
    if (event?.target?.value !== "") 
    {
      this.input = event?.target?.value
      this.inputCleared= false;
      this.isLoading = true;

      // Ensuite, exécuter votre code
      this.searchResponse = await this.searchGenericService.execSearch(event?.target?.value, [], '', [], '', true);
      const results = this.searchResponse.results || [];

      this.isLoading = false;

      this.searchResponseObservable = of(results);
      this.multipleResults = false;
      
      (this.searchResponse?.results || []).forEach(object => 
      {
        object.results.forEach((outerResult) => 
        {
          this.multipleResults = true;
          outerResult.displayProperties.forEach((innerResult, index) => 
          {
            if (innerResult.id === "Flags") 
            {
              innerResult.html = this.myTemRef;
            }
            if (innerResult.id === "rating") 
            {
              innerResult.html = this.temRating;
            }
            if (innerResult.id === "Menu" || innerResult.id === "type") 
            {
              outerResult.displayProperties.splice(index, 1);
            }
          });
        });

       
      })
    }
    else 
    {
      this.inputCleared= true;
      this.isLoading = false;
    }
  }

  clearButton()
  {
    this.inputCleared= true;
  }
}
