import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TableColumn, TableRow } from 'src/design-system/core/interfaces/table';

@Component({
  selector: 'app-users',
  templateUrl: 'bootstrap/users.component.html',
  styleUrls: ['bootstrap/users.component.scss'],
})
export class UsersComponent implements OnInit 
{
  assignAccess: boolean = false;

  columns: TableColumn[] = [
    {
      id: 'checkbox',
      type: 'Checkbox',
      label: 'Select',
    },
    {
      id: 'name',
      type: 'TextWithIcon',
      label: 'Name',
      canSort: true,
    },
    {
      id: 'email',
      type: 'Text',
      label: 'Email',
      canSort: true,
    },
    {
      id: 'role',
      type: 'Text',
      label: 'Role',
      canSort: true,
    },
    {
      id: 'status',
      type: 'Text',
      label: 'Status',
      canSort: true,
    },
    {
      id: 'more',
      type: 'Action',
      label: 'More',
      width: 40,
      _action: this.openPopup.bind(this),
    },
  ];
  data: TableRow[][] = [
    [
      {
        id: 'checkbox',
        value: '',
      },
      {
        id: 'name',
        value: 'Gilles Plamondon',
        iconImg: '/assets/images/user-1.png',
        isCircle: true,
      },
      {
        id: 'email',
        value: 'gillesplamondon@gmail.com',
      },
      {
        id: 'role',
        value: 'Admin',
      },
      {
        id: 'status',
        value: 'active',
      },
      {
        id: 'more',
        value: '',
        iconClass: 'gg-more-vertical-alt',
      },
    ],
    [
      {
        id: 'checkbox',
        value: '',
      },
      {
        id: 'name',
        value: 'Alard Leandres',
        iconImg: '/assets/images/user-2.png',
        isCircle: true,
      },
      {
        id: 'email',
        value: 'alardleandres@gmail.com',
      },
      {
        id: 'role',
        value: 'Admin',
      },
      {
        id: 'status',
        value: 'active',
      },
      {
        id: 'more',
        value: '',
        iconClass: 'gg-more-vertical-alt',
      },
    ],
    [
      {
        id: 'checkbox',
        value: '',
      },
      {
        id: 'name',
        value: 'Dione Sameul',
        iconImg: '/assets/images/user-3.png',
        isCircle: true,
      },
      {
        id: 'em ail',
        value: 'dionesameul@gmail.com',
      },
      {
        id: 'role',
        value: 'Admin',
      },
      {
        id: 'status',
        value: 'Disconnected',
      },
      {
        id: 'more',
        value: '',
        iconClass: 'gg-more-vertical-alt',
      },
    ],
  ];
  constructor(private titleService: Title) 
  {
    this.titleService.setTitle('Collaborators');
  }
  openPopup(row: TableRow) 
  {
    this.assignAccess = true;
  }
  ngOnInit(): void 
  {
  }
}
