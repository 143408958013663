import {
  DatePipe,
  NgClass,
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';

import { TableType } from '../table-type.pipe';
import { TableClass } from '../table-class.pipe';
import { ATableComponent } from '../table.component';
import { Dropdown, Loader, TextTruncate } from '@pdoc/aura';



@Component({
  exportAs: 'AuraTableRowComponent',
  selector: 'aura-table-row-extension',
  templateUrl: 'table-row.component.html',
  styleUrls: ['table-row.component.scss', '../table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DatePipe,
    NgbTooltipModule,
    NgClass,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgTemplateOutlet,
    RouterLink,
    TableType,
    TextTruncate,
    TableClass,
    Dropdown,
    Loader,
  ],
  standalone: true,
})
export class TableRowExtension extends ATableComponent 
{
  @Input() loading?: boolean = false;

  getMenuItems(items: any, row: any) 
  {
    return items.map((el: any) => 
    {
      return {
        label: el.label,
        callback: () => el.callback(row),
      };
    });
  }
}
