import { EventAnswerComponent } from "./event-answer/event-answer.component";
import { EventItemComponent } from "./event-item/event-item.component";
import { EventsComponent } from "./events/events.component";
import { FilesActionsComponent } from "./files-actions/files-actions.component";
import { PeopleComponent } from "./people/people.component";
import { RecentDocsComponent } from "./recent-docs/recent-docs.component";

export const components = [
  EventsComponent,
  EventItemComponent,
  FilesActionsComponent,
  PeopleComponent,
  RecentDocsComponent,
  EventAnswerComponent
];

export const entryComponents = [
  EventAnswerComponent,
  EventItemComponent
]


export * from "./event-item/event-item.component";
export * from "./event-answer/event-answer.component";
export * from "./events/events.component";
export * from "./people/people.component";
export * from "./recent-docs/recent-docs.component";