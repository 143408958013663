export type GedPermRole = "administrator" | "content manager" | "commenter" | "reader" | "none";

export type GedPermType = "user" | "group" | "domain";

// main infos for creating a new permission
export type GedPermissionPostInfos =
{
  type: GedPermType,
  email: string,
  role: GedPermRole,

  // if message is provided, send a notification to the user by email
  message?: string
};

// permission : who
export type GedPermWho =
{
  // user, group, domain
  type: GedPermType,

  // user or group info
  name?: string,
  email: string,
  photoLink?: string | null
}

// permission
export type GedPermission =
{
  id: string,
  who: GedPermWho,
  role: GedPermRole,
  canDelete: boolean
};

export type GedPermissionListResult =
{
  content: GedPermission[]
}

export type SpaceInfos = {
  name? : string,
  sid? : string,
  mainType? : number,
  groupName? : string,
  description? : string
}

export type SpaceId = string;
