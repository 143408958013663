<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="title">
    <div class="row">
        <div class="col-12 col-md-4">
            <h3 class="h3">Dashboard</h3>
        </div>
        <div class="col-12 col-md-8">
            <div class="row">
                <div class="col-3">
                    <aura-input-checkbox id label value></aura-input-checkbox>
                </div>
            </div>
        </div>
    </div>
</aura-modal>
