import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ListItem } from '@interfaces/list-item';
import { TableColumn, TableRow } from '@interfaces/table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Crumb } from 'src/modules/app-common/components/bread-crumb/bread-crumb.component';
import { TableElement } from 'src/modules/app-common/models/table-element/table-element.model';
import { GedService, DateService } from 'src/modules/app-common/services';
import { GedTask } from 'src/modules/tasks/model/ged-task.model';
import { TaskService } from 'src/modules/tasks/services/task.service';
const CURRENT_URL = "/t/all/view/table";
const defaultPictureDoc = environment.defaultPictureDesign;

@Component({
  selector: 'app-task-table',
  templateUrl: './task-table.component.html',
  styleUrls: ['./task-table.component.scss']
})
export class TaskTableComponent implements OnInit 
{



  displayedColumns: string[] = ['Name', 'Owner', 'Type', 'State'];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild('myTemRef') myTemRef!: TemplateRef<any>;

  showMytasks = true;
  showTeamtasks = true;

  isLoading = true;

  tasksList;
  myTasks = [];
  bc = [];
  teamTasks = []
  tempMytasks;
  tempTeamtasks;

  selectedElement: TableElement;
  gedSelectedElement;
  hostdomain = localStorage.host_domain

  constructor(
    private gedService: GedService,
    public dateService: DateService,
    private taskService: TaskService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,

  )
  {
  }

  async ngOnInit()
  {
    // await for the translate sce to be ready (after that, we can use instant() )
    await this.translate.get('Name').toPromise();

    const _home = environment.stringsFile.homeSideNav;
    const homeCrumb = new Crumb(_home,
      (event, self) => 
      {
        this.router
          .navigateByUrl("workspace/main");
      });



    this.bc = [homeCrumb, 'Tasks'];

    this.getTasks();
  }

  _(s : string) : string 
  {
    return this.translate.instant(s);
  }

  // tasks list view
  TasksListe: ListItem[] = [];
  TasksTeam: ListItem[] = []

  // tasks table view
  columns: TableColumn[];
  taskTableData: TableRow[][] = [];
  teamTaskTableData: TableRow[][] = []

  async getTasks()
  {
    this.isLoading = true;

    const tasksResponse = await this.taskService.getTasks();

    this.isLoading = false;
    this.tasksList = tasksResponse;

    // get tasks
    let myTasks = this.tasksList?.myTasks || [];
    const teamTasks = this.tasksList?.groupTasks || [];
  
    myTasks = myTasks.concat(teamTasks);

    this.TasksListe = this.prepareTaskList(myTasks);
    //this.TasksTeam = this.prepareTaskList(teamTasks);
   

    // prepare tables
    this.columns = this.getTableColumns();

    this.taskTableData = this.prepareTaskTable(myTasks);
    //this.teamTaskTableData = this.prepareTaskTable(teamTasks);
  }

  prepareTaskList(tasks: GedTask[]): ListItem[]
  {
    const taskList: ListItem[] = []
    let index = 0;

    for (const t of tasks)
    {
      taskList.push( this.getDisplayListProperties(t,index++,CURRENT_URL) );
    }

    return taskList;
  }

  prepareTaskTable(tasks: GedTask[]): TableRow[][]
  {
    const taskTable: TableRow[][] = []

    for (const t of tasks)
    {

      taskTable.push( this.getDisplayDataTable(t,CURRENT_URL) );
    }

    return taskTable;
  }

  /*
  prepareTable(taskList: GedTask[]): TableElement[]
  {
    let element: TableElement;
    let resultList = [];

    for (let t of taskList)
    {
      this.TasksListe.push(this.getDisplayListProperties(t,CURRENT_URL));

      //this.dataTask.push(this.getDisplayDataTable(t,CURRENT_URL));

      element =
      {
        Name: t.getName(),
        Owner: t.getOwner(),
        Type: t.getType(),
        State: t.getState(),
        uid: t.getUid(),
        path: t.getPath(),
        date: t.getDate()
      }

      resultList.push(element)
    }

    return resultList
  }
  */

  // prepare infos for list view
  getDisplayListProperties(t : GedTask, index:number, prefixURL: string) : ListItem
  {
    const displayProperties =
    {
      id: index,
      //id: t.getUid(),
      subtitle: t.getType(),
      title: t.getName(),
      text:   this._('state') +" : " + t.getState(),
      image: defaultPictureDoc,
      redirectTo: prefixURL+'/file/'+t.getUid(),

      menuItems :
      [
        {
          label: this._('Open'),
          callback: function ()
          {
            this.openDocument(t,prefixURL);
          },
        }
      ]
    }

    return displayProperties;
  }

  transform(tags: string, at: number) 
  {
    if (tags && tags.length && tags.length > at) 
    {
      return tags.substring(0, at - 3).concat('...');
    }
    else 
    {
      return tags
    }
  }

  // prepare infos for table view
  getDisplayDataTable(t: GedTask, prefixURL: string) : TableRow[]
  {
    const dataTable : TableRow[] =
      [
        {
          id: 'title',
          value: t.getName(),
          iconImg: t.getIcone(),
          link: prefixURL+'/file/'+t.getUid(),
        },
        {
          id: "flag",
          html: this.myTemRef,
          context: { title: t.source, class:t.className}
        },
        {
          id: 'tags',
          value: this.transform(
            (t.getTags() || '').split(',').join(', ')
            , 30)
        },
        {
          id: 'owner',
          value: t.getOwner() || "",
        },
        {
          id: 'doctype',
          value: t.getDocType() || ""
        },
        {
          id: 'lastEdit',
          value: t.getModifiedTime(),
        },
        {
          id: 'rating',
          value: t.getRating(),
        },
        {
          id: 'menu',
          value: '',
          menuItems:
            [
              {
                label: this._('Ouvrir'),
                callback: function (row: TableRow)
                {
                  this.openDocument(t);
                },
              }
            ]
        }
      ];

    return dataTable;
  }

  openMyDrawer(element, list: any[])
  {
    this.selectedElement = element;

    for (const item of list)
    {
      if (element.Name == this.gedService.getFileName(item.path))
      {
        this.gedSelectedElement = item;

        if (!this.drawer.opened)
        {
          this.drawer.open();
        }
      }
    }
  }

  closeDrawer()
  {
    this.drawer.close();
  }

  openDocument(task : GedTask) 
  {
    this.taskService.openDocument(task,CURRENT_URL);
  }

  rowIsSelected(row) 
  {
    //return this.selectedRowIds.has(id);
    return this.selectedElement == row;
  }

  toggleView(view: string)
  {
    this.router.navigate(['/t/all/view/grid'])  ;
    // this.activeTab = view;
  }


  activeTab: string = 'table';

  globalMenu: {
    label: string;
    callback: Function;
  }[] =
      [
        {
          label: 'Sort by name',
          callback: function ()
          {
            console.log('Sort by name');
          },
        },
        {
          label: 'Sort by date',
          callback: function () 
          {
            console.log('Sort by date');
          },
        },
        {
          label: 'Sort by type',
          callback: function () 
          {
            console.log('Sort by type');
          },
        }
      ];

  getTableColumns() : TableColumn[]
  {
    const cols : TableColumn[] =
    [
      {
        id: 'title',
        type: 'TextWithIcon',
        width: 320,
      },
      {
        id: "flag",
        label: "",
        type: "HTML",
        width: 100,
      },
      {
        id: "tags",
        //label: "Tags",
        width: 110,
      },
      {
        id: 'owner',
        type: 'Text',
        width: 110,
        //label: this._('Owner'),
        //label: "",
        //canSort :true
      },
      {
        id: "doctype",
        //label: "Doc type",
        type: "Text",
        width: 110,
        //canSort: true,
      },
      {
        id: "lastEdit",
        //label: "Last edit",
        type: "Date",
        width: 110,
        //canSort: true,
      },
      {
        id: "rating",
        //label: "Rating",
        type: "Text",
        width: 110,
        class: "yellow-text",
        //canSort: true,
      },

      {
        id: 'menu',
        type: 'Menu',
      },
    ];

    return cols;
  }



}
