import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { GedService } from 'src/modules/app-common/services';
import { DocumentService } from 'src/modules/documents/services/document.service';

@Component({
  selector: 'app-space-doclinks',
  templateUrl: './space-doclinks.component.html',
  styleUrls: ['./space-doclinks.component.scss']
})
export class SpaceDoclinksComponent implements OnInit, OnChanges 
{

  @Input('document') currentDocument;
  @Output() emitState: EventEmitter<any> = new EventEmitter();

  links = [];
  showLinks = true;
  path = '/'
  currentDocId = localStorage.docId;
  title: string;
  doc;
  responseDoc;
  docUpdate;
  docType;
  mimeType;

  constructor(private gedService: GedService,
    private router: Router,
    private docService: DocumentService
  ) 
  {
  }

  ngOnChanges(changes: SimpleChanges): void 
  {
    this.onDocChange(changes.currentDocument.currentValue)
  }

  onDocChange(currentDoc) 
  {
    this.links = []
    this.path += this.currentDocument.Name;
    this.currentDocId = this.currentDocument.id
    this.mimeType = this.currentDocument.mimeType;
    //this.docService.getTypeFromIcon(this.iconLink)
    if (this.mimeType == 'application/vnd.google-apps.spreadsheet') 
    {
      this.path += '.gsheet'

    }
    if (this.mimeType == 'application/vnd.google-apps.document') 
    {
      this.path += '.gdoc'

    }
    if (this.mimeType == 'application/vnd.google-apps.presentation') 
    {
      this.path += '.gpres'
    }
    if (this.mimeType == 'application/vnd.google-apps.forms') 
    {
      localStorage.setItem('path', 'forms')
      this.path += '.forms'

    }

    this.getDoc(this.path, this.currentDocId);

  }

  ngOnInit(): void 
  {
  }



  async getDoc(path: string, currentDocId: string): Promise<void> 
  {
    try 
    {
      const data = await this.gedService.getDoc(currentDocId);

      this.responseDoc = data;
      this.doc = data.content.data;
      const gedDoc = data.content.data;

      this.title = this.gedService.getFileName(this.path);
      this.getLinks(path, currentDocId, gedDoc);
    }
    catch (error) 
    {
      console.error('Error getting document:', error);
    }
  }


  getLinks(path, currentDocId, gedDoc) 
  {
    this.gedService.getLinks(path, currentDocId)
      .then((resp) => 
      {
        this.isLoading = false;
        this.links = this.docService.filterLinks(resp)
        if (this.links.length == 0) this.showLinks = false;
        else this.showLinks = true;
        this.emitState.emit(this.showLinks);
      });

  }

  isLoading = true;

  toLink(linkdoc) 
  {
    this.docService.toLink(linkdoc);
  }
}
