import { ShortNamePipe } from './../explorer/pipes/short-name.pipe';
import { AuraxButtonComponent } from './../aurax/aurax-button/aurax-button.component';
import { EmailToNamePipe } from './../explorer/pipes/email-to-name.pipe';
import { FlaggingStatusComponent } from './components/flagging-status/flagging-status.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DocumentsRoutingModule } from './documents-routing.module';
import { AppCommonModule } from '../app-common/app-common.module';
import { MaterialModule } from '../material/material.module';
// import { NgxDocViewerModule } from 'ngx-doc-viewer';
import * as docContainers from './containers';
import * as docComponents from './components';
import * as docServices from './services';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FileInputConfig, MaterialFileInputModule, NGX_MAT_FILE_INPUT_CONFIG } from 'ngx-material-file-input';
import { DocInfosComponent } from './components/doc-infos/doc-infos.component';
import { DocInfosSidebarComponent } from './components/doc-infos-sidebar/doc-infos-sidebar.component';
import { DocWorkflowMetadataComponent } from './components/doc-workflow-metadata/doc-workflow-metadata.component';
import { DocWorkflowProcessusComponent } from './components/doc-workflow-processus/doc-workflow-processus.component';
import { DocWorkflowHistoricComponent } from './components/doc-workflow-historic/doc-workflow-historic.component';
import { DocDescInfoComponent } from './components/doc-desc-info/doc-desc-info.component';
import { DocRelatedComponent } from './components/doc-related/doc-related.component';
import { DocRevisionComponent } from './components/doc-revision/doc-revision.component';
import { DocLinksComponent } from './components';
import { DocRatingComponent } from './components/doc-rating/doc-rating.component';
import { FormManagerModule } from '../form-manager/form-manager.module';
import { DocListApprovalActivityComponent } from './components/doc-list-approval-activity/doc-list-approval-activity.component';
import { DocListCompletedTasksComponent } from './components/doc-list-completed-tasks/doc-list-completed-tasks.component';
import { DocListHistoricApprovalActivityComponent } from './components/doc-list-historic-approval-activity/doc-list-historic-approval-activity.component';
import { DocAvatorCreatorComponent } from './components/doc-avator-creator/doc-avator-creator.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SidePanelComponent } from './containers/document-page/side-panel/side-panel.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { NofileComponent } from './containers/document-page/nofile/nofile.component';
import { DialogComponent } from './containers/document-page/side-panel/dialog/dialog.component';

export const config: FileInputConfig = {
  sizeUnit: 'Octet'
};

@NgModule({
  imports: [
    // BrowserModule,
    AppCommonModule,
    CommonModule,
    DocumentsRoutingModule,
    MaterialModule,
    MaterialFileInputModule,
    ShortNamePipe,
    // NgxDocViewerModule,
    FormsModule,
    ReactiveFormsModule,
    // BrowserAnimationsModule,
    FormManagerModule,
    // AuraModule,
    FlaggingStatusComponent,
    MatTooltipModule,
    EmailToNamePipe,
    AuraxButtonComponent,
    MatSidenavModule,
    MatTabsModule
  ],
  declarations: [...docContainers.containers, ...docComponents.components, DocInfosComponent, DocInfosSidebarComponent, DocWorkflowMetadataComponent, DocWorkflowProcessusComponent, DocWorkflowHistoricComponent, DocDescInfoComponent, DocRelatedComponent, DocRevisionComponent, DocLinksComponent, DocRatingComponent, DocListApprovalActivityComponent, DocListCompletedTasksComponent, DocListHistoricApprovalActivityComponent, DocAvatorCreatorComponent, SidePanelComponent, NofileComponent, DialogComponent],
  providers: [...docServices.services,
    { provide: NGX_MAT_FILE_INPUT_CONFIG, useValue: config },
    EmailToNamePipe,
    DatePipe,
    ShortNamePipe
  ],
  exports: [...docContainers.containers, ...docComponents.components]
})
export class DocumentsModule 
{ }
