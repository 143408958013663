import { MaterialModule } from "src/modules/material/material.module";
import { AppCommonModule } from "src/modules/app-common/app-common.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CrudListComponent } from "./crud-list/crud-list.component";
import { GenericPageRoutingModule } from "./crud-component/crud-component-routing.module";
import { FormManagerModule } from "../form-manager/form-manager.module";
import { CrudComponent } from "./crud-component/crud-component.component";

@NgModule({
  declarations: [CrudListComponent, CrudComponent],
  imports: [
    GenericPageRoutingModule,
    CommonModule,
    AppCommonModule,
    MaterialModule,
    FormManagerModule,
    
  ],
  exports: [CrudListComponent, CrudComponent],
})
export class CrudManagerModule 
{}
