import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { ICON_SOURCE_ARRAY, TIconSource, TIconSources } from "src/assets/icon_sources";


@Injectable({
  providedIn: 'root'
})
export class AppIconService 
{

  private get _icons(): TIconSources 
  {
    return ICON_SOURCE_ARRAY;
  }

  constructor(
    private readonly _domSanitizer: DomSanitizer,
    private readonly _matIconRegistry: MatIconRegistry
  ) 
  { }

  initMatIcons(): void 
  {
    this._icons.forEach(({
      label,
      source
    }: TIconSource) => 
    {
      const safeResourceUrl: SafeResourceUrl = this._domSanitizer.bypassSecurityTrustResourceUrl(source);

      this._matIconRegistry.addSvgIcon(label, safeResourceUrl);
    });
  }

}
