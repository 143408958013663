
export type TIconSource = {
  label: string;
  source: string;
};

export type TIconSources = TIconSource[];

export const ICON_SOURCE_ARRAY: TIconSources = [
  {label:'event-icon', source:'assets/icons/calendar_icon.svg'},
  {label:'docs-icon', source:'assets/icons/docs_icon.svg'},
  {label:'docs-icon-default', source:'assets/icons/docs-icon1.svg'},
  {label:'gmail-icon', source:'assets/icons/gmail_icon.svg'},
  {label:'google-contacts', source:'assets/icons/google-contacts.svg'},
  {label:'drive_icon', source:'assets/icons/drive_icon.svg'},
  {label:'gapps', source:'assets/icons/gapps.svg'},
  {label:'mydrive', source:'assets/logos/google-drive.svg'},
  {label:'mydrive-white', source:'assets/logos/google-drive-active.svg'},
  {label:'companion', source:'assets/logos/companion.svg'},
  {label:'google-icon',  source:'assets/logos/google-icon.svg'},
  {label:'google-meet',  source:'assets/icons/google-meet.svg'},
  {label:'event-icon-transparent',  source:'assets/icons/calendar_icon_transparent.svg'}
];
