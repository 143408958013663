import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { InfosStepTask, Workflow, WorkflowAction } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';
//import  AvatarInitials  from 'avatar-initials';

@Component({
  selector: 'app-doc-list-approval-activity',
  templateUrl: './doc-list-approval-activity.component.html',
  styleUrls: ['./doc-list-approval-activity.component.scss']
})
export class DocListApprovalActivityComponent implements OnInit 
{

  @Input("path") path;
  @Input() set _gedDoc(value) 
  {
    // console.log('vvv', value)
    this.gedDoc = value;
  }
  @Input() currentDocId!: string;

  @Input() canEdit: boolean = true;

  @Output() emitState: EventEmitter<any> = new EventEmitter();

  // Propriétés du composant
  doc;
  actions: WorkflowAction[] = [];
  isLoading = true;
  showActions = true;
  description: string;
  titleOfActions: string;
  noHistoryModel: boolean;
  withHistoryModel: boolean;
  loading: boolean = true;
  dataState: Workflow;
  userAvatar: string;
  icon: string;
  gedDoc;
  backgroundColor = '#66BD7A';
  textColor = '#006F43';
  actionExist: boolean = true;

  constructor(private workflowService: WorkflowService, private route: ActivatedRoute) 
  { }

  /**
   * Initialise le composant
   */
  ngOnInit(): void 
  {
    this.getDoc();
  }

  /**
   * Fonction déclenchée lors du clic sur un bouton
   */
  onButtonClick() 
  {
    // Ajoutez la logique à exécuter lors du clic sur le bouton
    console.log('Bouton cliqué !');
  }

  /**
   * Fonction déclenchée lors de changements dans les propriétés d'entrée
   */
  ngOnChanges(changes: SimpleChanges): void 
  {
    if (changes.gedDoc) 
    {
      this.gedDoc = changes.gedDoc.currentValue;
      this.getDoc();
    }
  }

  /**
   * Récupère les détails du document en fonction des données fournies
   */
  getDoc() 
  {
    if (this.gedDoc != undefined && this.currentDocId != undefined) 
    {
      this.doc = this.gedDoc.content.data;
      this.initActions(this.path, this.currentDocId);
      this.getWorkflow(this.currentDocId);
    }
  }

  /**
   * Récupère de manière asynchrone le flux de travail en fonction de l'ID du document
   * @param currentDocId - ID actuel du document
   */
  async getWorkflow(currentDocId: string) 
  {
    this.workflowService
      .getSteps(currentDocId)
      .then((workflowState : Workflow) => 
      {
        this.dataState = workflowState;
        this.loading = false;
        if (!this.dataState?.tasks || !this.actionExist) 
        {
          this.noHistoryModel = true;
          this.withHistoryModel = false;
        }
        else 
        {
          this.noHistoryModel = false;
          this.withHistoryModel = true;
        }
      });
  }

  /**
   * Récupère les actions liées au document et met à jour les propriétés du composant en conséquence
   * @param path - Chemin d'accès
   * @param currentDocId - ID actuel du document
   */
  initActions(path: string, currentDocId: string) : void
  {
    this.workflowService
      .getActions(path, currentDocId, this)
      .then((data) => 
      {
        this.actionExist = data.actions?.length !== 0;
        this.isLoading = false; // Supprime le spinner de chargement lors de la récupération des données

      });
  }

  /**
   * Met à jour la propriété showActions en fonction des actions de l'événement
   * @param event - Événement déclenché
   */
  showActionsEvent(event) 
  {
    this.isLoading = event.isLoading;
    this.showActions = event.actions;
  }

  /**
   * Met à jour les propriétés du composant en fonction des informations de l'étape
   * @param infosStep - Informations sur l'étape
   */
  getInfosStep(infosStep: InfosStepTask) 
  {
    if (infosStep.description) 
    {
      this.description = infosStep.description;
    }
    if (infosStep.titleOfActions) 
    {
      this.titleOfActions = infosStep.titleOfActions;
    }
    if (infosStep.icon) 
    {
      this.icon = infosStep.icon;
    }
  }
}