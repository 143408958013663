<div class="right-aside" [ngClass]="isToggled? 'on' : ''">
    <div class="wrap">
        <span class="settings-icon" (click)="toggleRightSidebar()">
            <i class="bi bi-sliders"></i>
        </span>
        <ul class="list-group">
            <li class="list-group-item">
                <aura-theme-switcher></aura-theme-switcher>
            </li>
        </ul>

    </div>
</div>
<div class="overlay" (click)="toggleRightSidebar()"></div>
