import { DocActionsComponent } from './doc-actions/doc-actions.component';
import { DocAddComponent } from './doc-add/doc-add.component';
import { DocDescComponent } from './doc-desc/doc-desc.component';
import { DocDialogsComponent } from './doc-dialogs/doc-dialogs.component';
import { DocDisplayComponent } from './doc-display/doc-display.component';
import { DocLinksComponent } from './doc-links/doc-links.component';
import { DocNavComponent } from './doc-nav/doc-nav.component';
import { DocRatingComponent } from './doc-rating/doc-rating.component';
import { DocSidebarComponent } from './doc-sidebar/doc-sidebar.component';
import { DocTypeAddComponent } from './doc-type-add/doc-type-add.component';
import { DocWorkflowComponent } from './doc-workflow/doc-workflow.component';

export const components = [
  DocAddComponent,
  DocActionsComponent,
  DocDescComponent,
  DocDialogsComponent,
  DocDisplayComponent,
  DocLinksComponent,
  DocSidebarComponent,
  DocWorkflowComponent,
  DocNavComponent,
  DocTypeAddComponent,
  DocRatingComponent
];

export const entryComponents = [
  DocDialogsComponent,
  DocTypeAddComponent
]

export * from './doc-add/doc-add.component';
export * from './doc-actions/doc-actions.component';
export * from './doc-desc/doc-desc.component';
export * from './doc-nav/doc-nav.component';
export * from './doc-display/doc-display.component';
export * from './doc-links/doc-links.component';
export * from './doc-sidebar/doc-sidebar.component';
export * from './doc-workflow/doc-workflow.component';
export * from './doc-type-add/doc-type-add.component';
export * from './doc-rating/doc-rating.component';