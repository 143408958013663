import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class OnlyGuestGuard implements CanActivate 
{
  constructor(private auth: AuthService, private router: Router) 
  { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
    return this.isGuest(route.url)
  }

  isGuest(url) 
  {
    // we can implement here other logic related to authenticated users
    // like redirections
    const state = !this.auth.isConnected();


    if (window.self === window.top) 
    {
      if (!state) 
      {
        this.router.navigate(['workspace', 'main'])
      }
    }
    else 
    {
      if (!state) 
      {
        //this.router.navigate(['sidepanel', 'default'])
        this.router.navigate(['workspace', 'main', 'file', '1'])

      }
    }

    console.info('guard called in isGuest : ' + url + '\n result is: ', state)
    /*if (!state) {
      this.router.navigate(['workspace', 'main'])
    }*/

    return state

  }
}