import moment from "moment";
import { stringFile } from "src/assets/strings/strings";
import { SearchGcsService } from "../services/search-gcs.service";
import { GcsItem } from "./gcs-item";

export class GcsWorkplace extends GcsItem 
{
  name: string;
  snippet: string;
  objectType: string;
  group: string;
  // thumbnailLink : string;
  mimeType: string;
  subtitle: string;
  subsubtitle: string;
  bottomTitle: string;

  updateTime: string;
  creatorName: string;

  iconLink: string;
  webLink: string;

  startTime: string;

  constructor(gcsItem) 
  {
    super(gcsItem);
    this.setName(gcsItem);
    this.setIcon(gcsItem);
    this.setWebLink(gcsItem);
    this.setSnippet(gcsItem);
    this.setObjectType(gcsItem);
    this.setType(gcsItem);
    this.setGroup();
    this.setUpdateTime(gcsItem);
    this.setStartTime(gcsItem);
    this.setCreator(gcsItem);
    this.getDisplayProperties();
  }

    
  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  [
      {
        id: 'name',
        value: this.getTitle(),
        iconImg: ''
      },
      {
        id: 'details',
        value: this.getSnippet() || this.getSubtitle() || this.getSubsubtitle()
      },
      {
        id: 'menu',
        value: '',
        menuItems: [
          {
            label: stringFile.stringsJson['openLink'],
            callback: function () 
            {
              window.open(self.getWebLink(),"_blank")
            }
          },
        ],
      }
            
    ]

    return this.displayProperties;
  }
    
  getName() 
  {
    return this.name;
  }
    
  setName(gcsWorkplace) 
  {
    let name = '';
    let indexOfA = 0;

    name = gcsWorkplace.title;
    if (name.includes('@')) 
    {
      indexOfA = name.indexOf('@');
      const finalTitle = name.substring(indexOfA + 2);

      this.name = finalTitle;
    }
    else 
    {
      this.name = name;
    }
    
  }
    
  getIcon() 
  {
    return this.iconLink;
  }

  getIconLink()
  {
    return this.iconLink;
  }
    
  setIcon(gcsWorkplace) 
  {
    if (gcsWorkplace?.metadata?.iconLink) 
    {
      this.iconLink = gcsWorkplace?.metadata?.iconLink;
    }
    else 
    {
      this.iconLink = '/assets/contact_icons/workchat_icon.svg';
    }
    
  }
    
  getWebLink() 
  {
    return this.webLink;
  }
    
  setWebLink(gcsWorkplace) 
  {
    this.webLink = gcsWorkplace.url;
  }
    
  getSubtitle() 
  {
    return this.subtitle;
  }
    
  setSubtitle() 
  {
    this.subtitle = this.getSnippet();
  }
    
    
  getSubsubtitle() 
  {
    return this.subsubtitle;
  }
    
  pushToArray(arrayy, element) 
  {
    if (element != null && element != undefined) 
    {
      arrayy.push(element);
    }
  }
    
  setSubsubtitle() 
  {
    const subsubtitleArray = [];

    this.pushToArray(subsubtitleArray, this.getObjectType());
    this.pushToArray(subsubtitleArray, this.getSource());
    const str = subsubtitleArray.join(' • ');

    this.subsubtitle = str;
  }
    
    
  setSnippet(gcsWorkplace) 
  {
    
    
    
    this.snippet = gcsWorkplace?.snippet?.snippet || null;
    
    
    const output = '';
    const matchRange = gcsWorkplace?.snippet?.matchRanges || [];

    for (const element of matchRange) 
    {
      let boldStart = element?.start || 0;
      let boldEnd = element?.end || this.snippet?.length - 1;
      const tempBoldStart = boldStart;
      const tempBoldEnd = boldEnd;
    
    
      const count = (this.snippet.match(new RegExp('<b>', 'g')) || [])?.length;
    
    
    
      for (let i = 0; i < count; i++) 
      {
    
    
        boldStart += 7;
        boldEnd += 7;
      }
    
    
      // if(this.snippet.slice(0, boldStart).includes("<b>")){ boldStart+=7;boldEnd+=7;
      // if(this.snippet.slice(0, boldStart).includes("</b>")){ boldStart+=4;boldEnd+=4;
    
      // if(this.snippet.slice(tempBoldEnd).includes("<b>")) boldEnd+=3
      // if(this.snippet.slice(tempBoldEnd).includes("</b>")) boldEnd+=4
    
    
      this.snippet = [this.snippet.slice(0, boldStart), '<b>', this.snippet.slice(boldStart, boldEnd), '</b>', this.snippet.slice(boldEnd)].join('');
    
    
    
    
    
    
    }
    
    this.snippet = this.snippet?.replace('&#39;', '\'');
    this.snippet = this.snippet?.replace('&#34;', '"');
    this.snippet = this.snippet?.replace('&quot;', '"');
    this.snippet = this.snippet?.replace('&quot;.', '".');
    this.snippet = this.snippet?.replace('&amp;', '&');
    if (this.snippet == undefined) 
    {
      this.snippet = null;
    }
    
    
  }
    
  getSnippet() 
  {
    return this.snippet;
  }
    
  getBottomTitle() 
  {
    return this.bottomTitle;
  }
    
  setBottomTitle() 
  {
    const lastModifiedTxt = stringFile.stringsJson.lastModified;
    const startsAtTxt = stringFile.stringsJson.startsAtTxt;

    this.bottomTitle = null;
    const bottomTitleArray = [];

    this.pushToArray(bottomTitleArray, this.getCreator());
    if (this.getUpdateTime()) 
    {
      this.pushToArray(bottomTitleArray, lastModifiedTxt + this.getUpdateTime());
    }
    if (this.getStartTime()) 
    {
      this.pushToArray(bottomTitleArray, startsAtTxt + this.getStartTime());
    }
    const str = bottomTitleArray.join(' • ');

    this.bottomTitle = str;
    // let publishedSiteText = stringFile.stringsJson.publishedSiteText;
    // if(this.getUpdateDate()!=null) this.bottomTitle = publishedSiteText + " - " + this.getUpdateDate();
  }
    
  getObjectType() 
  {
    return this.objectType;
  }
    
    
  setObjectType(gcsWorkplace) 
  {
    this.objectType = null;
    if (gcsWorkplace) 
    {
      this.objectType = gcsWorkplace?.metadata?.displayOptions?.objectTypeLabel || gcsWorkplace?.metadata?.objectType || null;
    }
    else 
    {
      this.objectType = null;
    }
  }
    
  getGroup() 
  {
    return this.group;
  }
    
  setGroup() 
  {
    if (this.objectType == 'post' || this.objectType == 'event') 
    {
      this.group = 'post';
    }
    
  }
    
  getType() 
  {
    return this.mimeType;
  }
    
  setType(gcsWorkplace) 
  {
    const mimeType = gcsWorkplace?.metadata?.mimeType;

    if (this.getTypefromMime(mimeType) != '') 
    {
      this.mimeType = stringFile.stringsJson[this.getTypefromMime(mimeType)];
    }
    else 
    {
      this.mimeType = 'Document';
    }
  }
    
  getTypefromMime(mimeType) 
  {
    if (mimeType == 'text/plain') 
    {
      return 'type_textdoc';
    }

    return 'type_textdoc';
  }
    
  getSource() 
  {
    return 'Workplace';
  }
    
    
  getCreator() 
  {
    return this.creatorName;
  }
    
  setCreator(gcsWorkplace) 
  {
    let creatorName = null;
    const creator = (gcsWorkplace?.metadata?.fields || []).find(field => field?.name == 'creator')?.objectValues?.values[0].properties || null;

    if (creator != null) 
    {
      creatorName = (creator || []).find(property => property.name == 'creatorName')?.textValues?.values[0] || null;
    }
    if (creatorName) 
    {
      this.creatorName = creatorName;
    }
    else 
    {
      this.creatorName = null;
    }
  }
    
  getUpdateTime() 
  {
    return this.updateTime;
  }
    
  setUpdateTime(gcsWorkplace) 
  {
    this.updateTime = null;
    if (gcsWorkplace) 
    {
      const updateTime = gcsWorkplace?.metadata?.updateTime;

      if (updateTime) 
      {
        this.updateTime = this.when(updateTime);
      }
      else 
      {
        this.updateTime = null;
      }
    }
  }
    
    
  getStartTime() 
  {
    return this.startTime;
  }
    
    
  setStartTime(gcsWorkplace) 
  {
    this.startTime = null;
    if (gcsWorkplace) 
    {
      const startTime = (gcsWorkplace?.metadata?.fields || []).find(field => field?.name == 'startTime')?.textValues?.values[0] || null;
    
      if (startTime) 
      {
        this.startTime = this.when(startTime);
      }
      else 
      {
        this.startTime = null;
      }
    }
    
  }
    
    
  when(dateString) 
  {
    const onDate = stringFile.stringsJson.onDate;
    const nowDate = new Date();
    const finalString = '';
    const updateDate = new Date(moment(dateString).format('YYYY-MM-DD'));
    const day = updateDate.getDate();
    const month = updateDate.getMonth();
    const year = updateDate.getFullYear();
    const monthName = stringFile.stringsJson.monthListAbbrev[month]

    if (year == nowDate.getFullYear()) 
    {
      return onDate + day + " " + monthName;
    }
    else 
    {
      return onDate + day + " " + monthName + " " + year;
    }
  }
    
}