import { stringFile } from "src/assets/strings/strings";
import { GFile } from "./gdrive-item";
import { Document, Tag } from "./gdrive-back.model";


/**
 * Represents a GDrive document without space, extending GFile.
 */
export class GdriveDocumentNoSpace extends GFile 
{
  /**
     * Indicates whether to include the rating in the display properties.
     */
  withRating: boolean;

  /**
     * Constructs a new instance of the GdriveDocumentNoSpace class.
     * @param item - The item data.
     * @param withRating - Indicates whether to include the rating. Default is false.
     */
  constructor(item: Document, withRating: boolean = false) 
  {
    super(item);
    this.withRating = withRating;
  }

  /**
     * Gets the display properties of the document for rendering.
     * @param urlPrefix - The URL prefix for constructing links. Default is an empty string.
     * @returns An array of display properties.
     */
  getDisplayProperties(urlPrefix: string = ''): any[] 
  {
    if (!this.displayProperties) 
    {
      const self: GdriveDocumentNoSpace = this;

      urlPrefix = urlPrefix || '/s/space/' + this.getParents();

      const tags: string = this.transform(
        (this.getTags() || '').split(',').join(', '),
        30
      );

      const flag: Tag = this.getFlag();
      const state: Tag = this.getState();
      const flags: Tag[] = [flag, state];

      const flagContext: any = {
        classFlag: this.getClassFlaged(flags),
        tooltipFlag: this.getTooltipText(flags),
        countFlags: this.countFlags(flags),
        labelFlag: this.getLabelFlag(flags),
      };

      this.displayProperties = [
        {
          id: 'name',
          value: this.getName(),
          iconImg: this.getIconLink(),
          file: {
            id: this.item.id,
            mimeType: this.item.mimeType
          }
        },
        {
          id: 'Flags',
          context: { flag: flagContext },
        },
        {
          id: "Tags",
          value: tags,
        },
        {
          id: 'owner',
          value: this.getOwner()?.displayName || this.getOwner()?.emailAddress,
        },
        {
          id: 'type',
          value: this.getTypeDocument(),
        },
        {
          id: 'lastModified',
          value: this.getModifiedTime(),
        }
      ];

      if (this.withRating)
      {
        this.displayProperties.push({
          id: 'rating',
          value: this.prop('rating')
        });
      }

      this.displayProperties.push({
        id: 'Menu',
        value: '',
        menuItems: [
          /*{
                        label: stringFile.stringsJson['fichierstr'],
                        callback: () => {
                            ToolbarComponent.instance.navigateTo([urlPrefix + '/file/' + self.getUid()])
                        },
                    },
                    {
                        label: stringFile.stringsJson['openDestination'],
                        callback: () => {
                            ToolbarComponent.instance.navigateTo(['/s/space/' + this.getParents()])
                        },
                    },*/
          {
            label: stringFile.stringsJson['fichier'],
            callback: () => 
            {
              window.open(self.getWebViewLink(), "_blank")
            }
          }
        ]
      });
    }

    return this.displayProperties;
  }

  /**
     * Gets the session identifier (sid) of the document.
     * @returns The session identifier.
     */
  getSid(): string 
  {
    return this.prop('sid');
  }

  /**
     * Gets the type of the document.
     * @returns The type of the document.
     */
  getTypeDocument(): string 
  {
    return this.prop('type_document');
  }

  /**
     * Gets the file identifier (file_oid or oid).
     * @returns The file identifier.
     */
  getOid(): string 
  {
    return this.prop('file_oid') || this.prop('oid');
  }

  /**
     * Gets the date of the document.
     * @returns The date of the document.
     */
  getDate(): string 
  {
    return this.prop('date');
  }

  /**
     * Gets the creation date of the document.
     * @returns The creation date of the document.
     */
  getDateCreation(): string 
  {
    return this.prop('date_creation');
  }

  /**
     * Gets the description of the document.
     * @returns The description of the document.
     */
  getDescription(): string 
  {
    return this.prop('description');
  }

  /**
     * Gets the metadata model of the document.
     * @returns The metadata model of the document.
     */
  getMetadataModel(): string 
  {
    return this.prop('metadata_model');
  }

  /**
     * Gets the chronological number of the document.
     * @returns The chronological number of the document.
     */
  getNumChrono(): string 
  {
    return this.prop('num_chrono');
  }

  /**
     * Gets the revision of the document.
     * @returns The revision of the document.
     */
  getRevision(): string 
  {
    return this.prop('revision');
  }

  /**
     * Gets the version of the document.
     * @returns The version of the document.
     */
  getVersion(): string 
  {
    return this.prop('version');
  }
}
