import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";

import { Colors, material_icons } from "./utils/exports";
import { IFormManagerInputData } from "./interfaces/server-data.interface";
import { IEnumDataProvider } from "./interfaces/enum-data-provider.interface";
import { IFieldDesc } from "./interfaces/field-desc.interface";
import { IFormField } from "./interfaces/form-field.interface";
import { EnumDataProvider } from "./modeles/enum-data-provider";
import { TEnumCheckboxData, TEnumData} from "./modeles/field-record.model";
import { FormRecord, TBackendRecordData } from "./modeles/form-record.model";
import { APISce, GedService } from "../app-common/services";
import { FormField } from "./modeles/form-field.model";
import { environment } from "src/environments/environment";
import { SidepanelService } from "src/modules/documents/containers/document-page/side-panel/sidepanel.service";

@Component({
  selector: "app-form-manager",
  templateUrl: "./form-manager.component.html",
  styleUrls: ["./form-manager.component.scss"],
})
export class FormManagerComponent implements OnInit, OnChanges 
{

  @ViewChild('autoCompleteComponent', { static: false }) autoCompleteComponent: ElementRef;

  // Inputs
  @Input() inputData: IFormManagerInputData;
  @Input() type: string;
  @Input() scrollable: boolean;
  @Input() displayButtons: boolean = true;
  @Input() displayGroupe: string;
  @Input() canEdit: boolean = true;
  @Input() showRecap: boolean = false;
  @Input() editForm: boolean = false;
  @Input() callBackInEdit: boolean = false;
  @Input() isExtension: boolean;


  @Input() displayHorizontal: boolean = false;

  @Input()
  set showEditFirst(value: boolean) 
  {
    this.showContent = !value;
  }

  // Outputs
  @Output() onFormSubmitEvent = new EventEmitter<any>();
  @Output() onFormCancelEvent = new EventEmitter<any>();
  @Output() onChangeEvent = new EventEmitter<string>();
  @Output() formValidityChanged = new EventEmitter<boolean>();
  @Output() callBack = new EventEmitter();
  @Output() onFormChangeEvent = new EventEmitter<TBackendRecordData>();

  selectedOption: string;
  formGroup: FormGroup = new FormGroup({});
  formControls: {};
  record: FormRecord;
  fields: any = {};
  fieldsArray: IFormField[];
  metadata = {};
  selectData = {};
  checkedOptions = {};
  enumDataProvider: IEnumDataProvider;
  jsonData = {};
  categories: any[] = [];
  defaultValue: string;
  showContent = !this.showEditFirst;
  isVisible: boolean = true; // Initialisation à true pour afficher par défaut
  inputValue: any[] = [];
  spaceActivated: boolean = environment.ESPACE_ACTIVATED;
  isOpen: boolean = false;
  tagDetached: string = "";


  constructor(private apiInstance: APISce, private gedService: GedService, private cdr: ChangeDetectorRef,
    private sidepanelService: SidepanelService) 
  {
    this.enumDataProvider = new EnumDataProvider(this.apiInstance);
  }

  /**
   * Fonction appelée après l'initialisation du composant
   */
  ngOnInit(): void 
  {
  }

  /**
  * Filtre les champs en fonction d'un champ de formulaire
  * @param field - Le champ de formulaire utilisé pour le filtrage
  */
  doFilterField2(field: IFormField) 
  {
    if (this.selectedOption == "" || this.selectedOption == field.desc.enumValues.html) 
    {
      field.desc.updateEnumList([]);
    }
    else 
    {
      const res = this.enumDataProvider.getAutocompleteData(field.desc.getUrl(), this.selectedOption);

      if (res) 
      {
        const autoComplete: Array<{ value: number; html: string }> = res;

        field.desc.updateEnumList(autoComplete);
      }
    }
  }

  /**
 * Filtre les champs en fonction de l'événement de filtrage.
 * @param event L'événement de filtrage.
 * @param field Le champ à filtrer.
 */
  async doFilterField(event, field) 
  {
    if (event == "") 
    {
      field.fieldAutoList = [];
    }
    else 
    {
      const res = await this.enumDataProvider.
        getAutocompleteData(field.desc.getUrl(), event);

      field.fieldAutoList = [];
      if (res && res.length) 
      {
        let autoComplete = [];

        autoComplete = res;
        for (const option of Object.values(autoComplete)) 
        {
          field.fieldAutoList.push(option);
        }
      }
    }

    field.desc.updateEnumList(field.fieldAutoList)
  }


  /**
  * Filtre les champs en fonction de l'événement de filtrage.
  * @param event L'événement de filtrage.
  * @param field Le champ à filtrer.
  */
  async filterFieldAutoComplete(field) 
  {

    const value = this.inputValue[field.desc.label].target.value;

    if (value == "") 
    {
      field.fieldAutoList = [];
    }
    else 
    {
      const res = await this.enumDataProvider.
        getAutocompleteData(field.desc.getUrl(), value);

      field.fieldAutoList = [];
      if (res && res.length) 
      {
        let autoComplete = [];

        autoComplete = res;
        for (const option of Object.values(autoComplete)) 
        {
          field.fieldAutoList.push(option);
        }
      }
    }

    field.desc.updateEnumList(this.transformToAura(field.fieldAutoList));

    return this.transformToAura(field.fieldAutoList);
  }

  /**
  * Vérifie si une valeur est de type EnumData
  * @param value - La valeur à vérifier
  * @returns Vrai si la valeur est de type EnumData, sinon faux
  */
  isTEnumData(value: any): boolean 
  {
    return typeof value === 'string';
  }

  /**
  * Filtre les options en fonction d'une valeur
  * @param options - Les options à filtrer
  * @param value - La valeur de filtrage
  * @returns Les options filtrées
  */
  filter(options, value) 
  {
    return options.filter((values) =>
      values.html.toLowerCase().includes(value)
    );
  }

  /**
  * Fonction appelée lorsque les modifications sont détectées
  */
  async ngOnChanges(changes: SimpleChanges): Promise<void> 
  {
    if (changes.editForm) 
    {
      if (this.editForm === true) 
      {
        this.showContent = false;
      }
    }
    if (this.inputData && (changes.editForm?.firstChange || !changes.editForm)) 
    {
      const record = this.record = new FormRecord();

      await this.record.loadData(this.inputData, this.type, null, null);
      this.setFields(record.getFields(), record.getData());
      this.fields = record.getFields(this.displayGroupe);
      this.fieldsArray = Object.values(this.fields);
      let i = 0;

      for (const fname in this.fieldsArray) 
      {
        if (!this.fieldsArray[fname].desc.index) 
        {
          this.fieldsArray[fname].desc.index = i;
          i++;
        }

      }
      this.fieldsArray.sort((a, b) => a.desc.index - b.desc.index);
    }
    if (!this.displayButtons) 
    {
      this.formGroup.valueChanges.subscribe(() => 
      {
        this.formValidityChanged.emit(this.formGroup.valid);
      });
    }
  }

  /**
  * Fonction appelée lorsqu'un champ est sélectionné
  * @param event - L'événement de sélection
  * @param element - L'élément sélectionné
  */
  onSelectedField(event, field) 
  {
    this.record.updateFielAutocompleteAura(event, field);
  }

  /**
  * Définit les champs du formulaire et les données
  * @param fields - Les champs du formulaire
  * @param data - Les données du formulaire
  */
  async setFields(fields: any, data: any) 
  {
    const form = {};

    if (fields && data) 
    {
      for (const fname in fields) 
      {
        const field = fields[fname] as FormField;
        const fdesc = field.desc;

        if (fdesc.isHidden() || (this.displayGroupe != undefined && !fdesc.hasTag(this.displayGroupe))) 
        {
          continue;
        }
        if (typeof field.value() == 'string' || 'number') 
        {
          field.formControl = form[fname] = new FormControl(
            field.value(),
            fdesc.required ? Validators.required : null
          );
        }
        if (field.desc.typeControl == 'select') 
        {
          field.formControl = form[fname] = new FormControl(
            field.getValueEnum(),
            fdesc.required ? Validators.required : null
          );
        }
        if (field.desc.typeControl == 'checkboxMultiple') 
        {
          field.formControl = form[fname] = new FormControl(
            this.transfer(field.value()),
            fdesc.required ? Validators.required : null
          );
        }

        if (field.desc.typeControl == 'date') 
        {
          field.formControl = form[fname] = new FormControl(
            this.transferToDate(field.value()),
            fdesc.required ? Validators.required : null
          );
        }
      }
      this.formControls = form;
      this.formGroup = new FormGroup(form);
      for (const fname in fields) 
      {
        const field = fields[fname] as FormField;
        const fdesc = field.desc;
        const onChangeSet = false;

        if (fdesc.isHidden() || (this.displayGroupe != undefined && !fdesc.hasTag(this.displayGroupe))) 
        {
          continue;
        }
        if ((field.desc.enum && fdesc.type == "string" && !fdesc.enumValues?.url)) 
        {
          if (!fdesc.enumValues?.url) 
          {
            fdesc.updateEnumList(field.desc.enums());
          }
        }
        else if (field.desc.typeControl === "select" || (field.desc.enums && fdesc.enumValues?.url && field.desc.typeControl !== "autocomplete")) 
        {
          data = await this.enumDataProvider.getEnumList(fdesc.getUrl());
          if (data) 
          {
            fdesc.updateEnumList(data);
            if (field.desc.typeControl === "checkboxMultiple") 
            {
              fdesc.setValues(field);
            }
          }
        }
        else if (field.desc.typeControl === "color") 
        {
          fdesc.updateEnumList(Colors);
        }
        else if (field.desc.fname === 'owner') 
        {
          fdesc.setIcon('far fa-user-circle');
        }
        else if (field.desc.typeControl === "icon") 
        {
          fdesc.updateEnumList(material_icons);
        }
        else if (field.desc.typeControl === "checkbox") 
        {
          data = this.enumDataProvider.getEnumList(fdesc.getUrl());
          if (data) 
          {
            fdesc.updateEnumList(data);
          }
        }
        if (!onChangeSet) 
        {
          this.setOnValueChange(field);
        }
      }
    }
  }

  /**
   * Transforme une chaîne de caractères contenant des nombres séparés par des virgules en un tableau de nombres.
   * @param field La chaîne de caractères à transformer.
   * @returns Un tableau de nombres.
   */
  transfer(field) 
  {
    return field.split(",").map(Number);
  }

  /**
  * Définit l'action à effectuer lorsqu'une valeur change dans un champ
  * @param field - Le champ
  */
  setOnValueChange(field: IFormField) 
  {
    field.formControl.valueChanges.subscribe((value: any) => 
    {
      field.update(value);
    });
  }

  /**
  * Obtient le label à partir d'une liste d'items et d'une clé
  * @param items - La liste d'items
  * @param key - La clé
  * @returns Le label correspondant à la clé
  */
  getLabelFromItems(items: TEnumData[], key: string | number) 
  {
    const item = items.find((f) => f.value === key);

    return item && (item.html || item.value);
  }


  /**
  * Filtre les données en fonction d'une valeur et d'une description de champ
  * @param enteredData - La valeur saisie
  * @param fdesc - La description de champ
  */

  async filterData(enteredData: string, fdesc: IFieldDesc) 
  {
    const text = String(enteredData).toLowerCase();
    const data = await this.enumDataProvider
      .getAutocompleteData(fdesc.getUrl(), text);

    if (data) 
    {
      fdesc.updateEnumList(data);
    }
  }

  /**
   * Fonction appelée lorsque l'utilisateur souhaite revenir en arrière
   */
  onGoBack() 
  {
    if (this.showRecap) this.showContent = true;
    this.onFormCancelEvent.emit();
  }

  /**
  * Gère le changement de date
  * @param event - L'événement de sélection de date
  */
  handleDateChange(event: MatDatepickerInputEvent<Date>) 
  {
    const selectedDate: Date = event.value;
    // Fait quelque chose avec la date sélectionnée
  }

  /**
  * Soumet le formulaire
  * @param formGroup - Le groupe de formulaire
  */
  submit() 
  {
    if (this.formGroup.valid) 
    {
      //if (this.showRecap) this.showContent = !this.showContent;
      this.onFormSubmitEvent.emit(this.record.getData(false, true));
      console.log(this.record.getData(false, true))
    }
  }

  selectField() 
  {
    this.sidepanelService.updateForm(this.record.getData(false, true));
    if (this.displayHorizontal) 
    {
      this.onFormChangeEvent.emit(this.record.getData(false, true));
    }
  }


  /**
   * Handles the change event for the checkboxes.
   * 
   * @param {string} field - The field identifier.
   * @param {string} name - The name of the checkbox.
   * @param {boolean} isChecked - Indicates whether the checkbox is checked.
   */
  onChangeEnumsChecked(field, fieldDesc, isChecked) 
  {
    this.record.onChangeEnumsChecked(field, fieldDesc, isChecked);
    this.onChangeEvent.emit(fieldDesc.name);
  }


  /**
   * Handles the change event for the checkboxes.
   * 
   * @param {string} field - The field identifier.
   * @param {string} name - The name of the checkbox.
   * @param {boolean} isChecked - Indicates whether the checkbox is checked.
   */
  onChangeEnumsChecked2(fieldDesc, isChecked) 
  {
    this.tagDetached = fieldDesc.label;
    this.isOpen = true;
    this.record.onChangeEnumsChecked2(fieldDesc, isChecked);
    this.selectField();
    this.onChangeEvent.emit(fieldDesc.name);
  }

  /**
   * 
   * @param {string} field - The field identifier. 
   */
  onChangeField(field) 
  {
    this.selectField();
    this.onChangeEvent.emit(field);
  }

  /**
   * 
   * @param {string} field - The field identifier. 
   */
  onChangeFieldEnum(fname, value) 
  {
    this.record.onChangeEnumsSelected(fname, value);
  }

  /**
   * Convertit une chaîne de caractères représentant une date en format 'YYYY-MM-DD'.
   * @param dateString La chaîne de caractères représentant la date.
   * @returns Une chaîne de caractères au format 'YYYY-MM-DD'.
   */
  transferToDate(date) 
  {
    return new Date(date);
    /* const defaultDateFormatted = {
       year: defaultDate.getFullYear(),
       month: defaultDate.getMonth() + 1,
       day: defaultDate.getDate()
     };
     return defaultDateFormatted;*/
    /*const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;*/
  }

  transferToDateAura(dateString) 
  {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Get month as an integer (not padded)
    const day = date.getDate();
    const dateToReturn = { year: year, month: month, day: day };

    return dateToReturn;
  }

  /**
   * Transforme un tableau d'objets en un nouveau tableau d'objets avec des propriétés 'label' et 'value'.
   * @param objets Le tableau d'objets à transformer.
   * @returns Un nouveau tableau d'objets avec les propriétés 'label' et 'value'.
   */
  transformToAura(objets) 
  {
    const objetsTransformes = objets.map(objet => 
    {
      return {
        'label': objet['html'],
        'value': objet['value']
      };
    });

    return objetsTransformes;
  }

  /**
  * Transforms an object to match the expected structure in Aura2.
  * 
  * @param {Object} yourObject - The object to transform.
  * @returns {Object} - The transformed object with 'label' key instead of 'html'.
  */
  transformToAura2(yourObject) 
  {
    if (typeof yourObject === 'object') 
    {
      // Create a new 'label' key and copy the value from 'html' key
      yourObject.label = yourObject.html ?? yourObject.label;

      // Remove the 'html' key if needed
      delete yourObject.html;

      return yourObject;
    }
  }

  /**
   * Transforms an object to match the expected structure in Psoft.
  * 
  * @param {Object} yourObject - The object to transform.
  * @returns {Object} - The transformed object with 'name' key instead of 'label'.
  */
  transformToPsoft(yourObject) 
  {
    // Create a new 'label' key and copy the value from 'html' key
    yourObject.html = yourObject.label ?? yourObject.html;

    // Remove the 'html' key if needed
    delete yourObject.label;

    return yourObject;
  }

  /**
 * Retrieves checked elements based on given values and elements.
 * 
 * @param {TEnumCheckboxData} values - Object containing 'value' field.
 * @param {TEnumCheckboxData[]} elements - Array of elements to filter.
 * @param {string} labelField - label of field.
 * @returns {Array} - Filtered elements based on the provided values.
 */
  getCheckedElement(values: TEnumCheckboxData, elements: TEnumCheckboxData[], labelField?: string) 
  {
    // Splitting the HTML string into an array of values and filtering out empty strings
    const searchLabels : string[] = values.html?.split(',').filter(val => val !== '');
    const classes : string[] = values.types?.replace(/\s/g, '').split(',').filter(val => val !== '') ?? [];

    // Preparing the searched values and elements to filter
    let searchValues : string[] = values.value?.split('|').filter(val => val !== '');
    let elementsToFilter: TEnumCheckboxData[];

    let numericValues;

    if (elements) 
    {
        // If elements are provided, convert searchValues to integers and use the provided elements
        
        elementsToFilter = elements;
        
        numericValues = searchValues.map(val => 
          parseInt(val, 10));
    }
     else 
    {
        // If no elements are provided, map searchValues to new elements using searchLabels
        elementsToFilter = searchValues.map((value, index) => 
        ({
            label: searchLabels[index],
            value: value
        }));
        numericValues = searchValues;
    }

    // Filtering and sorting elements based on searchValues
    const foundElements = elementsToFilter?.filter(element => 
      
      numericValues.includes(element.value))

        .sort((a, b) => 
        numericValues.indexOf(a.value) - numericValues.indexOf(b.value))
        
        .map((element) => 
        {
            const index = numericValues.indexOf(element.value);

            const elementClass = classes[index] ? 'field-' + labelField + '-' + classes[index] : undefined;
            
            const hoverText = classes[index] === "suggested" ? environment.stringsFile.suggested : undefined;

            return {
                value: element.value,
                label: element.label,
                class: elementClass,
                hover: hoverText
            };
        });

    return foundElements || '';
}

  /**
 * Handles the creation click event, adding new tags to the record.
 * 
 * @param {Object} objectToAdd - The object to add.
 * @param {Object} fieldDesc - The field description object.
 * @returns {Promise} - Promise resolving with added tag information.
 */
  async onCreateClick(objectToAdd, fieldDesc) 
  {

    if (!this.record.newTagsAdded[fieldDesc.name])
    {
      this.record.newTagsAdded[fieldDesc.name] = [];
    }
    const newItemAdded = this.transformToPsoft(objectToAdd);
    const itemAdded = await this.enumDataProvider.addTag(newItemAdded, fieldDesc)

    this.record.newTagsAdded[fieldDesc.name].push({ value: itemAdded.value, html: newItemAdded.html });

    this.selectField();
  }


  displayEdit() 
  {
    this.callBack.emit(true);
    if (this.canEdit) 
    {
      this.showContent = false;
    }
    if (this.callBackInEdit) 
    {
      this.callBack.emit();

    }
  }
}