<app-sidenav
  [breadcrumb]="bc"
  [title]="'groupsString' | translate"
  (emitdataGroupes)="getData($event)"
>
  <div class="h-100 d-flex flex-column">
    <div class="">
      <form *ngIf="displayGroups.length > 50" class="row mt-5">
        <div class="col pe-0 me-0">
          <aura-input-text
            className="shadow-sm border-0"
            id="search"
            size="large"
          ></aura-input-text>
        </div>

        <div class="col-4 col-md-2 me-0">
          <button
            auraButton
            label="Search"
            size="large"
            type="primary"
            [full]="true"
          ></button>
        </div>
      </form>

      <div class="row py-3">
        <div class="col icons d-flex justify-content-end gap-2 view-icons">
          <button
            class="new-btn"
            auraButton
            *ngIf="canAddSpace"
            icon="gg-math-plus"
            size="large"
            type="success"
            [label]="'New space' | translate"
            (click)="spaceNewModal = true"
          ></button>

          <span class="d-none d-md-block"> &nbsp;&nbsp;</span>

          <img
            class="d-none d-md-block"
            [src]="
              '/assets/images/view-list.svg#' +
              (activeTab == 'table' ? 'active' : '')
            "
            (click)="toggleView('table')"
          />

          <span class="d-none d-md-block"> &nbsp;&nbsp;</span>

          <img
            class="d-none d-md-block"
            [src]="
              '/assets/images/view-cols.svg#' +
              (activeTab == 'grid' ? 'active' : '')
            "
            (click)="toggleView('grid')"
          />
        </div>
      </div>

     

      <div class="grid spaces">
               
        <h3 *ngIf="displaySpaces.length > 0" class="aura-h3">
          {{'mainSpaces' | translate}}
        </h3>
               
        <aura-list
          verticalAlign="center"
          [columns]="activeTab == 'grid' ? 4 : 1"
          [hasMenu]="true"
          [data]="displaySpaces"
          [noShadow]="false"
        ></aura-list>

        
      </div>

      <div class="grid">
        
        <h3 *ngIf="displayGroups.length > 0" class="aura-h3">{{'spaceGroups' | translate }}</h3>

        <aura-list
          verticalAlign="center"
          [columns]="activeTab == 'grid' ? 4 : 1"
          [data]="displayGroups"
          [hasMenu]="true"
          [noShadow]="false"
        ></aura-list>
      </div>
    </div>

    <!-- <div class="row mt-auto mb-0">
          <div class="col text-right ">
              <button (click)="open()" class="btn btn-xl btn-primary mt-4 d-sm-inline-block d-block m-auto">+ Add New
                  Department</button>
                <app-new-department [isOpen]="isOpen" (close)="close()"></app-new-department>
          </div>
      </div> -->
  </div>

  <app-space-new
  [isOpen]="spaceNewModal"
  (close)="spaceNewModal = false"
></app-space-new>
</app-sidenav>
