import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { APISce } from "src/modules/app-common/services";

@Injectable({
  providedIn: "root",
})
export class CustomTableService 
{
  constructor(
    private api: APISce,
    private http: HttpClient
  ) 
  { }


  static handleError(error: HttpErrorResponse) 
  {
    if (error.status === 0) 
    {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error);
    }
    else 
    {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }

    // Return an observable with a user-facing error message.
    return throwError("Something bad happened; please try again later.");
  }

  async getData(url: string, serchVal: string): Promise<Observable<any>> 
  {
    const fullPath = environment.BACKEND_URL + url + `?$withMeta=true&q=${serchVal}`;

    const options = await this.api.httpConfig(true, true);

    return this.http
      .get(fullPath, options)
  }

  async deleteRecord(url: string, id: string): Promise<Observable<any>> 
  {
    const fullPath = environment.BACKEND_URL + url + `/${id}`;

    const options = await this.api.httpConfig(true, true);

    return this.http
      .delete(fullPath, options)
  }
}
