import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-google-apps',
  templateUrl: './google-apps.component.html',
  styleUrls: ['./google-apps.component.scss']
})
export class GoogleAppsComponent implements OnInit 
{
  @Input()
    url: string = "https://ogs.google.com/u/0/widget/app?bc=1&origin=https://www.google.com&cn=app&pid=1&spid=1&hl=en";

  
  urlSafe:SafeResourceUrl;
  constructor(private sanitizer: DomSanitizer) 
  {
    
  }
  ngOnInit(): void 
  {
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    
  }

}
