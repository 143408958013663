import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { SafeResourceUrl, Title } from "@angular/platform-browser";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import {
  GDocService,
  GedPermRole,
  GedPermType,
  GedPermission,
  GedService,
  SpaceInfos,
} from "src/modules/app-common/services";

import { TranslateService } from "@ngx-translate/core";

import {
  SpaceSearchService,
  SpaceTable,
} from "src/modules/spaces/services/space/space-search.service";

import { Crumb } from "src/modules/app-common/components/bread-crumb/bread-crumb.component";
import { FolderDeleteComponent, FolderShareComponent } from "../../components";

import { DocumentService } from "src/modules/documents/services/document.service";

import { SpaceService } from "src/modules/spaces/services";
import { Space } from "../../models/space/space.model";

import { GedFile } from "../../models/file/ged-file.model";
//import { GdriveFile } from "../../models/file/gdrive-file.model";
import { DomSanitizer } from "@angular/platform-browser";
import { filter, pairwise, tap } from "rxjs/operators";
import { SpaceColumn } from "../../models/table/column.model";

import { ListItem } from "@interfaces/list-item";
import { TableColumn, TableRow } from "@interfaces/table";
import {
  ListItemFile,
  TableRowFile,
} from "../../models/table/tableRowFile.model";

import { environment } from "src/environments/environment";
import { AddFolderComponent } from "src/modules/app-common/containers";
const themePath = environment.themePath;

// for text search in spaces
import { FormControl } from "@angular/forms";

// console logs (activated by ENV variables)
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DebugLog } from "src/modules/app-common/services/log/log.service";
import { PDocEventsService } from "src/modules/p-doc-envents/p-docs-events.service";
const trace = DebugLog.build("space");

@Component({
  selector: "app-space-home",
  templateUrl: `./${themePath}/space-home.component.html`,
  styleUrls: [`./${themePath}/space-home.component.scss`],
})
export class SpaceHomeComponent implements OnInit 
{
  loading: boolean = true;
  pdoc: boolean = true; // design V2
  vmat: boolean = false; // design V1 material
  bc: Crumb[] = [];

  // document list for List Grid
  docListDataAll: ListItem[];
  docListData: ListItem[];

  white = "#FFF";
  driveId = localStorage.driveId;

  filterValues = {};
  readonly searchTextControl: FormControl = new FormControl();

  files: any = [];
  fieldDescs: any = [];

  sid = "";
  tables: any[];
  canEditSpace: boolean = false;

  filterSelectObjStatus = [];
  tableColummns = [];

  columnsToDisplayNostates: any = {};
  columnsToDisplayStates: any = {};

  MetaObjects = [];
  isDrive = false;
  spaceUid;
  selectedRowIds: Set<number> = new Set<number>();
  selectedRowId: number;
  selectedRow = null;

  selected;
  meta;
  fichiers: any[] = [];

  showParentLink = false;
  showCarde = false;
  selectFolder = false;
  selectDocs = false;
  selectGuide = false;
  formFilter = false;
  showLinks = true;
  dataStatus = false;

  isSpace = true;
  statusValue = "";
  iframUrl;
  fulldata;

  parentId: string;
  currentName: string;
  currentId: string;
  currentSpace: any;
  parentSpace: any[];
  parents: any[];
  spaceList = [];
  docView = [];
  previousUrl: string;
  space: Space;
  gedFile: GedFile[] = [];
  spaceColumns: SpaceColumn[] = [];

  navLinks: any[];
  activeLinkIndex = -1;
  spaceName2;
  tabEtat = false;
  monDrive = false;
  queryParams = {};

  staticItems = [
    {
      name: environment.stringsFile.Etat,
      columnProp: "state",
      options: [],
    },
    /*
    {
      name: "Propriétaire  ",
      columnProp: "owner",
      options: [],
    },
    */
  ];

  sortItem;
  spaceUrlframe: SafeResourceUrl;
  access_tokenString: string; // obsolete?

  displayedColumnsTable = [];
  displayedColumnsTableKeys = [];
  displayedColumnsTableHeader = [];
  columnsByName: {};
  columnsFacets: [any?];
  columnsToDisplay3: string[];

  filterSelectObj = [];

  /** display modal More Details */
  moreDetailsModal: boolean = false;

  createDocumentModal: boolean = false;

  /** display modal add folder */
  addFolderModal: boolean = false;

  /** display modal for managing permissions on the space */
  permissionModal: boolean = false;

  /** add sharing button if config allow it and is admin of the space */

  canShareSpace: boolean;
  space_add_doc: boolean;
  space_create_doc: boolean;

  constructor(
    private route: ActivatedRoute,
    private spaceService: SpaceService,
    private documentService: DocumentService,
    private gdocService: GDocService,
    private titleService: Title,
    private gedService: GedService,
    private router: Router,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private spaceSearchService: SpaceSearchService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private pDocsEventsService: PDocEventsService
  ) 
  {
    this.canShareSpace = environment.SPACE_ADMIN;
    this.space_add_doc = environment.SPACE_ADD_DOC;
    this.space_create_doc = environment.SPACE_CREATE_DOC;
  }

  // load route params and init data
  async ngOnInit() 
  {
    await this.translate.get("Name").toPromise();

    //getOneParent
    // this.parents = await this.gdocService.loadParentsPath({id:this.spaceUid,name:this.currentName});
    this.router.events
      .pipe(
        filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((e: any) => 
      {
        this.previousUrl = e[0].urlAfterRedirects;
      });

    this.route.params
      .pipe(
        tap((params) => 
        {
          const spaceId = params["spaceId"];

          this.spaceUid = spaceId;
          this.isDrive = spaceId == "root" || spaceId == "drives";
          this._initComponent(spaceId);
        })
      )
      .subscribe();
    //this.route.snapshot.paramMap.get('spaceId');
    // just in case, remove ?view=default

    // this.getDriveRoute()
  }

  // i8n
  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  // init data
  async _initComponent(spaceId: string) 
  {
    this.parents = [];
    const id = (this.currentId = spaceId);

    // make sure a view is defined
    if (spaceId != "root") 
    {
      if (spaceId.indexOf("view") == -1) 
      {
        // if no view is defined add ?view=default to URL
        this.route.queryParams.subscribe((params) => 
        {
          this.router.navigate([], {
            queryParams: { view: "default" },
            skipLocationChange: false,
            queryParamsHandling: "merge",
          });

          return;
        });
      }
      else 
      {
        //console.log("selected spaceId view ??? "+spaceId);
      }
    }

    // fix on responsible people facet ? TBD. WHY??
    /*
    this.facets.main.forEach((r, index) => {
      if (r.fname === "responsible") {
        if (r.options && r.options.length > 0) {
          // Remove the last item from the "options" array
          r.options.pop();
          console.log("%c removed last option ","orange");
        }
        console.log("filterResults", r);
      }
    });
    */

    /* FY
    localStorage.setItem("spaceId item", id);
    */

    // INIT DATA
    this.loadSpaceData(id);
    this.showParents(id);
    this.getCapabilities(id);

    // test perms (remove after tests...)
    // this.testPerms(id);

    this.closeDrawer();
    this.docView.push(this.status);
  }

  /**
   * set BC for folder
   *
   * @param parents
   * @returns
   */
  async setFolderBC(parents = null) 
  {
    if (!parents) parents = this.parents;

    // add parents in path
    this.parents.forEach((parent) => 
    {
      parent.label = parent.name;
      parent.callback = () => 
      {
        this.router.navigateByUrl("s/space/" + parent.id);
      };
    });

    // add HOME
    const _home = environment.stringsFile.homeSideNav;
    const homeCrumb = new Crumb(_home, (event, self) => 
    {
      this.router.navigateByUrl("workspace/main");
    });

    // set home of the path
    if ((this.currentName = "Mon Drive")) 
    {
      this.currentName = environment.stringsFile.drive_str;
    }

    this.bc = [homeCrumb, ...parents, this.currentName];

    return;
  }

  /**
   * set BC for space type of folder
   *
   * @param parents
   * @returns
   */
  async setSpaceBC(space: Space, type = null) 
  {
    this.bc = [];

    if (!type) return;

    const _type = this._(type);
    const _Groups = this._("Spaces");
    const _home = environment.stringsFile.homeSideNav;

    // add HOME
    this.bc.push(
      new Crumb(_home, (event, self) => 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["workspace/main"]));
      })
    );

    // add SPACES
    this.bc.push(
      new Crumb(_Groups, (event, self) => 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/s/groups/all"]));
      })
    );

    // add GROUP if any
    const groupName = space.getGroupName();

    if (groupName) 
    {
      this.bc.push(
        new Crumb(groupName || _type, (event, self) => 
        {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["/s/space/groups/" + groupName]));
        })
      );
    }

    // add SPACE name
    const spaceName = space.getName();

    this.bc.push(new Crumb(spaceName));
  }

  /*
  async createSpace(spaceInfos: SpaceInfos) {
    try {
      let spaceId = await this.gedService.createSpace(spaceInfos);
      return spaceId;
    }
    catch (error) {
      console.error("permissions", error);
    }
  }
  */

  /*
  async getPermissions(spaceId: string): Promise<GedPermission[]> {
    try {
      let perms = await this.gedService.getPermissionList(spaceId);
      return perms;
    }
    catch (error) {
      console.error("permissions", error);
    }
  }
  */

  /*
  async addPermission(
    spaceId: string,
    email: string,
    role: GedPermRole = "content manager",
    type: GedPermType = "user"
  ): Promise<GedPermission> {
    try {
      type = type || "user";

      let perm: GedPermission = await this.gedService.addPermission(
        spaceId,
        email,
        role,
        type
      );

      return perm;
    } catch (error) {
      console.error("permissions", error);
      return null;
    }
  }
*/

  /*
  async removePermission(spaceId: string, permId: string) {
    try {
      await this.gedService.removePermission(spaceId, permId);
      return true;
    } catch (error) {
      console.error("permissions", error);
      return false;
    }
  }
  */

  getActiveSort() 
  {
    if (this.sortItem) return this.sortItem;
  }
  getSortDirection() 
  {
    return "asc";
  }

  ngAfterViewChecked() 
  {
    this.cdr.detectChanges();
  }

  // listen for state change events
  ngAfterViewInit(): void 
  {}
  @HostListener("window:popstate", ["$event"])
  onPopState(event) 
  {
    //this.location.back();
  }

  isStateSpace = false;

  // load data from server : space or folder
  async loadSpaceData(spaceId) 
  {
    try 
    {
      this.loading = true;
      const folderName = "";
      const gedFolder = await this.gedService.inGedSpace("", folderName, spaceId);

      if (!gedFolder || gedFolder == undefined) 
      {
        // =========== SIMPLE FOLDER =========

        // simple folder : is displayed by child component
        await this.initFolderData(spaceId);
      }
      else 
      {
        // =========== SPACE FOLDER =========
        //
        await this.initSpaceData(spaceId, gedFolder);
      }

      this.loading = false;
    }
    catch (error) 
    {
      throw error;
    }
  }

  // ====== INIT FOLDER DATA ============
  async initFolderData(spaceId: string) 
  {
    this.isSpace = false;

    const nextPageToken = null;
    const data = await this.gdocService.getFolderItems(spaceId, nextPageToken);

    if (data) 
    {
      const content = data?.["spaceResponse"]["content"];
      let folderName = content?.name || null;

      this.isDrive = true;

      if (folderName && folderName == "Drive" && content.id) 
      {
        const sharedDrive = await this.gdocService.getSharedDriveByUid(
          content.id
        );

        folderName = (await sharedDrive).name();
      }

      // console.log("initGedFolderData(id:string) folderName=",folderName);
      // this.spaceName2 = folderName;
      this.currentName = folderName;
      this.titleService.setTitle(this.currentName);
    }

    this.monDrive = true;

    // load parents for breadcrumb
    this.parents = await this.gdocService.loadParentsPath({
      id: spaceId,
      name: this.currentName,
    });

    this.setFolderBC(this.parents);
  }

  spaceTableSearch: SpaceTable;

  // =========== INIT SPACE DATA ==============

  async initSpaceData(id: string, gedFolder, searchText: string = "") 
  {
    // for displaying space rather then drive (should be removed once the component structure is done right...)
    this.isSpace = true;
    this.monDrive = false;

    this.fulldata = gedFolder;

    // get space model
    const space = (this.space = new Space(gedFolder));

    Space.setDocTypes(this.gedService.getDocTypesByMetaModel());

    // get list of metadata tables that can be edited for the space
    this.tables = space.getMetaTables(); // metadata tables

    this.setAppURL_forIframe(space); // if an "application" is attached to the space, display it here

    // this.setAppURL_forIframe(space);     // if an "application" is attached to the space, display it here

    // get space id and name
    this.sid = space.getSid();
    this.currentName = space.getName();
    // this.spaceName2 = space.getName();

    // set title and breadcrumb
    this.titleService.setTitle(this.currentName);

    // retreive actual space from current list
    const spaceInNav = await this.spaceService.getSpaceByUid(space.getUid());

    this.setSpaceBC(spaceInNav, this.currentName);

    // get/init states
    if (space.hasStates()) 
    {
      this.initWorkflowStates(space.getStates());
    }

    this.spaceTableSearch = this.spaceSearchService.getSpaceTable(space);

    // get space files
    this.files = space.getFiles();

    // get metadata fields from children list
    this.fieldDescs = space.getFieldDescs();

    // build column list for display
    const { columns, facetColumns, allColumns } =
      this.setSpaceTableColumns(space);

    // build list of facet values for filtering
    this.buildFacets(allColumns, space);

    // prepare data for display in table V1
    /*
    if(this.vmat)
      this.initMatTable();
    */

    // prepare data for table V2
    this.tableRowsAll = this.initDocTable(columns, space);

    this.dataRows = this.spaceTableSearch.FilterTable(this.tableRowsAll);
    columns.forEach((r, index) => 
    {
      if (r.fname == "owner") 
      {
        columns.splice(index, 1);
      }
    });
    // prepare data for list mode
    this.docListDataAll = this.initDocList(columns, space);
    this.docListData = this.spaceTableSearch.FilterItems(this.docListDataAll);

    this.dataStatus = false;
    this.formFilter = false;

    /*
    this.filterSelectObj.filter((o) => {
      o.options = this.getUniqueValuesForField(this.files, o.columnProp);
    });
    */
    this.pDocsEventsService.viewSpaceEvent(this.currentName);
  }

  async refreshSpaceData(searchText) 
  {
    try 
    {
      let folderName;

      const gedFolder = await this.gedService.inGedSpace(
        "",
        folderName,
        this.currentId,
        searchText
      );

      await this.initSpaceData(null, gedFolder, searchText);
    }
    catch (error) 
    {
      console.error("space text query error", error);
    }
  }

  facets = { main: [], right: [] };

  searchFacets;

  // build facets values (options) from columns data, for facets fields only
  buildFacets(columns: SpaceColumn[], space: Space) 
  {
    const filesData = space.getFiles();

    this.facets = { main: [], right: [] };

    let facetsNames;
    const facets = space.getFacets();

    if (facets?.fields && facets.fields != "*") 
    {
      // get facets
      facetsNames = facets.fields;

      if (facetsNames.trim) 
      {
        facetsNames = facetsNames
          .trim()
          .split(",")
          .map((f) => 
          {
            return f.trim();
          });
      }
    }
    else 
    {
      // all fields but name
      facetsNames = columns
        .map((col) => col.fname)
        .filter((fname) => fname != "name");
    }

    // build facet options from file properties, on facet names
    if (facetsNames) 
    {
      // get columns values
      const colByName = {};

      columns.forEach((c) => (colByName[c.fname] = c));

      if (this.spaceTableSearch) 
      {
        this.searchFacets = Object.values(
          this.spaceTableSearch.getSearchFacets()
        );

        /* if (this.spaceTableSearch) {
        // this.facets.main.push(this.spaceTableSearch.getSearchFacets());
        this.searchFacets = Object.values(
          this.spaceTableSearch.getSearchFacets()
        );*/
        // make facet capitalizeFirstLetter and delete duplicated
        this.searchFacets.forEach((category) => 
        {
          category.options = this.removeOptionsDuplicate(category.options);
        });
      }

      // else
      {
        this.fillFacetsValues(colByName, filesData, facetsNames);
      }

      let i = 0;
      const facets = {};

      for (const fname of facetsNames) 
      {
        const facet = colByName[fname] || null;

        // add facets that are not empty
        if (!facets[fname] && facet && facet.options) 
        {
          facets[fname] = facet;

          this.facets.main.push(facet);

          i++;
        }
      }

      this.facets.main.forEach((r, index) => 
      {
        if (r.fname == "owner") 
        {
          this.facets.main.splice(index, 1);
        }
      });
    }
  }

  /** remove duplicates in options. */
  removeOptionsDuplicate(options) 
  {
    const uniqueOptions = new Map();
    const updatedOptions = [];

    // list all options for the facet
    options.forEach((option) => 
    {
      const words = option.label.split(" "); // Split label into words
      const capitalizedWords = words.map((word) =>
        this.capitalizeFirstLetter(word)
      ); // Capitalize each word

      const capitalizedLabel = capitalizedWords.join(" "); // Join words back into a label
      const lowercaseLabel = capitalizedLabel.toLowerCase();

      if (!uniqueOptions.has(lowercaseLabel)) 
      {
        uniqueOptions.set(lowercaseLabel, option);
        updatedOptions.push({ ...option, label: capitalizedLabel });
      }
    });

    return updatedOptions;
  }

  capitalizeFirstLetter(str: string): string 
  {
    const words = str.split(" ");

    if (words.length >= 2) 
    {
      const firstWord =
        words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
      const restOfWords = words.slice(1).map((word) => word.toLowerCase());

      return [firstWord, ...restOfWords].join(" ");
    }
    else if (words.length === 1) 
    {
      return words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase();
    }
    else 
    {
      return "";
    }
  }

  // ============ INIT DATA FOR DESIGN SYSTEM TABLE =========
  columns: TableColumn[];
  // data: TableRow[][];
  data: TableRowFile[];
  dataRows: TableRow[][];
  tableRowsAll: TableRowFile[];

  initDocTable(columns, space: Space): TableRowFile[] 
  {
    let files = space.getFiles();

    // init header
    columns = columns.map((col) => 
    {
      const fname = col.fname;
      const label = col.label;

      if (fname == "name" || col.hasTag("name")) 
      {
        return {
          id: fname,
          label,
          type: "TextWithIcon",
          width: 250,
          class: "custom-name",
        };
      }

      if (fname == "other_partners" || col.hasTag("other_partners")) 
      {
        return {
          id: fname,
          label,
          width: 50,
          canSort: true,
          class: "custom-partner",
        };
      }

      if (fname == "rating" || col.hasTag("rating")) 
      {
        return {
          id: fname,
          label,
          type: "Text",
          canSort: true,
          class: "custom-rating",
        };
      }
      else 
      {
        return {
          id: fname,
          label,
          type: "Text",
          canSort: true,
        };
      }
    });

    columns.push({
      id: "menu",
      type: "Menu",
      width: 55,
    });

    this.columns = columns;

    this.columns.forEach((r, index) => 
    {
      if (r.id == "owner") 
      {
        this.columns.splice(index, 1);
      }
    });

    // sort table
    const sortOrder = space.getContentSortOrder();

    if (sortOrder) 
    {
      const sortFname = sortOrder.fname;
      const direction = sortOrder.reverse ? -1 : 1;

      files = files.sort((a, b) => 
      {
        return (a.prop(sortFname) - b.prop(sortFname)) * direction;
      });
    }

    // init rows data
    const rows = files.map((file) => 
    {
      const iconLink48 = this.documentService.getIconbyMimeType(file.iconLink);

      const row = new TableRowFile(
        file,
        this.fieldDescs,
        "name",
        columns,
        iconLink48,
        space
      );
      const parentId = row.getParent();

      row.setMenuItems([
        {
          label: "Open file",
          callback: () => 
          {
            this.openDoc(file);
          },
        },
        {
          label: "Open Folder",
          callback: () => 
          {
            this.toFolder(parentId);
          },
        },
      ]);

      return row;
    });

    this.data = rows;
    //}

    return rows;
  }

  setSearchFieldValue(fname, value) 
  {
    this.spaceTableSearch.setFormValue(fname, value?.value || "");
  }

  filterResults($event) 
  {
    this.docListData = this.spaceTableSearch.FilterItems(this.docListDataAll);
    this.dataRows = this.spaceTableSearch.FilterTable(this.tableRowsAll);

    $event.stopPropagation();

    return false;
  }

  onChangeFilter(option) 
  {
    this.docListData = this.spaceTableSearch.FilterItems(this.docListDataAll);

    this.dataRows = this.spaceTableSearch.FilterTable(this.tableRowsAll);

    return false;
  }

  onTextSearch($event, text = "") 
  {
    this.refreshSpaceData(text);
  }

  // ============ INIT DATA FOR DESIGN SYSTEM LIST =========

  initDocList(columns, space: Space): ListItem[] 
  {
    const docListData: ListItem[] = [];

    const spaceUrl = space.getUrl();
    const files = space.getFiles();

    let fnameId;

    // init header : get columns names
    columns.forEach((col) => 
    {
      const fname = col.fname;
      const label = col.label;

      if (fname == "name" || col.hasTag("name")) 
      {
        fnameId = fname;
      }
    });

    // init data
    files.forEach((file) => 
    {
      const iconLink48 = this.documentService.getIconbyMimeType(file.iconLink);
      const item = new ListItemFile(
        file,
        this.fieldDescs,
        fnameId,
        iconLink48,
        space
      );

      const parentId = item.getParent();

      const menuItems = [
        {
          label: "Open file",
          callback: () => 
          {
            this.openDoc(file);
          },
        },
      ];

      if (parentId) 
      {
        menuItems.push({
          label: "Open Folder",
          callback: () => 
          {
            this.toFolder(parentId);
          },
        });
      }

      item.setIcon(iconLink48);
      item.setMenuItems(menuItems);
      item.setLink(spaceUrl + "/file/" + file.id);

      docListData.push(item);
    });

    return docListData;
  }

  // ================ SPACE STATES ==========

  // object with state names as keys
  workflowStates: any;

  // sorted array of states
  status: any[];
  sortedStates: any[];

  initWorkflowStates(workflowStates) 
  {
    this.workflowStates = workflowStates;

    // sort states
    const sortedStates = Object.values(workflowStates).sort(
      (first, second) => 0 - (first["index"] > second["index"] ? -1 : 1)
    );

    this.status = this.sortedStates = sortedStates;

    if (workflowStates) 
    {
      if (Object.keys(workflowStates).length !== 0) 
      {
        this.isStateSpace = true;
      }
      else 
      {
        this.isStateSpace = false;
      }
    }
  }

  getColorState(status) 
  {
    const state = status?.properties?.state;

    if (this.workflowStates[state]) return this.workflowStates[state].color;

    return "";

    /*
    const aState = Object.values(this.status).filter((res) => {
      //console.log('getColorState',res)
      return res.label == status?.properties?.state;
    });

    return aState[0]["color"];
    */
  }

  //if url is my drive: dont show parents, if url is a space : show parents, if url isnt space dont show parents
  showParents(id) 
  {
    if (id != this.driveId || id != "root") 
    {
      this.showParentLink = true;
    }
    else 
    {
      this.showParentLink = false;
    }

    return this.showParentLink;
  }

  public orderByKey(a, b) 
  {
    return a.key;
  }

  // init IFRAME URL attached to the space (material version)
  setAppURL_forIframe(space: Space) 
  {
    let url;

    if (space.hasAppUrl()) 
    {
      this.iframUrl = space.getUrl();
      url = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframUrl);
    }

    this.spaceUrlframe = url;
  }

  // get doc web URL and open it in new tab
  openInDrive(id?) 
  {
    if (!id) id = this.currentId;

    this.gdocService.getFile(id).then((folder) => 
    {
      this.currentSpace = folder;
      window.open(folder.webViewLink, "_blank");
    });
  }

  canDelete: boolean = false;
  //getCapabilities
  getCapabilities(id?) 
  {
    if (!id) id = this.currentId;

    this.gdocService.getFile(id).then((data) => 
    {
      if (data != undefined) 
      {
        this.canDelete = data?.capabilities?.canDelete;
        this.canEditSpace = data?.capabilities?.canEdit && this.canShareSpace;
      }
      else 
      {
        this.canDelete = false;
        this.canEditSpace = false;
      }
    });
  }

  // multi tab space (material version)
  selectTab(a) 
  {
    this.tabEtat = a.index;
    const queryParams = { view: "" };

    this.route.queryParams.subscribe((params) => 
    {
      if (a.index == 0) 
      {
        queryParams.view = "items";
      }
      if (a.index == 1) 
      {
        queryParams.view = "folders";
      }
      if (a.index == 2) 
      {
        queryParams.view = "application";
      }

      this.router.navigate([], {
        queryParams: queryParams,
        skipLocationChange: false,
        queryParamsHandling: "merge",
        //  replaceUrl: true,

        relativeTo: this.route,
      });
    });
  }

  // Get Unique values from columns to build filter
  getUniqueValuesForField(fullObj, key) 
  {
    const uniqueValues = {};

    fullObj?.forEach((obj) => 
    {
      const value = obj.properties[key];

      uniqueValues[value] = true;
    });

    return Object.keys(uniqueValues);
  }

  showContent(indicator): void 
  {
    this.selectDocs = false;
    this.selectFolder = false;
    this.selectGuide = false;
    switch (indicator.index) 
    {
    case 0: {
      this.selectFolder = true;
      break;
    }
    case 1: {
      this.selectDocs = true;
      break;
    }
    case 3: {
      this.selectGuide = true;
      break;
    }
    }
  }

  hideForme() 
  {
    this.formFilter = false;
  }

  showForme() 
  {
    this.formFilter = true;
  }

  showCrad() 
  {
    this.showCarde = true;
  }

  /*
  showTableStatus(status: string) {
    this.dataStatus = true;
    this.statusValue = status;
    this.loadDataSourceStatus(status);

    this.dataSourceState = new MatTableDataSource(
      this.loadDataSourceStatus(status)
    );
  }
  */

  loadDataSource(status: string) 
  {
    const listeData = this.files?.filter((res) => 
    {
      return res?.properties?.state == status;
    });

    return listeData;
  }

  loadDataSourceStatus(status: string) 
  {
    this.dataStatus = true;
    this.statusValue = status;
    const listeData = this.files?.filter((res) => 
    {
      return res.properties.state == status;
    });

    return listeData;
  }

  async capitalize(str) 
  {
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);

    return str2;
  }

  // prepare table data with columns for data fields, state, owner and actions
  setSpaceTableColumns(space?: Space) 
  {
    this.columnsByName = {};
    this.columnsFacets = [];
    const allColumns = [];

    this.spaceColumns = [];

    const fieldDescs = space.getFieldDescs() || {};
    const spaceFacets = space.getFacetsFields() || {};

    this.meta = spaceFacets || []; // fieldDescs;

    if (!this.meta) return;

    // ======== INIT columns with icon
    this.displayedColumnsTableKeys = [
      {
        icon: "icon",
      },
    ];
    this.displayedColumnsTable = ["icon"];
    this.displayedColumnsTableHeader = ["icon"];

    // ======== add DATA FIELDS in columns
    const fieldDescsArray = Object.entries(fieldDescs);

    if (fieldDescsArray.length)
    {
      for (const [fname, fdesc] of fieldDescsArray) 
      {
        const label =
          fdesc["label"] || fname.charAt(0).toUpperCase() + fname.slice(1);

        // if (xtag.includes("meta")) {
        const column = new SpaceColumn(fdesc, fname);

        this.spaceColumns.push(column);
        allColumns.push(column);
        this.columnsByName[fname] = column;

        this.displayedColumnsTableKeys.push({
          [fname]: label,
        });

        this.displayedColumnsTable.push(fname);
        this.displayedColumnsTableHeader.push(label);
      }
    }

    // complete data for facets
    for (const [fname, fdesc] of Object.entries(spaceFacets)) 
    {
      // if already in table columns, no need to add
      if (!this.columnsByName[fname]) 
      {
        const column = new SpaceColumn(fdesc, fname);

        allColumns.push(column);
        this.columnsByName[fname] = column;
      }

      this.columnsFacets.push(this.columnsByName[fname]);
    }

    // ========= add STATE column
    // if the stace has states, add them to the list
    if (this.isStateSpace) 
    {
      this.displayedColumnsTable.push("state");

      this.displayedColumnsTableHeader.push("State");
      this.displayedColumnsTableKeys.push({
        state: environment.stringsFile.Etat,
      });
    }

    // ========= add static fields : OWNER and ACTIONS
    if (false && !this.columnsByName["owner"]) 
    {
      this.displayedColumnsTable.push("owner");
      this.displayedColumnsTableHeader.push("Owner");
      this.displayedColumnsTableKeys.push({ owner: "Propriétaire" });
    }

    // ========= add static field : ACTIONS
    this.displayedColumnsTable.push("actions");
    this.displayedColumnsTableHeader.push("Actions");
    this.displayedColumnsTableKeys.push({ actions: "Actions" });

    // ========= add other static fields
    for (const value of this.staticItems) 
    {
      const fname = value.columnProp;

      if (!this.columnsByName[fname]) 
      {
        const col = new SpaceColumn(
          {
            label: this._(value.name),
          },
          fname
        );

        this.spaceColumns.push(col);
        allColumns.push(col);
      }
    }

    this.displayedColumnsTableKeys.forEach((item) => 
    {
      const firstKey = Object.keys(item)[0];

      this.tableColummns.push(firstKey);
    });

    this.columnsToDisplay3 = this.tableColummns.slice();

    return {
      columns: this.spaceColumns,
      facetColumns: this.columnsFacets,
      allColumns,
    };
  }

  // TODO : remove facet building here because already done
  // by space search service

  // create filters and add them to column.options
  fillFacetsValues(columnsByname, files, facets) 
  {
    const colValues = {};

    facets.forEach((fname) => 
    {
      colValues[fname] = {};
    });

    files.forEach((row) => 
    {
      const appProps = row.appProperties || {};
      const props = { ...row.properties, appProps };

      facets.forEach((fname) => 
      {
        const colDesc = columnsByname[fname];
        const propName = colDesc?.value || fname;

        if (props[propName]) colValues[fname][props[propName]] = true;
      });
    });

    facets.forEach((fname) => 
    {
      const col = columnsByname[fname];
      const propName = col?.value || fname;

      const values = Object.keys(colValues[fname]);

      values.sort((a, b) => (a > b ? 1 : -1));

      if (col) 
      {
        const optsKeys = {};

        values.forEach((value1) => 
        {
          if (value1 != "-" && value1 != "||") 
          {
            value1.split(",").forEach((value) => 
            {
              optsKeys[value.toLowerCase()] = value;
            });
          }
        });

        col.options = Object.values(optsKeys)
          .map((value) => 
          {
            return { label: value, value };
          })
          .sort((a, b) => 
          {
            return (
              (a.label &&
                ((a.label < b.label && -1) || (a.label > b.label && 1) || 0)) ||
              0
            );
          });
      }
    });
  }

  getRoute(space) 
  {
    const spaceUrl = this.getSpaceUrl(space);

    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([spaceUrl]));
  }

  getSpaceUrl(space = null) 
  {
    const id = space?.uid || this.currentId;

    return "/s/space/" + id;
  }

  // Called on Filter change
  /*
  filterChange(filter: string, event)
  {
    this.filterValues[filter] = event.value.trim().toLowerCase();

    // this.dataSource.filter = JSON.stringify(this.filterValues);
  }
  */

  // Reset table filters
  /*
  resetFilters()
  {
    this.filterValues = {};
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    });
  }
  */

  openFolderDetails() 
  {
    this.showFolderDetails = true;
    this.detailsDrawer?.open();
  }

  onBreadCrumbClick($event) 
  {
    if ($event && $event.id) 
    {
      this.toFolder($event.id);
    }
  }

  filterBreadCrumbParent(parent) 
  {
    return true;
    // return parent.parents && (parent.parents[0]['id'] != 'root');
  }

  toFolder(parentId) 
  {
    //console.log('parentIdparentId',parentId)
    this.gdocService.getMyDriveId().then((driveId: string) => 
    {
      if (parentId == driveId) 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/s/space/root"]));
      }
      else if (parentId == null) 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/workspace/main/"]));
      }
      else 
      {
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => this.router.navigate(["/s/space/" + parentId]));
      }
    });
  }

  shareFolder(): void 
  {
    const dialogRef = this.dialog.open(FolderShareComponent, {
      width: "fit-content",
      data: {
        title: "share folder title",
      },
    });

    dialogRef.afterClosed().subscribe((result) => 
    {});
  }

  deleteFolder(): void 
  {
    const dialogRef = this.dialog.open(FolderDeleteComponent, {
      width: "fit-content",
      height: "fit-content",
      data: {
        title: environment.stringsFile.deleteFolderTitle,
        folder: this.currentId,
        parentFolder: this.parents[this.parents.length - 1],
      },
    });

    dialogRef.afterClosed().subscribe((result) => 
    {});
  }

  /* SpaceTable content events*/

  @ViewChild("drawer") public detailsDrawer: MatDrawer;

  selectedDocument;
  previewLink = "https://drive.google.com/thumbnail?sz=w360&id=";
  isDocument = false;
  docinGed;
  showFolderDetails = false;

  getSelectedRow(event) 
  {
    this.isDocument = event.isdoc;
    this.selectedDocument = event.document;

    //this.docinGed = event.docinGed;
    this.previewLink += this.selectedDocument.id;
    this.isDocInGed();
  }

  isDocInGed() 
  {
    this.gedService
      .getDoc(this.selectedDocument.id, localStorage.path)
      .then((res) => 
      {
        const temp = res;

        if (temp != null && typeof temp == "object") 
        {
          this.docinGed = true;
        }
        else 
        {
          this.docinGed = false;
        }
      });
  }

  openDrawer() 
  {
    this.showFolderDetails = false;
    this.detailsDrawer.open();
  }

  closeDrawer() 
  {
    this.detailsDrawer?.close();
  }

  openDoc(file) 
  {
    const url = this.space.getFileUrl(file);

    this.router.navigate([url], {
      queryParams: { d: localStorage.host_domain, relativeTo: this.router },
    });
  }

  openFileOrFolder() 
  {
    const file = this.selectedDocument;

    this.spaceService.openFileOrFolder(file);
  }

  doSomething(showLinks) 
  {
    this.showLinks = showLinks;
  }

  getSortValue(key) 
  {
    this.sortItem = key;
    this.dataStatus = true;
  }

  // Space view : table or cards
  activeTab: string = "table";
  toggleView(view: string) 
  {
    this.activeTab = view;
  }

  // FAKE DATA
  // ================================================================
  isOpen: boolean = false;
  newDocumentPopup() 
  {
    this.isOpen = true;
  }

  // used?
  close() 
  {
    this.isOpen = false;
  }

  goback() 
  {}

  // ====== panel open / close ==========
  openFacetsPanel() 
  {
    this.toggleRightSidebar();
  }

  isToggled: boolean = false;
  toggleRightSidebar() 
  {
    this.isToggled = !this.isToggled;
  }

  closePanel() 
  {
    this.isToggled = false;
  }

  activeAccountMenu: boolean = false;

  toggleAccountMenu() 
  {
    this.activeAccountMenu = !this.activeAccountMenu;
  }

  //Open Parent of space

  async openParentFolder() 
  {
    const dataparent = await this.gdocService.getFolderParent(this.spaceUid);

    this.router.navigate(["/s/space/" + dataparent]);
  }

  openFolderDialog(): void 
  {
    const dialogRef = this.dialog.open(AddFolderComponent, {
      width: "650px",
      data: {
        title: environment.stringsFile.newFolder,
        folderName: "",
        parentId: this.parentId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => 
    {
      //this.folder = result;
    });
  }
}
