import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { ITableColumn, ITableRow } from './table.interface';

@Component({
  selector: '// DO NOT INSTANCIATE VIA HTML, ONLY USE FOR EXTENDS COMPONENT',
  template: '',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class ATableComponent 
{
  @Input() columns: ITableColumn[] = [];
  @Input() data: ITableRow[][] = [];
  @Input() useCallback: boolean = false;
  @Input() disableHead: boolean = false;
  @Input() emptyTableMssage: string = 'No item(s)';
  @Input('data-title') title: string = '';

  @Output() row: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSort: EventEmitter<any> = new EventEmitter<any>();

  sortBy(colId: string, col: ITableColumn): void 
  {
    if (col.type == 'Menu') return;

    let x = 1;

    const ind: number = this.columns.findIndex(({ id }: ITableColumn) => 
    {
      return id == col.id;
    });

    if (this.columns[ind].order == 'DESC') 
    {
      this.columns[ind].order = 'ASC';
      x = -1;
    }
    else 
    {
      this.columns[ind].order = 'DESC';
    }

    if (this.useCallback) 
    {
      this.onSort.emit({
        orderBy: this.columns[ind].id,
        order: this.columns[ind].order,
      });

      return;
    }

    this.data.sort(function (a, b) 
    {
      type TParam = { id: string; value: string; sortValue?: string };
      const paramA: TParam = a.find(({ id }: ITableRow) => 
      {
        return id === colId;
      }) ?? { value: '', id: '' };
      const paramB: TParam = b.find(({ id }: ITableRow) => 
      {
        return id === colId;
      }) ?? { value: '', id: '' };

      if (
        paramA?.sortValue &&
        paramB?.sortValue &&
        paramA?.sortValue > paramB?.sortValue
      )
      {
        return x * 1;
      }

      if (
        paramA?.sortValue &&
        paramB?.sortValue &&
        paramA?.sortValue < paramB?.sortValue
      )
      {
        return x * -1;
      }

      if (paramA?.value > paramB?.value) return x * 1;

      if (paramA?.value < paramB?.value) return x * -1;

      return 0;
    });
  }

  onClickRowEventHandler(data: any): void 
  {
    this.row.emit(data.oid);
  }
}
