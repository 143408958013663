import { IFieldDesc } from "../interfaces/field-desc.interface";

export class FieldDesc implements IFieldDesc
{
  tags: string[];
  _control: string;
  control: string;
  _ui: { [key: string]: any }; // ui properties (obsolete)
  show: boolean;
  required: boolean;
  type: string;
  multiple?: boolean;
  name: string;
  label: string;
  fname: string;
  url: string;
  index: number;
  [key: string]: any;
  typeControl: string;
  typedValues:any;
  displayEmpty: boolean;
  urlAdd: string;
  icon?: string;

  /**
   * Constructor for FieldDesc
   * @param desc - Metadata descriptor for the field
   * @param fname - Field name or ID
   */
  constructor(desc: any, fname: string) 
  {
    // Copy desc fields to the instance
    for (const k in desc) 
    {
      this[k] = desc[k];
    }

    this.fname = this.name = fname;
    this.label = this.label || fname;

    this.tags = desc["x-tags"] || this.tags || [];
    this._ui = desc["x-ui"] || {};
    this.control = desc["control"] || desc["x-control"] || "text";
    this.index = desc["index"];

    if (typeof desc["x-show"] !== "undefined") 
    {
      this.show = desc["x-show"];
    }
    else if (typeof desc["show"] !== "undefined") 
    {
      this.show = desc["show"];

    }

    this.displayEmpty = desc["display-empty"] ?? false;
    this.urlAdd = desc["url_add"] ?? false;


    this.required =
      typeof desc["required"] !== "undefined"
        ? desc["required"] === "true" || desc["required"] === true
        : false;

    if (desc["typeControle"]) 
    {
      this.typeControl = desc["typeControle"];
    }
    else if (desc['control'] ==='checkbox'  && desc['multiple'] && !desc["enum"]) 
    {
      this.typeControl = 'checkboxMultiple';
    }
    else if (desc["control"] == 'checkbox' ) 
    {
      this.typeControl = 'checkbox';
    }

    else if (desc["control"] == 'select' || desc["enum"]) 
    {
      this.typeControl = 'select';
    }
    else if (desc["control"] == 'autocomplete' && desc["multiple"] == true) 
    {
      this.typeControl = 'autocompleteMutiple';
    }
    else if (desc["x-dynamic-values"] && (desc["type"] == 'integer' || desc["type"]  == 'string')) 
    {
      this.typeControl = 'autocomplete';
    }
    else if (desc["type"] == 'date') 
    {
      this.typeControl = 'date';
    }
    else 
    {
      this.typeControl = this.control;
    }///ajouter les valeurs possibles de type control
    // remplacer typeControl par control
  }

  /**
   * Check if the field should be shown
   * @returns True if the field should be shown, false otherwise
   */
  isShown() 
  {
    return !(this.show === false);
  }

  /**
   * Check if the field is hidden
   * @returns True if the field is hidden, false otherwise
   */
  isHidden() 
  {
    return this.show === false;
  }

  /**
   * Check if the field is required
   * @returns True if the field is required, null if not required
   */
  isRequired() 
  {
    const r = this.required === true;

    return r || null;
  }

  /**
   * Check if the field has a specific tag
   * @param t - Tag to check
   * @returns True if the field has the tag, false otherwise
   */
  hasTag(t: string) 
  {
    return this.tags.includes(t);
  }

  /**
   * Get all tags associated with the field
   * @returns Array of tags
   */
  getTags() 
  {
    return this.tags;
  }

  /**
   * Get all tags associated with the field
   * @returns Array of tags
   */
  getLabel() 
  {
    return this.label.toLowerCase();
  }

  /**
   * Get the UI property value of a specific type
   * @param type - Type of the UI property
   * @param deft - Default value if the UI property is not found
   * @returns Value of the UI property or default value
   */
  ui(type: string | number, deft: any) 
  {
    return typeof this._ui[type] != "undefined" ? this._ui[type] : deft;
  }

  /**
   * Check if the field is an enum type
   * @returns False (as FieldDesc is not an enum type)
   */
  isEnum() 
  {
    return false;
  }

  /**
  * Check if the field is an enum type
  * @returns False (as FieldDesc is not an enum type)
  */
  enums(): any[] | null 
  {
    return null;
  }

  /**
   * Get the enum value for the given key
   * @param v The key of the enum value
   * @returns Null (as FieldDesc is not an enum type)
   */
  getEnum(v: any): any 
  {
    return null;
  }

  /**
   * Get the label of the enum value for the given key
   * @param v The key of the enum value
   * @returns Null (as FieldDesc is not an enum type)
   */
  getEnumLabel(v: any): any 
  {
    return null;
  }

  /**
   * Update the list of enum values with the provided data
   * @param data The array of enum values
   */
  updateEnumList(data: any[]): void 
  { }

  /**
   * Get the query parameters for the field, if needed
   * @returns Null (as FieldDesc does not require query parameters)
   */
  queryParams(): any 
  {
    return null;
  }

  /**
   * Get the URL associated with the field, if any
   * @returns Null (as FieldDesc does not have a URL)
   */
  getUrl(): string 
  {
    return this.url;
  }

  /**
   * Check if the field must be preloaded (typically used for select fields with dynamic values)
   * @returns False (as FieldDesc does not need to be preloaded)
   */
  mustPreload(): boolean 
  {
    return false;
  }

  /**
   * Update the icon of field
   * @param icon string of data
   */
  setIcon(icon: string) 
  {
    this.icon=icon
  }
}




