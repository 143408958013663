import { stringFile } from "src/assets/strings/strings";

export const search_config =
{
  engineList: [
    {
      e: "ged",
      active: true,
      handlerName: "SearchGedEngine",
      serviceName: "SearchGdriveService"
    },

    {
      e: "gdrive",
      active: false,
      handlerName: "SearchGdriveEngine",
      serviceName: "SearchGdriveService",
      searchExtensionActive: false
            
    },

    /*{
            e: "gcontact",
            active: true,
            handlerName: "SearchGcontactEngine",
            serviceName: "SearchGcontactService"

        },
        {
            e: "gcontact2",
            active: true,
            handlerName: "SearchGcontact2Engine",
            serviceName: "SearchGcontact2Service"

        },
        {
            e: "gpeople",
            active: true,
            handlerName: "SearchGpeopleEngine",
            serviceName: "SearchGpeopleService"

        },*/
    /*{
            e: "gcal",
            active: true,
            handlerName: "SearchGcalEngine",
            serviceName: "SearchGcalService"

        },*/
    {
      e: "gcs",
      active: false,
      handlerName: "SearchGcsEngine",
      serviceName: "SearchGcsService"
    },
    {
      e: "gcontrib",
      active: true,
      handlerName: "SearchGcontributorEngine",
      serviceName: "SearchGcontributorService"
    }
  ],

  //groups == tabs == categories
  categories: [
    {
      id: "spaces",
      label: stringFile.stringsJson.searchCategories.spaces,
      engines: [
        "ged"
      ],
      order: 0
    },
    /*{
            id: "people",
            label: stringFile.stringsJson.searchCategories.people,
            engines: [
                "gcontact","gcontact2","gpeople"
            ],
            order: 2
        },*/
    {
      id: "contributor",
      label: stringFile.stringsJson.searchCategories.people,
      engines: [
        "gcontrib"
      ],
      order: 2
    },
    /*{
            id: "events",
            label: stringFile.stringsJson.searchCategories.events,
            engines: [
                "gcal"
            ],
            order: 3
        },/*
        
        /*{
            id: "cloudsearch",
            label: stringFile.stringsJson.searchCategories.gcs,
            engines: [
                "gcs"
            ],
            order: 3
        }
        */
  ]
}
