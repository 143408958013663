<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="title" [fullscreen]="true">
    <div class="px-5 py-4 mb-5">
        <section class="steps-line row justify-content-between gx-0 " [ngClass]="'step-' + step">
            <div class="progress"><span [style.width.%]="100/(3 - 1) * step"></span></div>
            <div class="col position-relative number-step" data-label="Contrats all">
                <span class="d-flex justify-content-center align-items-center shadow-sm active">1</span>
            </div>
            <div class="col"></div>
            <div class="col position-relative number-step" data-label="Contrats Juridique">
                <span class="d-flex justify-content-center align-items-center shadow-sm">2</span>
            </div>
            <div class="col"></div>
            <div class="col position-relative number-step" data-label="Contrats DG">
                <span class="d-flex justify-content-center align-items-center shadow-sm">3</span>
            </div>
        </section>
    </div>

    <div class="container-fluid mb-4">
        <div class="row gx-2">
            <div class="col-6  rounded p-3">
                <div class="card border-0 shadow-sm">
                    <div class="card-body text-start">
                        <h4 class="h3">Informations Contrats</h4>
                        <ul class="infos">
                            <li>Client :</li>
                            <li>Date :</li>
                            <li>Montant :</li>
                            <li>Reference :</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-6  rounded p-3">
                <div class="card border-0 shadow-sm">
                    <div class="card-body text-start">
                        <h4 class="h3">Etat de documents</h4>
                        <ul class="infos">
                            <li>Type document :</li>
                            <li>Etat :</li>
                            <li>Version :</li>
                            <li>Num chrono :</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="controls py-3">
        <button (click)="currentTab = 0" [ngClass]="{'active': currentTab == 0}" class="btn linked ps-0 pe-4">
            Linked Documents
        </button>
        <button (click)="currentTab = 1" [ngClass]="{'active': currentTab == 1}" class="btn comments ps-3 pe-4 ">
            Document History
        </button>
    </div>

    <div *ngIf="currentTab == 0" class="row list hide">
        <div class="col">
            <aura-table-row [data]="data" [columns]="columns"></aura-table-row>
        </div>
    </div>
    <div *ngIf="currentTab == 1" class="row list hide">
        <div class="col">
            <aura-table-row [data]="history" [columns]="columns"></aura-table-row>
        </div>
    </div>
</aura-modal>
