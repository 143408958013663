<div class="mx-4">
  <div class="row mt-4 mb-2">
    <!-- Left Button -->
    <div class="col text-start" (click)="goback()">
      <img src="/assets/images/icon-left-arrow-circle.svg" width="33" />
      <span class="align-middle d-inline-block ms-2 primary-color fw-bold">{{ 'tag.button.back' | translate }}</span>
    </div>
  
    <!-- Right Button -->
    <div class="col text-end">
      <button auraButton
        label="{{ 'addBtn' | translate }}"
        size="large"
        type="success"
        [style.min-width]="'160px'"
        (click)="addPage()"></button>
    </div>
  </div>
  
  


  <aura-table-solid *ngIf="!loading"
    [columns]="columns"
    [data]="dataSource"
    (row)="openLink($event)"></aura-table-solid>

  <div class="spinner-container" *ngIf="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>
