import { Component, EventEmitter, Input, NgZone, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';
import { AdvancedListItem } from '@interfaces/advanced-list-item';
import { environment } from 'src/environments/environment';
import { EventPerson } from '../../models/event/event-person.model';
import { GcalEvent } from '../../models/event/gcal-event.model';
import { CalendarService } from '../../services/calendar.service';
import { EventAnswerComponent } from '../event-answer/event-answer.component';
import { EventItemComponent } from '../event-item/event-item.component';
import { RecentDocsComponent } from '../recent-docs/recent-docs.component';

const searchRoute = environment.searchRoute
const themePath = environment.themePath;

@Component({
  selector: 'app-events',
  templateUrl: `./${themePath}/events.component.html`,
  styleUrls: [`./${themePath}/events.component.scss`]
})

export class EventsComponent implements OnInit, OnChanges 
{
  @Input('Events') events

  static instance: EventsComponent

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private calendarService: CalendarService,
    private dialog: MatDialog) 
  {
    EventsComponent.instance = this;
  }

  ngOnChanges(changes: SimpleChanges): void 
  {
    //console.log("events meets",this.events)

    Object.values(this.events).forEach(item => 
    {
      this.setDisplayProperties(item['displayMeetings']);
    })
  }

  linkCalendar = environment.calendarLink;

  ngOnInit(): void 
  {
  }

  toLink(p) 
  {
    if (p != null && p != undefined) 
    {
      window.open(p, '_blank');
    }
  }

  eventSearch(p) 
  {
    this.router.navigate(searchRoute, { queryParams: { q: p, d: localStorage.host_domain } });
  }

  forTest(p) 
  {
  }

  /* loadEvents(){
    this.workspaceService.getEvents().then(res=>{
       this.events = res;

    })
  } */


  toEvents() 
  {
    window.open(this.linkCalendar, '_blank');
  }

  @Output() emitState: EventEmitter<any> = new EventEmitter();

  async meetOptionClick(event: GcalEvent, response: string) 
  {
    event = await this.calendarService.setMeetingResponse(event, response);
    this.events = [];
    this.emitState.emit({ relaunchEventsReq: true })

    // this.ngZone.run(() => {
    //   this.events = []
    //   this.webEvents = []
    //  });
  }

  async cancelAnswer(eventPerson: EventPerson) 
  {
    const response = environment.stringsFile.allStatus;

    eventPerson.selfStatus = "needsAction";
    eventPerson.setDisplayedSelfStatus(eventPerson.getSelfStatus());

  }

  acceptStatus = environment.stringsFile.acceptStatus;
  declineStatus = environment.stringsFile.declineStatus;
  maybeStatus = environment.stringsFile.maybeStatus;

  meetingOptions = [this.acceptStatus, this.declineStatus, this.maybeStatus]

  getEventElementOutput(event) 
  {
    const relaunchEventsReq = event?.relaunchEventsReq || false;

    this.emitState.emit({ relaunchEventsReq: relaunchEventsReq })
  }

  displayMeetings: (AdvancedListItem &
  {
    question?: { label: string; callback: Function };
    answers?: { value: string; label: string }[];
  })[] = [];

  setDisplayProperties(results) 
  {
    this.displayMeetings.push(results[0])
  }




  async meetOptionClickStatus(event: GcalEvent, response?: string) 
  {
    event = await this.calendarService.setMeetingResponseListAdvanced(event, response);
    this.displayMeetings = []
    this.emitState.emit({ relaunchEventsReq: true })



    // this.emitState.emit({relaunchEventsReq: true})

    // this.ngZone.run(() => {
    //   this.events = []
    //   this.webEvents = []
    //  });



  }

  moreDetailsModal: boolean = false;
  eventItem;
  openPopup(eventItem: GcalEvent) 
  {
    this.moreDetailsModal = true
    this.eventItem = eventItem
    /*  console.log("openPopup2",eventItem)
  
     const dialogRef = this.dialog.open(EventAnswerComponent, {
       width: "650px",
       data: {
         eventItem: eventItem
       },
     });
  
     dialogRef.afterClosed().subscribe((result) => {
  
  
       this.emitState.emit({relaunchEventsReq: result?.relaunchEventsReq})
  
     }); */

  }



}
