// mock-data.service.ts
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MockDataService 
{
  private mockData ={
    "code":"OK",
    "content":{
      "groups":[
        {
          "engine":"gcontact"
        },
        {
          "engine":"gcontributor",
          "people":[
            {
              "files":[
                [
                  "Yvetot Franck"
                ],
                [
                  "oumaima"
                ]
              ],
              "resourceName":"people/114589323610361733849",
              "etag":"%EgwBAgMJCwwTLjc9Pj8aAwECByIMa2lOdVNjOEVaZzA9",
              "names":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"114589323610361733849"
                    },
                    "sourcePrimary":true
                  },
                  "displayName":"Yvetot Franck",
                  "familyName":"Franck",
                  "givenName":"Yvetot",
                  "displayNameLastFirst":"Franck, Yvetot",
                  "unstructuredName":"Yvetot Franck"
                },
                {
                  "metadata":{
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "displayName":"Franck Yvetot",
                  "familyName":"Yvetot",
                  "givenName":"Franck",
                  "displayNameLastFirst":"Franck Yvetot",
                  "unstructuredName":"Franck Yvetot"
                }
              ],
              "photos":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "url":"https://lh3.googleusercontent.com/a-/ALV-UjVpQ0w0X2uHqsbtPr4wY1zDwSVIaqyNkn634wSwXcc01N4=s100"
                }
              ],
              "emailAddresses":[
                {
                  "metadata":{
                    "primary":true,
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    },
                    "sourcePrimary":true
                  },
                  "value":"franck@presencesoft.com"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"franck@presencemedia.ma"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"contact@presencemedia.ma"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"*@presencemedia.ma"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"franck@presencesourcing.com"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"*@presencesourcing.com"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"contact@presencesourcing.com"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"franck.yvetot@presencesoft.com"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "value":"franck@presencedoc.com"
                }
              ],
              "organizations":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    },
                    "sourcePrimary":true
                  },
                  "title":"VP / CTO"
                }
              ],
              "relations":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"114589323610361733849"
                    }
                  },
                  "person":"louis@presencesoft.com",
                  "type":"manager",
                  "formattedType":"Manager"
                }
              ]
            },
            {
              "files":[
                [
                  "Yvetot Franck"
                ],
                [
                  "oumaima"
                ],
                [
                  "oumaima"
                ],
                [
                  "oumaima"
                ]
              ],
              "resourceName":"people/116016669576032644363",
              "etag":"%EgwBAgMJCwwTLjc9Pj8aAwECBw==",
              "names":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"116016669576032644363"
                    },
                    "sourcePrimary":true
                  },
                  "displayName":"Franck Yvetot",
                  "familyName":"Yvetot",
                  "givenName":"Franck",
                  "displayNameLastFirst":"Yvetot, Franck",
                  "unstructuredName":"Franck Yvetot"
                },
                {
                  "metadata":{
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"116016669576032644363"
                    }
                  },
                  "displayName":"Franck Yvetot",
                  "familyName":"Yvetot",
                  "givenName":"Franck",
                  "displayNameLastFirst":"Franck Yvetot",
                  "unstructuredName":"Franck Yvetot"
                }
              ],
              "photos":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"116016669576032644363"
                    }
                  },
                  "url":"https://lh3.googleusercontent.com/a/ACg8ocIooBDO3EXb8xKOvc3qTDVICsGZnKDy23z58EgYT05R=s100",
                  "default":true
                }
              ],
              "emailAddresses":[
                {
                  "metadata":{
                    "primary":true,
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"116016669576032644363"
                    },
                    "sourcePrimary":true
                  },
                  "value":"franck@presencesoft.net"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"116016669576032644363"
                    }
                  },
                  "value":"franck2@presencemedia.ma"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"116016669576032644363"
                    }
                  },
                  "value":"franck2@presencesourcing.com"
                }
              ]
            },
            {
              "files":[
                [
                  "Yvetot Franck"
                ]
              ],
              "resourceName":"people/102588617991131411410",
              "etag":"%EgwBAgMJCwwTLjc9Pj8aAwECBw==",
              "names":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"102588617991131411410"
                    },
                    "sourcePrimary":true
                  },
                  "displayName":"Raf Test",
                  "familyName":"Test",
                  "givenName":"Raf",
                  "displayNameLastFirst":"Test, Raf",
                  "unstructuredName":"Raf Test"
                },
                {
                  "metadata":{
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "displayName":"Raf Test",
                  "familyName":"Test",
                  "givenName":"Raf",
                  "displayNameLastFirst":"Raf Test",
                  "unstructuredName":"Raf Test"
                }
              ],
              "photos":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "url":"https://lh3.googleusercontent.com/a/ACg8ocJDCBvZYIDajAV-VTVZgKQpFkWPchIKV4NJAiymPUY9=s100",
                  "default":true
                }
              ],
              "emailAddresses":[
                {
                  "metadata":{
                    "primary":true,
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    },
                    "sourcePrimary":true
                  },
                  "value":"test1@presencesoft.com"
                },
                {
                  "metadata":{
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "value":"franck.yvetot@gmail.com",
                  "type":"work",
                  "formattedType":"Work"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "value":"test1@presencemedia.ma"
                },
                {
                  "metadata":{
                    "verified":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "value":"test1@presencesourcing.com"
                }
              ],
              "phoneNumbers":[
                {
                  "metadata":{
                    "primary":true,
                    "source":{
                      "type":"DOMAIN_PROFILE",
                      "id":"102588617991131411410"
                    }
                  },
                  "value":"+33699190082",
                  "canonicalForm":"+33699190082",
                  "type":"work",
                  "formattedType":"Work"
                }
              ]
            }
          ],
          "totalSize":3
        }
      ]
    },
    "errors":[
      {
        "engine":"gcontact2",
        "error":"Request failed with status code 403"
      }
    ]
  };

  getMockedData(): Observable<any> 
  {
    return of(this.mockData);
  }
}
