import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HandleSuccessComponent } from './components/handle-success/handle-success.component';
import { HandleFailureComponent } from './components/handle-failure/handle-failure.component';


const routes: Routes = [
  {
    path: 'oauth-psoft/success/:token',
    component: HandleSuccessComponent
  },
  {
    path: 'oauth-psoft/failure',
    component: HandleFailureComponent
  },
];



@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule 
{ }
