import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { APISce } from 'src/modules/app-common/services';
import { GcalEvent } from '../models/event/gcal-event.model';

const GCALENDAR_API = environment.GCALENDAR_API;

@Injectable({
  providedIn: 'root'
})
export class CalendarService 
{

  constructor(
    private api: APISce) 
  { }

  acceptStatus = environment.stringsFile.acceptStatus;

  declineStatus = environment.stringsFile.declineStatus;
  maybeStatus = environment.stringsFile.maybeStatus;
  allStatus = environment.stringsFile.allStatus;

  async setMeetingResponse(event: GcalEvent, response: string, calendarId = "primary") 
  {

    const eventAnswer = this.getResponseStatus(response);
    const eventId = event.getId();


    const url = `${GCALENDAR_API}/${calendarId}/events/${eventId}/${eventAnswer}`;
    const options = this.api.httpConfig() // {} //this.googleLoginService.httpConfig(); // to be seen after

    const updatedEventResponse = await this.api.put(url, null, options)

    const updatedEvent = updatedEventResponse['content'] || null;

    event = new GcalEvent(updatedEvent);

    return event;

  }


  async setMeetingResponseListAdvanced(event: GcalEvent, response: string, calendarId = "primary") 
  {

    const eventAnswer = response;
    const eventId = event.getId();


    const url = `${GCALENDAR_API}/${calendarId}/events/${eventId}/${eventAnswer}`;
    const options = this.api.httpConfig() //{} //this.googleLoginService.httpConfig();

    const updatedEventResponse = await this.api.put(url, null, options)

    const updatedEvent = updatedEventResponse['content'] || null;

    event = new GcalEvent(updatedEvent);

    return event;

  }

  meetingOptions = [this.acceptStatus, this.declineStatus, this.maybeStatus, this.allStatus]
  getResponseStatus(response) 
  {
    if (response == this.allStatus) return "needsAction";
    if (response == this.acceptStatus) return "accepted";
    if (response == this.declineStatus) return "declined";
    if (response == this.maybeStatus) return "tentative";

  }
}
