<div class="pl-20">
    <ng-container *ngIf="resultGroup==''">
        <div *ngFor="let group of searchResponseObservable | async">

            <div class="row list hide" *ngIf="!loading">
                <div class="col" *ngIf="group.data.length!=0">
                    <aura-table-row [data]="group.data" [columns]="group.columns" [title]="group.label"
                        [disableHead]="true">
                    </aura-table-row>
                </div>



            </div>

            <div *ngIf="group.data.length!=0 && !loading" class="row d-flex justify-content-center"
                style="cursor: pointer;" (click)="setGroup(group.id)">
                <p style="width: fit-content; font-weight: bold;">{{'showAllResults' | translate:{'group': group.label}
                    }}</p>
            </div>
        </div>
        <!--  <div class="col" *ngIf="group.data.length == 0">
            <p>{{'No_results' | translate }}. . .</p>
        </div> -->
        <div class="row justify-content-center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
    </ng-container>
    <ng-container *ngIf="resultGroup!=''"> <!-- resultGroup :  string with name of group -->
        <div class="row list hide">
            <div class="col" *ngIf="selectedGroup!=undefined ">
                <!-- selectedGroup :  group object with data etc -->
                <ng-container *ngIf="selectedGroup.data.length!=0 && !loading">
                    <aura-table-row [data]="selectedGroup.data" [columns]="selectedGroup.columns"
                        [title]="selectedGroup.label" [disableHead]="true">
                    </aura-table-row>
                </ng-container>

                <div class="row justify-content-center" *ngIf="loading">
                    <mat-spinner></mat-spinner>
                </div>

            </div>

            <div class="col" *ngIf="selectedGroup==undefined">
                <div *ngIf="loading" class="row justify-content-center">
                    <mat-spinner></mat-spinner>
                </div>


            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="allEmptyData">
        <div class="no-results">
            <div>
                <h1 class="no-results-title">No Results Found</h1>
            </div>
            <div>We found 0 documents for "{{ keyword}}" </div>
            <div></div>
            <div>Maybe try another search request</div>
            <div class="no-results-image">
                <svg width="200" height="200" viewBox="0 0 360 349" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M349.189 174.5C349.189 267.461 272.464 343 177.595 343C82.7257 343 6 267.461 6 174.5C6 81.539 82.7257 6 177.595 6C272.464 6 349.189 81.539 349.189 174.5Z"
                        stroke="white" stroke-width="12" />
                    <mask id="mask0_1783_78447" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="10" y="10"
                        width="335" height="329">
                        <ellipse cx="177.595" cy="174.503" rx="167.171" ry="164.258" fill="white" />
                    </mask>
                    <g mask="url(#mask0_1783_78447)">
                        <mask id="mask1_1783_78447" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="48" y="96"
                            width="259" height="254">
                            <path d="M48.9089 96.1168H306.281V349.004H48.9089V96.1168Z" fill="white" />
                        </mask>
                        <g mask="url(#mask1_1783_78447)">
                            <path
                                d="M275.227 166.565L214.271 106.671C211.705 104.3 208.301 103.003 204.779 103.056C201.257 103.108 197.895 104.506 195.404 106.953C192.913 109.399 191.489 112.703 191.434 116.163C191.379 119.624 192.698 122.969 195.11 125.491L204.779 170.884L226.504 181.07L256.066 185.385C258.606 187.882 262.052 189.285 265.644 189.285C267.423 189.285 269.185 188.941 270.829 188.273C272.472 187.604 273.966 186.624 275.224 185.388C276.482 184.153 277.48 182.685 278.161 181.071C278.842 179.456 279.193 177.725 279.193 175.977C279.193 174.229 278.843 172.499 278.163 170.884C277.482 169.269 276.485 167.801 275.227 166.565Z"
                                fill="#0083F6" />
                            <path
                                d="M204.687 102.77H103.093C95.9073 102.77 89.0164 105.575 83.9357 110.567C78.855 115.559 76.0007 122.33 76.0007 129.39V315.728C76.0007 322.788 78.855 329.559 83.9357 334.551C89.0164 339.543 95.9073 342.348 103.093 342.348H252.097C259.283 342.348 266.173 339.543 271.254 334.551C276.335 329.559 279.189 322.788 279.189 315.728V175.975H218.233C211.46 175.975 204.687 169.32 204.687 162.665V102.77Z"
                                fill="url(#paint0_linear_1783_78447)" />
                            <path
                                d="M204.687 102.771H191.141V162.665C191.141 169.725 193.995 176.496 199.076 181.488C204.157 186.481 211.047 189.285 218.233 189.285H279.189V175.975H218.233C211.46 175.975 204.687 169.32 204.687 162.665V102.771Z"
                                fill="white" />
                            <path
                                d="M170.822 176.824C170.822 178.589 170.108 180.281 168.838 181.529C167.568 182.777 165.845 183.479 164.049 183.479H109.865C108.069 183.479 106.346 182.777 105.076 181.529C103.806 180.281 103.093 178.589 103.093 176.824C103.093 175.059 103.806 173.366 105.076 172.118C106.346 170.87 108.069 170.169 109.865 170.169H164.049C165.845 170.169 167.568 170.87 168.838 172.118C170.108 173.366 170.822 175.059 170.822 176.824Z"
                                fill="white" />
                            <path
                                d="M170.822 278.452C170.822 280.217 170.108 281.91 168.838 283.158C167.568 284.406 165.845 285.107 164.049 285.107H109.865C108.069 285.107 106.346 284.406 105.076 283.158C103.806 281.91 103.093 280.217 103.093 278.452C103.093 276.688 103.806 274.995 105.076 273.747C106.346 272.499 108.069 271.798 109.865 271.798H164.049C165.845 271.798 167.568 272.499 168.838 273.747C170.108 274.995 170.822 276.688 170.822 278.452Z"
                                fill="white" />
                            <path
                                d="M252.097 209.126C252.097 210.891 251.384 212.584 250.114 213.832C248.843 215.08 247.121 215.781 245.324 215.781H109.865C108.069 215.781 106.346 215.08 105.076 213.832C103.806 212.584 103.093 210.891 103.093 209.126C103.093 207.361 103.806 205.669 105.076 204.421C106.346 203.173 108.069 202.471 109.865 202.471H245.324C247.121 202.471 248.843 203.173 250.114 204.421C251.384 205.669 252.097 207.361 252.097 209.126Z"
                                fill="white" />
                            <path
                                d="M226.905 241.382C226.905 243.158 226.313 244.861 225.261 246.117C224.208 247.373 222.781 248.078 221.292 248.078H109.043C107.554 248.078 106.127 247.373 105.074 246.117C104.021 244.861 103.43 243.158 103.43 241.382C103.43 239.606 104.021 237.902 105.074 236.647C106.127 235.391 107.554 234.685 109.043 234.685H221.292C222.781 234.685 224.208 235.391 225.261 236.647C226.313 237.902 226.905 239.606 226.905 241.382Z"
                                fill="white" />
                        </g>
                        <path
                            d="M257.366 283.861L254.67 286.332L246.669 277.902L243.828 274.908L246.524 272.438L249.364 275.432L257.366 283.861Z"
                            fill="#0083F6" />
                        <path
                            d="M249.364 275.43C248.934 275.858 248.492 276.278 248.042 276.691C247.593 277.104 247.133 277.509 246.669 277.898L243.828 274.904L246.531 272.434L249.364 275.43ZM257.799 284.256C257.369 284.683 256.926 285.103 256.476 285.516C256.027 285.929 255.567 286.335 255.103 286.724L252.262 283.73L254.965 281.259L257.799 284.256Z"
                            fill="#0083F6" />
                        <path
                            d="M260.551 244.544C260.239 253.184 257.327 261.539 252.185 268.553C247.043 275.568 239.901 280.928 231.66 283.957C223.419 286.985 214.45 287.546 205.885 285.569C197.32 283.591 189.543 279.165 183.537 272.847C175.489 264.366 171.198 253.091 171.608 241.502C172.019 229.913 177.097 218.958 185.727 211.048C203.667 194.604 231.884 195.567 248.62 213.199C252.619 217.389 255.733 222.317 257.781 227.697C259.829 233.078 260.771 238.804 260.551 244.544Z"
                            fill="url(#paint1_linear_1783_78447)" />
                        <path
                            d="M252.24 244.276C251.914 253.479 247.967 262.199 241.228 268.599C234.49 275 225.486 278.581 216.114 278.588C215.687 278.588 215.257 278.581 214.824 278.567C212.577 278.491 210.342 278.212 208.147 277.734C203.968 276.821 199.989 275.178 196.4 272.885C193.92 271.296 191.645 269.416 189.627 267.288C183.157 260.381 179.73 251.239 180.094 241.859C180.458 232.478 184.583 223.62 191.57 217.217C198.556 210.814 207.836 207.386 217.385 207.682C226.934 207.977 235.977 211.971 242.54 218.793C243.986 220.316 245.295 221.959 246.452 223.705C248.789 227.228 250.463 231.135 251.394 235.24C252.066 238.204 252.35 241.241 252.24 244.276Z"
                            fill="#EFF1F3" />
                        <path
                            d="M228.048 224.007C222.201 220.746 215.275 219.9 208.793 221.655C202.312 223.41 196.805 227.623 193.483 233.366"
                            stroke="white" stroke-width="15" stroke-linecap="round" stroke-linejoin="round" />
                        <path
                            d="M290.609 320.454C289.087 321.854 287.08 322.631 284.996 322.628H284.704C283.625 322.594 282.563 322.351 281.581 321.913C280.598 321.474 279.713 320.849 278.977 320.073L248.706 289.322L260.748 278.279L291.014 309.014C292.501 310.585 293.293 312.672 293.217 314.816C293.141 316.961 292.203 318.988 290.609 320.454Z"
                            fill="#0083F6" />
                        <path
                            d="M291.68 319.257C290.919 320.302 289.916 321.153 288.754 321.741C287.591 322.329 286.303 322.636 284.996 322.638H284.704C283.624 322.603 282.562 322.359 281.579 321.919C280.597 321.479 279.712 320.851 278.977 320.074L248.706 289.322L250.657 287.533L280.865 318.214C281.6 318.991 282.485 319.617 283.468 320.056C284.45 320.494 285.512 320.738 286.591 320.771H286.883C288.605 320.776 290.283 320.246 291.68 319.257Z"
                            fill="#0083F6" />
                        <path d="M260.578 281.924L289.083 309.932" stroke="white" stroke-width="3"
                            stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <ellipse cx="321.113" cy="78.3885" rx="38.8864" ry="38.2088" fill="#E3262F" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M309.957 68.2154C310.583 67.6001 311.599 67.6001 312.225 68.2154L320.712 76.555L329.2 68.2154C329.826 67.6001 330.842 67.6001 331.468 68.2154C332.094 68.8307 332.094 69.8283 331.468 70.4437L322.98 78.7833L331.468 87.1228C332.094 87.7382 332.094 88.7358 331.468 89.3511C330.842 89.9664 329.826 89.9664 329.2 89.3511L320.712 81.0115L312.225 89.3511C311.599 89.9664 310.583 89.9664 309.957 89.3511C309.331 88.7358 309.331 87.7382 309.957 87.1228L318.445 78.7833L309.957 70.4437C309.331 69.8283 309.331 68.8307 309.957 68.2154Z"
                        fill="white" />
                    <defs>
                        <linearGradient id="paint0_linear_1783_78447" x1="177.595" y1="342.348" x2="177.595" y2="102.77"
                            gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0083F6" stop-opacity="0.4" />
                            <stop offset="1" stop-color="#0083F6" />
                        </linearGradient>
                        <linearGradient id="paint1_linear_1783_78447" x1="216.082" y1="199.318" x2="216.082"
                            y2="286.728" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0083F6" />
                            <stop offset="1" stop-color="#0083F6" />
                        </linearGradient>
                    </defs>
                </svg>

            </div>
        </div>
    </ng-container>
</div>
<ng-template #myTemRef let-flag="flag">
    <span class="tag {{ flag.classFlag }}" [matTooltip]="flag.tooltipFlag">{{
        flag.labelFlag
        }}</span><span *ngIf="flag.countFlags" [matTooltip]="flag.tooltipFlag" class="tag tag-plus">{{ flag.countFlags
        }}</span>
</ng-template>