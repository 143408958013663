import { TableColumn } from "src/design-system/core/interfaces/table";
import { SearchEngine } from "src/modules/engines/models/search-engine";
import { SearchGcontactService } from "../services/search-gcontact.service";
import { GcontactPerson } from "./gcontact-person";


export class SearchGcontactEngine extends SearchEngine 
{


  searchGcontactService: SearchGcontactService
  facets = [];

  constructor(searchGcontactService?,clientContexts?)
  {
    super();
    this.searchGcontactService = searchGcontactService
  }

  setEngine()
  {
    this.engine = "gcontact";
  }

  setResults(engineResponse)
  {
    const jsonResults = engineResponse?.results || []

    this.results = jsonResults.map(item=>new GcontactPerson(item))

    this.setDisplayProperties(this.results);
    this.getColumns();

    return this.results;

  }
  displayProperties;

  setDisplayProperties(results)
  {
    this.displayProperties = results.map(item=>item.getDisplayProperties())
  }

  columns: TableColumn[]
  getColumns()
  {
    this.columns = [
      {
        id: 'name',
        type: 'TextWithIcon'
      },
      {
        id: 'mail',
        type: 'Text',
        label: 'Mail'
      },
      {
        id: 'Menu',
        type: 'Menu',
        label: 'Actions',
      }
    ];

    return this.columns;
  } 


  getDisplayProperties()
  {
    return this.displayProperties;
  }
  getResults()
  {
    return this.results;
  }

  setEngineOptions()
  {
    this.engineOptions = {engine: this.getEngine()}
  }

  setFacets(engineResponse)
  {
    const jsonFacets = engineResponse;

    return this.facets;
  }

  getFacets()
  {
    return this.facets;
  }

}