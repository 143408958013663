import { Injectable } from '@angular/core';
import { AuthService } from '../user/services';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let gtag: Function;


@Injectable({
  providedIn: 'root'
})
export class PDocEventsService 
{

  user: string;
  gaActive = environment.G_ANALYTICS;

  getUser(): string 
  {
    this.auth.profileSubject
      .pipe(
        filter(value => !!value),
        tap(user => 
        {
          this.user = user.name
        }))
      .subscribe();

    return this.user;
  }

  /**
    * Constructeur de la classe.
    */
  constructor( private auth: AuthService) 
  {}

  /**
     * envoie un événement à Google Analytics.
     * @param action - L'action associée à l'événement.
     * @param params - Les paramètres additionnels de l'événement (optionnels).
     */
  sendEventToGa(action: string, params?: any) 
  {
    if (this.gaActive && typeof gtag !== 'undefined' && typeof gtag === 'function' ) 
    {
      // L'ID de suivi de Google Analytics est valide
      gtag('event', action, {
        ...params
      });
    }
  }
  
  /**
     * envoie un événement à Google Analytics pour la création d'un document.
     * @param name - Le nom du document créé.
     * @param user - L'utilisateur associé au document.
     * @param space - L'espace dans lequel le document a été créé.
     */
  createDocumentEvent(name: string, user: string, space: string) 
  {
    this.sendEventToGa( 'document-create', {
      user: user,
      document: name,
      space: space,
      bc: space +'=>'+ name
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour la visualisation d'un document.
     * @param name - Le nom du document visualisé.
     * @param user - L'utilisateur associé au document.
     * @param space - L'espace dans lequel le document est visualisé.
     */
  viewDocumentEvent(name: string, user: string, space: string) 
  {
    this.sendEventToGa( 'document-view', {
      user: user,
      document: name,
      space: space,
      bc: space +'=>'+ name
    });
  }
  
  /**
     * envoie un événement à Google Analyticss pour la mise à jour d'un document.
     * @param name - Le nom du document mis à jour.
     * @param user - L'utilisateur associé au document.
     * @param space - L'espace dans lequel le document est mis à jour.
     */
  updateDocumentEvent(name: string, user: string, space: string) 
  {
    this.sendEventToGa( 'document-update', {
      user: user,
      document: name,
      space: space,
      bc: space +'=>'+ name
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour la visualisation d'un espace.
     * @param user - L'utilisateur associé à l'espace.
     * @param space - L'espace visualisé.
     */
  viewSpaceEvent(space: string) 
  {
    this.sendEventToGa( 'space-view', {
      user: this.getUser(),
      space: space
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour la création d'un espace.
     * @param user - L'utilisateur associé à l'espace.
     * @param space - L'espace créé.
     */
  createSpaceEvent(space: string) 
  {
    this.sendEventToGa( 'space-create', {
      user: this.getUser(),
      space: space
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour la mise à jour d'un espace.
     * @param user - L'utilisateur associé à l'espace.
     * @param space - L'espace mis à jour.
     */
  updateSpaceEvent(space: string) 
  {
    this.sendEventToGa( 'space-update', {
      space: space
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour une recherche globale.
     * @param user - L'utilisateur effectuant la recherche.
     * @param keyWord - Le mot-clé utilisé dans la recherche.
     */
  searchEvent(user: string, keyWord: string) 
  {
    this.sendEventToGa( 'global-search', {
      user: user,
      keyWord: keyWord
    });
  }
  
  /**
     * envoie un événement à Google Analytics pour une recherche dans un espace spécifique.
     * @param user - L'utilisateur effectuant la recherche.
     * @param keyWord - Le mot-clé utilisé dans la recherche.
     * @param space - L'espace dans lequel la recherche est effectuée.
     */
  searchInSpaceEvent(user: string, keyWord: string, space: string) 
  {
    this.sendEventToGa( 'space-search', {
      user: user,
      keyWord: keyWord,
      space: space
    });
  }
}
