import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DocType } from 'src/modules/app-common/models/doc-type/doc-type.model';
import { DoctypeDialogData } from 'src/modules/app-common/models/doctype-dialog-data/doctype-dialog-data.model';
import { GedService } from 'src/modules/app-common/services';
import { DocumentService } from 'src/modules/documents/services/document.service';
import { SpaceService } from 'src/modules/spaces/services';

const themePath = environment.themePath;

@Component({
  selector: 'app-create-doc',
  templateUrl: './create-doc.component.html',
  styleUrls: ['./create-doc.component.scss']
})
export class CreateDocComponent implements OnInit
{
  constructor(
    // public dialogRef: MatDialogRef<AddDoctypeComponent>, @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData,
    private router: Router,
    private spaceService: SpaceService,
    private gedService: GedService,
    private documentService: DocumentService,
    private cdr: ChangeDetectorRef) 
  {
  }

  @Inject(MAT_DIALOG_DATA) public data: DoctypeDialogData

  @Input() isOpen: boolean = false;
  @Input('spaceUid') spaceUid;
  @Input('space') space;
  @ViewChild("drawer") public drawer: MatDrawer;
  @ViewChild('f') public docFormulaire:NgForm;
  @Output() close = new EventEmitter<any>();

  typeDocStr : string = environment.stringsFile.newDoc;

  title: string = 'New Document';
  isLoading = false;

  previewLink: string;
  temp = null;
  docType = {
    oid: "",
    title: "",
  };
  currentId;
  fieldAutoList = [];
  words;
  inGed = false;
  typeDoc;
  showAuto;
  autoUrl;
  showForm = false;
  testInput;
  done = false;

  currentDoc;
  name;
  code;
  dataToSave = [];
  outerElement = {
    key: "",
    value: null,
  };
  jsontosend = {};
  templateId = "";
  spaceId = "";

  autoComplete ;
  typeList = [];
  listeSpaces = [];
  listeTemplates = [];

  defaultTemplate;
  defaultSpace;
  jsonData = {};
  formFields = [];
  temporaryList = [];
  isCreated : boolean = false;


  ngAfterViewChecked()
  {
    this.cdr.detectChanges();
  }


  ngOnInit(): void
  {
    this.getTypeDocumentbySpace()
  }

  closeModal()
  {
    this.close.emit();
    this.docFormulaire.form.reset()
    this.getTypeDocumentbySpace()
    this.showForm = false;
    this.drawer.close();
 
  }

  onCancel(): void
  {
    //this.dialogRef.close();
  }

  doFilter(value)
  {
    if (value == "")
    {
      this.typeList = [];
    }
    else
    {
      this.gedService.getDocTypes(value).then((res) =>
      {
        this.autoComplete = res;
        this.typeList = [];


        for (const option of Object.values(this.autoComplete))
        {
          this.typeList.push(option["title"]);
        }
        this.filterType(this.typeList, value);
      });
    }
  }  

  filterType(options, value)
  {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.toLowerCase().includes(value)
    );
  }

  doFilterField(field)
  {
    if (field.value == "")
    {
      field.fieldAutoList = [];
    }
    else
    {
      this.gedService
        .getAutocompleteList(field.value, field.auto.url)
        .then((res) =>
        {
          let autoComplete = [];

          autoComplete = res;

          field.fieldAutoList = [];
          for (const option of Object.values(autoComplete))
          {
            field.fieldAutoList.push(option);
          }

          this.filter(field.fieldAutoList, field.value);
        });
    }
  }

  filter(options, value)
  {
    return options.filter((values) =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      values.html.toLowerCase().includes(value)
    );
  }

  async getSpacebyType(type)
  {
    const space = this.spaceService.getSpacebySid(type);

    return space;
  }

  // Document type select
  onSelectedType(event)
  {
    this.drawer.open();
    this.formFields = [];
    const type = event.value;

    this.gedService.getDocTypes("", null, this.spaceUid).then((res) =>
    {
      this.autoComplete = res;
      let oid;

      for (const item of this.autoComplete)
      {

        if (item.title == type)
        {
          oid = item.oid;
          this.docType = item;

          this.gedService.getMetaByType(oid, true, true,null,true)
            .then((res) =>
            {
              this.showForm = true;
              const data = res["data"];
              const metadata = res["metadata"]["fields"];

              for (const key of Object.keys(metadata))
              {
                if (metadata[key]["x-show"] != false && metadata[key]["show"] != false)
                {
                  let element: DocType = new DocType(key, data[key], metadata[key], this.gedService);

                  //temporary
                  //begin
                  if (element.getAuto() != null  && element.getControl() =="select" )
                  {
                    this.gedService.getAutocompleteListSelect(element.getUrlAuto())
                      .then((autolist) =>
                      {
                        let tempList = [];

                        tempList = autolist || [];

                        for (const item of tempList)
                        {
                          element.fieldAutoList.push(item);
                        }
                      });

                  }
                  else if   (element.getControl() =="checkbox")
                  {
                    if(element.getUrlAuto())
                    {
                      this.gedService.getAutocompleteListSelect(element.getUrlAuto())
                        .then((autolist) =>
                        {
                          let tempList = [];

                          tempList = autolist || [];

                          for (const item of tempList)
                          {
                            element.fieldAutoList.push(item);
                          }
                        });
                    }
                  }
                  else if(element.getIsEnum()&& !(element.getIsMultiple()))
                  {
                    element.setValue({ value : element.getValue().value.toLowerCase() , html : element.getValue().html.toLowerCase()});
                  }
                  if (element.getAuto() != null)
                  {
                    if (element.getKey() == "space" ||element.getKey() == "template")
                    {
                    // dynamic enum values can be dependent on another fields values
                    // the names of that other fields are provided in the query
                      const otherFieldsKey = metadata[key]["x-dynamic-values"]["query"];

                      if (otherFieldsKey)
                      {
                        this.gedService.getAutocompleteList(element.getValue(),element.getUrlAuto(),oid,otherFieldsKey)
                          .then((autolist) =>
                          {
                            let tempList = [];

                            if (autolist?.length != 0)
                            {
                            //  element.setValue(autolist[0]);
                              if (autolist)
                              {
                                element.value = { ...autolist[0] };
                                element.setPreviewLink(autolist[0]);
                              }

                            }
                            tempList = autolist || [];

                            for (const item of tempList)
                            {
                              if (element.getKey() == "space")
                              {
                                this.listeSpaces = element.fieldAutoList;
                              //let space = this.getSpacebyType(item.html);
                              }
                              element.fieldAutoList.push(item);
                            }

                            if (element.getKey() == "template")
                            {
                              this.listeTemplates = element.fieldAutoList;
                              this.previewLink = element.getPreviewLink();
                            }
                          });
                      }

                    }
                  }
                  else
                  {
                    this.gedService.getAutocompleteList(element.getValue(), element.getAuto()).then((autolist) => 
                    {
                      let tempList = [];

                      tempList = autolist || [];
                      for (const item of tempList)
                      {
                        element.fieldAutoList.push(item);
                      }
                    });
                  }

                  //end.

                  if (this.data?.eventItem)
                  {
                    element = this.fillEventElement(element)
                  }
                  this.formFields.push(element);

                  this.temporaryList.push(element);

                }
              }
            });
        }
      }
    });
  }

  fillEventElement(element)
  {
    const eventItem = this.data.eventItem;

    /*  if(element.key=='description') element.value = eventItem?.getDesc()
     if(element.key=='name') element.value = eventItem.getTitle() */
    return element;
  }

  onSelectedField(event: MatAutocompleteSelectedEvent, element)
  {
    for (const option of element.fieldAutoList)
    {
      if (option.html == event.option.value)
      {
        const temp =
        {
          name: element.key,
          value: option,
        };

        this.jsonData[element.key] = option;
      }
    }
  }

  onSelectedValue(field, value)
  {
    if (field.key == "template")
    {
      for (const item of field.fieldAutoList)
      {
        if (value.html == item.html)
        {
          //field.value=item;
          // field.value=item;
          const id = item?.infos.id || null;

          this.previewLink = `https://drive.google.com/thumbnail?sz=w300&id=${id}`;
        }
      }
      //console.log('previewLink',this.previewLink)
    }
  }

  async getIdSpace(field)
  {
    const spaces = this.listeSpaces;

    for (let i = 0; i < spaces.length; i++)
    {
      const space = spaces[i];

      if (space["html"] == field)
      {
        return space;
      }
    }

    return null;
  }

  async getIdTemplate(field)
  {
    const themes = this.listeTemplates;

    for (let i = 0; i < themes.length; i++)
    {
      const t = themes[i];

      if (t["html"] == field) return t;
    }

    return null;
  }

  encodeMulKeys(keys)
  {
    return keys.length && ('|' + keys.join('|') +'|') || "";
  }

  checkedOptions = {}
  onChangeEnumsChecked(field, name,event)
  {

    if(!this.checkedOptions[name.key])
    {
      this.checkedOptions[name.key] = [];
    }

    const tags = []

    if(event.source.selected)
    {
      this.checkedOptions[name.key].push(field)
    }
    else
    {
      const index = this.checkedOptions[name.key].indexOf(x => x.value == field);

      this.checkedOptions[name.key].splice(index, 1);
    }

  }

 
  async onCreateDoc(values)
  {
    // let spaceId = await this.getIdSpace(values["space"]);
    const templeteId = await this.getIdTemplate(values["template"]);

    this.done = true;
    this.isLoading = true;
    const data = {};

    for (const item of this.formFields)
    {
      const key = item.key;

      if (item.auto == null && !item.isEnum &&  item.control != "checkbox")
      {
        data[key] = values[key];
      }

      else if ( item.isEnum && !(item.isMultiple))
      {
        data[key] =
        {
          value:  item.value.html,
          html:  item.enumValues[item.value.html],
        };
      }

      else if (item.isEnum && (item.isMultiple) && item.control == "checkbox")
      {
        let keys = [];
        let datavalues  = [];

        if (this.checkedOptions[key])
        {
          keys = this.checkedOptions[key].map((obj) => 
          {
            return obj['key'];
          });

          datavalues = this.checkedOptions[key].map((obj) => 
          {
            return obj['value'];
          });
        }

        data[key] =
        {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(','),
          values: this.checkedOptions[key]
        };
      }
      else if ( !(item.isEnum) && (item.isMultiple) && item.control == "checkbox")
      {
        let keys = [];
        let datavalues  = [] ;

        if (this.checkedOptions[key])
        {
          keys = this.checkedOptions[key].map((obj) => 
          {
            return obj['value'];
          });

          datavalues = this.checkedOptions[key].map((obj) => 
          {
            return obj['html'];
          });
        }

        data[key] =
        {
          value: this.encodeMulKeys(keys),
          html: datavalues.join(','),
          values: this.checkedOptions[key]
        };
      }

      else if (item.key == "template")
      {
        data[key] =
        {
          value: templeteId["value"],
          html: item.value.html,
        };
      }

      else if (item.key == "space")
      {
        data[key] =
        {
          html: this.space._data.name,
          sid: this.space._data.sid,
          value: this.space._data.oid,
          infos : {}
        };
      }
      else
      {
        const ResData = await this.gedService.getAutocompleteList(values[key], item["auto"]["url"]);

        data[key] = ResData?.find(item => item?.html == values[key])
        data[key] = ResData?.find(item => item?.html == values[key])

        if (ResData?.length == 0)
        {
          this.gedService.getAutocompleteList('', item["auto"]["url"])
            .then(response =>
            {
              data[key] = response.find(item => item?.html == values[key])
            })
        }
      }
    }

    for (const item of this.formFields)
    {
      if (item.key == "template")
      {
        this.templateId = templeteId["uid"] || null;
      }
    }

    this.jsonData = data
    if (this.templateId != null)
    {
      this.gedService.createNewDocInGED(this.docType.oid,this.jsonData,this.templateId,"root")
        .then((resp) =>
        {
          this.isCreated = true;
          this.isLoading = false;
          if (resp?.id)
          {
            let url = this.documentService.getRoutebyMimeType(resp?.mimeType);

            url = '/s/space/' + this.spaceUid +  '/file/' + resp.id;

            this.router.navigate([url]);
          }
          /*  if (resp?.id)
            {
              let url = this.documentService.getRoutebyMimeType(resp?.mimeType);
               url = '/s/space/' + this.spaceUid + url + resp.id;
               this.router
                .navigateByUrl("/", { skipLocationChange: true })
                .then(() => {
                  this.router.navigate([url]);
                });
              //this.router.navigateByUrl(url);
            }
            */
        });
    }
  }

  cancelForm()
  {
    for (const item of this.formFields)
    {
      item.value = "";
    }
    this.showForm = false;
  }

   
  async getTypeDocumentbySpace()
  {
    this.autoComplete = []
    this.autoComplete = await  this.gedService.getDocTypes("", null, this.spaceUid) ; 
  }
}


