import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { FieldDescEnum } from "../modeles/field-desc-enum.model";
import { FieldDesc } from "../modeles/field-desc.model";
import { FormFieldDate } from "../modeles/form-field-date.model";
import { FormFieldEnum } from "../modeles/form-field-enum.model";
import { FormField } from "../modeles/form-field.model";
import { TEnumData } from "./field-record.model";

type FormName = string;

type TRecordFieldDescs = Record<string, FieldDesc>;
type TRecordMetaData = { fields: TRecordFieldDescs };

type TRecordData = Record<string, FormField | FormFieldEnum>;
type TRecordGroupData = Record<string, TRecordData>;

// backend data types
export type TFormFieldValue = {value: any, html:string, values?:any} | string | number | null;
export type TBackendRecordData = Record<string, TFormFieldValue>;
export type TBackendRecord = { data?: TBackendRecordData; metadata?: any }

export class FormRecord
{
  private _fields: TRecordData = {};
  private _metadata: TRecordMetaData = { fields: {} };
  private _groups: TRecordGroupData = {};

  private _params: any;
  private _source: string = "";

  autoCompleteData = {};

  loadDataCB: any;
  checkedOptions = [];
  newTagsAdded = [];


  data: any = {};

  constructor() 
  { }


  /**
   * Get the metadata of the form record.
   * @returns The metadata object containing fields information.
   */
  getMetadata(): TRecordMetaData 
  {
    return this._metadata;
  }

  /**
   * Get the data of the form record, optionally skipping hidden fields.
   *
   * @param skipHidden - Whether to skip hidden fields.
   * @returns The data object containing form field values.
   */
  getData(skipHidden = false, isSubmit = false): TBackendRecordData
  {
    const fields = this._fields;

    let found: TBackendRecordData = {};

    for (const fname in fields)
    {
      const field = fields[fname];
      const fdesc = field.desc;

      if (skipHidden && fdesc.isHidden())
      {
        continue;
      }

      found[field.name] = field.data();

      if (field.desc.typeControl === "select" && field.desc.enumValues.url) 
      {
        found[field.name] = field.data();
      }
      if (field.desc.typeControl === "date" ) 
      {
        found[field.name] = this.transformDate(field.data());
      }

      if (field.desc.typeControl === "autocomplete"  ) 
      {
        found[field.name] =this.transformToAuraForOneObject2( field.data());
      }
      if (field.desc.typeControl === "autocompleteMultiple" && typeof(field.data()) == "string" ) 
      {
        found[field.name] = {value:"", html:""};
      }
      if(isSubmit && field.desc.typeControl == "select")
      {
        let valueSelect;

        if (Array.isArray(field.desc.enumValues.data)) 
        {
          valueSelect = found[field.name] = field.desc.enumValues?.data.find(obj => obj.value == field.value());
        }
        found[field.name] = valueSelect ?? field.data();
      }
    }

    for (const key of Object.keys(this.checkedOptions)) 
    {
      const checkedTags = this.checkedOptions[key];
      const newTags = this.newTagsAdded[key];

      if (checkedTags && checkedTags.length > 0 && newTags && newTags.length > 0) 
      {
        for (let i = 0; i < checkedTags.length; i++) 
        {
          const checkedTag = checkedTags[i];

          if (checkedTag.html) 
          {
            for (let j = 0; j < newTags.length; j++) 
            {

              if (checkedTag.html === newTags[j].html) 
              {
                // Remplacer l'objet dans checkedOptions par celui de newTagsAdded
                this.checkedOptions[key][i] = newTags[j];
                  
                break;
              }
            }
          }
        }
      }
      
      this.checkedOptions[key] = this.transformToAura(this.checkedOptions[key], isSubmit);
      
      let keys = [];
      let datavalues = [];
      let dataClasses = [];

      if (this.checkedOptions[key])
      {
        keys = this.checkedOptions[key].map((obj) => 
        {
          return obj["value"];
        });

        datavalues = this.checkedOptions[key].map((obj) => 
        {
          return obj.hasOwnProperty('label') ? obj["label"] : obj["html"];
        });

        dataClasses = this.checkedOptions[key].map((obj) => 
        {
          return obj["class"];
        });

      }

      const value = {
        value: this.encodeMulKeys(keys),
        html: datavalues.join(","),
        values: this.checkedOptions[key],
        types: dataClasses.join(",")
      };

      found = {
        ...found, // Copy existing properties from data
        [key]: value, // Update the dateField property with the new value
      };
    }

    for (const key of Object.keys(this.autoCompleteData))
    {
      if (this.autoCompleteData[key])
      {
        if(isSubmit)
        {
          found = {
            ...found, // Copy existing properties from data
            [key]: this.transformToAuraForOneObject2(this.autoCompleteData[key]), // Update the dateField property with the new value
          };

        }
        else
        {
          found = {
            ...found, // Copy existing properties from data
            [key]: this.autoCompleteData[key], // Update the dateField property with the new value
          };

        }

       
      }
    }

    return found;
  }

  /**
   * Get the keys of the form record's metadata fields.
   * @returns An array of field names.
   */
  getMetaKeys(): string[] 
  {
    return Object.keys(this._fields);
  }

  transformDateFromAura(dateObj): string 
  {
    const { day, month, year } = dateObj;

    // Create a Date object using the provided values
    const date = new Date(year, month - 1, day); // Month in JavaScript's Date object is 0-indexed

    // Extract year, month, and day values from the Date object
    const transformedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return transformedDate;
  }

  transformDate(dateObj): string 
  {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  /**
   * Get a form field by its ID.
   * @param fname - The field name/ID.
   * @returns The form field with the specified ID, or null if not found.
   */
  getFieldById(fname: string): FormField | null 
  {
    return this._fields[fname] || null;
  }

  encodeMulKeys(keys) 
  {
    return (keys.length && "|" + keys.join("|") + "|") || "";
  }

  /**
   * Load data into the form record.
   * @param data - The data object to load, including data and metadata.
   * @param source - The data source (default: "doc").
   * @param params - Additional parameters.
   * @param loadDataCB - Callback function for data loading.
   * @param filterTag - Filter tag for field filtering.
   */
  async loadData(
    data: TBackendRecord | null,
    source: string = "doc",
    params: any = null,
    loadDataCB: any = null,
    filterTag: string | null = null
  ) : Promise<void>
  {
    this._params = params;
    this._source = source;
    this.loadDataCB = loadDataCB;

    if (!data) return;

    if (data.data) 
    {
      this.data = data.data;
    }

    if (!data.metadata) return;

    const fdescs: TRecordFieldDescs = {};
    const meta = (data.metadata && data.metadata.fields) || data.metadata;

    // filter by tag?
    if (this._source === "doc") filterTag = "meta";

    // build fields
    for (const fname in meta)
    {
      const desc = meta[fname];
      const value = this.data[fname];

      const field : FormField = this._buildField(fname, desc, value);
      const fdesc : FieldDesc = field.desc;

      fdescs[fname] = fdesc;

      // filter by tags?
      if (filterTag && !fdesc.hasTag(filterTag))
      {
        continue;
      }

      this._fields[fname] = field;

      // add to tag groups
      fdesc.getTags().forEach((tag) => 
      {
        this.addToGroup(tag, fname, field);
      });
    }

    this._metadata = { fields: fdescs };

    // init fields that have dynamic values (enums)
    for (const fname in fdescs)
    {
      const v = await this._fields[fname].init(this._fields, params, loadDataCB);

      this.data[fname] = this._fields[fname].data();
    }
  }

  /**
   * Builds a form field based on the provided field name, description, and data.
   * @param fname - The name of the field.
   * @param desc - The description of the field.
   * @param data - The data object.
   * @returns The constructed form field.
   */
  _buildField(
    fname: string,
    desc: any,
    data: {}): FormField
  {
    let v: any = data;

    if (v === null) v = "";

    let fdesc: FieldDesc;

    if (desc.enumValues || desc.enum || desc['x-dynamic-values'] || desc.selectValues)
    {
      fdesc = new FieldDescEnum(desc, fname);
    }
    else
    {
      fdesc = new FieldDesc(desc, fname);
    }

    if (fdesc.isEnum()) 
    {
      return new FormFieldEnum(v, fdesc as FieldDescEnum);
    }

    if (desc.type == "date") 
    {
      return new FormFieldDate(v, fdesc);
    }

    return new FormField(v, fdesc);
  }

  /**
   * Met à jour les valeurs des champs et met à jour le cache de données.
   *
   * @param data Les données à mettre à jour.
   * @param init Indique s'il faut initialiser les données d'énumération.
   */
  async updateData(data: any, init: boolean = true): Promise<void>
  {
    // update values
    for (const fname in data)
    {
      const field = this.getFieldById(fname);

      if (field) 
      {
        // update field
        field.update(data[fname]);

        // update data cache
        this.data[fname] = this._fields[fname].data();
      }
    }

    // init enum data
    if (init)
    {
      for (const fname in data)
      {
        const field = this.getFieldById(fname);

        if (field && field.init)
        {
          this.data[fname] = await field.init(this._fields, this._params, this.loadDataCB);
        }
        /*.then((v) => {
            this.data[fname] = v;
          });
        */
      }
    }
  }

  /**
   * Ajoute un champ à un groupe spécifique.
   * @param tag Le tag du groupe.
   * @param n Le nom du champ.
   * @param field Le champ à ajouter.
   */
  addToGroup(tag: string, n: string, field: FormField): void
  {
    this._groups[tag] = this._groups[tag] || {};
    this._groups[tag][n] = field;
  }

  /**
 * Récupère les champs (tous, ou par tags) selon certains critères.
 * @param displayGroupe Le groupe à afficher (facultatif).
 * @param tags Les étiquettes des champs à récupérer (facultatif).
 * @param skipHidden Indique s'il faut sauter les champs cachés (par défaut à true).
 * @param key La clé à utiliser pour les champs (par défaut à "name").
 * @param value La valeur à utiliser pour les champs (par défaut à "value").
 * @returns Un objet contenant les champs correspondants aux critères.
 */
  getFields(
    displayGroup: string = undefined,
    tags: string | null = null,
    skipHidden: boolean = true,
    key: string = "name",
    value: string = "value"
  ): {} 
  {
    const fields = this._fields;
    // if (!tags) return fields;

    const found = {};

    //const atags = tags.split(",");
    for (const fname in fields) 
    {
      const field = fields[fname];
      const fdesc = field.desc;

      if ((skipHidden && fdesc.isHidden()) || (displayGroup != undefined && !fdesc.hasTag(displayGroup))) continue
      found[fname] = fields[fname];
    }

    return found;
  }


  /**
 * Récupère les données des champs.
 * @param display Indique s'il faut afficher les données.
 * @returns Un objet contenant les données des champs.
 */
  getFieldsData(display: boolean = false): {}
  {
    {
      if (!display)
      {
        return this.data;
      }

      const data = {};

      for (const n in this.data) 
      {
        if (n.endsWith("__html")) continue;

        try 
        {
          const fdata = this.getfieldDataByName(n, true);

          if (typeof fdata != "undefined")
          {
            data[n] = fdata;
          }
        }
        catch (e) 
        {
          console.error("error on field " + n);
        }
      }

      return data;
    }
  }


  /**
 * Récupère les données d'un champ par son nom.
 * @param fname Le nom du champ.
 * @param display Indique s'il faut afficher les données.
 * @returns Les données du champ spécifié.
 */
  getfieldDataByName(fname: string, display: boolean = false)
  {
    if (!display) return this.data[fname];

    const v = this.data[fname];

    if (!v || typeof v.value == "undefined")
    {
      return v;
    }
    else
    {
      return v.html;
    }
  }

  /**
   * Gère le changement de sélection d'options d'énumération (checkboxes).
   * @param option L'option sélectionnée.
   * @param field Le champ associé à l'option.
   * @param event L'événement de changement.
   */
  onChangeEnumsChecked(option, field, event)
  {
    if (!this.checkedOptions[field.name])
    {
      this.checkedOptions[field.name] = [];
    }

    if (event?.source?.selected)
    {
      this.checkedOptions[field.name].push(option);
    }
    else
    {
      const index = this.checkedOptions[field.name]
        .indexOf((x) =>
          x.value == option);

      this.checkedOptions[field.name].splice(index, 1);
    }
  }

  onChangeEnumsChecked2(field, option)
  {
    this.checkedOptions[field.desc.name] = option;
  }

  

  onChangeEnumsCheckedPush(field, option)
  {
    console.log(this.checkedOptions);
    this.checkedOptions[field.desc.name].push(option);
    console.log(this.checkedOptions)
  }

  /**
   * Gère le changement de sélection d'énumératigetFieldHtmlon.
   * @param fname Le nom du champ associé à l'énumération.
   * @param value La valeur sélectionnée.
   */
  onChangeEnumsSelected(fname, value) 
  {
    const fields = this._fields;
    const data = fields[fname].desc.enums();
    const result = data.find(objet => objet.value === value);

    const valeurPrecise = result ? result : { 'value': '', 'html': '' };

    this._fields[fname].setData(valeurPrecise);
  }

  /**
   * Met à jour les données d'autocomplétion pour un champ donné lorsqu'une option est sélectionnée.
   * @param event L'événement de sélection d'autocomplétion.
   * @param element L'élément à mettre à jour.
   */
  updateFielAutocomplete(event: MatAutocompleteSelectedEvent, element)
  {
    if (!this.autoCompleteData[element.key])
    {
      this.autoCompleteData[element.key] = [];
    }

    for (const option of element.fieldAutoList)
    {
      if (option.html == event.option.value)
      {
        const temp = {
          name: element.key,
          value: option
        };

        this.autoCompleteData[element.desc.fname] = option;
      }
    }
  }
  updateFielAutocompleteAura(event, element)
  {
    if (!this.autoCompleteData[element.key])
    {
      this.autoCompleteData[element.key] = [];
    }

    for (const option of element.desc.enums())
    {
      if (option.value == event.value)
      {
        const nouvelObjetJson = { 
          ...option,
          html: option.label,
        };
      
        delete nouvelObjetJson.label;

        this.autoCompleteData[element.desc.fname] = nouvelObjetJson;
      }
    }
  }

  transformToAuraForOneObject(yourObject) 
  {
    // Create a new 'label' key and copy the value from 'html' key
    yourObject[0].html = yourObject[0].label ?? yourObject[0].html;
    
    // Remove the 'html' key if needed
    delete yourObject[0].label;
   
    return yourObject[0];
  }

  transformToAuraForOneObject2(yourObject) 
  {
    // Create a new 'label' key and copy the value from 'html' key
    yourObject.html = yourObject.label ?? yourObject.html;
    
    // Remove the 'html' key if needed
    delete yourObject.label;
   
    return yourObject;
  }

  

  transformToAura(objets, isSubmit = false) 
  {
    if(isSubmit)
    {
      const objetsTransformes = objets.map(objet => 
      {
        return {
          'html': objet['html'] ?? objet['label'],  
          'value': objet['value']
        };
      });

      return objetsTransformes;

    }
    else
    {
      const objetsTransformes = objets.map(objet => 
      {
        return {
          'label': objet['html'] ?? objet['label'],  
          'value': objet['value'],
        };
      });

      return objetsTransformes;
    }
    
  }
}
