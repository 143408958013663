<form>
    <div *ngFor=" let option of data.options">
        <aura-input-checkbox [label]="option.option" [checked]="option.selected" [(ngModel)]="option.selected"
            [ngModelOptions]="{standalone: true}"></aura-input-checkbox>
    </div>
    <div class="actions">
        <button auraButton label="{{ 'cancelBtn' | translate}}" (click)="close()"></button>
        <button auraButton type="primary" [disabled]="!isButtonEnabled()" (click)="submit()">{{data.action}}
            {{ 'content' | translate}}</button>
    </div>
</form>