
  <table class="w-100 tbl">
  <tbody>
    <ng-container *ngFor="let d of allData; let i=index">
      <tr *ngIf="d.val" >
        <td > {{d.label}} :</td>
        <th class="value">{{d.val}}</th>
     </tr>
    </ng-container>


  </tbody>
</table>
