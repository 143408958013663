export class SpaceColumn 
{
  label:string;
  fname:string;
  fdesc:string;
  tags:{};
  options : {label,value} [];

  constructor(fdesc , fname)
  {
    this.label =  fdesc["label"] || fdesc;
    this.fname = fname;
    this.fdesc = fdesc;
    this.tags = {};
    (fdesc['x-tags']||[]).forEach(t=>this.tags[t]=true);
  }

  getLabel()
  {
    return this.label;
  }

  getName()
  {
    return this.fname;
  }

  getDesc()
  {
    return this.fdesc;
  }

  hasTag(t)
  {
    return this.tags[t]||false;
  }

  getOptions() 
  {
    return this.options || [];
  }
}
