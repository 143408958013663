import { Component } from '@angular/core';

@Component({
  selector: 'app-nofile',
  templateUrl: './nofile.component.html',
  styleUrls: ['./nofile.component.scss']
})
export class NofileComponent 
{


}
