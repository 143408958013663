import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ActivatedRoute } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { GedPermRole, GedPermType, GedPermission, GedService } from 'src/modules/app-common/services';
import { TUserData, User } from 'src/modules/user/models';
import { Gcontact } from 'src/modules/workspace/models/contact/gcontact.model';
import { UserService } from 'src/modules/workspace/services/user/user.service';

@Component({
  selector: 'app-space-permission',
  templateUrl: './space-permission.component.html',
  styleUrls: ['./space-permission.component.scss']
})
export class SpacePermissionComponent implements OnInit 
{

  @Input() isOpen: boolean = false;
  @Output() close = new EventEmitter<any>();
  @ViewChild('form') public formPerm: NgForm;

  cachedusers: any;
  usersList: Gcontact;
  permissions: any;
  permission;
  userSelected;
  isGeneral: boolean = true;


  spaceUid: string;

  userCtrl: FormControl = new FormControl();
  permissionCtrl: FormControl = new FormControl();

  listePermission: GedPermission[];

  isLoading: boolean = false;

  permissionRoles = [];

  selectedOptions: string[] = [];

  constructor(
    UserService: UserService,
    private gedService: GedService,
    private route: ActivatedRoute,

  ) 
  {
    const permissionRoles = ["administrator", "content manager", "commenter", "reader", "none"];

    const capitalizedRoles = permissionRoles.map(role => 
    {
      const capitalizedRole = role.charAt(0).toUpperCase() + role.slice(1);

      return { label: capitalizedRole, value: role };
    });

    this.permissionRoles = capitalizedRoles;

  }

  ngOnInit(): void 
  {

    this.getUsers();
    this.updatedUser();
    // this.getPermission();
    this.route.params.forEach((params) => 
    {
      this.spaceUid = params["spaceId"];
      if (this.spaceUid !== "drives") 
      {

        this.getPermissions(this.spaceUid)
      }

    });

  }

  onSelectChange(event) 
  {
  }

  updatedUser() 
  {

    /* this.usersList = this.userCtrl.valueChanges
    .pipe(
      startWith(''),
      map(displayname => displayname ? this.filterUsers(displayname) : this.cachedusers)
    ); */

  }

  // users caché
  async getUsers() 
  {
    this.cachedusers = await UserService.getInstance().getCachedUsers();
  }




  async getPermissions(spaceId: string): Promise<GedPermission[]> 
  {
    try 
    {
      this.listePermission = await this.gedService.getPermissionList(spaceId);

      if (!Array.isArray(this.listePermission)) 
      {
        console.error("Permissions API returned unexpected data:", this.listePermission);

        return [];
      }
      this.listePermission.sort((a, b) => (a?.who?.name || "").localeCompare(b?.who?.name || ""));

      const hasUser = this.listePermission?.find(item => item?.who?.type === "domain");

      if (!hasUser) 
      {
        this.isGeneral = false;
      }
      else 
      {
        this.isGeneral = true;

      }


      return this.listePermission;
    }
    catch (error) 
    {
      console.error("An error occurred while fetching permissions:", error);
    }
  }



  filterUsers(name: string) 
  {
    return this.cachedusers.filter(user =>
      user.getDisplayName().toLowerCase().includes(name.toLowerCase()));
  }

  doFilterField(key: MatAutocompleteSelectedEvent) 
  {
    this.gedService.getAutocompleteListUser(key).then(res => 
    {
      if (res)
      {
        this.usersList = res.map(item => new Gcontact(item));
      }
    })
    this.alphabeticalOrder(this.usersList);

  }

  async alphabeticalOrder(array) 
  {
    return array?.sort((a, b) => (a?.['name'] < b?.['name'] ? -1 : 1));
  }

  onSelectUser(event: MatAutocompleteSelectedEvent) 
  {
    this.userSelected = this.userCtrl.value;
  }

  onSelectedPerm(event) 
  {
    this.permissionCtrl.patchValue(event.value)
    this.permission = this.permissionCtrl.value;
  }

  async addPermissiontoUser() 
  {
    try 
    {
      this.isLoading = true;
      const perm = await this.addPermission(this.spaceUid, this.userSelected, this.permission, "user");

      if (perm) 
      {

        /*  this.userCtrl.patchValue('')
         this.permissionCtrl.patchValue('') */
        this.userCtrl.reset();
        this.permissionCtrl.reset();
        this.isLoading = false;

        this.getPermissions(this.spaceUid);
      }
    }
    catch (error) 
    {
      console.error("permissions", error);
      this.isLoading = true;

      return false;
    }
    finally 
    {
      this.isLoading = false;
    }


  }
  async removePermForUser(perm) 
  {
    try 
    {
      this.isLoading = true;
      const remove = await this.removePermission(this.spaceUid, perm.id);

      if (remove) 
      {
        this.getPermissions(this.spaceUid);
        this.isLoading = false;
      }
    }
    catch (error) 
    {
      console.error("permissions", error);
      this.isLoading = true;

      return false;
    }
    finally 
    {
      this.isLoading = false;
    }

  }

  async addPermission(
    spaceId: string,
    email: string,
    role: GedPermRole = "content manager",
    type: GedPermType = "user"
  ): Promise<GedPermission> 
  {
    try 
    {
      type = type || "user";

      const perm: GedPermission = await this.gedService.addPermission(spaceId, email, role, type);



      return perm;

    }
    catch (error) 
    {
      console.error("permissions", error);

      return null;
    }
  }

  async removePermission(
    spaceId: string,
    permId: string
  ) 
  {
    try 
    {
      await this.gedService.removePermission(spaceId, permId);

      return true;
    }
    catch (error) 
    {
      console.error("permissions", error);

      return false;
    }
  }


  closeModal() 
  {
    this.close.emit();
    this.userCtrl.reset();
    this.permissionCtrl.reset();

  }

  changeIcon(event: any) 
  {
  }
  checkboxesChange(event: any) 
  {
  }

}
