import { Component } from "@angular/core";

import { AppI18nService } from "./app-i18n.service";
import { AppIconService } from "./app-icon.service";
import { AppTitleService } from "./app-title.service";
import { environment } from 'src/environments/environment';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent 
{

  title = "ged-app";

  constructor(
    private readonly _appI18nService: AppI18nService,
    private readonly _appIconService: AppIconService,
    private readonly _appTitleService: AppTitleService
  ) 
  { }

  ngOnInit(): void 
  {
    console.log("environment.production " + environment.production)
    this._appIconService.initMatIcons();
    this._appI18nService.initLanguage();
    this._appTitleService.autoUpdateTitle();
  }

}
