<aside class="aside pt-30 pb-3" [ngClass]="{'on': isOn}">

    <div class="sidemenu">
        <ul>
            <li>
                <a href="javascript:;" (click)="open()">
                    <span class="sqaure green">
                        <img src="/assets/images/icon-plus.svg">
                    </span>
                    New doc
                </a>
            </li>
        </ul>
        <app-new-document [isOpen]="isDocModalOpen" (close)="close()"></app-new-document>
        <ul class="mainmenu">
            <li *ngFor="let item of pages" [ngClass]="{'mt-auto mb-0': item.isLastItem}" (click)="onClick($event)">
                <a [routerLink]="item.path" routerLinkActive="active" [routerLinkActiveOptions]={exact:true}
                    (click)="togglesidebar()">
                    <span *ngIf="item.icon" class="sqaure">
                        <i [class]="item.icon"></i>
                    </span>
                    {{item.pagename}}
                </a>

                <ul class="submenu shadow" *ngIf="item.subpages">
                    <li *ngFor="let subitem of item.subpages">
                        <a [routerLink]="subitem.path" routerLinkActive="active" [routerLinkActiveOptions]={exact:true}
                            (click)="togglesidebar()">
                            <span *ngIf="subitem.icon">
                                <i [class]="item.icon"></i>
                            </span>
                            {{subitem.pagename}}
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</aside>