<app-sidenav>
    <mat-toolbar class="toolbarPath">
        <span class="people">{{'peoplePage' | translate}}</span>

        <span class="app-spacer"></span>


        <button mat-icon-button  (click)="addNewContact()">
            <i class="material-icons-round">person_add</i>
        </button>

 <!--
      <button mat-icon-button [matMenuTriggerFor]="moreMenu" matTooltip="{{'moreTT' | translate}}">
        <i class="material-icons-round">more_vert</i>
      </button>

     <mat-menu #moreMenu="matMenu">
        <button mat-menu-item class="newElementBtn" (click)="shareFolder()">
          <i class="material-icons-outlined menuIcon">person_add</i>
          {{'addContactBtn' | translate}}
        </button>

      </mat-menu>

    -->
      </mat-toolbar>

  <!-- <aura-title icon="/assets/images/icon-file.svg">Recent Documents</aura-title>

  <aura-list title="Recent Documents"
    verticalAlign="center"
    [data]="recentDocs"
    [hasMenu]="true"></aura-list> -->

  <aura-list title="Recent Documents"
    verticalAlign="center"
    icon="/assets/images/icon-file.svg"
    [data]="recentDocs"
    [hasMenu]="true"></aura-list>
</app-sidenav>
