import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { Crumb } from "../app-common/components/bread-crumb/bread-crumb.component";

// import { stringFile } from "src/assets/strings/strings";
// import { DesignSystemRoutingModule } from "src/design-system/design-system-routing.module";

interface Tdesc {
  label: string;
  addPageTitle: string;
  addPageRoute: string;
  editPageTitle: string;
  editPageRoute: string;
  dataFromUrl: string;
  routeSpaceUrl: string;
}

interface TableInfos {
  
  title: string,                  // table title
  // tid: DesignSystemRoutingModule, // table id
  tid: string, // table id
  url: string,  // backend API URL

  sid: string,    // space infos : sid
  spaceName: string,
  routeSpacedUrl:string, // metadata table component route
  routeSpaceUrl:string   // back route url

  // table strings
  strings: {
    add: string,
    edit: string,
    item: string,
    items: string
  }
}


@Component({
  selector: "app-metatable",
  templateUrl: "./metatable.component.html",
  styleUrls: ["./metatable.component.scss"],
})
export class MetatableComponent implements OnInit 
{
  loading: boolean = true;
  spaceName:string="";
  
  bc : Crumb[] = [];
  currentName: string;

  // https://github.com/supportPsoft/psoft-ng-crud-manager
  tdesc: Tdesc = {
    label: "",
    addPageTitle: "",
    addPageRoute: "",
    editPageTitle: "",
    editPageRoute: "",
    dataFromUrl: "",
    routeSpaceUrl:".."
  };

  constructor(private router: Router, private route: ActivatedRoute) 
  {}

  ngOnInit(): void 
  {
    const params = this.route.snapshot.params;
    let 
      sid :string,
      tid : string, 
      label:string,
      url:string;

    let table : TableInfos;

    if(params.tdesc64)
    {
      table = JSON.parse(atob(params.tdesc64));
      this.spaceName = table.spaceName;
      tid = table.tid;


      this.currentName = table.title;
      const bcString = localStorage.getItem('bc'); // Retrieve the JSON string from localStorage

      if (bcString) 
      {
        this.bc = JSON.parse(bcString); // Parse the JSON string into a crumb object
      }
      this.tdesc = 
      {
        label: table.title,
        addPageTitle: table.strings.add,
        addPageRoute: "/add-"+tid,
        editPageTitle: "Edit "+table.strings.item,
        editPageRoute: "/edit-"+tid,
        dataFromUrl: table.url,
        routeSpaceUrl : table.routeSpaceUrl
      };
    }
    else
    {
      sid = params.sid;
      tid = params.tid;
      url = params.url && atob(params.url) || "/ged/"+sid+"/"+tid;
      label = tid;  

      // capitalize and pluralize
      const title = tid.charAt(0).toUpperCase() + tid.slice(1)+"s";
      
      this.tdesc = {
        label: title,
        addPageTitle: "Add "+label,
        addPageRoute: "/add-"+tid,
        editPageTitle: "Edit "+label,
        editPageRoute: "/edit-"+tid,
        dataFromUrl: url,
        routeSpaceUrl:'..'
      };

    }

  }

  loadMore() 
  {}

  filterChange(value) 
  {}

  refreshData(value) 
  {}

  goback() 
  {
    this.router.navigate([ this.tdesc.routeSpaceUrl],{ relativeTo: this.route });
  }
}
