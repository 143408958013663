<aura-modal
  [isOpen]="isOpen"
  (close)="closeModal()"
  [title]="data.title"
  width="60%"
  [fullscreen]="false"
>
  <div class="">
    <div class="row justify-content-center spinnerLoading">
      <h3>{{ data.subtitle }}</h3>
      <mat-spinner *ngIf="isLoading"></mat-spinner>
    </div>

    <div class="col-12 actions mb-2" align="end">
      <div style="padding-right: 31px">
        <button
          auraButton
          type="light"
          class="cancel-btn"
          [hasShadow]="true"
          (click)="onCancel()"
          class="cancelBtn"
          label="{{ 'cancelBtn' | translate }}"
        ></button>
        <button
          auraButton
          
          type="primary"
          [hasShadow]="true"
          label="{{ btnTxt }}"
          (click)="onConfirm()"
          [disabled]="isLoading"
        ></button>
      </div>
    </div>
  </div>
</aura-modal>
