<app-sidenav [title]="currentName" [breadcrumb]="bc">
  

  <div class="container">
    <app-crud-list
      [label]="tdesc.label"
      [addPageTitle]="tdesc.addPageTitle"
      [addPageRoute]="tdesc.addPageRoute"
      [editPageTitle]="tdesc.editPageTitle"
      [editPageRoute]="tdesc.editPageRoute"
      [data]="tdesc"
      [loading]="loading"
      [dataFromUrl]="tdesc.dataFromUrl"
      (onTableScrollEvent)="loadMore()"
      (onChangeEvent)="filterChange($event)"
      (onLoadData)="refreshData($event)"></app-crud-list>
  </div>
</app-sidenav>
