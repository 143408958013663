import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GDocService } from 'src/modules/app-common/services';
const themePath = environment.themePath;

export interface ProfileData{
  user: any;
  photoUrl: string;
  name: string;
  email:string;
}
@Component({
  selector: 'app-profile',
  templateUrl: `./${themePath}/profile.component.html`,
  styleUrls: [`./${themePath}/profile.component.scss`],
})
export class ProfileComponent implements OnInit 
{
  email:string;
  name:string;
  currentPhotoUrl:string = localStorage.getItem('photoUrl');

  constructor( private gdocService: GDocService, private router: Router,
    public dialogRef: MatDialogRef<ProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfileData,) 
  {
    this.name=data.name;
    this.email=data.email;
  }

  onCancel(): void 
  {
    this.dialogRef.close();
  }
  ngOnInit(): void 
  {
  }


  openAcc()
  {
    window.open("https://myaccount.google.com/?utm_source=OGB&tab=mk&utm_medium=act&gar=1")
  }

  toDrive()
  {
    
    this.gdocService.getMyDriveId()
      .then(id=>
      {
        const driveId = id;

        this.router.navigate(['/s/space/'+driveId],{queryParams:{ d: localStorage.host_domain}})
      }) 
  }
}
