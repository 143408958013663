import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Subject, of } from 'rxjs';
import { TableColumn, TableRow } from '@interfaces/table';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-main',
  templateUrl: './search-main.component.html',
  styleUrls: ['./search-main.component.scss']
})
export class SearchMainComponent implements OnInit, OnChanges 
{
  @Input("searchResponse") searchResponse;
  @Input("resultGroup") resultGroup;
  @Input("keyword") keyword;
  @Input("loadingData") loadingData: boolean;
  @Input("allEmptyData") allEmptyData: boolean;
  searchResponseObservable;
  @ViewChild("myTemRef") myTemRef!: TemplateRef<any>;

  loading : boolean;
  

  documentData : any;

  ngOnInit(): void 
  {
    this.selectedGroup = 
      (this.searchResponse.results || [])
        .find(group => 
        {
          return group.id==this.resultGroup;
        });
          

         
  }

  selectedGroup
  
  private dataSubject = new Subject<any>();

  ngOnChanges(changes: SimpleChanges): void 
  {
    if (changes.loadingData && changes.loadingData.currentValue == true) 
    {
      this.loading= true
    }
    if (changes.searchResponse) 
    {
      const results = this.searchResponse.results || [];

      this.selectedGroup = results.find(group => group.id == this.resultGroup);
      (this.searchResponse?.results || []).forEach(object => 
      {
        object.results.forEach((r) => 
        {
          r.displayProperties.forEach((r) => 
          {
            if (r.id === "Flags") 
            {
              r.html = this.myTemRef;
            }
          });
        });

        object.columns.forEach((r) => 
        {
          if (r.id === "rating") 
          {
            r.class = "custom-rating";
          }
        });
      });
     
      this.documentData = results[0]?.data;

      if (this.resultGroup == '') 
      {
        this.setDataLength();
      }

      this.setTable(results);

      // Émettre les données mises à jour pour l'affichage
      this.dataSubject.next(results);
    }
  }

  constructor(private router: Router) 
  {
    // Abonnez-vous aux mises à jour de données avec un délai de 300 ms
    this.dataSubject.pipe(
      debounceTime(300)
    ).subscribe((data) => 
    {
      // Mettre à jour l'affichage en émettant les nouvelles données pour searchResponseObservable
      this.searchResponseObservable = of(data);
      this.loading =false
    });
  }

  setDataLength()
  {
    (this.searchResponse?.results || []).forEach(group=>group.data = group.data.filter((item,index)=>index<3))
  }

  tableObservable

  setTable(searchResponse)
  {
    // not needed
    const tableResponse =[]
      
    const arr = [];

    tableResponse.forEach(groupArray=>arr.push(...groupArray));

    this.tableObservable = of(tableResponse)   
  }

  setGroup(group)
  {

    this.router.navigate(['/search'],{queryParams: {q:this.keyword,group: group}});
  }


}
