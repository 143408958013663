import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProfileComponent } from 'src/modules/user/containers';
// import { GoogleLoginService } from 'src/modules/user/services';
import { GSIUser } from "src/modules/user/models/user/GSIUser.model"
import { AuthService, Profile } from 'src/modules/user/services';

const themePath = environment.themePath;


@Component({
  selector: 'app-user-bar',
  templateUrl: `./${themePath}/user-bar.component.html`,
  styleUrls: [`./${themePath}/user-bar.component.scss`]
})
export class UserBarComponent implements OnInit 
{

  currentUser: Profile;
  currentName = '';
  currentPhotoUrl = '';



  user_guide: boolean;
  support: boolean;
  items: any[];
  constructor(
    private auth: AuthService,
    public dialog: MatDialog) 
  {

    this.support = environment.SUPPORT_LINK;
    this.user_guide = environment.USER_GUIDE_LINK;
    this.items = [
      { label: environment.stringsFile.support, icon: 'gg-support', callback: this.getToSupport.bind(this) },
      { label: environment.stringsFile.userguide, icon: 'gg-ereader', callback: this.getUserGuide.bind(this) },
      { label: environment.stringsFile.logoutBtn, icon: 'gg-log-off', callback: this.onLogOut.bind(this) }
    ];

  }
  ngOnInit(): void 
  {
    this.auth.profileSubject
      .pipe(
        filter(value => !!value),
        tap(user => 
        {
          this.currentUser = user
          this.currentName = user.name
          this.currentPhotoUrl = user.picture
        }))
      .subscribe()

    // to be changed after // profile // ag
    // if (!!this.googleLoginService.user) {

    //   this.currentUser = this.googleLoginService.user;
    //   this.currentPhotoUrl = localStorage.photoUrl;
    // }


  }

  toProfile() 
  {
    const dialogRef = this.dialog.open(ProfileComponent, {
      width: '500px',
      minWidth: '650px',
      height: 'fit-content',
      data: {
        photourl: this.currentPhotoUrl,
        name: localStorage.name,
        email: localStorage.email
      }
    });

    dialogRef.afterClosed().subscribe(result => 
    {
    });
  }


  onLogOut() 
  {
    // this.googleLoginService.signOut() // signout here
    this.auth.logout()

  }

  getToSupport() 
  {
    window["$sleek"]["open"]();
    /*  var redirectUrl = "https://devoteam.service-now.com/itportal/?id=index";
    window.open(redirectUrl, "_blank"); */
  }

  getUserGuide() 
  {

    const redirectUrl = "https://help.presencedoc.com/user-guide";

    window.open(redirectUrl, "_blank");
  }


}
