import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { APISce } from '../http/api.service';
import { TranslateService } from '@ngx-translate/core';

export const BACKEND_URL = environment.BACKEND_URL

class SharedDrive 
{
  _data: any;

  constructor(data) 
  {
    this._data = data;
  }

  name() 
  {
    return this._data && this._data.name || "Drive";
  }

  id() 
  {
    return this._data && this._data.id;
  }
}

@Injectable({
  providedIn: 'root'
})
export class GDocService 
{
  file: any;
  myDriveId: string = null;
  constructor(
    private translate: TranslateService,
    private api: APISce,
    private snackBar: MatSnackBar) 
  {
    this.getMyDriveId();
  }



  /* ####### DRIVE FILES ####### */

  async setMeta(meta, fileInfos) 
  {
    const uid = fileInfos.id;

    const options = await this.api.httpConfig(true, false);

    return this.api.post(BACKEND_URL + '/gdrive/files/' + uid + '/properties', meta, options)
      .then(data => 
      {
        return data;
      })
      .catch(error => 
      {
        console.log(error);

        return error;
      });
  }

  //get file in drive by its uid
  async getFile(uid) 
  {
    const url = `/gdrive/files/${uid}`;
    const options = await this.api.httpConfig(true, false);

    const response = await this.api.get(url, {}, options)

    return response['content'] || null;
  }

  //change to back
  //returns files in My Drive
  async getDriveFiles() 
  {
    const driveFiles = await this.getFolderItems('root');

    return driveFiles?.['content'].files || [];
  }

  //returns name of space or folder by its uid
  async getSpaceName(spaceId) 
  {
    const response = await this.getFolderItems(spaceId);
    const spaceName = response['spaceResponse']['content']['name'] || null;

    return spaceName;
  }


  /**getMore Items */
  nextPageToken;

  async getFolderItems(
    spaceuid: string,
    pageToken?: string,
    fields = "id,name,parents,iconLink,properties,files(id,name,iconLink,properties,thumbnailLink)")
  {
    const uid = spaceuid;
    const nbItems = 10;

    let url: string;

    if (pageToken) 
    {
      url = `/gdrive/folders/${uid}?fields=${fields}&nbItems=${nbItems}&pageToken=${pageToken}&withPath=false`;
    }
    else 
    {
      url = `/gdrive/folders/${uid}?fields=${fields}&nbItems=${nbItems}&withPath=false`;
    }

    const spaceContent = [];

    try 
    {
      const options = await this.api.httpConfig(true, false);


      // let spaceResponse : { content?: {files?:{}[], folders?:{}[]}};
      const spaceResponse = await this.api.get(url, {}, options);

      if (spaceResponse) 
      {
        const content = spaceResponse["content"];

        const folders = content?.folders || [];
        const files = content?.files || [];

        this.nextPageToken = content?.nextPageToken || null;

        if (content && content.drives)
        {
          this.setSharedDriveCache(content.drives);
        }

        spaceContent.push(...folders, ...files);
      }

      // console.log("spaceResponse",spaceResponse)
      return { spaceContent, spaceResponse, nextPageToken: this.nextPageToken };

    }
    catch (error) 
    {
      console.error(error.message || error);

      return {
        spaceContent
      }
    }
  }

  async getDrives(
    spaceuid: string,
    maxItems: number = -1,
    pageToken?: string,
    fields = "id,name,parents,iconLink,properties,files(id,name,iconLink,properties,thumbnailLink)")
  {
    const uid = spaceuid;

    let url: string;

    if (pageToken) 
    {
      url = `/gdrive/folders/${uid}?fields=${fields}&nbItems=${maxItems}&pageToken=${pageToken}&withPath=false`;
    }
    else 
    {
      url = `/gdrive/folders/${uid}?fields=${fields}&nbItems=${maxItems}&withPath=false`;
    }

    const spaceContent = [];

    try 
    {
      const options = await this.api.httpConfig(true, false);


      // let spaceResponse : { content?: {files?:{}[], folders?:{}[]}};
      const spaceResponse = await this.api.get(url, {}, options);

      if (spaceResponse) 
      {
        const content = spaceResponse["content"];

        const folders = content?.folders || [];
        const files = content?.files || [];

        this.nextPageToken = content?.nextPageToken || null;

        if (content && content.drives)
        {
          this.setSharedDriveCache(content.drives);
        }

        spaceContent.push(...folders, ...files);
      }

      // console.log("spaceResponse",spaceResponse)
      return { spaceContent, spaceResponse, nextPageToken: this.nextPageToken };

    }
    catch (error) 
    {
      console.error(error.message || error);

      return {
        spaceContent
      }
    }
  }

  sharedDrivesByUid = {};
  setSharedDriveCache(drives) 
  {
    const sharedDrivesByUid = {};

    drives.map(drive => 
    {
      if (drive.id)
      {
        sharedDrivesByUid[drive.id] = new SharedDrive(drive);
      }
    });

    this.sharedDrivesByUid = sharedDrivesByUid;
  }

  async getSharedDriveByUid(uid): Promise<SharedDrive> 
  {
    if (!this.sharedDrivesByUid[uid]) 
    {
      await this.getDrives('drives');
    }

    return this.sharedDrivesByUid[uid] || null;
  }

  //change to back
  async getMyDriveId() 
  {
    if (!this.myDriveId) 
    {
      const driveFolder = await this.getFolderById('root');

      this.myDriveId = driveFolder?.id || null;
      localStorage.setItem("driveId", this.myDriveId)
    }

    return this.myDriveId;
  }

  async getDocumentParent(docId) 
  {
    const document = await this.getFile(docId);

    const parentId = this.getFileParentId(document);

    return parentId;
  }

  async getFolderParent(folderId) 
  {
    const folder = await this.getFile(folderId);

    return this.getFileParentId(folder, 'root');
  }

  getFileParentId(file, deft = 'root') 
  {
    const parents = file && file["parents"] || [];
    let parentId = (parents.length != 0) ? parents[0] : deft;

    // check if we do something for shared drive root ?
    // let fileDriveId = file["driveId"] || this.myDriveId;

    if (parentId == this.myDriveId) 
    {
      // TBC : use drive ID?
      parentId = deft;
    }

    return parentId;
  }
  _(s: string): string 
  {
    return this.translate.instant(s);
  }
  async loadParentsPath(currentFolder) 
  {
    const parents = [];
    const mydrives = { id: 'drives', name: 'Drives' };

    await this.getMyDriveId();

    let suid = null;
    let sid = null;

    let folder = currentFolder;
    let parentId;
    const currentId = folder.id;

    if (currentId == 'root') 
    {
      parents.push(mydrives);

      return parents;
    }

    if (currentId == 'drives') 
    {
      return parents;
    }

    // reload to get all fields
    if (!folder.parents)
    {
      folder = await this.getFile(currentId);
    }

    // then load the rest
    do 
    {
      // is the space in traversal ?
      if (!suid) 
      {
        if (folder?.properties?.suid) 
        {
          suid = folder?.properties?.suid;
        }
        else if (folder?.properties?.type_document == 'space') 
        {
          suid = folder.id;
        }
      }
      if (!sid && folder?.properties?.sid) 
      {
        sid = folder?.properties?.sid;
      }

      parentId = this.getFileParentId(folder);
      if (!parentId || parentId == 'root') // my drive root
      {
        parents.unshift(mydrives);
        break;
      }

      const parentFile = await this.getFile(parentId);

      if (!parentFile)  // shared drive root or "shared with us" folder
      {
        break;
      }

      if (parentFile.teamDriveId == parentId) 
      {
        // its a shared drive, get its actual name (instead of "Drive")
        const sd = await this.getSharedDriveByUid(parentId)

        parentFile.name = sd.name();
      }

      // parent is a SD root ?

      if (parentId == this.myDriveId) 
      {
        parents.unshift({ id: parentId, name: parentFile.name });
        parents.unshift(mydrives);
        // parentId = 'root';
        break;
      }

      parents.unshift({ id: parentId, name: parentFile.name });
      folder = parentFile;

    } while (parentId && parentId != 'drives');

    if (suid)
    {
      parents.forEach(parent => 
      {
        if (parent.id != 'root' && parent.id != 'drives')
        {
          parent.suid = suid;
        }
      });
    }

    if (sid)
    {
      parents.forEach(parent => 
      {
        if (parent.id != 'root' && parent.id != 'drives')
        {
          parent.sid = sid;
        }
      });
    }

    // console.log("path",parents.map(f => f.name));
    return parents;
  }

  //  ======== CREATE FILE directly in Google Drive ==========

  async createDoc(name, mimeType, parentId) 
  {
    const url = `/gdrive/files/`;
    const options = await this.api.httpConfig(true, false);

    const data =
    {
      name: name,
      parentId: parentId,
      mimeType: mimeType
    };

    try 
    {
      const response = await this.api.post(url, data, options) || null;
      const docData = response['content'] || null;

      //let newDocument = new GedFile(null,docData)
      const newDocument = docData;

      if (newDocument) 
      {
        this.snackBar.open("Document '" + newDocument["name"] + "' created successfully", '', {
          duration: 5000
        });

        return newDocument;
      }
    }
    catch (error) 
    {
      this.snackBar.open("Document '" + data.name + "' creation failed", '', {
        duration: 5000,
      });
      console.log(error);

      return error;
    }
  }

  // =========== FOLDER ============
  async getFolderById(folderId) 
  {
    const spaceResponse = await this.getFolderItems(folderId);
    const folder = spaceResponse['spaceResponse']?.['content'] || null;

    return folder;
  }

  async createFolder(name, parentId) 
  {
    const data = {
      name,
      parentId
    };

    const url = `/gdrive/folders`;
    const options = await this.api.httpConfig(true, false);

    try 
    {
      const response = await this.api.post(url, data, options)
      const newFolder = response["content"]

      this.snackBar.open("Folder '" + data.name + "' created successfully!", '', {
        duration: 5000
      });

      return newFolder;

    }
    catch (error) 
    {
      this.snackBar.open("Folder '" + data.name + "' creation failed", '', {
        duration: 5000
      });
      console.log(error);

      return error;
    }

  }

  // ======== UPLOAD FILE TO FOLDER =========

  async uploadFile(file, parentId, fields = "id,name,iconLink,properties,thumbnailLink") 
  {
    const data = file;

    const url = `/gdrive/files/upload?fields=${fields}&parentId=${parentId}`;
    const options = await this.api.httpConfig(true, false);

    try 
    {
      const response = await this.api.post(url, data, options)

      alert("Document " + data.name + " uploaded");

      return response;
    }
    catch (error) 
    {
      alert("Document " + data.name + " creation failed");
      console.log(error);

      return error;
    }
  }


  /* =========  DELETE ============= */

  async deleteFile(file) 
  {
    const uid = file.id;
    const url = `/gdrive/files/${uid}`;
    const options = await this.api.httpConfig(true, false);

    try 
    {
      const response = await this.api.delete(url, {}, options)

      this.snackBar.open("Document successfully deleted", '', {
        duration: 5000
      });

      return response;
    }
    catch (error) 
    {
      this.snackBar.open("Failure : document not deleted", '', {
        duration: 5000
      });
      console.log(error);

      return error;
    }
  }

  async deleteFolder(uid) 
  {

    const url = `/gdrive/folders/${uid}`;
    const options = await this.api.httpConfig(true, false);

    try 
    {
      const data = await this.api.delete(url, options)

      alert("Folder successfully deleted");

      return data;

    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }
}
