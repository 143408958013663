<div *ngIf="!hasReceivedOneMessage" style="height:100%"> <app-nofile></app-nofile></div>
<div *ngIf="isLoading || !isChildrenDisplay" class="spinner"><mat-spinner></mat-spinner></div>
<div class="box" *ngIf="!isLoading && hasReceivedOneMessage">
    <div class="header">
        <div class="header-first-row">
            <div>
                <span class="header-title">{{document?.name.slice(0,25)}}</span>
                <span *ngIf="document?.name.length > 25">...</span>
            </div>
            <div class="header-rating-threedotmenu">
                <div *ngIf="ratings  && ratings != 0">
                    <mat-icon class="header-star-rating">star</mat-icon>
                    <span class="header-number-rating">{{ratings}}/5</span>
                </div>
                <div class="header-three-dot">
                </div>
            </div>
        </div>
        <div class="header-second-row" *ngIf="docIsPublished.value || (sidepanelService.lastFlaggingEvent$ | async)">
            <div class="label-fondamental">
                <span *ngIf="docIsPublished.value" class="etiquette" matTooltip="{{'fondational-title' | translate}}"
                    [matTooltipPosition]="'left'" [matTooltipClass]="'custom-tooltip'"> {{'fondational' |
                    translate}}</span>
            </div>
            <app-flagging-status (delete)="opendialogUnflag()"></app-flagging-status>
        </div>
    </div>
    <div class="main-content">
        <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">
            <mat-tab label="Overview">
                <app-doc-desc *ngIf="documentCurent?.content?.data !== null  && isChildrenDisplay " [canEdit]="document?.capabilities.canEdit"
                    [gedDoc]="documentCurent" (emitState)="detectClick($event)"></app-doc-desc>
                <app-doc-add *ngIf="documentCurent?.content?.data === null" [canEdit]="document?.capabilities.canEdit"
                    [dontShowDocType]="true" (emitState)="detectClick($event)"></app-doc-add>
                <div class="section-rating">
                    <div class="section-rating-sentence">
                        What rating would you give ?
                    </div>
                    <div *ngIf="displayRating">
                        <span *ngFor="let starIndex of [0,1,2,3,4]; index as i">
                            <mat-icon class="section-rating-icon" (click)="postRating(starIndex)">
                                {{showIcon(i)}}
                            </mat-icon>
                        </span>
                    </div>
                    <div style="height:15px"></div>
                </div>
            </mat-tab>
            <mat-tab label="{{ 'ApprovalSidePanel' | translate}}">
                <ng-template matTabContent>
                    <div class="spinner-div" *ngIf="!documentCurent">
                        <mat-spinner></mat-spinner>
                    </div>
                    <app-doc-list-approval-activity *ngIf="documentCurent" [_gedDoc]="documentCurent" [canEdit]="document?.capabilities.canEdit"
                        [currentDocId]="document.id" class="body-container-height">
                    </app-doc-list-approval-activity>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="footer">
        <!--<button class="like-button" [class]="documentLiked ? 'button-liked' : ''" (click)="likeDocument()">
            <mat-icon fontIcon="thumb_up" class="like-button-icon material-symbols-outlined"></mat-icon>
            <div class="like-button-text" *ngIf="!documentLiked">Like</div>
            <div class="like-button-text" *ngIf="documentLiked">{{ documentNbLikes }}</div>
        </button>-->
        <div class="submit-buttons" *ngIf="!showFeedBack">
            <div>

                <button auraButton label="{{'ACTIONS.CANCEL' | translate}}" (click)="onGoBack()" [hasShadow]="true"
                    [full]="false" class="cancelBtn" [style.padding-inline]="'14px'" type="light">
                </button>
            </div>
            <div>
                <button auraButton size="small" label="{{'ACTIONS.SAVE' | translate}}" [full]="false"
                    imageIcon="/assets/images/icon-plus.svg" (click)="submit()" class="submit">
                </button>
            </div>
        </div>
        <div class="feedback-section" *ngIf="showFeedBack">
            <div class="chip">
                <div class="chip-content" (click)="openDialogFlag()">
                    <mat-icon class="feedback-section-icon">
                        outlined_flagp
                    </mat-icon>
                    {{'flagContent' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
