<ng-container>


  <div class="row">
    <div class="searchbar">
      <div class="input mt-2">
        <aura-input-text
          className=""
          placeholder="{{ 'Search file in drives' | translate }}"
          [custom-style]="{
            'background-color': '#DBE2EA88',
            border: 'none',
            size: 'large',
            width: '50%',
            float: 'right'
          }"
          [formControl]="searchKey"
          (keydown.enter)="$event.preventDefault()"
        ></aura-input-text>
        <span class="icon">
          <i
            class="gg-close"
            (click)="clearInput()"
            *ngIf="searchKey?.value"
          ></i>
          <i
            class="gg-search"
            *ngIf="!searchKey.value || searchKey.value.length === 0"
          ></i>
        </span>
      </div>
    </div>
    <div
      class="bc mt-4"
      *ngIf="!searchKey.value || searchKey.value.length === 0"
    >
      <nav aria-label="breadcrumb mt-1">
        <ol class="breadcrumb">
          <li class="breadcrumb-item item" *ngFor="let parent of parents">
            <a (click)="getDrivesData(parent.id)"
              ><strong>{{ parent.name }}</strong></a
            >
          </li>
          <li class="breadcrumb-item item active" aria-current="page">
            <strong>{{ curentPath }}</strong>
          </li>
        </ol>
      </nav>
    </div>
    <div class="bc mt-4" *ngIf="searchKey.value && searchKey.value.length > 0">
      <span><strong>All Drives > Search results</strong> </span>
    </div>
    <div
      class="col-12 driveData mt-2" (scroll)="onWindowScroll()" id="driveData"
      *ngIf="!searchKey.value || searchKey.value.length === 0"
    >
      <table class="table table-hover" >
        <thead>
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="drivesData.length > 0; else emptyBlock">
            <tr *ngFor="let item of drivesData"  (click)="handleRowClick(item)"

            >
              <td>
                <div class="itemInfo">
                  <img [src]="item.iconLink" class="item-icon" />
                  <span>{{ item.name }}</span>
                </div>
              </td>
              <td class="actionbtn">
                <button
                  auraButton
                  type="primary"
                  [ngClass]="{ selected: item.selected }"
                  [hasShadow]="true"
                  label="{{ 'Add this document' | translate }}"
                  *ngIf="!item.isFolder && !item.isDrive"
                  (click)="selectDocument(item)"
                ></button>
                <button
                  auraButton
                  type="light"
                  (click)="getDrivesData(item.id)"
                  [hasShadow]="true"
                  label="{{ 'Open Drive' | translate }}"
                  *ngIf="item.isDrive && !item.isFolder"
                ></button>

                <button
                  auraButton
                  type="light"
                  [hasShadow]="true"
                  label="{{ 'Open Folder' | translate }}"
                  *ngIf="item.isFolder"
                  (click)="getDrivesData(item.id)"
                ></button>
              </td>
            </tr>
          </ng-container>
          <ng-template #emptyBlock>
            <div class="empty-folder-container mt-5">
              <h2 aura-h2 class="empty-folder-paragraph">{{ 'The folder is empty.' | translate }}</h2>
            </div>
          </ng-template>
        </tbody>
      </table>
    </div>

    <div
      class="col-12 mt-4 driveData"  (scroll)="onWindowScroll()" id="driveData"
      *ngIf="searchKey.value && searchKey.value.length > 0"
    >
      <div class="table-container">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of dataSearch">
              <tr
                *ngIf="
                  !item.properties?.type_document ||
                  item.properties?.type_document === 'My Drive' ||
                  item.properties?.type_document === 'space'
                "
                [class.selected]="item.selected"
              >
                <td>
                  <div class="itemInfo">
                    <img [src]="item.iconLink" class="item-icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </td>
                <td class="actionbtn">
                  <button
                    auraButton
                    type="primary"
                    [hasShadow]="true"
                    label="{{ 'Add this document' | translate }}"
                    (click)="selectDocument(item)"
                    *ngIf="!item.isFolder"
                  ></button>
                  <button
                    auraButton
                    type="light"
                    [hasShadow]="true"
                    label="{{ 'Open Folder' | translate }}"
                    *ngIf="item.isFolder"
                    (click)="getDrivesData(item.id, true)"
                  ></button>
                </td>
              </tr>
              <tr
                *ngIf="
                  item.properties?.type_document &&
                  item.properties?.type_document !== 'My Drive' &&
                  item.properties?.type_document !== 'space'
                "
                [class.selected]="item.selected"
              >
                <td>
                  <div class="itemInfo">
                    <img [src]="item.iconLink" class="item-icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </td>
                <td class="actionbtn">
                  {{"already in a space"|translate}}
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>

    </div>


  </div>
</ng-container>
