import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { GedService } from 'src/modules/app-common/services';

@Injectable({
  providedIn: 'root'
})
export class GedDocService 
{
  constructor(
    private gedService: GedService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog) 
  {
  }

  async onUpdateGedDocMeta(formValues, chipContent, doc, responseDoc) 
  {
    const originalDoc = responseDoc;
    const updatedDoc = doc;

    for (const i in formValues) 
    {
    /*for (let i in formValues)
    {
      let key = chipContent[i].meta;

      if (chipContent[i].type == "date"  &&
          Object.values( chipContent[i].enumValues).length !=0)
      {
        updatedDoc[key] = formValues[i].toString();
        updatedDoc[key] = chipContent[i].value;
      }

      if (chipContent[i].dataType != "object"  &&
          Object.values( chipContent[i].enumValues).length !=0)
      {
        if (chipContent[i].type != "date")
        {
           updatedDoc[key] = chipContent[i].value;
        }
      }
      else
      {
        if (formValues[i].html == "-") {
           chipContent[i].value = { html: "-", value: "-" };
           updatedDoc[key] = null;
        }
        else
        {
          // chipContent[i].value = formValues[i];
          updatedDoc[key] = formValues[i];
        }
      }

      if (  chipContent[i].control === "checkbox" &&
            Object.values( chipContent[i].enumValues).length !=0)
      {
        let defaultValue = chipContent[i].value;

        let t;
        if (defaultValue != ""  && defaultValue != "||")
        {
          let dataenum = [];
          let dataenumLabel = [];

          if (chipContent[i].isAuto )
          {
            let url =  chipContent[i].autoUrl;
            let enumsOthers =  await this.gedService.getEnumsValues(url);

            t= defaultValue?.split(',');

            Object.values(t).forEach((item) =>
            {
              let existeCheck =  Object.values(enumsOthers)
                .find(keyc=> keyc['html'] === item);

              if(existeCheck != undefined)
              {
                dataenumLabel.push(existeCheck['html']);

                dataenum.push((existeCheck["value"]).toString());

                updatedDoc[key] =
                {
                  value: '|' + dataenum.join('|') +'|' || "",
                  html:   dataenumLabel.join(',') ||  "",
                  values: existeCheck || []
                };
              }
            });
           }
          else
          {
            let enumVals = chipContent[i].enumValues;

            t= defaultValue?.split(',');

            Object.values(t).forEach((item)=>
            {
              let existeCheck =  Object.entries(enumVals).find(key=> key[1]===item)

              if(existeCheck != undefined)
              {
                dataenumLabel.push(existeCheck[1]);

                dataenum.push(existeCheck[0])
                  updatedDoc[key]   = {
                  value: '|' + dataenum.join('|') +'|' || "",
                  html:   dataenumLabel.join(',') || "" ,
                  values: dataenum || []
                };
              }
            });
          }
        }
        else
        {
          updatedDoc[key] =
            {
              value:  "",
              html:  "",
              values:  []
            };
        }
      }

      if (chipContent[i].dataType === "enum" &&
          chipContent[i].control !== "checkbox" &&
          Object.values( chipContent[i].enumValues).length !==0 &&
            !(chipContent[i].isAuto ))
      {
        if (formValues[i].html === "-" || formValues[i].html === undefined)
        {
          chipContent[i].value = { html: "-", value: "-" };
          updatedDoc[key] = null;
        }
        else
        {
            chipContent[i].value = {html: formValues[i].html,
            value: chipContent[i].enumValues.find(item=>item.html===formValues[i].html)?.value || '-'};
            updatedDoc[key] = chipContent[i].value;
        }
      }
    }*/


      const response = await this.gedService.updateMetadata(originalDoc,formValues);

      if(response?.content)
      {
        this._snackBar.open("Document Updated!", null, {duration: 2000,});
      }
      else 
      {
        this._snackBar.open("Document not updated", null, { duration: 2000, });
      }

      return response?.content;
    }
  }
}
