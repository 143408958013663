<header class="header">
    <div class="_navbar pe-5">
        <div class="brand ms-4 ms-md-5 ms-lg-0">
            <a routerLink="/"><img src="/assets/images/logo-wt.svg#{{Mode}}" width="200" alt=""></a>
        </div>
        <div class="d-none d-md-block">
            <h1 class="entry-tite">{{title}}</h1>
            <div class="">
                <xng-breadcrumb [separator]="iconTemplate"></xng-breadcrumb>
                <ng-template #iconTemplate>
                    >
                </ng-template>
            </div>
        </div>
        <div class="_snam">
            <div class="searchbar" [ngClass]="activeSearch ? ' on ' : ''">
                <span class="icon" (click)="toggleSearch()">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </span>
                <aura-input-text className="ps-5" [custom-style]="{'background-color': '#DBE2EA88', border: 'none'}"
                    placeholder="Search" >
                </aura-input-text>
            </div>

            <div class="notifs" [ngClass]="activeNotifsMenu ? ' on ' : ''">
                <div class="icon" (click)="toggleNotifsMenu()">
                    <img src="/assets/images/icon-bell.svg">
                </div>
                <div class="notif-list">
                    <ul>
                        <li>
                            <a href="">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-person-circle" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fill-rule="evenodd"
                                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>
                                </figure>
                                <div class="">
                                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                                    <span class="date">1 min ago</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-person-circle" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fill-rule="evenodd"
                                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>
                                </figure>
                                <div class="">
                                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                                    <span class="date">1 min ago</span>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <figure>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="bi bi-person-circle" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                        <path fill-rule="evenodd"
                                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                    </svg>
                                </figure>
                                <div class="">
                                    <span>Lorem ipsum dolor sit amet consectetur.</span>
                                    <span class="date">1 min ago</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>

            </div>

            <div class="menu-account" [ngClass]="activeAccountMenu ? ' on ' : ''">
                <div class="user-photo" (click)="toggleAccountMenu()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                        class="bi bi-person" viewBox="0 0 16 16">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                    </svg>
                </div>
                <div class="menu">
                    <ul>
                        <li>
                            <a href="">
                                <span><i class="gg-user"></i></span>
                                Profile
                            </a>
                        </li>
                        <li>
                            <a href="">
                                <span><i class="gg-log-off"></i></span>
                                Disconnect
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header><!-- End Header -->
