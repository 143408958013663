import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APISce } from 'src/modules/app-common/services';
import { GedFile, GedTask,IUserTaskData } from '../model/ged-task.model';

import { DebugLog } from "src/modules/app-common/services/log/log.service"
import { environment } from 'src/environments/environment';
import { TFileProperties } from "../model/ged-task.model";
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/modules/user/services';
import { ApiObsService } from 'src/modules/app-common/services/http/api.obs.service';
import { Observable, throwError } from 'rxjs';
const console = DebugLog.build("Tasks","green");

@Injectable({
  providedIn: 'root'
})
export class TaskService
{

  constructor(
    private router: Router,
    private api: APISce,
    private apiObs: ApiObsService,
    private auth: AuthService,
  ) 
  {

  }

  openDocument(task: GedTask, prefixUrl: string = null)
  {
    const url = prefixUrl + '/file/' + task.getUid();

    this.router.navigate([url], { queryParams: { d: localStorage.host_domain } });
  }

  async getTasks()
  {
    //let url = `/ged/documents/workflows/mytasks`;
    const url = `/workflow/mytasks`;

    try 
    {

      const tasks : {content:{

        mytasks:{
          items: IUserTaskData[]
        },

        group_tasks:{
          items: IUserTaskData[]
        }
      }} = await this.api.get(url);

      const allTasks = tasks?.['content'];

      let index = 0;
      const tasksJson =
      {
        myTasks: (allTasks?.mytasks?.items || [])
          .map(t => new GedTask(t,index++, environment.stringsFile.taskForMe, 'my-task'))
          .filter(task => task.getUid() != null),

        groupTasks: (allTasks?.group_tasks?.items || [])
          .map(t => new GedTask(t,index++, environment.stringsFile.taskForMyTeam, 'task-team'))
          .filter(task => task.getUid() != null)
      }

      return tasksJson;
    }
    catch (error) 
    {
      console.log(error);

      return error;
    }
  }

  async approveAllDocuments(approbations: any, workflowService: any) {
    let logsDevoteam: any = [];
    let chunk: number = 0;
    const chunkSize: number = 20;
    let promises: Array<any> = [];
    /* Loop through all the documents waiting for approval and approve them */
    approbations.forEach( async (document) => 
    {
      const action: any = {role: "default", name: "Yes", action: "publish"};
      const doc = {state: document?.data?.state, oid: document?.data?.resource?.resource_id, uid: document?.data?.resource?.resource_uid};

      // Store the async query in a promise array
      promises.push(
        Promise.resolve(workflowService.execAction(action, doc).then((element) => {
          console.log('Trying to approve a document response from server : ', element);
          logsDevoteam.push({'info:': 'Trying to approve document', 'document': document, 'status': element});
          localStorage.setItem('logsDevoteam', JSON.stringify(logsDevoteam));
        }).catch((error) => {
            console.log("Error while approving document : ", error);
            logsDevoteam.push({'info:': 'Error while approving document', 'document': document, 'status': error});
        }))
      );
      chunk++;
      if (chunk >= chunkSize) {
        /* Wait for all promises to be resolved */
        await Promise.all(promises);
        chunk = 0;
        promises = [];
      }
    });
  }

  getTasksApprobations()
  {
    //let url = `/ged/documents/workflows/mytasks`;
    const url = `/workflow/mytasks`;

    return this.apiObs.get(url).pipe(
      map((tasks) => 
      {
        return {
          myTasks: ((tasks as any)?.content?.mytasks?.items || [])
            .map((t, index) =>
            {
              return new GedTask(t,index , environment.stringsFile.taskForMe, 'my-task');
            })
            .filter(task => 
            {
              return task.getUid() != null;
            }),

          groupTasks: ((tasks as any)?.content?.group_tasks?.items || [])
            .map((t, index) => 
            {
              return new GedTask(t as  any ,index , environment.stringsFile.taskForMyTeam, 'task-team');
            })
            .filter(task => 
            {
              return task.getUid() != null
            })
        }
      })
    );


  }

  async getFiles(profile): Promise<GedFile[]>
  {
    const owner = { value: profile.email, html: profile.name };

    const search = '{"labels":{"html":"Flagged","value":"flag@flag.appProperties"},"owner":' + JSON.stringify(owner) + '}';

    const search64  = btoa(search);

    const params: any = {
      search64,
      all: true,
      withFolders: false,
      nbItems: 25
    };

    const files : {
      content:{
        files:TFileProperties[]
      }
    } = await this.api.get('/ged/documents/files/search/',
      params);

    const allFiles = files?.['content']['files'];
    let index = 0;
    const filesJson =
      {
        myFiles: (allFiles || [])
          .map(f => new GedFile(f,index++)),
      }

    return filesJson.myFiles;

  }


  async getFlaggedFiles(): Promise<GedFile[]> 
  {
    try 
    {
      const user = await this.auth.profileSubject.pipe(
        filter(Boolean),
        take(1) // Utilisez take(1) pour s'assurer que l'observable se termine après la première émission
      ).toPromise();

      const files = this.getFiles(user);

      return files;
    }
    catch (error) 
    {
      console.error('Une erreur s\'est produite lors de la récupération des fichiers :', error);
      throw error; // Vous pouvez choisir de gérer l'erreur de manière appropriée ici
    }
  }
  getFlaggedTasksObs(): Observable<GedFile[]> 
  {

    return this.auth.profileSubject.pipe(
      filter(Boolean),
      take(1),
      switchMap((user) => 
      {
        return this.getFilesObs(user)
      }),
      catchError(error => 
      {
        console.error('Une erreur s\'est produite lors de la récupération des fichiers :', error);

        return throwError(error); // Rethrow the error or handle it as you see fit
      })
    )
  }

  getFilesObs(userProfile): Observable<GedFile[]>
  {
    const owner = { value: userProfile.email, html: userProfile.name };

    const search = '{"labels":{"html":"Flagged","value":"flag@flag.appProperties"},"owner":' + JSON.stringify(owner) + '}';

    const search64  = btoa(search);

    const params: any = {
      search64,
      all: true,
      withFolders: false,
      nbItems: 25
    };

    return this.apiObs.get('/ged/documents/files/search/', params)
      .pipe(
        map(resp => resp?.['content']['files'] || []),

        map(files => files.map((file, index) => new GedFile(file, index)))
      )
  }
}
