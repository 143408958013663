import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GedService } from 'src/modules/app-common/services';
import { TaskService } from '../../services/task.service';
import { TasksListComponent } from '../tasks-list/tasks-list.component';

@Component({
  selector: 'app-tasks-bar',
  templateUrl: './tasks-bar.component.html',
  styleUrls: ['./tasks-bar.component.scss']
})
export class TasksBarComponent implements OnInit
{
  @Input('element') element; 
  @Input('gedElement') gedElement; 

  constructor(private drawer: TasksListComponent, 
    private router: Router, private taskService: TaskService) 
  { }

  ngOnInit(): void 
  {
  }

  openDocument(element)
  {
 
    this.taskService.openDocument(element)
  }

  closeDrawer()
  {
    this.drawer.closeDrawer();
  }

}
