import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IEvent, IEventTypeEnum, IOption } from '../models';
import { Observable } from 'rxjs';
import { ApiObsService } from 'src/modules/app-common/services/http/api.obs.service';
import { GedService } from 'src/modules/app-common/services';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FlaggingService 
{

  constructor(private http: HttpClient,
    private gedService: GedService,
    private api: ApiObsService) 
  { }
  // private base = 'http://localhost:3001/'
  private base = 'https://flagging-module-sa6ibfsjvq-od.a.run.app/'
  flagOptions = [
    'Information is not well presented',
    'Information is outdated',
    'Information is inaccurate or incorrect'
  ]
  verifyOptions = [
    'Information is well presented',
    'Information is up-to-date',
    'Information is accurate and correct'
  ]

  flag(resourceId: string, options: IOption[]): Observable<IEvent> 
  {
    if (!options) 
    {
      alert('select one option at least')

      return;
    }
    const httpOptions = this.api.httpConfig(true, false);

    return this.http.post<IEvent>(this.base + 'flag/' + resourceId, {
      options
    }, httpOptions).pipe(tap(
      resp => 
      {
        this.gedService.flagDocument(resourceId, IEventTypeEnum.flag)
      }
    ))
  }
  status(resourceId: string): Observable<IEvent[]> 
  {
    const options = this.api.httpConfig(true, false);

    return this.http.get<IEvent[]>(this.base + 'status/' + resourceId, options)
  }
  verify(resourceId: string, options: IOption[]) 
  {
    if (!options) 
    {
      alert('select one option at least')

      return;
    }
    const HttpOptions = this.api.httpConfig(true, false);

    return this.http.post<IEvent>(this.base + 'verify/' + resourceId, {
      options
    }, HttpOptions).pipe(tap(
      resp => 
      {
        this.gedService.flagDocument(resourceId, IEventTypeEnum.verify)
      }
    ))
  }
}
