import { IFormField } from "../interfaces/form-field.interface";
import { FieldDescEnum } from "./field-desc-enum.model";
import { FieldDesc } from "./field-desc.model";
import { TEnumData, TFieldData } from "./field-record.model";

/**
 * FormField: model for fields from the backend API.
 * Wraps the data and metadata details and offers a clean interface for services and components.
 * All actions on the data to be retrieved/sent to the backend should be done through this model.
 */
export class FormField implements IFormField
{
  name: string; // API field name
  desc: FieldDesc | FieldDescEnum; // API metadata
  formControl: any; // Angular form control can be attached here
  _data: TFieldData; // Data value (string or {value, html})
  [key: string]: any;

  constructor(data: TFieldData, desc: FieldDesc | FieldDescEnum) 
  {
    this.desc = desc;
    this.name = this.desc.fname;
    this.set(data);
  }

  /**
   * Generic initialization of the field.
   * Preprocesses data from the server and completes metadata (enums, etc.).
   * @param {object} fields - Optional fields object.
   * @param {object} params - Optional parameters object.
   * @param {Function} cb - Optional callback function.
   * @returns {Promise<any>} - The initialized data.
   */
  async init(fields : any = null, params = null, cb = null): Promise<TFieldData> 
  {
    return this._data;
  }

  /**
   * Returns the name of the field.
   * @returns {string} - The field name.
   */
  getName(): string 
  {
    return this.name;
  }

  /**
   * Returns the data of the field.
   * @returns {TFieldData} - The field data.
   */
  data(): TFieldData 
  {
    return this._data;
  }

  /**
   * Returns the description of the field.
   * @returns {FieldDesc} - The field description.
   */
  getDesc(): FieldDesc | FieldDescEnum 
  {
    return this.desc;
  }

  /**
   * Returns the formControl of the field.
   * @returns {string} - The formControl name.
   */
  getFormControl(): string 
  {
    return this.formControl;
  }


  /**
   * set the formControl of the field.
   * @returns {string} - The formControl name.
   */
  setFormControl(formControlName: string): string 
  {
    this.formControl = formControlName;

    return this.formControl;
  }

  /**
   * Returns the control type of the field.
   * @returns {string} - The field control type.
   */
  control(): string 
  {
    return this.desc._control || "text";
  }

  /**
   * Sets the data value of the field.
   * @param {TFieldData} v - The value to set.
   * @returns {TFieldData} - The updated data value.
   */
  set(v: TFieldData): TFieldData 
  {
    this._data = v;

    return this._data;
  }

  /**
   * Updates the data value of the field.
   * @param {TFieldData} v - The value to update.
   * @returns {TFieldData} - The updated data value.
   */
  update(v: TFieldData): TFieldData 
  {
    this._data = v;

    return this._data;
  }

  /**
   * Returns the value of the field.
   * @param {boolean} native - Optional flag to return the native value.
   * @returns {TFieldData} - The field value.
   */
  value(): TFieldData 
  { 
    if (this._data === null) return null;
    if (typeof this._data === 'object' && 'html' in this._data) 
    {
      return this._data.html;
        
    }

    return this._data;
  }

  /**
   * Returns the string representation of the field.
   * @returns {string} - The string representation.
   */
  toString(): string | number |TEnumData
  {
    if (this._data === null || typeof this._data == "undefined") return "";

    return this.getFieldHtml(this._data) || this.getFieldvalue(this._data) || this._data;
  }

  /**
   * Iterates through each tag of the field and executes a callback function.
   * @param {Function} cb - The callback function to execute for each tag.
   */
  forEachTag(cb: Function): void 
  {
    const tags = this.desc.tags;

    for (let i = 0; i < tags.length; i++)
    {
      const tag = tags[i];

      cb(tag, this);
    }
  }

  getFieldHtml(v: TFieldData): string | undefined 
  {
    if (typeof v === 'object' && v !== null && 'html' in v) 
    {
      return v.html;
    }

    return undefined;
  }

  getFieldvalue(v: TFieldData): string | number | null 
  {
    if (typeof v === 'object' && v !== null && 'value' in v) 
    {
      return v.value;
    }

    return null;
  }

  formateFieldDate(): string 
  {
    const field = this.value();

    if( typeof field === 'string')
    {
      const date = new Date(field);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
      const day = String(date.getDate()).padStart(2, '0');
  
      return `${year}-${month}-${day}`;
    }
  }
}



