import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SpaceHomeComponent } from './containers';
import { DocumentPageComponent } from '../documents/containers/document-page/document-page.component';
import { SpaceTypeComponent } from './components/space-type/space-type.component';
import { SpaceGroupsComponent } from './components/space-groups/space-groups.component';

import { OnlyLoggedGuard } from "src/modules/user/guards";

const routes: Routes = [
  {
    path: "groups/all",
    component: SpaceGroupsComponent,
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/groups/:type",
    component: SpaceTypeComponent,
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/groups/:type/:spaceId",
    component: SpaceTypeComponent,
    canActivate: [OnlyLoggedGuard]
  },

  {
    path: 'space/:spaceId',
    component: SpaceHomeComponent,
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/:spaceId/meta-edit/:tdesc64",
    loadChildren: () =>
      import("../metatable/metatable-routing.module").then(
        (m) => m.MetatableRoutingModule
      ),
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/:spaceId/file/:docId",
    component: DocumentPageComponent,
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/:spaceId/metatable/:sid/:tid/:url",
    loadChildren: () =>
      import("../metatable/metatable-routing.module").then(
        (m) => m.MetatableRoutingModule
      ),
    canActivate: [OnlyLoggedGuard]
  },
  {
    path: "space/:spaceId/crud-component",
    loadChildren: () =>
      import("../crud/crud-component/crud-component-routing.module").then((m) => m.GenericPageRoutingModule),
    canActivate: [OnlyLoggedGuard],
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpacesRoutingModule 
{ }
