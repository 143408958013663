<div class="container-fuild">
  <div class="row gx-3">
    <div class="col-12 col-md-6 col-lg-4">
      <!-- <aura-title icon="/assets/images/icon-file.svg">Recent Documents</aura-title>

      <aura-list verticalAlign="center"
        [data]="recentDocs"
        [hasMenu]="true"></aura-list> -->
            <aura-list verticalAlign="center" icon="/assets/images/icon-file.svg" [hasMenu]="true"
                title="Recent Documents" [data]="recentDocs">
            </aura-list>
        </div>

    <div class="col-12 col-md-6 col-lg-4 mt-4 mt-md-0">
      <div class="box">
        <!-- <aura-title icon="/assets/images/icon-building.svg">Departments</aura-title>

        <aura-list verticalAlign="center"
          [data]="departments"
          [hasMenu]="true"></aura-list> -->
                <aura-list verticalAlign="center" icon="/assets/images/icon-building.svg" title="Departments"
                    [data]="departments" [hasMenu]="true">
                </aura-list>
                <br>
<!--
        <aura-title icon="/assets/images/icon-envolope.svg">Emails</aura-title>

        <aura-list titleSize="h4"
          [data]="emails"
          [isImageCirle]="true"
          [maxHeight]="315"
          [hasRightText]="true"></aura-list> -->

        <aura-list icon="/assets/images/icon-envolope.svg"
          title="Emails"
          titleSize="h4"
          [data]="emails"
          [isImageCirle]="true"
          [maxHeight]="315"
          [hasRightText]="true"></aura-list>
      </div>
    </div>

    <div class="col-12 col-md-12 col-lg-4">
      <div class="box bg-color pb-2 rounded mt-5">
        <div class="px-3 pt-3 mb-3">
          <div class="bg-primary color-white w-100 position-relative text-left ps-3 h-155 rounded">
            <span class="h1">Events</span>

            <img alt=""
              class="banner-img m-auto d-block pb-2 position-absolute end-40 top-0"
              src="/assets/images/event.svg"
              width="250" />
          </div>
        </div>

        <div class="bg-white d-flex justify-content-center"
          id="color-calendar"></div>

        <div class="sub_box px-3">
          <aura-list-advanced answers
            question
            verticalAlign="top"
            [data]="meetings"
            [hasRightIcons]="true"></aura-list-advanced>
        </div>
      </div>
    </div>
  </div>
</div>
