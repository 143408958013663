
<mat-toolbar class="dialog-header">
  <span mat-dialog-title>{{data.title}}</span>
  <span class="app-spacer"></span>
  <button mat-icon-button style="vertical-align:middle;" (click)="onCancel()">
    <mat-icon style="color: white">close</mat-icon>
</button>
</mat-toolbar>
<div mat-dialog-content>
  <form #f="ngForm">
    <mat-form-field appearance="fill" style="display: block; text-justify: center;" >
      <mat-label>Document Name</mat-label>
      <input matInput [(ngModel)]="data.documentName" name="documentName" required placeholder="document name"
      cdkFocusInitial (keydown.enter)="onCreateDoc(data.documentName)">
    </mat-form-field>
  </form>

</div>
<div mat-dialog-actions class="col-12 actions"  align="end">
  <div style="padding-right: 31px;">

    <button mat-button class="cancelBtn" mat-dialog-close>{{'cancelBtn' | translate | uppercase}}</button>
    <button mat-button  class="submitBtn" [mat-dialog-close]="data.documentName"
    (click)="onCreateDoc(data.documentName)" [disabled]="!f.valid">{{'addBtn' | translate | uppercase}}</button>
  </div>

</div>
