<div class="container" *ngIf="loading && canEdit">
    <div class="spinner-div">
        <mat-spinner></mat-spinner>
    </div>
</div>

<div class="container" *ngIf="!canEdit">
    <div class="icon-container">
        <img src="/assets/images/icon-verification-request.svg"/>
    </div>
    <div class="title">
        <div>{{ 'verificationRequest' | translate}}</div>
    </div>
    <div class="description">
        {{ 'noPermissionToStartWorkflow' | translate}}
    </div>
</div>

<div class="container" *ngIf="noHistoryModel && canEdit">
    <div class="icon-container">
        <img src="/assets/images/icon-verification-request.svg"/>
    </div>

    <div class="title">
        <div>{{titleOfActions | translate}}</div>
    </div>

    <div class="description">
        {{description}}
    </div>

    <div class="actions">
        <div class="spinner-div">
            <mat-spinner *ngIf="isLoading"></mat-spinner>
        </div>
        <app-doc-actions *ngIf="!isLoading" [path]="path" (emitState)="showActionsEvent($event)"
            (infoActions)="getInfosStep($event)" [_gedDoc]="gedDoc"></app-doc-actions>
    </div>

</div>
<div class="container2" *ngIf="withHistoryModel && canEdit">
    <div class="section">
        <div class="left-section">
            <div *ngIf="dataState?.currentUser">
                <div class="conteneur">
                    <div class="title-approver">{{'Approver' | translate}} </div>
                    <span class="etiquette" [ngStyle]="{
                        'background-color': dataState?.approvalStatus.labelBackgroundColor,
                        'color':  dataState?.approvalStatus.labelStateColor
                      }"> <i class="{{dataState?.approvalStatus.labelIcon}}"></i>
                        <span>{{dataState?.approvalStatus.status}}</span></span>

                </div>

                <div class="user-profile">
                    <app-doc-avator-creator [name]="dataState?.assignedUser "
                        [icone]="dataState?.approvalStatus.labelIcon"
                        [classIcone]="{'backgroundColor':backgroundColor, 'textColor':dataState?.approvalStatus.labelStateColor}"></app-doc-avator-creator>
                    <div class="user-details">
                        <div class="user-name">{{dataState?.assignedUser}}</div>
                        <div class="user-role">
                            {{dataState?.approvalStatus.descriptionOfCurrentState + (dataState?.isForApproval ? "" : dataState?.tasks[0]?.dateTask)}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="dataState?.tasks?.length !== 0">
                <hr class="divider">
                <div class="title-approver">{{'ApprovalActivity' | translate}}</div>
                <app-doc-list-historic-approval-activity
                    [tasks]="dataState?.tasks"></app-doc-list-historic-approval-activity>
            </div>
        </div>
    </div>
</div>

<div class="actions boutons-fixes" *ngIf="withHistoryModel && dataState?.actions.length > 0 && canEdit ">
    <div class="bottom-title">
        <div>{{dataState?.approvalStatus.titleOfActions ?? titleOfActions }}</div>
    </div>
    <div class="spinner-div" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <app-doc-actions *ngIf="!isLoading" [path]="path" (emitState)="showActionsEvent($event)"
        (infoActions)="getInfosStep($event)" [_gedDoc]="gedDoc"></app-doc-actions>
</div>