import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDocument } from '../../../../../app/shared/models/doc.model';
import { SidepanelService } from './sidepanel.service';
import { HttpService } from './http.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Workflow } from 'src/modules/workflow/models';

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss']
})


export class SidePanelComponent implements OnInit
{
  public document?: IDocument;
  public documentCurent?;

  public docIsPublished = new FormControl<boolean>(false);
  public isLoading: boolean = false;
  public showFeedBack: boolean = true;
  public hasReceivedOneMessage: boolean = false;

  public ratings: number = 0;
  public myRating : number = 0;

  public isChildrenDisplay: boolean = true;
  documentLiked: boolean = false;
  documentNbLikes: number = 0;

  public displayRating: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elementRef: ElementRef,
    public sidepanelService: SidepanelService,
    private httpService: HttpService,
    public dialog: MatDialog
  )
  { }

  likeDocument(): void {
    this.documentLiked = !this.documentLiked;
    this.documentNbLikes = this.documentLiked ? this.documentNbLikes + 1 : this.documentNbLikes - 1;
  }

  openDialog(action, options, actionTranslated?: string, isSingleOptionEnough: boolean = true): void
  {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        action: action,
        options: options
          .map(option => ({ option, selected: false })),
        isSingleOptionEnough: isSingleOptionEnough  
      },
      maxWidth: 'none',
      width: '95%'
    });

    dialogRef.afterClosed().subscribe(result =>
    {
      if (actionTranslated === "flag")
      {
        this.onSubmitFlag(this.document.id, result.message);
      }
      if (actionTranslated === "Unflag")
      {
        this.onSubmitVerify(this.document.id, result.message);
      }
    })
  }

  ngOnInit(): void
  {
    if (this.route.snapshot.paramMap.get('docId') !== "1")
    {
      this.hasReceivedOneMessage = true;
      this.initServices(
        this.route.snapshot.paramMap.get('docId')
      )
    }

    window.addEventListener("message", (e) =>
    {
      if (e.data.docId)
      {
        if (e.data.docId !== "1" || e.data.docId !== "notSelected")
        {
          this.hasReceivedOneMessage = true;
          this.initServices(e.data.docId);
        }
        else
        {
          this.hasReceivedOneMessage = false;
        }
      }
    });
  }

  initServices(docId)
  {
    this.isLoading = true;
    this.getDocCurrent(docId);

    this.httpService.getFile(docId).then((data: IDocument) =>
    {
      if (data)
      {
        this.document = data;
      }
    });

    this.httpService.status(docId).subscribe(
      /**
       * Hide Verified label from the experience
       * Ticket sprint 12
       */
      ([status,]) =>
      {
        this.sidepanelService.setLastFlaggingEvent(status);
      }
    );
  }

  async getDocCurrent(id: string)
  {
    this.documentCurent = await this.httpService.getDoc(id);

    const ratings = await this.httpService.getRatingDoc(this.documentCurent?.content?.data.uid);
    if (ratings)
      this.ratings = ratings.rating;

    const myRating = await this.httpService.getMyRatingDoc(this.documentCurent?.content?.data.uid);

    if (myRating)
    {
      this.myRating = myRating.rating;
    }
    else
    {
      this.myRating = 0;
    }

    this.getWorkflowActive();
    this.isLoading = false;
  }

  onSubmitVerify(docId, verifyOptions)
  {

    this.httpService.verify(docId, verifyOptions)
      .subscribe((status) => this.sidepanelService.setLastFlaggingEvent(status))
  }

  onSubmitFlag(documentId, flagOptions)
  {
    this.httpService.flag(documentId, flagOptions)
      .subscribe(result => this.sidepanelService.setLastFlaggingEvent(result))
  }

  getWorkflowActive()
  {
    const uid = this.documentCurent?.content?.data?.uid;

    if (uid)
    {
      this.httpService.getsteps(uid).then((data: Workflow) =>
      {
        if (data?.state?.toLowerCase() === "published") this.docIsPublished.setValue(true);
        else this.docIsPublished.setValue(false);
      })
    }
  }

  detectClick(event: boolean)
  {
    if(this.document?.capabilities.canEdit)
    {
      this.showFeedBack = !event;
    }
  }

  onTabChanged($event)
  {
    if ($event.index === 1)
    {
      this.elementRef.nativeElement.querySelectorAll('.footer')[0].style.setProperty("display", "none");
    }
    else
    {
      this.elementRef.nativeElement.querySelectorAll('.footer')[0].style.setProperty("display", "flex");
      this.showFeedBack = true;
    }
  }

  async postRating(index: number)
  {
    if (this.documentCurent )
    {
      this.displayRating = false

      const rating = await this.httpService.rateDocument(this.documentCurent.content.data.uid, index + 1);

      this.ratings = rating.data.rating;

      const myRating = await this.httpService.getMyRatingDoc(this.documentCurent?.content?.data.uid);

      this.myRating = myRating.rating;

      this.displayRating = true
    }
  }

  showIcon(index: number)
  {

    if (this.myRating >= index + 1)
    {
      return 'star';
    }
    else
    {
      return 'star_outline';
    }
  }

  redirect()
  {
    const curUrl = this.router.url;

    this.router.navigateByUrl("/", { skipLocationChange: false }).then(() =>
    {
      this.router.navigateByUrl(curUrl);
    });
  }

  async submit()
  {
    const typeofForm = this.sidepanelService.getTypeOfForm();
    const form = this.sidepanelService.getForm();

    if (typeofForm === "desc")
    {
      const response = await this.httpService.onUpdateGedDocMeta(form, this.documentCurent)

      if (response) this.redirect();
    }
    else
    {
      this.httpService.addDocToGed(this.document, form).then((response) =>
      {
        if (response) this.redirect();
      })
    }
  }

  onGoBack()
  {
    this.showFeedBack = true;
    this.isChildrenDisplay = false;

    setTimeout(() =>
    {
      this.isChildrenDisplay = true;
    }, 3000);
  }

  opendialogUnflag()
  {
    this.openDialog(
      environment.stringsFile.unflag,
      [
        environment.stringsFile.unflagContentOne,
        environment.stringsFile.unflagContentTwo,
        environment.stringsFile.unflagContentThree
      ],
      'Unflag',
      false
    )
  }

  openDialogFlag()
  {
    this.openDialog(
      environment.stringsFile.flag,
      [
        environment.stringsFile.flagContentOne,
        environment.stringsFile.flagContentTwo,
        environment.stringsFile.flagContentThree
      ],
      'flag',
      true
    )
  }
}
