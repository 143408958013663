/**
 * File item built form google file properties
 * and field descs
 */
export class FileItem
{
  // extra data
  id;
  properties : string[];
  parentId: string;
  file : any;
  fieldDescs? : any;

  constructor(file,fieldDescs=null)
  {
    this.file = file;
    Object.assign(this,file);

    this.id = file.id;

    // set extra data
    this.fieldDescs = fieldDescs;

    // merge public and app properties
    const properties = file.properties || {};
    const appProperties = file.appProperties || {};

    this.properties = {...properties,...appProperties}; // add data for filtering

    this.parentId = file && file.parents && file.parents[0];
  }

  /**
   * get file id
   */
  fileId() : string 
  {
    return this.file.id;
  }

  /**
   * get icon link from Google file
   */
  iconLink() : string
  {
    return this.file.iconLink;
  }

  /**
   * get file name
   */
  getName() : string 
  {
    return this.file.name;
  }

  /**
   *  get parent folder id
   */
  getParent() : string 
  {
    return this.parentId;
  }

  getModelName() : string 
  {
    return this.prop('type_document');
  }

  setTypeName(typeName) 
  {
    this.properties['type_document'] = typeName;
  }

  /**
   * get file property or appProperty value
   */
  docProp(k) : any 
  {
    return this.properties[k] || '';
  }

  prop(k,dft='') : any 
  {
    return this.properties[k] || dft;
  }

  /**
   * get file link in application
   */
  getLink(prefix='') : string 
  {
    return prefix+'/file/'+this.file.id;
  }
}
