<header class="header">
    <div class="_navbar pe-5">
        <div class="brand ms-4 ms-md-5 ms-lg-0">
            <a routerLink="/"><img src="/assets/images/logo-2.png" width="200" alt=""></a>
        </div>
        <div class="d-none d-md-block bc-title">
            <h1 auraTextTruncate class="entry-tite">{{title}}</h1>
            <!--   <div class="">
                <xng-breadcrumb [separator]="'iconTemplate'"></xng-breadcrumb>
                <ng-template #iconTemplate>
                    >
                </ng-template>
            </div> -->



            <div class="breadcrumb_wrapper">
                 
                <aura-breadcrumb *ngIf="breadcrumb !== null" [steps]="breadcrumb"></aura-breadcrumb>

                <!--  <app-bread-crumb *ngIf="breadcrumb"
                [parents]="breadcrumb"
                [filter]="filterBreadCrumbParent"
                separator=" > "
                (click)="onBreadCrumbClick($event)"></app-bread-crumb>    -->
            </div>

        </div>
        <div class="_snam">
            <div class="searchbar" [ngClass]="activeSearch ? ' on ' : ''">
                <span class="icon" (click)="toggleSearch()">

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                </span>

                <app-search-bar></app-search-bar>


                <!--  <app-text-input className="ps-5" [custom-style]="{'background-color': '#DBE2EA88', border: 'none'}"
                    placeholder="Search">
                </app-text-input> -->
            </div>

            <div class="notifs">
                <app-notif-bar #notif></app-notif-bar>
            </div>

            <div class="menu-account">
                <app-user-bar #user></app-user-bar>
            </div>
        </div>
    </div>
</header><!-- End Header -->