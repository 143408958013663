import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, switchMap, tap } from "rxjs/operators";
import { Observable, combineLatest, iif, of } from "rxjs";
import { AuthService } from '../../../user/services';
import { OauthService } from 'src/modules/auth/services/oauth.service';
const BACKEND_URL = environment.BACKEND_URL;
const CLIENT_ID_KEY = 'Client_id'

@Injectable({
  providedIn: "root",
})
export class ApiObsService 
{
  constructor(private http: HttpClient, private auth: AuthService, private oauth: OauthService) 
  { }

  httpConfig(withGoogleToken = true, withJWT = true, withGoogleCookie = false) 
  {
    let headers = new HttpHeaders();
    headers = headers.set("X-CLIENT-ID", this.oauth.client_id);
    if (withJWT) 
    {
      // headers.set
      headers = headers.set("Authorization", "Bearer " + this.getJWT());

      if (withGoogleToken) 
      {
        this.getCurrentToken()
          .pipe(
            tap(
              (gtoken) => (headers = headers.set(`GTOKEN`, `Bearer ${gtoken}`))
            )
          )
          .subscribe({
            error: () => console.error("missing token in API"),
          });
      }
    }
    else if (withGoogleToken) 
    {
      this.getCurrentToken()
        .pipe(
          tap(
            (gtoken) =>
              (headers = headers.set(`Authorization`, `Bearer ${gtoken}`))
          )
        )
        .subscribe({
          error: () => console.error("missing token in API"),
        });
    }

    if (withGoogleCookie) 
    {
      headers = headers.set("Cookie", "G_ENABLED_IDPS=google");
    }

    const options = { headers };

    // console.log("headers from source")
    // console.log(options)
    return options;
  }

  httpConfigObs(
    withGoogleToken = true,
    withJWT = true
  ): Observable<{ headers: HttpHeaders }> 
  {
    let headers = new HttpHeaders();
    headers = headers.set("X-CLIENT-ID", this.oauth.client_id);

    const gTokenHeader$ = this.getCurrentToken();
    const authHeader$ = of(this.getJWT());

    return combineLatest([gTokenHeader$, authHeader$]).pipe(
      map(([gTokenHeader, authHeader]) => 
      {
        if (withJWT) 
        {
          headers = headers.set("Authorization", `Bearer ${authHeader}`);
          if (withGoogleToken) 
          {
            headers = headers.set("GTOKEN", `Bearer ${gTokenHeader}`);
          }
        }
        else if (withGoogleToken) 
        {
          headers = headers.set("Authorization", `Bearer ${gTokenHeader}`);
        }

        return { headers };
      })
    );
  }

  getJWT() 
  {
    return this.auth.jwt;
  }

  getCurrentToken() 
  {
    return this.auth.getTokenWithObservable();
  }

  getToken() 
  {
    return this.auth.token;
  }

  get<T>(url, params?, options?, withJwt = true, withGoogleToken = true) 
  {
    url = BACKEND_URL + url;

    return iif(
      () => !options,
      this.httpConfigObs(withGoogleToken, withJwt),
      of(options)
    ).pipe(
      switchMap((options) => 
      {
        if (params) options.params = params;

        return this.http.get<T>(url, options);
      })
    );
  }

  post<T>(
    url,
    params?,
    body?,
    options?,
    withJwt = true,
    withGoogleToken = true
  ) 
  {
    url = BACKEND_URL + url;

    return iif(
      () => !options,
      this.httpConfigObs(withGoogleToken, withJwt),
      of(options)
    ).pipe(
      switchMap((options) => 
      {
        if (params) options.params = params;

        return this.http.post<T>(url, body, options);
      })
    );
  }
}
