import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteService 
{

  constructor(private route: ActivatedRoute) 
  { }

  isPath(pathName) : boolean 
  {
    this.route.url.forEach(urls=>
    {
      const path = urls[0]["path"]

      if(path==pathName) return true;
    })

    return false;
  }

}
