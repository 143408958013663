<app-sidenav
  [inGed]="isSpace"
  [title]="currentName"
  [breadcrumb]="bc"
  *ngIf="!loading"
>
  <!-- ================ DESIGN V2 ============== -->
  <div class="" *ngIf="pdoc && !monDrive && (docListData || data)">
    <div class="row gx-2">
      <div
        *ngFor="let facet of searchFacets"
        class="col-12 col-sm-6 col-md-3 col-lg-2 mb-2"
      >
        <aura-select
          className="shadow-sm border-0"
          inline
          size="large"
          [items]="facet.getOptions()"
          [placeholder]="facet.getLabel()"
          (onChange)="setSearchFieldValue(facet.fname, $event)"
          (onChange)="onChangeFilter($event)"
        ></aura-select>
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-2"></div>
    </div>

    <div class="options">
      <div class="inline">
        <div>
          <aura-input-text
            class="search-space"
            icon="bi bi-search"
            [inline]="true"
            *ngIf="searchTextControl"
            inline
            ngDefaultControl
            size="medium"
            [formControl]="searchTextControl"
            placeholder="{{ 'searchCurrentSpace' | translate }}"
            (keydown.enter)="onTextSearch($event, $event.target.value)"
          ></aura-input-text>
        </div>
        <span>
          {{ dataRows.length || 0 }} document{{
            dataRows.length > 1 ? "s" : ""
          }}
        </span>
      </div>

      <div>
        <a
          data-bs-toggle="modal"
          data-bs-target="#exModal"
          *ngIf="space_create_doc"
          class="btn btn-success ng-star-inserted mb-3 mt-3"
          (click)="createDocumentModal = true"
        >
          <span class="sqaure green"
            ><img src="/assets/images/icon-plus.svg"
          /></span>
          {{ "newDoc" | translate }}
        </a>

        <a
          data-bs-toggle="modal"
          data-bs-target="#exModal"
          *ngIf="space_add_doc"
          class="btn btn-success ng-star-inserted mb-3 mt-3 ml-3 btnDoc"
          (click)="moreDetailsModal = true"
        >
          <span class="sqaure green"
            ><img src="/assets/images/icon-plus.svg"
          /></span>
          {{ "Add document" | translate }}
        </a>

        <button
          auraButton
          class="share-btn"
          size="medium"
          type="light"
          icon="gg-user-add"
          label="{{ 'share' | translate }}"
          (click)="permissionModal = true"
          *ngIf="canEditSpace"
        ></button>

        <span class="justify-content-end gap-2 view-icons">
          <!-- <button auraButton size="large" type="light" icon="gg-user-add" [full]="true" label="Share"></button> -->
          &nbsp;&nbsp;
          <img
            [src]="
              '/assets/images/view-list.svg#' +
              (activeTab == 'table' ? 'active' : '')
            "
            (click)="toggleView('table')"
          />
          &nbsp;&nbsp;
          <img
            [src]="
              '/assets/images/view-cols.svg#' +
              (activeTab == 'grid' ? 'active' : '')
            "
            (click)="toggleView('grid')"
          />

          <div ngbDropdown class="d-inline-block menuBtn">
            <i
              class="ms-auto me-0 d-block material-icons-round"
              id="dropdownBasic1"
              ngbDropdownToggle
              >more_vert</i
            >

            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
              class="menu-content"
            >
              <button
                ngbDropdownItem
                class="btn-menu"
                *ngIf="isSpace && canEditSpace"
                (click)="openFacetsPanel()"
              >
                <span><i class="gg-info"></i></span>
                <span class="menu-items">{{
                  "viewFolderDetailsBtn" | translate
                }}</span>
              </button>

              <hr *ngIf="isSpace && canEditSpace" />
              <button
                button
                ngbDropdownItem
                class="btn-menu"
                (click)="openInDrive()"
              >
                <span> <i class="gg-arrow-top-right-r"></i> </span>
                <span class="menu-items">
                  {{ "openFolderDriveTT" | translate }}</span
                >
              </button>

              <button
                button
                ngbDropdownItem
                class="btn-menu"
                (click)="openParentFolder()"
              >
                <span> <i class="gg-folder"></i> </span>

                <span class="menu-items">
                  {{ "openDestination" | translate }}</span
                >
              </button>
              <ng-container *ngIf="canEditSpace">
                <ng-container *ngFor="let t of tables">
                  <button
                    button
                    ngbDropdownItem
                    class="btn-menu"
                    routerLink="{{ t.routeSpacedUrl }}"
                  >
                    <span>
                      <i
                        class="gg-menu-oreos"
                        [ngClass]="{ disabledIcon: isDrive }"
                      ></i
                    ></span>
                    <span class="menu-items"> {{ t.title | translate }} </span>
                  </button>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <div
            *ngIf="false"
            ngbDropdown
            class="dropdown my-auto"
            placement="bottom-right"
          >
            <div
              type="button"
              id="dropdownMenuButton"
              aria-expanded="false"
              ngbDropdownToggle
            >
              <i
                style="font-size: 1.4rem"
                class="bi bi-three-dots-vertical"
              ></i>
            </div>
            <ul
              ngbDropdownMenu
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="dropdownMenuButton"
            >
              <li *ngFor="let t of tables">
                <a
                  ngbDropdownItem
                  class="dropdown-item"
                  routerLink="t.routeSpacedUrl"
                  >{{ t.title | translate }}</a
                >
              </li>

              <li>
                <a
                  ngbDropdownItem
                  class="dropdown-item"
                  (click)="openFolderDetails()"
                >
                  <i
                    [ngClass]="{ disabledIcon: isDrive }"
                    class="material-icons-outlined menuIcon"
                    >folder_special</i
                  >
                  {{ "setFolderTypeBtn" | translate }}</a
                >
              </li>

              <li>
                <a
                  ngbDropdownItem
                  class="dropdown-item"
                  (click)="deleteFolder()"
                >
                  <i
                    [ngClass]="{ disabledIcon: isDrive }"
                    class="material-icons-outlined menuIcon"
                    >delete</i
                  >
                  {{ "deleteFolderBtn" | translate }}</a
                >
              </li>
            </ul>
          </div>
        </span>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3 col-lg-2"></div>
  </div>

  <!-- modal add document-->
  <app-space-doc-add
    [isOpen]="moreDetailsModal"
    (close)="moreDetailsModal = false"
    [space]="space"
    [spaceUid]="spaceUid"
  ></app-space-doc-add>

  <!-- modal create document 2 -->
  <app-create-doc
    [isOpen]="createDocumentModal"
    (close)="createDocumentModal = false"
    [space]="space"
    [spaceUid]="spaceUid"
  ></app-create-doc>

  <!-- modal permission-->
  <app-space-permission
    [isOpen]="permissionModal"
    (close)="permissionModal = false"
  ></app-space-permission>

  <!--
      <div class="row g-3">
        <div *ngFor="let col of facets.main"
          class="col-12 col-sm-6 col-md-3 col-lg-2"
           >
          <aura-select className="shadow-sm border-0"
            inline
            size="large"
            [items]="col.getOptions()"
            [placeholder]="col.label"
            (change)="setSearchFieldValue(col.fname,$event)"
            (change)="onChangeFilter($event)"></aura-select>
        </div>

        <div
          class="col-12 col-sm-6 col-md-3 col-lg-2"
           >
        </div>
      </div>
      -->
  <!--
      <div class="row py-3">
          <div class="col icons d-flex justify-content-end gap-2 view-icons">
              &nbsp;&nbsp;
              <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                  (click)="toggleView('table')">
              &nbsp;&nbsp;
              <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                  (click)="toggleView('grid')">

                  <div ngbDropdown class="d-inline-block">
                    <i class=" menuBtn ms-auto me-0 d-block  material-icons-round" id="dropdownBasic1" ngbDropdownToggle>more_vert</i>

                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="menu-content">
                    <button ngbDropdownItem    class="btn-menu" *ngIf="isSpace && canEditSpace"  (click)="openFacetsPanel()">
                      <span><i class="gg-info"></i></span>
                      <span class="menu-items">{{'viewFolderDetailsBtn' | translate}}</span>
                    </button>

                    <hr *ngIf="isSpace && canEditSpace">
                    <button button ngbDropdownItem    class="btn-menu"   (click)="openInDrive()">
                      <span>  <i class="gg-arrow-top-right-r"></i> </span>
                     <span class="menu-items"> {{ 'openFolderDriveTT' | translate }}</span>
                    </button>

                    <button button ngbDropdownItem  class="btn-menu"  (click)="openParentFolder()">
                      <span>  <i class="gg-folder"></i> </span>

                     <span class="menu-items"> {{ 'openDestination' | translate }}</span>
                    </button>
                    <ng-container  *ngIf="canEditSpace">
                      <ng-container *ngFor="let t of tables">
                        <button button ngbDropdownItem  class="btn-menu"   routerLink="{{t.routeSpacedUrl}}">
                           <span> <i class="gg-menu-oreos"  [ngClass]="{ disabledIcon: isDrive }"></i></span>
                           <span class="menu-items"> {{ t.title | translate }} </span>

                        </button>
                       </ng-container>

                    </ng-container>

                  </div>
                </div>


              <div *ngIf="false" ngbDropdown class="dropdown my-auto" placement="bottom-right">
                  <div type="button" id="dropdownMenuButton" aria-expanded="false" ngbDropdownToggle>
                      <i style="font-size: 1.4rem;" class="bi bi-three-dots-vertical"></i>
                  </div>
                  <ul ngbDropdownMenu class="dropdown-menu dropdown-menu-end"
                      aria-labelledby="dropdownMenuButton">
                      <li *ngFor="let t of tables"><a ngbDropdownItem class="dropdown-item"
                        routerLink="t.routeSpacedUrl">{{ t.title | translate }}</a></li>

                      <li><a ngbDropdownItem class="dropdown-item"
                          (click)="openFolderDetails()">
                            <i [ngClass]="{ disabledIcon: isDrive }"
                            class="material-icons-outlined menuIcon"
                            >folder_special</i>
                            {{ "setFolderTypeBtn" | translate }}</a>
                        </li>

                      <li><a ngbDropdownItem class="dropdown-item"
                          (click)="deleteFolder()">
                            <i [ngClass]="{ disabledIcon: isDrive }"
                            class="material-icons-outlined menuIcon"
                            >delete</i>
                            {{ "deleteFolderBtn" | translate }}</a>
                      </li>
                  </ul>
              </div>
          </div>
      </div> -->

  <!-- DATA GRID LIST -->
  <div *ngIf="activeTab === 'grid'" class="row grid">
    <aura-list
      [hasMenu]="true"
      [noShadow]="false"
      [columns]="3"
      verticalAlign="center"
      [data]="docListData"
    ></aura-list>
  </div>

  <!-- DATA TABLE -->
  <div *ngIf="activeTab === 'table' && !isDrive" class="row list hide">
    <div class="col">
      <aura-table-row [data]="dataRows" [columns]="columns"></aura-table-row>
    </div>
  </div>

  <!-- MENU : FOLDER Links -->
  <div *ngIf="pdoc && monDrive && !isDrive">
    <div class="row py-3">
      <div class="col icons d-flex justify-content-end gap-2 view-icons">
        <div ngbDropdown class="d-inline-block">
          <i
            class="menuBtn ms-auto me-0 d-block material-icons-round"
            id="dropdownBasic1"
            ngbDropdownToggle
            >more_vert</i
          >

          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="menu-content"
          >
            <button
              ngbDropdownItem
              class="btn-menu"
              *ngIf="isSpace && canEditSpace"
              (click)="openFacetsPanel()"
            >
              <span><i class="gg-info"></i></span>
              <span class="menu-items">{{
                "viewFolderDetailsBtn" | translate
              }}</span>
            </button>

            <hr *ngIf="isSpace && canEditSpace" />
            <button
              button
              ngbDropdownItem
              class="btn-menu"
              (click)="openInDrive()"
            >
              <span> <i class="gg-arrow-top-right-r"></i> </span>
              <span class="menu-items">
                {{ "openFolderDriveTT" | translate }}</span
              >
            </button>

            <button
              button
              ngbDropdownItem
              class="btn-menu"
              (click)="openParentFolder()"
            >
              <span> <i class="gg-folder"></i> </span>

              <span class="menu-items">
                {{ "openDestination" | translate }}</span
              >
            </button>
            <ng-container *ngIf="canEditSpace">
              <ng-container *ngFor="let t of tables">
                <button
                  button
                  ngbDropdownItem
                  class="btn-menu"
                  routerLink="{{ t.routeSpacedUrl }}"
                >
                  <span>
                    <i
                      class="gg-menu-oreos"
                      [ngClass]="{ disabledIcon: isDrive }"
                    ></i
                  ></span>
                  <span class="menu-items"> {{ t.title | translate }} </span>
                </button>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <div
          *ngIf="false"
          ngbDropdown
          class="dropdown my-auto"
          placement="bottom-right"
        >
          <div
            type="button"
            id="dropdownMenuButton"
            aria-expanded="false"
            ngbDropdownToggle
          >
            <i style="font-size: 1.4rem" class="bi bi-three-dots-vertical"></i>
          </div>
          <ul
            ngbDropdownMenu
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdownMenuButton"
          >
            <li *ngFor="let t of tables">
              <a
                ngbDropdownItem
                class="dropdown-item"
                routerLink="t.routeSpacedUrl"
                >{{ t.title | translate }}</a
              >
            </li>

            <li>
              <a
                ngbDropdownItem
                class="dropdown-item"
                (click)="openFolderDetails()"
              >
                <i
                  [ngClass]="{ disabledIcon: isDrive }"
                  class="material-icons-outlined menuIcon"
                  >folder_special</i
                >
                {{ "setFolderTypeBtn" | translate }}</a
              >
            </li>

            <li>
              <a ngbDropdownItem class="dropdown-item" (click)="deleteFolder()">
                <i
                  [ngClass]="{ disabledIcon: isDrive }"
                  class="material-icons-outlined menuIcon"
                  >delete</i
                >
                {{ "deleteFolderBtn" | translate }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- DATA GRID LIST -->
    <div *ngIf="activeTab === 'grid'" class="row grid">
      <aura-list
        [hasMenu]="true"
        [noShadow]="false"
        [columns]="3"
        verticalAlign="center"
        [data]="docListData"
      ></aura-list>
    </div>

    <!-- DATA TABLE -->
    <div *ngIf="activeTab === 'table' && !isDrive" class="row list hide">
      <div class="col">
        <aura-table-row [data]="dataRows" [columns]="columns"></aura-table-row>
      </div>
    </div>
  </div>

  <!-- MENU : FOLDER Links -->
  <div *ngIf="pdoc && monDrive">
    <div class="row py-3">
      <div class="col icons d-flex justify-content-end gap-2 view-icons">
        <div ngbDropdown class="d-inline-block">
          <i
            class="menuBtn ms-auto me-0 d-block material-icons-round"
            id="dropdownBasic1"
            ngbDropdownToggle
            >more_vert</i
          >

          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="menu-content"
          >
            <button ngbDropdownItem (click)="openInDrive()" class="btn-menu">
              <span> <i class="gg-arrow-top-right-r"></i> </span>
              <span class="menu-items">
                {{ "openFolderDriveTT" | translate }}</span
              >
            </button>

            <button
              ngbDropdownItem
              data-bs-toggle="modal"
              class="btn-menu"
              data-bs-target="#exModal"
              (click)="addFolderModal = true"
            >
              <span> <i class="gg-folder-add"></i> </span>
              <span class="menu-items"> {{ "newFolder" | translate }}</span>
            </button>
            <app-add-folder
              [isOpen]="addFolderModal"
              (close)="addFolderModal = false"
              [parentId]="currentId"
            ></app-add-folder>

            <hr *ngIf="isSpace && canEditSpace" />
            <ng-container *ngIf="isSpace && canEditSpace">
              <ng-container *ngFor="let t of tables">
                <button
                  ngbDropdownItem
                  class="btn-menu"
                  routerLink="{{ t.routeSpacedUrl }}"
                >
                  <span>
                    <i
                      class="gg-folder"
                      [ngClass]="{ disabledIcon: isDrive }"
                    ></i
                  ></span>
                  <span class="menu-items"> {{ t.title | translate }} </span>
                </button>
              </ng-container>
            </ng-container>

            <button
              ngbDropdownItem
              (click)="openFacetsPanel()"
              class="btn-menu"
              *ngIf="canEditSpace"
            >
              <span
                ><i [ngClass]="{ disabledIcon: !isDrive }" class="gg-folder"></i
              ></span>
              <span class="menu-items">
                {{ "setFolderTypeBtn" | translate }}</span
              >
            </button>

            <button
              ngbDropdownItem
              class="btn-menu"
              (click)="deleteFolder()"
              *ngIf="canDelete"
            >
              <span style="margin-left: 6px !important">
                <i class="gg-trash" [ngClass]="{ disabledIcon: isDrive }"></i>
              </span>
              <span class="menu-items">
                {{ "deleteFolderBtn" | translate }}
              </span>
            </button>
          </div>
        </div>

        <!--  <div class="menu-account" [ngClass]="activeAccountMenu ? ' on ' : ''">
            <div class="user-photo" (click)="toggleAccountMenu()">
              <i class=" menuBtn ms-auto me-0 d-block  material-icons-round">more_vert</i>

            </div>
            <div class="menu">
              <ul>
                <li  >
                  <a href="javascript:;"    (click)="openInDrive()">
                    <span>  <i class="gg-arrow-top-right-r"></i> </span>
                   <span class="menu-items"> {{ 'openFolderDriveTT' | translate }}</span>
                  </a>

               </li>
               <li  >
                <a   data-bs-toggle="modal"
                data-bs-target="#exModal" (click)="addFolderModal = true" >
                  <span>  <i class="gg-folder-add"></i> </span>
                 <span class="menu-items"> {{ 'newFolder' | translate }}</span>
                </a>

             </li>

             <app-add-folder [isOpen]="addFolderModal" (close)="addFolderModal = false" [parentId]="currentId"></app-add-folder>

               <hr *ngIf="isSpace">
               <li *ngFor="let t of tables">
                <a href="javascript:;"   routerLink="{{t.routeSpacedUrl}}">
                   <span> <i class="gg-folder"  [ngClass]="{ disabledIcon: isDrive }"></i></span>
                   <span class="menu-items"> {{ t.title | translate }} </span>

                </a>
               </li>
               <li *ngIf="!isDrive">
                  <a href="javascript:;"   (click)="openFacetsPanel()" >
                    <span><i [ngClass]="{ disabledIcon: !isDrive }"class="gg-folder" ></i></span>
                    <span class="menu-items">  {{ "setFolderTypeBtn" | translate }}</span>
                  </a>
               </li>
                <li *ngIf="canEditSpace">
                 <a href="javascript:;"     (click)="deleteFolder()"   >
                  <span style="margin-left: 6px !important;">     <i class="gg-trash" [ngClass]="{ disabledIcon: isDrive }"></i> </span>
                <span  class="menu-items"> {{ "deleteFolderBtn" | translate }} </span>
                </a>
               </li>

              </ul>

            </div>
          </div> -->
      </div>
    </div>

    <app-table-space (emitState)="getSelectedRow($event)"></app-table-space>
  </div>

  <!-- ================ RIGHT SIDE (NB. move to design system...) ============== -->
  <mat-drawer #drawer class="rightdrawer" mode="side" position="end">
    <div *ngIf="!showFolderDetails && isDocument">
      <mat-card>
        <mat-card-header>
          <mat-card-title>
            <mat-toolbar class="card-title">
              <span style="word-wrap: break-word">{{
                selectedDocument?.Name
              }}</span>
              <span class="app-spacer"></span>
              <button
                mat-flat-button
                class="openDocBtn"
                style="vertical-align: middle"
                (click)="openFileOrFolder()"
              >
                {{ "openBtn" | translate | uppercase }}
              </button>
              <button
                mat-icon-button
                style="vertical-align: middle"
                (click)="drawer.close()"
              >
                <mat-icon style="color: white">close</mat-icon>
              </button>
            </mat-toolbar>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content *ngIf="docinGed" style="padding: 0px 5px">
          <mat-tab-group
            [ngStyle]="{ backgroundColor: white }"
            [color]="'accent'"
          >
            <mat-tab label="INFO">
              <app-space-doc-desc
                [document]="selectedDocument"
              ></app-space-doc-desc>
              <app-space-doc-meta
                [document]="selectedDocument"
              ></app-space-doc-meta>
            </mat-tab>
            <mat-tab label="PREVIEW">
              <div style="text-align: center">
                <img [src]="previewLink" class="previewLink" />
              </div>
            </mat-tab>
            <mat-tab label="LINKS" *ngIf="showLinks">
              <app-space-doclinks
                [document]="selectedDocument"
                (emitState)="doSomething($event)"
              ></app-space-doclinks>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>

        <mat-card-content
          *ngIf="!docinGed"
          style="display: block; padding: 5px"
        >
          <mat-list>
            <div>
              <mat-list-item class="listitem" role="listitem">
                <span class="label">Name</span>
                <span>{{ selectedDocument?.name }}</span>
              </mat-list-item>
              <mat-divider></mat-divider>
              <mat-list-item class="listitem" role="listitem">
                <span class="label">Owner</span>
                <span>{{ selectedDocument?.Owner }}</span>
              </mat-list-item>
            </div>
          </mat-list>
          <div style="text-align: center">
            <img [src]="selectedDocument?.thumbnailLink" style="width: 100%" />
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div *ngIf="showFolderDetails || !isDocument" class="desc-space">
      <div>showFolderDetails</div>

      <div *ngIf="isSpace">
        <app-space-desc [currentSpace]="fulldata"></app-space-desc>
      </div>
      <div *ngIf="!isSpace">
        <app-folder-add></app-folder-add>
      </div>
    </div>
  </mat-drawer>

  <!-- RIGHT SIDE PANEL : FACETS -->

  <div class="right-aside" [ngClass]="isToggled ? 'on' : ''">
    <div class="wrap" *ngIf="showFolderDetails || !isDocument">
      <div class="row facets-title-div" *ngIf="isSpace">
        <p class="facets-title">{{ "Espace" | translate }}</p>
        <div
          class="ps-0 d-flex justify-content-end ms-auto me-0"
          style="width: fit-content"
        >
          <button
            type="button"
            class="btn-close ng-star-inserted"
            (click)="closePanel()"
          ></button>
        </div>
        <app-space-desc
          [currentSpace]="fulldata"
          [canEditSpace]="canEditSpace"
        ></app-space-desc>
      </div>

      <div class="row facets-title-div" *ngIf="!isSpace">
        <p class="facets-title">{{ "addtoGedTitle" | translate }}</p>
        <div
          class="ps-0 d-flex justify-content-end ms-auto me-0"
          style="width: fit-content"
        >
          <button
            type="button"
            class="btn-close ng-star-inserted"
            (click)="closePanel()"
          ></button>
        </div>
        <app-folder-add></app-folder-add>
      </div>
    </div>
  </div>

  <div class="overlay" (click)="toggleRightSidebar()"></div>
</app-sidenav>
