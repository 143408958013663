<aura-modal [isOpen]="isOpen" (close)="closeModal()" [title]="title">

    <div class="steps step-1" *ngIf="currentStep == 1">
        <aura-input-text className="mb-5" label="Name your Department " placeholder="Name" size="large">
        </aura-input-text>
        <div class="row justify-content-between gx-2">
            <div class="col-md-4 col-6">
                <button auraButton label="Next" (click)="nextStep()" [full]="true"  type="primary"></button>
            </div>
        </div>
    </div>

    <!-- ----------------------------------------- -->

    <div class="steps step-2 " *ngIf="currentStep == 2">
        <aura-select-icon></aura-select-icon>

        <aura-input-color-picker></aura-input-color-picker>

        <div class="row justify-content-between gx-2">
            <div class="col-md-4 col-6">
                <button auraButton label="Back" (click)="prevStep()" [hasShadow]="true" [full]="true"
                    type="light">
                </button>
            </div>
            <div class="col-md-4 col-6">
                <button auraButton label="Next" (click)="nextStep()" [full]="true"  type="primary"></button>
            </div>
        </div>
    </div>

    <!-- ----------------------------------------- -->

    <div class="steps step-3 " *ngIf="currentStep == 3">

        <div class="selected-icon d-flex flex-column mb-4">
            <span class="h2">Share only with</span>
        </div>

        <aura-select-user [usersList]="usersList" [selectedUsers]="selectedUsers"></aura-select-user>

        <div class="row justify-content-between gx-2">
            <div class="col-md-4 col-6">
                <button auraButton label="Back" (click)="prevStep()" [hasShadow]="true" [full]="true"
                    type="light">
                </button>
            </div>
            <div class="col-md-4 col-6">
                <button auraButton label="Next" (click)="nextStep()" [full]="true"  type="primary"></button>
            </div>
        </div>
    </div>


    <!-- ----------------------------------------- -->

    <div class="steps step-4 " *ngIf="currentStep == 4">
        <div class="selected-icon d-flex flex-column mb-4">
            <span class="h2">Share only with</span>
        </div>

        <aura-select-user [usersList]="usersList" [selectedUsers]="selectedUsers2" (change)="onChange()">
        </aura-select-user>

        <div class="row justify-content-between gx-2">
            <div class="col-md-4 col-6">
                <button auraButton label="Back" (click)="prevStep()" [hasShadow]="true" [full]="true"
                    type="light">
                </button>
            </div>
            <div class="col-md-4 col-6">
                <button auraButton label="Next" [full]="true"  type="primary"></button>
            </div>
        </div>
    </div>
</aura-modal>
