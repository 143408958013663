import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { DocumentService } from "../../services/document.service";
import { Link } from "../../models/link/link.model";
import { environment } from "src/environments/environment";
import { LinkData } from "../../models/link/link-data.model";
import { MatDialog } from "@angular/material/dialog";
import { DocTypeAddComponent } from "../doc-type-add/doc-type-add.component";
import { LinkAdd } from "../../models/link-add/link-add.model";
import { ListItem } from "@interfaces/list-item";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

const themePath = environment.themePath;

export interface Section
{
   name: string;
   updated: Date;
}
@Component({
  selector: "app-doc-links",
  templateUrl: `./${themePath}/doc-links.component.html`,
  styleUrls: [`./${themePath}/doc-links.component.scss`],
})
export class DocLinksComponent implements OnInit, OnChanges
{
   @Input("path") path;
   @Input("gedDoc") gedDoc;
   @Input("canEdit") canEdit;

   @Input() isOpen: boolean = false;
   @Output() close = new EventEmitter<any>();
   @Output() emitState: EventEmitter<any> = new EventEmitter();
   @Output() emitAdd: EventEmitter<any> = new EventEmitter();



   isLoading = true;
   moreDetailsModal: boolean = false;
   currentDocId = localStorage.docId;
   defaultPicture = environment.defaultPictureDoc;
   doc;
   responseDoc;
   docUpdate;
   docType;
   doclinks;
   documentLinks: Link[] = [];
   parentUid = null;
   links = [];
   links2 = []
   showLinks = false;
   filteredLinks2: any =
     {
       title: "",
       name: "",
       data: [],
       add: [],
     };
   LinkData: LinkData;

   linkAdd: LinkAdd ;
   CurentDoc;
   linkedDocs2: { category: string; docs: ListItem[] }[] = []
   displayProperties: ListItem[] = [];

   static instance: DocLinksComponent;

   constructor(
      private docService: DocumentService,
      private router: Router,
      public dialog: MatDialog,
      private route: ActivatedRoute,
      private translate: TranslateService
   )
   {
     DocLinksComponent.instance = this;
   }

   ngOnChanges(changes: SimpleChanges): void
   {
     // this.gedDoc = this.gedDoc.currentValue
     this.currentDocId = localStorage.docId;
     this.getDoc();
     this.getLinks();
   }

   ngOnInit(): void
   {
   }

   _(s : string) : string 
   {
     return this.translate.instant(s);
   }

   /*  ngOnInit(): void {
      this.currentDocId = localStorage.docId;
      this.getDoc();
      this.getLinks();
    } */

   closeModal()
   {
     this.close.emit();
   }

   getDoc()
   {
     this.doc = this.gedDoc.content.data;
   }

   async alphabeticalOrder(array:[])
   {
     return array.sort((a, b) => (a < b ? -1 : 1));
   }

   data3 = { add: [] };
   getLinks()
   {
     this.documentLinks = []
     const data = this.gedDoc.content.links;

     this.isLoading = false;

     let dataEmit =
      {
        Add: [],
        parentUid: ""
      };

     if (data != undefined && data != null)
     {
       const data_Links = this.docService.filterLinks(data);

       const filteredLinks = [];

       data_Links.forEach((item) =>
       {
         const q =
            [
              ...new Map(item.data.map((obj) => [JSON.stringify(obj), obj])).values(),
            ];

         this.filteredLinks2.title = this._( item.title);
         this.filteredLinks2.name = item.name;
         const res = q.filter((obj) => obj["name"] !== null);

         if (item.add)
         {
           if (item.add[0].menuSection !== "")
           {

             if (item.add[0].parentUidFrom !== "current_doc")
             {
               const data4 = item?.data || [];

               this.parentUid = (data4?.length != 0) ? data4[0]?.uid : null;
               this.data3.add = item.add;

             }
             else
             {
               this.parentUid = this.doc.uid;
             }

             dataEmit =
                  {
                    Add: item.add,
                    parentUid: this.parentUid
                  }

           }

           else
           {
             this.parentUid = this.doc.uid;
           }
           //delete item["add"];
         }


         this.filteredLinks2.data = res;

         this.filteredLinks2.add = item.add;

         if (item.add)
         {
           dataEmit =
               {
                 Add: item.add,
                 parentUid: this.parentUid
               }
         }


         this.filteredLinks2.data.sort((a, b) => (a.name > b.name ? 1 : -1));

         this.documentLinks.push(new Link(this.filteredLinks2));


         this.setDisplayProperties(this.documentLinks)
       });

       const first = this.documentLinks.find((obj) =>
       {
         if (this.data3.add)
         {
           if (this.data3.add[0])
           {
             if (obj.name === this.data3.add[0].menuSection)
             {
               obj.add = this.data3.add;
             }
             if ( this.data3.add[0].title.includes(obj.name)|| this.data3.add[0].name.includes(obj.title) )
             {
               delete obj["add"];
             }
           }
         }


       });

       this.linkAdd = new LinkAdd(dataEmit)
       this.emitAdd.emit(this.linkAdd);

     }

     if (this.documentLinks.length == 0)
     {
       this.showLinks = false;
     }
     else
     {
       this.showLinks = true;
     }

     this.emitState.emit(this.showLinks);
   }


   toLink(linkdoc)
   {

     const url  = this.router.url;

     const UrlRelative = url.slice(0,url.indexOf('/file/'))


     this.docService.toLink(linkdoc , UrlRelative)
   }

   getUrlRelative()
   {
     const url  = this.router.url;

     const UrlRelative = url.slice(0,url.indexOf('/file/'))

     return UrlRelative;

   }
   //Create new Document Type
   openDocumenttypeDialog(dataAdd): void
   {
     const dialogRef = this.dialog.open(DocTypeAddComponent,
       {
         width: "500px",
         minWidth: "650px",
         height: "max-content",
         data:
         {
           title: environment.stringsFile.newDoc,
           add: dataAdd,
           parentId: this.parentUid,
         },
       });

     dialogRef.afterClosed().subscribe((result) => 
     { });
   }

   //get Liste docuement Links.
   setDisplayProperties(results)
   {
     Object.values(results).forEach(doc =>
     {
       this.linkedDocs2.push(doc['displayProperties'][0])
     })
   }
}
