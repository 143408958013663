
export interface IEvent {
    resourceID: string
    type: IEventTypeEnum
    user: IUser,
    date: string | Date
    options: IOption[]
}

export enum IEventTypeEnum {
    flag = 'flag',
    verify = 'verify'

}
export interface IOption {
    option: string
    selected: boolean
}
export interface IUser {
    /** The user's email address. */
    email: string;
    firstName: string;
    lastName: string;
}