<app-sidenav>
  <mat-drawer-container class="example-container search-Content">
    <div class="row layout-row">
      <div [ngClass]="{'col-5' : gpeoples?.length!=0 || events?.length!=0   ||  gcontact2?.length!=0  ||  gcontact?.length!=0, 'col-12' : tableContent?.length==0 && events?.length!=0 }" >
          <div class="row ">
            
            <div [ngClass]="{'col-12 gpeople' : gpeoples?.length!=0 }"*ngIf="gpeoples?.length> 0">
            
            <mat-card>
              <mat-card-header>
                <mat-card-title  >{{'peoples' | translate}}</mat-card-title>
                <div fxFlex class="flex"></div>
                 <mat-icon class="header-icon" svgIcon="google-contacts" aria-hidden="false" (click)="toContactDirectory()"></mat-icon>
                </mat-card-header>
              <mat-list *ngIf="gpeoples.length!=0">
                 <mat-list-item *ngFor="let p of gpeoples">
                  <p matLine [ngStyle]="{'font-size':'13px'}">{{p.getName()}}</p>
                  <p matLine [ngStyle]="{'font-size':'12px'}">{{p.getEmail()}}</p>
            
                  <img matListAvatar *ngIf="!!p.getPicture()" [src]="p.getPicture()" class="img-people">
                  <div class="spareImg" *ngIf="!p.getPicture()" matListAvatar imgInitial>
                    <mat-icon>person</mat-icon>
                  </div>
                  <div class="md-secondary-container">

                    <span class="secondaryButton"  *ngIf="p.getEmail()" ><mat-icon aria-hidden="false" aria-label="Example email icon"  (click)="toMailSearch(p.getEmail())" >email</mat-icon></span>
                  </div>
                  <mat-divider></mat-divider>
            
  
                </mat-list-item>
  
              </mat-list>
  
            </mat-card>
  
  
            <div *ngIf="isLoading" class="row justify-content-center">
              <mat-spinner></mat-spinner>
  
            </div>
          </div>
          <div [ngClass]="{'col-12 gpeople' : gcontact2?.length!=0 }"*ngIf="gcontact2?.length> 0">
           
            <mat-card>
              <mat-card-header>
                <mat-card-title  >{{'contacts' | translate}}</mat-card-title>
                <div fxFlex class="flex"></div>
                 <mat-icon class="header-icon" svgIcon="google-contacts" aria-hidden="false" (click)="toContacts()"></mat-icon>
                </mat-card-header>
              <mat-list *ngIf="gcontact2.length!=0">
                <mat-list-item *ngFor="let p of gcontact2">
                  <p matLine [ngStyle]="{'font-size':'13px'}">{{p.getName()}}</p>
                  <p matLine [ngStyle]="{'font-size':'12px'}">{{p.getEmail()}}</p>
            
                  <img matListAvatar *ngIf="!!p.getPicture()" [src]="p.getPicture()" class="img-people">
                  <div class="spareImg" *ngIf="!p.getPicture()" matListAvatar imgInitial>
                    <mat-icon>person</mat-icon>
                  </div>
                  <div class="md-secondary-container">
                    <span class="secondaryButton"><mat-icon aria-hidden="false" aria-label="Example email icon"  (click)="toContactInfo(p.getEmail())" >account_circle</mat-icon></span>

                    <span class="secondaryButton" *ngIf="p.getEmail()" ><mat-icon aria-hidden="false" aria-label="Example email icon"  (click)="toMailSearch(p.getEmail())" >email</mat-icon></span>
                  </div>
                  <mat-divider></mat-divider>
            
  
                </mat-list-item>
  
              </mat-list>
  
            </mat-card>
  
  
            <div *ngIf="isLoading" class="row justify-content-center">
              <mat-spinner></mat-spinner>
  
            </div>
          </div>
          <div [ngClass]="{'col-12 gpeople' : gcontact?.length!=0 }"*ngIf="gcontact?.length> 0">
            
            <mat-card>
              <mat-card-header>
                <mat-card-title  >{{'otherContacts' | translate}}</mat-card-title>
                <div fxFlex class="flex"></div>
                 <mat-icon class="header-icon" svgIcon="google-contacts" aria-hidden="false" (click)="toContactOthers()"></mat-icon>
                </mat-card-header>
              <mat-list *ngIf="gcontact.length!=0">
                <mat-list-item *ngFor="let p of gcontact">
                  <p matLine [ngStyle]="{'font-size':'13px'}">{{p.getName()}}</p>
                  <p matLine [ngStyle]="{'font-size':'12px'}">{{p.getEmail()}}</p>
            
                  <img matListAvatar *ngIf="!!p.getPicture()" [src]="p.getPicture()" class="img-people">
                  <div class="spareImg" *ngIf="!p.getPicture()" matListAvatar imgInitial>
                    <mat-icon>person</mat-icon>
                  </div>
                  <div class="md-secondary-container">

                    <span class="secondaryButton" *ngIf="p.getEmail()" ><mat-icon aria-hidden="false" aria-label="Example email icon"  (click)="toMailSearch(p.getEmail())" >email</mat-icon></span>
                  </div>
                  <mat-divider></mat-divider>
            
  
                </mat-list-item>
  
              </mat-list>
  
            </mat-card>
  
  
            <div *ngIf="isLoading" class="row justify-content-center">
              <mat-spinner></mat-spinner>
  
            </div>
          </div>
          <div [ngClass]="{'col-12' : events?.length!=0 }" *ngIf="events?.length> 0">
            <!-- <mat-toolbar class="toolbarSearch">
              <span class="keywordsSearch">{{'event' | translate}}</span>
              <div fxFlex class="flex"></div>
              <mat-icon class="header-icon" svgIcon="event-icon" aria-hidden="false" (click)="toEvents()"></mat-icon>
            </mat-toolbar>
   -->
            <mat-card>
              <mat-card-header> 
              <mat-card-title  >{{'event' | translate}}</mat-card-title>
              <div fxFlex class="flex"></div>
               <mat-icon class="header-icon" svgIcon="event-icon" aria-hidden="false" (click)="toEvents()"></mat-icon>
              </mat-card-header>

              <mat-list *ngIf="events.length!=0" class="custom-scroll-bar-events">
  
  
                <mat-list-item *ngFor="let event of events"  >
                  <span matLine [ngStyle]="{'font-size':'13px'}" (click)="toLink(event.getHtmlLink())">{{event?.getSummary()}}</span>
                   <span matLine  [ngStyle]="{'font-size':'12px' ,'line-height':'1.5'}">{{ event?.getDate() }}</span>  
                  
                   <mat-icon   matListAvatar>event</mat-icon>
  
                  <div class="md-secondary-container">
                    <span class="secondaryButton" mat-flat-button>
                      <mat-icon aria-hidden="false" aria-label="Example videocam icon" (click)="toLink(event.getHangoutLink())" class="iconEvents">
                        videocam
                      </mat-icon>
                    </span>
                    <span class="secondaryButton" mat-flat-button>
                      <mat-icon aria-hidden="false" aria-label="Example email icon" (click)="toLink(event.getHtmlLink())" class="iconEvents">
                        open_in_new
                      </mat-icon>
                    </span>
                    <span class="secondaryButton" mat-flat-button>
                      <mat-icon aria-hidden="false" aria-label="Example search icon" (click)="eventSearch(event.getSummary())" >
                        search
                      </mat-icon>
                    </span>
  
                  </div>
                  <mat-divider></mat-divider>
  
                </mat-list-item>
  
              </mat-list>
  
            </mat-card>
  
  
            <div *ngIf="isLoading" class="row justify-content-center">
              <mat-spinner></mat-spinner>
  
            </div>
          </div>
          </div>
      </div>
      <div *ngIf="tableContent?.length!== 0"    [ngClass]="{'col-7 driveTable' : gpeoples?.length!=0 ||   events?.length!=0 ||  gcontact2?.length!=0 ||  gcontact?.length!=0 && tableContent?.length!=0, 'col-12' :searchResults?.gfrive?.length!=0 && gpeoples?.length==0 && events?.length==0 && gcontact2?.length==0  }">
        <div >

        <!-- <mat-toolbar class="toolbarSearch">
          <span class="keywordsSearch">{{'drive' | translate}}</span>
          <div fxFlex class="flex"></div>
              <mat-icon class="header-icon" svgIcon="drive_icon" aria-hidden="false" (click)="toEvents()"></mat-icon>
        </mat-toolbar>
 -->
        <mat-card class="container-table">
          <mat-card-header> 
          <mat-card-title  >{{'drive' | translate}}</mat-card-title>
              <div fxFlex class="flex"></div>
               <mat-icon class="header-icon" svgIcon="drive_icon" aria-hidden="false" (click)="toEvents()"></mat-icon>
              </mat-card-header>
          <table mat-table [dataSource]="tableContent" class="mat-elevation-z0" matSort  >

            <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let i=index ; let j = last">
          
              <th mat-header-cell *matHeaderCellDef > {{displayHeaderValeur[i]}}</th>
              
              <td mat-cell *matCellDef="let element" style="vertical-align: middle;" [ngClass]="{'namee': i==0}">
                <div class="drive-content" (click)="rowClick(element)" >
                  <img [src]="element.iconLink" class="itemIcon"  
                  *ngIf="i==0"/>
                  <span class="elementTable"  [ngClass]="{'nameCell': i==0 }"     >{{element[column]}}</span>
                </div>
                

                <span  *ngIf="j" >
                  <button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" >
                    <i class="material-icons-round">more_vert</i>
                  </button>
                  <mat-menu #menu="matMenu" >
                  
                   
                    <button mat-menu-item   class="newElementBtn" (click)="rowClick(element)"  >
                      <mat-icon  aria-hidden="true" > folder_open </mat-icon>
                         <span *ngIf="element.Type == 'Folder'">{{'folderstr' | translate}}</span> <span *ngIf="element.Type != 'Folder'">{{'fichierstr' | translate}}</span> 
                    </button>
              

                    <button mat-menu-item   class="newElementBtn" (click)="rowParent(element)"  >
                      <mat-icon  aria-hidden="true"> drive_file_move_rtl  </mat-icon>
                        <span  >{{'openParent' | translate}}</span>  
                    </button>
                    <button mat-menu-item   class="newElementBtn"  (click)="toExternalLink(element)" >
                      <mat-icon  aria-hidden="true"> open_in_new </mat-icon>
                        <span *ngIf="element.Type == 'Folder'">{{'folder' | translate}}</span> <span *ngIf="element.Type != 'Folder'">{{'fichier' | translate}}</span>  
                    </button>
                </mat-menu>
              
            </span>


              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnsToDisplay;sticky: true" style="width: 10px;"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"  class="element-row"
            [class.selectedRow]="rowIsSelected(row)" (dblclick)="onRowDblClick(row)" 
            style="cursor: pointer;"></tr>
          </table>
          <!-- (click)="onRowClick(row)" -->
        </mat-card>


        <div *ngIf="isLoading" class="row justify-content-center">
          <mat-spinner></mat-spinner>

        </div>
      </div>
      </div>


    

    </div>
    <div class="noResult" *ngIf="gpeoples?.length===0 && events?.length===0    &&   gcontact2?.length===0   &&   gcontact?.length===0 && tableContent?.length==0 && events?.length===0 " >
      <h3>{{'aucunResult' | translate }}</h3>
<!--       <button  mat-raised-button  >{{'resetFiltBtn' | translate | uppercase}}</button>
 -->
    </div>
    <mat-drawer #drawer class="rightdrawer" mode="side" position="end" opened>
      <div *ngIf="!showDocDetails" class="filterDrawer">
        
        <app-search-filters [searchResults]="tableContent"  [filterCentent]="filterCentent" (emitState)="doSomething($event)">

          
        </app-search-filters>
      </div>
      <!--<div *ngIf="showDocDetails">
        <div *ngIf="isDocument">
          <mat-card>
            <mat-card-header>
              <mat-card-title>

                <mat-toolbar class="card-title">
                  <span style="word-wrap: break-word;">{{selectedDocument.Name}}</span>
                  <span class="app-spacer"></span>
                  <button mat-flat-button class="openDocBtn" style="vertical-align:middle;"
                  (click)="openDoc()">{{'openBtn' | translate | uppercase}}</button>
                  <button mat-icon-button style="vertical-align:middle;" (click)="showDocDetails=false">
                    <mat-icon style="color:#ffffff">close</mat-icon>
                  </button>
                </mat-toolbar>
              </mat-card-title>
            </mat-card-header>
            <mat-card-content *ngIf="docinGed" style="padding: 0px 5px;">
              <mat-tab-group [ngStyle]="{'backgroundColor' : 	'#ffffff'}" [color]="'accent'">
                <mat-tab label="INFO">
                  <app-space-doc-desc [document]="selectedDocument"></app-space-doc-desc>
                  <app-space-doc-meta [document]="selectedDocument"></app-space-doc-meta>
                </mat-tab>
                <mat-tab label="PREVIEW">
                  <img [src]="previewLink" class="previewLink">
                </mat-tab>
                <mat-tab label="LINKS" *ngIf="showLinks">
                  <app-space-doclinks [document]="selectedDocument"  (emitState)="doSomething2($event)"></app-space-doclinks>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>

            <mat-card-content *ngIf="!docinGed" style="display: block; padding: 5px;">
              <mat-list>

                  <div>
                    <mat-list-item class="listitem" role="listitem">
                      <span class="label">Name</span>
                      <span>{{selectedDocument.Name}}</span>
                     </mat-list-item>
                     <mat-divider></mat-divider>
                      <mat-list-item class="listitem" role="listitem">
                        <span class="label">Owner</span>
                        <span>{{selectedDocument.Owner}}</span>
                      </mat-list-item>

                    </div>
              </mat-list>
              <div style="text-align: center;">
                <img [src]="selectedDocument?.thumbnailLink" style="width: 100%;">
              </div>
            </mat-card-content>
          </mat-card>


      </div>


      </div>-->
    </mat-drawer>
  </mat-drawer-container>

</app-sidenav>
