import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent 
{
  @Input() icon: string = '';
  @Input() label: string = '';
  @Input() badgeValue: string = '';
  @Input() activeClass: string = '';
  
  constructor() 
  { }

}
