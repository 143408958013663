<div class="">
    <div class="row pb-3">
        <div class="col icons d-flex justify-content-end gap-2 view-icons">
            <img [src]="'/assets/images/view-list.svg#' + (activeTab == 'table' ? 'active' : '')"
                (click)="toggleView('table')">
            <img [src]="'/assets/images/view-cols.svg#' + (activeTab == 'grid' ? 'active' : '')"
                (click)="toggleView('grid')">
        </div>
    </div>
    <div *ngIf="activeTab === 'grid'" class="row grid">
        <div class="col-12 col-md-6 col-lg-3">
            <div class="box">
              <!-- <aura-title>Urgent</aura-title>

                <aura-list [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList"></aura-list> -->
                <aura-list [hasGlobalMenu]="true" [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList" title="Urgent"></aura-list>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3  mt-5 mt-md-0">
            <div class="box">
              <!-- <aura-title>To do</aura-title>

                <aura-list [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList"></aura-list> -->
                <aura-list [hasGlobalMenu]="true" [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList" title="To do"></aura-list>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-5 mt-lg-0">
            <div class="box">
              <!-- <aura-title>To edit</aura-title>

                <aura-list [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList"></aura-list> -->
                <aura-list [hasGlobalMenu]="true" [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList" title="To edit"></aura-list>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-5 mt-lg-0">
            <div class="box">
              <!-- <aura-title>Done</aura-title>

                <aura-list [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList"></aura-list> -->
                <aura-list [hasGlobalMenu]="true" [globalMenuItems]="globalMenu" verticalAlign="center" [hasMenu]="true"
                    [data]="urgentList" title="Done"></aura-list>
            </div>
        </div>
    </div>
    <div *ngIf="activeTab === 'table'" class="row list hide">
        <div class="col">
            <aura-table-row title="Assigned" [columns]="columns" [data]="data"></aura-table-row>
            <aura-table-row [disableHead]="true" title="To do" [columns]="columns" [data]="data"></aura-table-row>
            <aura-table-row [disableHead]="true" title="To Edit" [columns]="columns" [data]="data"></aura-table-row>
            <aura-table-row [disableHead]="true" title="Done" [columns]="columns" [data]="data"></aura-table-row>
            <button class="btn gray-500 w-100 border bg-transparent border-2 border-dashed">+ Add New row</button>
        </div>
    </div>
</div>
