import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GedService } from 'src/modules/app-common/services';
import { GedTask } from 'src/modules/tasks/model/ged-task.model';
import { WorkflowUserTask } from 'src/modules/workflow/models';
import { WorkflowService } from 'src/modules/workflow/services';
import { TableColumn, TableRow } from 'src/design-system/core/interfaces/table';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DocumentService } from '../../services';


@Component({
  selector: 'app-doc-list-completed-tasks',
  templateUrl: './doc-list-completed-tasks.component.html',
  styleUrls: ['./doc-list-completed-tasks.component.scss']
})
export class DocListCompletedTasksComponent implements OnInit 
{

  @Input("path") path;
  @Input() set _gedDoc(gedDoc) 
  {
    this.gedDoc = gedDoc;
  }
  @Output() htState: EventEmitter<any> = new EventEmitter();

  isLoadingState = true;
  gedDoc;
  historiques = [];
  doc;
  currentDocId: string;
  isRoleUser = true;
  pending: any;
  workflowRoles = null;
  showWorkflow = true;
  showState = true;
  revision: string;

  //workflow variables
  currentTask: WorkflowUserTask;
  workflowTasks: WorkflowUserTask[];
  workflowState: string;

  isLoading = true;
  dataTable: TableRow[][] = [];

  
  lastVersion;
  revisions;
  tasks: GedTask[];

  columns: TableColumn[] = [
    {
      id: 'index',
      type: 'Text',
      width: 100,
      class: 'custom-index'
    },
    {
      id: 'action',
      type: 'Text',
      width: 260,
      label: environment.stringsFile.Action,
      canSort: true,
      class: 'custom-column'
    },
    {
      id: 'state',
      type: 'TextWithIcon',
      label: environment.stringsFile.StateTable,
      canSort: true,
      class: 'custom-state',
    },
    {
      id: 'by',
      type: 'Text',
      label: environment.stringsFile.By,
      canSort: true,
      class: 'custom-column',

    },
    {
      id: 'on',
      type: 'Text',
      label: environment.stringsFile.On,
      class: 'custom-column',
      canSort: true,
    },

    {
      id: 'version',
      type: 'TextWithIcon',
      label: environment.stringsFile.Version,
      canSort: true,
      width: 200,
      class: 'custom-column',
    },
    {
      id: 'numVersion',
      type: 'Text',
      class: 'custom-column',
    },
  ];

  constructor(
    private gedService: GedService,
    public datepipe: DatePipe,
    private workflowService: WorkflowService,
    private route: ActivatedRoute,
    private documentService: DocumentService,
  ) 
  {
    this.isLoading = true;
    this.currentDocId = localStorage.docId;
  }

  ngOnInit(): void 
  {
    this.route.params.subscribe(params => 
    {
      const id = params["docId"];

      this.currentDocId = id;
      if (this.gedDoc?.content?.data) 
      {
        this.initData(this.currentDocId, this.gedDoc.content.data);
      }
    });
  }


  getDisplayDataTable(task, i): TableRow[] 
  {
    let taskTable: TableRow[];

    taskTable =
      [
        {
          id: 'index',
          value: i,

        },
        {
          id: 'action',
          value: task?.data.actionLabel || "",

        },
        {
          id: 'state',
          value: task.data.fromStateLabel || "",
        },
        {
          id: 'by',
          value: task.data.from_user?.html || "",
        },
        {
          id: 'on',
          value: this.getTime(task.data?.date_creation?.time),
        },
        {
          id: 'version',
          value: '',
          iconImg:  this.documentService.getGoogleIconFrom16(task.revisionDesc?.iconLink),
          onClick: () => this.revisionDrive(task.revisionDesc?.webViewLink),

        },
        {
          id: 'numVersion',
          value: 'v' + task.revId,
        }
      ];
    i++;
    
    return taskTable;
  }

  async initData(currentDocId, docData) 
  {
    if (this.gedDoc != undefined) 
    {
      this.doc = docData;

      const workflow = await this.workflowService.getWorkflow(currentDocId);

      this.buildTasksRevisions(this.doc.oid, this.doc.uid);

      this.buildWorkflow(workflow);
    }

    this.isLoading = false;
  }

  async buildWorkflow(workflow) 
  {
    this.workflowRoles = workflow.getRoles();
    this.workflowTasks = workflow.getTasks();
    this.workflowState = workflow.getCurrentState();

    if (this.workflowTasks)
    {
      for (const task of this.workflowTasks) 
      {
        const userOid = task.getUserOid();

        if (userOid) 
        {
          const picture = await await this.workflowService.getUserPictureUrl(userOid);

          task.setPicture(picture);
        }

        this.historiques.push(task);
      }
    }
  }

  async buildTasksRevisions(currentDocId, uid) 
  {
    let revisions, tasks = null;

    // load tasks and revisions
    const history = await this.gedService.getDocHistory(uid);

    if (!history) 
    {
      return; 
    }


    {
      this.revisions = revisions = history.revisions;
      tasks = history.tasks;
    }

    // const revisions = await this.gedService.getDocRevisions(currentDocId);
    // this.revision = this.lastVersion = revisions && revisions[0] ||null;

    // create revision lookup by v.rev id
    const revisionById = {};

    revisions.forEach(rev => 
    {
      const { version, revision } = rev.properties;
      const revId = "" + version + "." + revision;

      revisionById[revId] = rev;
    })

    // build tasks
    let index = 0;

    this.tasks = (tasks || []).map(t => new GedTask(t, index++));

    // Sort the tasks array using the custom sorting function
    this.tasks = this.tasks.sort(compareClosingDate);

    // and attach revisions to tasks
    this.tasks.forEach(t => 
    {
      const revId = t.getRevisionId();

      if (revisionById[revId])
      {
        t.setRevisionDesc(revisionById[revId]);
      }
    })
    let i = 1;

    for (const t of this.tasks) 
    {
      this.dataTable.push(this.getDisplayDataTable(t, i)); i++;

    }

    this.htState.emit(this.tasks);
  }

  getTaskLabel(task) 
  {
    return task.actionLabel;
  }

  getTime(revision) 
  {
    if (!revision ) 
    {
      return '';
    }
  
    const date = new Date(revision);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${month}/${day}/${year} ${environment.stringsFile.At} ${hours}:${minutes}:${seconds}`;
  }
  

  revisionDrive(link) 
  {
    window.open(link, "_blank");
  }

  showFilrstletter(name) 
  {
    const x = name.charAt(0);

    return x;
  }
}

// sort by task.date_closing.time, descending, and if no closing date set it last
function compareClosingDate(a, b) 
{
  const dateA = a.data.date_closing && a.data.date_closing.time || a.data.date_creation && a.data.date_creation.time || 0;
  const dateB = b.data.date_closing && b.data.date_closing.time || b.data.date_creation && b.data.date_creation.time || 0;

  // Sort by date_closing in ascending order
  return dateA - dateB;
}


