<div class="container-fluid">
    <div class="row mb-5">
        <aura-list titleSize="h1" [data]="infos" [columns]="4" verticalAlign="center"></aura-list>
    </div>



    <div class="row mb-5">
        <div class="col canvas">
            <h5>Total progress</h5>
            <aura-chart-line [labels]="labels" [datasets]="datasets"></aura-chart-line>
            <h5>Department Performance</h5>

        </div>

    </div>




    <form class="row mb-3">
        <div class="col-3">
            <aura-input-text placeholder="Search by name" size="large" className="border-0 shadow" id="sb-name">
            </aura-input-text>
        </div>
        <div class="col-3">
            <aura-input-text placeholder="Search By Departments" size="large" className="border-0 shadow"
                id="sb-department">
            </aura-input-text>
        </div>
        <div class="col-3">
            <aura-input-text placeholder="Search By Space" size="large" className="border-0 shadow" id="sb-department">
            </aura-input-text>
        </div>
        <div class="col-3">
            <button auraButton label="Search" [full]="true" size="large"></button>
        </div>
    </form>
    <div class="">
        <aura-list [hasMenu]="true" [columns]="4" verticalAlign="center" [data]="departments"></aura-list>
    </div>


</div>
