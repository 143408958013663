import { MatSnackBar } from "@angular/material/snack-bar";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GenericPageService } from "./service/crud-component.service";
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
import { Crumb } from "src/modules/app-common/components/bread-crumb/bread-crumb.component";

interface State {
  title: string;
  url: string;
  template: string;
  id: string;
}

@Component({
  selector: "app-crud-component",
  templateUrl: "./crud-component.component.html",
  styleUrls: ["./crud-component.component.css"],
})
export class CrudComponent implements OnInit 
{
  state: State;
  inputData: any = null;
  loading: boolean = true;
  bc : Crumb[] = [];
  currentName: string;
  inGed = true;

  constructor(
    private router: Router,
    private genericPageService: GenericPageService,
    private snackbar: MatSnackBar,
    private location: Location,
    private translate: TranslateService
  ) 
  {
  }

  async ngOnInit() 
  {
    await this.translate.get('Name').toPromise();
    const bcString = localStorage.getItem('bc'); // Retrieve the JSON string from localStorage

    if (bcString) 
    {
      this.bc = JSON.parse(bcString); // Parse the JSON string into a crumb object
    }


    if (window.history.state.data) 
    {

      this.state = window.history.state.data;
      localStorage.setItem('myData', JSON.stringify(this.state));

      this.loadData();

      return;
    }
    else
    {
      const savedData = localStorage.getItem('myData');

      if (savedData) 
      {
        this.state = JSON.parse(savedData);
        this.loadData();
      }
    }
    // this.router.navigateByUrl("/", { skipLocationChange: true });
  }

  // i8n
  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  loadData() 
  {
    console.log(this.state);

    try 
    {
      this.genericPageService
        .getById(this.state.url, this.state.id)
        .then((data) => 
        {
          if (data && data.content) 
          {
            console.log(data.content);
            console.log(this.state.url)
            this.inputData = data.content;
          }
          this.loading = false;
        });
    }
    catch (error) 
    {
      console.error("Une erreur s'est produite lors du chargement des données :", error);
      // Gérer l'erreur ici, par exemple, afficher un message d'erreur à l'utilisateur
    }
  }

  onSubmit(values) 
  {
    if (this.state.template === "edit") 
    {
      if (values) 
      {
        this.genericPageService
          .updateRecord(values, this.state.url, this.state.id)
          .then((data) => 
          {
            this.snackbar.open(
              this._("Le record a été mis à jour avec succès"),
              "",
              {
                duration: 5000,
              }
            );
            this.location.back();
          });
      }

      return;
    }

    if (this.state.template === "add") 
    {
      if (values) 
      {
        this.genericPageService
          .addRecord(values, this.state.url)
          .then((data) => 
          {
            this.snackbar.open(
              this._("Le record a été ajouté avec succès"),
              "",
              {
                duration: 5000,
              }
            );
            this.location.back();
          });
      }

      return;
    }
  }

  onCancel() 
  {
    this.location.back();
  }

}
