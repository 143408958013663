import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SearchGenericService } from 'src/modules/search-generic/services/search-generic.service';
import { GDocService, GedService } from 'src/modules/app-common/services';
import { DrivesData } from 'src/modules/spaces/models/drives-data/drives-data.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-doc-drive',
  templateUrl: './doc-drive.component.html',
  styleUrls: ['./doc-drive.component.scss']
})
export class DocDriveComponent implements OnInit, AfterViewInit, OnChanges 
{
  dataSearch: any[] = [];
  searchResponse: [];
  searchKey = new FormControl();
  allEmptyData = false;
  drivesData: any[] = [];
  curentPath: string;
  previousRouteNames: string[] = [];
  @Output() documentSelected = new EventEmitter();
  @Output() lastPath = new EventEmitter<string>();
  @Output() lastSearch = new EventEmitter<string>();

  @Input('CurrentRoute') CurrentRoute;
  @Input('CurrentSearch') CurrentSearch;

  lastRoute: string;
  nextToken: string;
  parents: any[] = [];

  constructor(
    private searchGenericService: SearchGenericService,
    private gdocService: GDocService,
    private el: ElementRef,
    private translate: TranslateService,
    private gedService: GedService,

  ) 
  { }
  ngOnChanges(changes: SimpleChanges): void 
  {

  }


  ngAfterViewInit(): void
  {
    if (this.CurrentRoute)
    {
      this.getDrivesData(this.CurrentRoute.id);
    }

    if (this.CurrentSearch)
    {
      this.onKeywordChange(this.CurrentSearch);
      this.searchKey.setValue(this.CurrentSearch)
    }
    else
    {
      this.getDrivesData('drives');

      this.searchKey.valueChanges.subscribe((value) => 
      {
        this.onKeywordChange(value);
      });

      this.execSearch('');
    }
  }


  ngOnInit(): void 
  {

  }


  /**
   * Called when a user has typed something in the search box.
   *
   * @param text texte or URL typed by user in search input box
   */
  async onKeywordChange(text : string)
  {
    console.log("Search String:", text);
    this.dataSearch = [];

    if (text.includes("/file/") || text.includes("/d/"))
    {
      try
      {
        const file = await this.getFileByUrl(text);

        if (file)
        {
          const driveData = new DrivesData();

          Object.assign(driveData, file);
          driveData.setMimeType(file.mimeType);

          this.dataSearch.push(driveData);
          console.log("found doc by URL",file,driveData);

          return;
        }
      }
      catch (error)
      {
        // file not found, fallbacks to regular search?
      }
    }

    // if not a URL, then search  as keywords
    const searchResponse = await this.execSearch(text);
    const searchResults = searchResponse?.['results'][0] || [];

    this.dataSearch = searchResults['results'].map((drive: any) =>
    {
      const driveData = new DrivesData();

      Object.assign(driveData, drive.item);
      driveData.setMimeType(drive.item.mimeType);

      return driveData;
    });
  }

  checkIfEmptyData(results) 
  {
    this.allEmptyData = (results.find(item => item.results.length != 0) == undefined) ? true : false;
  }

  /**
   * get a file by URL. The URL can be of the google drive form or presencedoc form.
   * Extracts the ID from the URL and load the file by API.
   * Returns file data for display.
   *
   * @param url file URL for loading a document
   */
  async getFileByUrl(url:string) : Promise<any>
  {
    const regex = /\/(file|d)\/([^/]+)(?:\/edit)?/;

    // extracts the uid of the document
    const match = url?.match(regex);

    console.log("match",match)
    const uid = match[2];

    if(!uid)
    {
      return null;
    }

    const file = await this.gdocService.getFile(uid);

    if (file)
    {
      return file;
    }

    return null;
  }

  /**
   * Execute a search by text in drives.
   *
   * @param text text to search in drives
   * @returns search results
   */
  async execSearch(text : string): Promise<any> 
  {

    const queryParams = {
      facets: "",
      q: text || '',
      withFolders: false,
      group: "drive"
    }
    // get text query

    // this.searchResponse = [];
    const query = queryParams['q'];

    this.lastSearch.emit(query);

    // get current group
    const group = queryParams['group'];

    this.searchResponse = await this.searchGenericService.execSearch(query, [], group, queryParams);

    return this.searchResponse
  }

  handleRowClick(item)
  {
    if(item.isDrive  || item.isFolder)
    {
      this.getDrivesData(item.id)
    }if(!item.isFolder && !item.isDrive)
    {
      this.selectDocument(item)
    }
  }

  onWindowScroll()
  {
    const elem = document.getElementById("driveData");
    const endScroll = (elem.scrollTop + elem.offsetHeight +1) >= elem.scrollHeight;

    if (endScroll && this.nextToken )
    {
      this.getDrivesData(this.lastRoute['id'],false ,this.nextToken);
    }
  }

  _(s: string): string 
  {
    return this.translate.instant(s);
  }

  async getDrivesData(routeName?: string, isSearch?: boolean , token?) 
  {
    let nextToken;

    if (token) 
    {
      nextToken = token;
    }
    else 
    {
      nextToken = null;
    }
    const defaultRoute = 'drives';

    // this.parents = [];
    this.curentPath = "";

    if (defaultRoute === routeName) 
    {

      if (this.lastRoute?.['id'] !== routeName) 
      {
        this.drivesData = [];
      }
      const response = await this.gdocService.getFolderItems(defaultRoute, nextToken);

      this.nextToken = response["nextPageToken"];
      this.curentPath = response.spaceResponse?.content?.name;
      this._(this.curentPath);
      this.lastRoute = response.spaceResponse?.content;

      if (this.curentPath === 'My Drives') 
      {
        this.curentPath = 'All Drives';
      }
      const newDrivesData = response.spaceResponse?.content?.drives?.map((drive: any) => 
      {
        const driveData = new DrivesData();

        Object.assign(driveData, { ...drive });
        driveData.setMimeType(drive.mimeType);

        return driveData;
      });

      this.drivesData = this.drivesData.concat(newDrivesData);
    }
    else if (routeName) 
    {
      if (this.lastRoute['id'] !== routeName) 
      {
        this.drivesData = [];
      }

      const response = await this.gdocService.getFolderItems(routeName, nextToken);

      this.nextToken = response["nextPageToken"];
      this.curentPath = response.spaceResponse?.content?.name;
      this._(this.curentPath);
      this.lastRoute = response.spaceResponse?.content;
      if (this.curentPath = 'Mon Drive') 
      {
        this.curentPath =  environment.stringsFile.drive_str;
      }

      const newDrivesData = response?.spaceContent?.map((drive: any) => 
      {
        const driveData = new DrivesData();

        Object.assign(driveData, { ...drive });
        driveData.setMimeType(drive.mimeType);

        return driveData;
      });

      this.drivesData = this.drivesData.concat(newDrivesData);
      const clickedItem = this.drivesData.find((item) => item.id === routeName);

      if (clickedItem) 
      {
        clickedItem.selected = true;
      }

      this.parents = await this.gdocService.loadParentsPath({ id: response.spaceResponse?.content?.id, name: response.spaceResponse?.content?.name });
    }

    const index = this.parents.findIndex(parent => parent.name === "My Drives");

    if (this.curentPath = 'Mon Drive') 
    {
      this.curentPath = 'My drive';
    }
    // If the element with the name "My Drives" is found, replace its name with "All drives"
    if (index !== -1) 
    {
      this.parents[index].name = "Drives";
    }
    if ((this.curentPath == "Drive") && this.lastRoute['id']) 
    {
      const sharedDrive = await this.gdocService.getSharedDriveByUid(this.lastRoute['id']);
      const folderName = (await sharedDrive).name();

      this.curentPath = folderName;
    }

    // Handle the search functionality and emit events
    if (isSearch) 
    {
      this.searchKey.setValue('');
      this.CurrentSearch = null;
      this.lastSearch.emit(this.CurrentSearch);
    }
    console.log("(this.curentPath",this.curentPath)
    this.lastPath.emit(this.lastRoute);
  }


  clearInput() 
  {
    this.searchKey.setValue('');
    this.CurrentSearch = null;
    this.lastSearch.emit(this.CurrentSearch);

  }

  rowClicked(item: any) 
  {

  }


  selectDocument(item) 
  {
    this.documentSelected.emit(item);
    console.log("itemitem",item)
    this.drivesData.forEach((drive) => 
    {
      drive.selected = drive === item;
    });
  }
}
