import { stringFile } from "src/assets/strings/strings";
import { GcsItem } from "./gcs-item";

export class GcsPerson extends GcsItem
{

  constructor(gcsItem)
  {
    super(gcsItem);
  }

  setIconLink(gcsItem) 
  {
    this.iconLink = '/assets/google_icons/admin_icon.svg';
  }


  getDisplayProperties() 
  {
    const self = this;

    this.displayProperties =  [
      {
        id: 'name',
        value: this.getTitle(),
        iconImg: ''
      },
      {
        id: 'details',
        value: ''
      },
      {
        id: 'menu',
        value: '',
        menuItems: [
          {
            label: stringFile.stringsJson['openLink'],
            callback: function () 
            {
              window.open(self.getWebLink(),"_blank")
            }
          },
        ],
      }
            
    ]

    return this.displayProperties;
  }
}