import { HandleSuccessComponent } from './components/handle-success/handle-success.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth.routing.module';
import { HandleFailureComponent } from './components/handle-failure/handle-failure.component';



@NgModule({
  declarations: [
    HandleFailureComponent,
    HandleSuccessComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule
  ],
  exports: [
    AuthRoutingModule,
    HandleFailureComponent,
    HandleSuccessComponent
  ]
})
export class AuthModule 
{ }
