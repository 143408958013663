import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spaceLink',
  pure: true,
  standalone: true
})
export class SpaceLinkPipe implements PipeTransform 
{

  transform(spaceID: string, type: 'space' | 'group'): unknown 
  {
    if (spaceID == 'all')
    {
      return '/s/groups/all'
    }

    let base = '/s/space/'

    if (type === 'group')
    {
      base += 'groups/'
    }

    return base + spaceID;

  }

}


// /*'/s/space/' + */space?.['uid'],
// /*'/s/space/groups/' + */espace?.['title'],
// /*'/s/groups/all' */ 'all',
